// RevenueChart.js
import React from 'react'
import Chart from 'react-apexcharts'

const RevenueForcastChart = ({ chartData, title }) => {
  const titleStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1rem',
    fontWeight: 'bold',
    height: '2rem',
    margin: '0 auto',
    width: '100%',
  }

  return (
    <div className="t-w-1/2 t-pr-2">
      <div style={titleStyle}>{title}</div>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="line"
        width="100%"
      />
    </div>
  )
}

export default RevenueForcastChart
