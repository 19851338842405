import React, { useEffect } from 'react'
import { Card, Col, Row, Dropdown, Menu, Skeleton, Tag, Tooltip } from 'antd'
import { IoIosArrowDropdownCircle } from 'react-icons/io'
import { useDispatch } from 'react-redux'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { timeInAgo } from 'utils'
import { GrView } from 'react-icons/gr'
import { useHistory } from 'react-router-dom'
import {
  getProgramInvites,
  updateClassAcceptanceStatus,
} from 'store/programSlice/programActions'
import {
  programActions,
  useProgramSelector,
} from 'store/programSlice/programReducer'

const ClassInvites = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { loading, programs } = useProgramSelector()
  const { user } = useAuthSelector()

  useEffect(() => {
    dispatch(getProgramInvites(user.email))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onAcceptClick = (programId) => {
    dispatch(updateClassAcceptanceStatus(programId, 'Accepted', user.email))
  }

  const onRejectClick = (programId) => {
    dispatch(updateClassAcceptanceStatus(programId, 'Rejected', user.email))
  }

  const onViewProgramClick = (programId) => {
    dispatch(programActions.setProgram(null))
    history.push(`/programs/${programId}`)
  }

  const customMenu = (programId, isPending) => (
    <>
      {isPending ? (
        <Menu>
          <Menu.Item
            id="acceptInvite"
            key={1}
            onClick={() => onAcceptClick(programId)}
          >
            Accept Invitation
          </Menu.Item>
          <Menu.Item
            id="rejectInvite"
            key={2}
            onClick={() => onRejectClick(programId)}
          >
            Reject Invitation
          </Menu.Item>
        </Menu>
      ) : (
        <></>
      )}
    </>
  )

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <>
          <h6 className="mb-0 t-text-2xl">My Class Invites</h6>
          <h6 className="font-regular">
            You are invited to host the following events
          </h6>
        </>,
      ]}
    >
      <div style={{ minHeight: 'calc(100vh - 250px)' }}>
        <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
          <Col span={24}>
            {programs.length === 0 && (
              <Card className="t-h-96 t-w-full t-flex t-justify-center t-items-center">
                <div>No Invites</div>
              </Card>
            )}
            <div id="presenterEventList">
              {programs.map((item, index) => (
                <Card key={index} className="w-full t-my-2 t-p-2">
                  <Row gutter={[24, 0]} className="t-flex t-justify-between">
                    <div className="font-bold t-flex t-space-x-4">
                      <div>
                        You are invited to teach the following class{' '}
                        <span className="t-italic t-text-secondary-100">
                          {item?.Title}
                        </span>
                      </div>
                      <div>
                        {item?.TeacherAcceptance === 'Accepted' && (
                          <Tag color="green" className="font-bold">
                            Accepted
                          </Tag>
                        )}
                        {item?.TeacherAcceptance === 'Rejected' && (
                          <Tag color="red" className="font-bold">
                            Rejected
                          </Tag>
                        )}
                        {(item?.TeacherAcceptance === 'Pending' ||
                          !item?.TeacherAcceptance) && (
                          <Tag color="gray" className="font-bold">
                            Pending
                          </Tag>
                        )}
                      </div>
                    </div>

                    <Dropdown
                      overlay={customMenu(
                        item?.ProgramId,
                        item?.TeacherAcceptance === 'Pending',
                      )}
                    >
                      {item?.TeacherAcceptance === 'Pending' ? (
                        <IoIosArrowDropdownCircle
                          id="inviteMenu"
                          fontSize="1.5rem"
                          className="t-text-secondary-100 t-cursor-pointer"
                        />
                      ) : (
                        <>
                          <Tooltip title="View Program">
                            <GrView
                              id="viewEvent"
                              fontSize="1.2rem"
                              className="t-text-secondary-100 t-cursor-pointer"
                              onClick={() => onViewProgramClick(item.id)}
                            />
                          </Tooltip>
                        </>
                      )}
                    </Dropdown>
                  </Row>
                  <Row gutter={[24, 0]}>
                    <Tooltip title="Start Date">
                      {item?.StartDate && timeInAgo(item.StartDate)}
                    </Tooltip>
                  </Row>
                </Card>
              ))}
            </div>
          </Col>
        </Skeleton>
      </div>
    </Card>
  )
}

export { ClassInvites }
