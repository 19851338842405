import React, { useEffect, useState } from 'react'
import {
  Button,
  Divider,
  List,
  Modal,
  Popconfirm,
  Skeleton,
  Tooltip,
  message,
} from 'antd'
import { makeRequest } from 'api'

const CategoryComponentModal = ({
  isVisible,
  onClose,
  Id,
  Name,
  enableOrDisablePaymentCategory,
}) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()
  const [fundraiserList, setFundraiserList] = useState([])
  // const data = {
  //   isUsedAnywhere: true,
  //   isUsedInOrganization: true,
  //   organizationId: 'e4326123-bdde-4f4d-9b62-b8ffdfd43af3',
  //   isUsedInFundraiser: true,
  //   fundraiserList: [
  //     {
  //       Id: 'FiT49OPrNc',
  //       Name: 'Event Prompted 2',
  //       Type: 'Event',
  //     },
  //     {
  //       Id: 'L62eef3KAz',
  //       Name: 'Image Value Test',
  //       Type: 'Campaign',
  //     },
  //   ],
  // }

  const getCategoryInUseBy = () => {
    setLoading(true)
    makeRequest('get', `/payment-categories/in-use-by/${Id}`)
      .then((res) => {
        const dummyData = res?.results
        if (dummyData) {
          setFundraiserList(dummyData.fundraiserList)
          setData(dummyData)
        }
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        message.error(error?.response?.data?.message || 'Something went wrong')
      })
  }

  useEffect(() => {
    getCategoryInUseBy(Id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const disableCategory = () => {
    onClose()
    enableOrDisablePaymentCategory(0)
  }

  const { isUsedAnywhere, isUsedInOrganization, isUsedInFundraiser } =
    data || {}

  return (
    <Modal
      centered
      title="Currently In Use By"
      open={isVisible}
      onCancel={onClose}
      footer={null}
      destroyOnClose
    >
      <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
        {data && (
          <>
            {isUsedAnywhere ? (
              <p className="t-mb-2">
                <b>Note:</b> The following events use &#39;{Name}&#39; as a
                payment category. If you disable, your users will not be able to
                make payments to this category
              </p>
            ) : (
              <p className="t-mb-2">
                &#39;{Name}&#39; is not used anywhere. You can disable this
              </p>
            )}
            <Popconfirm
              title="Are you sure you want to disable this payment category？"
              placement="top"
              //   loading={loading}
              onConfirm={() => disableCategory()}
              okText="Disable"
              cancelText="Cancel"
            >
              <Button type="primary" className="t-w-full t-p-x-4">
                Disable Payment Category
              </Button>
            </Popconfirm>
            {isUsedAnywhere && (
              <>
                <Divider />
                <p>
                  Click on the event title to view the details of each event.
                </p>
                {isUsedInOrganization && (
                  <>
                    <div className="conversations-list">
                      <List.Item key={data.organizationId}>
                        <List.Item.Meta
                          key={data.organizationId}
                          title={
                            <div
                              className="t-cursor-pointer t-text-secondary-100"
                              onClick={() =>
                                window.open(
                                  `/settings/register-organization/${data.organizationId}`,
                                  '_blank',
                                )
                              }
                            >
                              <Tooltip title="Organization Name">
                                Sherlock Inc
                              </Tooltip>
                            </div>
                          }
                          description={
                            <Tooltip title="Type">Organization</Tooltip>
                          }
                        />
                      </List.Item>
                    </div>
                  </>
                )}
                {isUsedInFundraiser && (
                  <List
                    itemLayout="horizontal"
                    dataSource={fundraiserList || []}
                    split={false}
                    className="conversations-list"
                    renderItem={(item) => (
                      <List.Item key={item.Id}>
                        <List.Item.Meta
                          key={item.Id}
                          title={
                            <div
                              className="t-cursor-pointer t-text-secondary-100"
                              onClick={() =>
                                window.open(
                                  `/settings/create-event/${item.Id}`,
                                  '_blank',
                                )
                              }
                            >
                              <Tooltip title="Fundraiser Name">
                                {item.Title}
                              </Tooltip>
                            </div>
                          }
                          description={
                            <Tooltip title="Type">{item.EventType}</Tooltip>
                          }
                        />
                      </List.Item>
                    )}
                  />
                )}
              </>
            )}
          </>
        )}
      </Skeleton>
    </Modal>
  )
}

export { CategoryComponentModal }
