import React from 'react'
import sample from 'assets/images/sample/sample1.png'
import { awsconfig } from 'configs'
import { Card } from 'antd'

const ComponentInformation = ({ item, itemType }) => {
  const getTitle = () => {
    let title = 'No Title'
    switch (itemType) {
      case 'RentalRoom':
        title = item?.room?.Name
        break
      default:
        title = item?.Title
        break
    }
    return title
  }
  const getDescription = () => {
    let description = 'No description'
    switch (itemType) {
      case 'RentalRoom':
        description = item?.room?.Instructions
        break
      default:
        description = item?.Description
        break
    }
    return description
  }
  return (
    <Card
      className="header-solid t-p-1 md:t-p-2 t-m-4 t-shadow-2xl t-rounded-lg"
      bordered={false}
    >
      <div className="t-flex t-flex-col md:t-flex-row md:t-justify-center-center md:t-space-x-8">
        <img
          src={
            item?.Flyer?.fileName
              ? awsconfig.bucketUrl + item?.Flyer?.fileName
              : item?.organization?.BackgroundImage
              ? awsconfig.bucketUrl +
                item?.organization?.BackgroundImage?.fileName
              : sample
          }
          alt="card"
          className="shadow-3 t-rounded-md t-h-44 md:t-w-[80%] t-bg-cover t-bg-no-repeat t-object-cover"
        />

        <div className="t-flex t-flex-col t-space-y-6">
          <div className="t-pt-4 md:t-pt-0">
            <h1 className="t-text-secondary-100 t-font-bold t-text-2xl">
              {item?.organization?.Name}
            </h1>
            <h1 className="t-text-secondary-100 t-font-bold t-text-lg">
              {getTitle()}
            </h1>
          </div>

          <div>
            <h1 className="t-text-xs t-text-gray-500">{getDescription()}</h1>
          </div>
        </div>
      </div>
    </Card>
  )
}

export { ComponentInformation }
