import { Button, Col, Form, Input, Row, Select, Skeleton, message } from 'antd'
import { makeRequest } from 'api'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { organizationUpdateSettingValidation } from 'validations/updateSetting'

const Settings = () => {
  const [activeKeys, setActiveKeys] = useState([])
  const [loading, setLoading] = useState(true)
  const [btnLoading, setBtnLoading] = useState(false)

  // eslint-disable-next-line
  // const [setting, setSetting] = useState({})
  const history = useHistory()
  const [form] = Form.useForm()
  const { organization } = useAuthSelector()

  const onFinish = async (values) => {
    setBtnLoading(true)
    const data = {
      RequireEmailAddress: values.RequireEmailAddress === 'Yes',
      RequireStreetAddress: values.RequireStreetAddress === 'Yes',
      DefaultDonationValues: [
        values.donationValue0?.replace('$', ''),
        values.donationValue1?.replace('$', ''),
        values.donationValue2?.replace('$', ''),
        values.donationValue3?.replace('$', ''),
      ],
      PaymentCategories: activeKeys,
      PaymentCategoryAdded: true,
    }
    try {
      const response = await makeRequest(
        'patch',
        '/webapp/organization/setting',
        { organizationId: organization.id },
        data,
      )
      message.success('Settings updated successfully!', response)
      history.push('/settings/manage-organization/organization-detail')
      setBtnLoading(false)
    } catch (error) {
      setBtnLoading(false)
      message.error('Failed to update settings.')
    }
  }

  const onFinishFailed = (errorInfo) => {
    message.error('Fail', errorInfo)
  }

  const handleBack = () => {
    history.push('/organizations')
  }

  const handleActiveKey = (key) => {
    setActiveKeys((prevActiveKeys) => {
      if (prevActiveKeys.includes(key)) {
        return prevActiveKeys.filter((k) => k !== key)
      }
      return [...prevActiveKeys, key]
    })
  }

  const getSettingData = () => {
    setLoading(true)
    makeRequest('get', '/webapp/organization?type=settings', {
      organizationId: organization?.id,
    })
      .then((res) => {
        setLoading(false)
        // setSetting(res.item)
        // Set default donation values
        const donationValues = {}
        res.item.DefaultDonationValues.forEach((value, index) => {
          donationValues[`donationValue${index}`] = `$${value}`
        })
        form.setFieldsValue({
          RequireEmailAddress: res.item.RequireEmailAddress ? 'Yes' : 'No',
          RequireStreetAddress: res.item.RequireStreetAddress ? 'Yes' : 'No',
          PaymentCategories: res.item.PaymentCategories || [],
          ...donationValues,
        })
        // Set active keys
        setActiveKeys(res.item.PaymentCategories || [])
      })
      .catch(() => {
        setLoading(false)
        message.error('Something went wrong!')
      })
  }

  useEffect(() => {
    if (organization?.id) {
      getSettingData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization.id])
  return (
    <Skeleton loading={loading} active paragraph={{ rows: 16 }}>
      <div className="t-mx-2 lg:t-mr-7 lg:t-ml-3">
        <Form
          name="basic"
          layout="vertical"
          labelCol={{
            span: 12,
          }}
          wrapperCol={{
            span: 24,
          }}
          className="t-w-[80%] sm:t-w-[100%] t-m-auto ant-roboto-font"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
        >
          <div className="t-flex t-flex-row t-justify-between t-border-b t-border-b-gray-500 t-mt-8 t-mb-8">
            <h1 className="t-text-base t-font-semibold t-text-black t-pb-1 roboto-font">
              Payment Page Settings:
            </h1>
          </div>
          <Form.Item
            label="Require Street Address"
            name="RequireStreetAddress"
            rules={[organizationUpdateSettingValidation]}
          >
            <Select defaultValue="Yes">
              <Select.Option value="Yes">Yes</Select.Option>
              <Select.Option value="No">No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Require Email Address"
            name="RequireEmailAddress"
            rules={[organizationUpdateSettingValidation]}
          >
            <Select defaultValue="Yes">
              <Select.Option value="Yes">Yes</Select.Option>
              <Select.Option value="No">No</Select.Option>
            </Select>
          </Form.Item>
          <div className="t-flex t-flex-row t-justify-between t-border-b t-border-b-gray-500 t-mt-8 t-mb-8">
            <h1 className="t-text-base t-font-semibold t-text-black t-pb-1 roboto-font">
              Org Donation Button Amount:
            </h1>
          </div>
          <Row className="t-flex t-justify-center t-item-center t-mt-4">
            <Col span={8} className="t-mx-6">
              <Form.Item
                name="donationValue0"
                rules={[organizationUpdateSettingValidation]}
              >
                <Input className="t-text-lg t-text-center t-py-8" />
              </Form.Item>
            </Col>
            <Col span={8} className="t-mx-6">
              <Form.Item
                name="donationValue1"
                rules={[organizationUpdateSettingValidation]}
              >
                <Input className="t-text-lg t-text-center t-py-8" />
              </Form.Item>
            </Col>
          </Row>
          <Row className="t-flex t-justify-center t-item-center t-mt-4">
            <Col span={8} className="t-mx-6">
              <Form.Item
                name="donationValue2"
                rules={[organizationUpdateSettingValidation]}
              >
                <Input className="t-text-lg t-text-center t-py-8" />
              </Form.Item>
            </Col>
            <Col span={8} className="t-mx-6">
              <Form.Item
                name="donationValue3"
                rules={[organizationUpdateSettingValidation]}
              >
                <Input className="t-text-lg t-text-center t-py-8" />
              </Form.Item>
            </Col>
          </Row>
          <div className="t-flex t-flex-row t-justify-between t-border-b t-border-b-gray-500 t-mt-8 t-mb-8">
            <h1 className="t-text-base t-font-semibold t-text-black t-pb-1 roboto-font">
              Active Payment Categories:
            </h1>
          </div>
          {[
            'Operations',
            'Sadaqah-Zakaat',
            'Construction',
            'School',
            'Fitrah',
            'School Projects',
          ].map((item) => (
            <Form.Item key={item} rules={[organizationUpdateSettingValidation]}>
              <div
                onClick={() => handleActiveKey(item)}
                className={`t-w-[90%] t-m-auto t-rounded-md t-my-2 t-cursor-pointer ${
                  activeKeys.includes(item)
                    ? 't-bg-[#e6f4ff] t-border-2 t-border-[#2e97de]'
                    : 't-bg-[#f3f3f3]'
                }`}
              >
                <h1 className="t-text-lg t-font-medium t-text-center t-py-4 roboto-font">
                  {item}
                </h1>
              </div>
            </Form.Item>
          ))}
          <Row className="t-flex t-justify-center lg:t-justify-end t-item-center t-mt-8">
            <Col span={8} className="t-mx-3">
              <Form.Item>
                <Button
                  htmlType="button"
                  className="t-w-[85%] t-text-[14px]"
                  onClick={handleBack}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={btnLoading}
                  className="t-w-[85%] t-text-[14px] t-bg-[#2e97de]"
                >
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Skeleton>
  )
}

export { Settings }
