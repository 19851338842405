import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Row, Skeleton, Avatar } from 'antd'
import { BankFilled } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { fetchMoreData, searchOrganizations } from 'utils'
import {
  getFavouriteBusinesses,
  removeFavourite,
} from 'store/favouriteSlice/favouriteActions'
import { useFavouriteSelector } from 'store/favouriteSlice/favouriteReducer'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { TbHeartMinus } from 'react-icons/tb'
import { awsconfig, config } from 'configs'
import InfiniteScroll from 'react-infinite-scroll-component'

const FavouriteList = ({ searchText, sortKey }) => {
  const dispatch = useDispatch()
  // const history = useHistory()

  const { user, roles } = useAuthSelector()
  const { loading, businesses } = useFavouriteSelector()
  const [current, setCurrent] = useState([])

  const searchedData = searchOrganizations(businesses, searchText)

  useEffect(() => {
    setCurrent(searchedData.slice(0, config.itemFirstLoad))
    // eslint-disable-next-line
  }, [businesses, searchText])

  const fetchData = async () => {
    const payload = {
      Username: user?.id,
      Type: 'business',
      isSub: roles?.includes('subscriber') ? 1 : 0,
    }
    dispatch(getFavouriteBusinesses(payload, sortKey))
  }
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, [dispatch, roles, sortKey])

  const handleBusinessSelected = (business) => {
    console.log('Business', business)
    // dispatch(organizationActions.setOrganization(null))
    // history.push(`businesses/${organization.id}`)
  }

  const hanleRemoveFavourite = (id) => {
    dispatch(removeFavourite(id, fetchData))
  }

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
      <div
        className="t-flex t-flex-col t-justify-between t-h-full"
        style={{ minHeight: 'calc(100vh - 270px)' }}
      >
        <InfiniteScroll
          dataLength={current.length}
          next={() => fetchMoreData(current, setCurrent, searchedData)}
          hasMore={current.length < searchedData.length}
          loader={
            <div className="t-flex t-justify-center t-items-center">
              {/* <Spin /> */}
            </div>
          }
        >
          <Row gutter={[24, 24]} className="t-h-full">
            {current.length === 0 && (
              <div
                className="t-h-96 t-flex t-justify-center t-items-center t-w-full"
                style={{ minHeight: 'calc(100vh - 300px)' }}
              >
                <div className="t-flex t-flex-col t-items-center t-space-y-2">
                  <BankFilled
                    style={{ fontSize: '2rem' }}
                    className="t-text-secondary-100"
                  />
                  <p>No records found</p>
                </div>
              </div>
            )}
            {current.map((item, index) => (
              <Col span={24} lg={8} className="mb-24" key={index}>
                <Card
                  bodyStyle={{ paddingTop: '14px' }}
                  bordered
                  className="card-project-2 header-solid"
                  title={[
                    <Row className="t-flex t-justify-between">
                      <div
                        className="t-flex"
                        style={{
                          width: '80%',
                        }}
                      >
                        <Col className="w-[40px]">
                          {item?.Logo?.fileName ? (
                            <img
                              src={awsconfig.bucketUrl + item?.Logo?.fileName}
                              alt="card-2"
                              className="t-h-10 t-w-full t-rounded-lg shadow-3 t-object-cover"
                            />
                          ) : (
                            <Avatar
                              src={<BankFilled />}
                              style={{
                                width: '40px',
                                height: '40px',
                                lineHeight: '74px',
                                borderRadius: '8px',
                                padding: '1rem',
                                backgroundColor: config.colors.primary,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            />
                          )}
                        </Col>
                        <div
                          className="t-flex t-justify-between t-flex-col t-px-2"
                          style={{
                            width: 'calc(100% - 40px)',
                          }}
                        >
                          <h6
                            className="t-font-bold t-text-2lg  t-text-secondary-100"
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                            }}
                          >
                            {item?.Name || 'No Title Found'}
                          </h6>
                          <h6
                            className="t-font-normal t-text-xs "
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                            }}
                          >
                            {`${item.City}, ${item.State}`}
                          </h6>
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <TbHeartMinus
                          fontSize="1.7rem"
                          className="t-cursor-pointer t-text-secondary-100"
                          onClick={() => hanleRemoveFavourite(item.favId)}
                        />
                      </div>
                    </Row>,
                  ]}
                >
                  <p className="t-line-clamp-1 t-text-sm">
                    {item?.Description}
                  </p>
                  <div className="t-flex t-justify-end t-mt-4">
                    <Button
                      type="link"
                      size="small"
                      className="t-rounded-full"
                      onClick={() => handleBusinessSelected(item)}
                    >
                      See More
                    </Button>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </InfiniteScroll>
      </div>
    </Skeleton>
  )
}

export { FavouriteList }
