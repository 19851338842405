import * as Yup from 'yup'

const schema = Yup.object().shape({
  RequireEmailAddress: Yup.string()
    .oneOf(['Yes', 'No'], 'Invalid value')
    .required('This field is required'),
  RequireStreetAddress: Yup.string()
    .oneOf(['Yes', 'No'], 'Invalid value')
    .required('This field is required'),
  donationValue0: Yup.string()
    .min(0, 'Donation value cannot be negative')
    .required('This field is required'),
  donationValue1: Yup.string()
    .min(0, 'Donation value cannot be negative')
    .required('This field is required'),
  donationValue2: Yup.string()
    .min(0, 'Donation value cannot be negative')
    .required('This field is required'),
  donationValue3: Yup.string()
    .min(0, 'Donation value cannot be negative')
    .required('This field is required'),
  PaymentCategories: Yup.array().of(Yup.string()),
})

const organizationUpdateSettingValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { organizationUpdateSettingValidation }
