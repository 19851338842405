import React, { useEffect, useState } from 'react'
import { message, Space, Spin, Typography } from 'antd'
import { makeRequest } from 'api'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { UserMessageView } from 'components'

const { Title } = Typography

const StripeConnect = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  // const [stripeError, setStripeError] = useState(null)
  const [apiError, setApiError] = useState(null)

  const getStripeAccount = async ({ data, code }) => {
    setLoading(true)
    try {
      const response = await makeRequest(
        'get',
        `/connect/stripe/get-oauth-stripe-account/${code}`,
      )
      if (response?.stripeId) {
        message.success(
          response?.message || 'Stripe Account Saved Successfully',
        )
        const link = getRedirectLink({ data, stripeId: response?.stripeId })
        history.push(link)
      }
      setLoading(false)
    } catch (error) {
      const errorMessage =
        error?.response?.data?.error || 'Something Went Wrong'
      setApiError(errorMessage)
      setLoading(false)
    }
  }

  const fetchDataFromURL = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    if (Object.entries(params).length > 0) {
      if (params.code) {
        const data = JSON.parse(params.state)
        if (data && data?.caller) {
          await getStripeAccount({ data, code: params?.code })
        }
      }
    }
  }

  useEffect(() => {
    fetchDataFromURL()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getRedirectLink = ({ data, stripeId }) => {
    const caller = data?.caller
    const categoryId = data?.category_id
    let link = ''
    switch (caller) {
      case 'ConnectDefaultCategory':
        link = `/settings/manage-payments-accounts?account_id=${stripeId}`
        break
      case 'ConnectGeneralCategory':
        link = `/settings/manage-payments-accounts?category_account_id=${stripeId}&category_id=${categoryId}`
        break
      case 'AddGeneralCategory':
        link = `/settings/add-payment-category?account_id=${stripeId}`
        break
      case 'ConnectBusiness':
        link = `/settings/manage-organization/manage-payments-accounts?account_id=${stripeId}`
        break
      default:
        link = `/settings/manage-payments-accounts?account_id=${stripeId}`
        break
    }
    return link
  }

  return (
    <div className="t-h-screen t-w-screen t-flex t-justify-center t-items-center">
      {loading ? (
        <div className="t-flex t-flex-col t-items-center t-space-y-2">
          <Space size="middle">
            <Spin size="large" />
          </Space>
          <Title level={4}>Saving Stripe Account</Title>
        </div>
      ) : (
        <>
          {apiError ? (
            <UserMessageView heading="Error" subHeading={apiError} />
          ) : (
            <div className="t-flex t-flex-col t-items-center t-space-y-2">
              <Title level={4}>Successful</Title>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export { StripeConnect }
