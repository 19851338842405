/* eslint-disable */
import React, { useState, useEffect } from 'react'
import {
  Button,
  Card,
  Form,
  Row,
  Col,
  Typography,
  Tag,
  Switch,
  Skeleton,
  message,
  Input,
  Select,
  Tooltip,
} from 'antd'
import * as Yup from 'yup'
import {
  formatAmount,
  formateDate,
  createFrequencyNumbers,
  getHoursFromDurations,
} from 'utils'
import { IoIosArrowBack } from 'react-icons/io'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import {
  acceptChangeRequest,
  checkRoomAvailability,
  getReservation,
  getRoom,
  requestReservation,
  updateReservation,
} from 'store/roomSlice/roomActions'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useLocation } from 'react-router-dom'
import { BiBlock } from 'react-icons/bi'
import {
  RoomNotFound,
  RoomDetail,
  ReservationDetail,
  SelectableCalender,
  Resolver,
} from './components'

const { Title } = Typography

const recurringTypes = ['Daily', 'Weekly', 'Monthly', 'Quaterly', 'Yearly']

const stringRegex = /^[A-Za-z ]+$/
const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
const schema = Yup.object().shape({
  IsRecurring: Yup.boolean(),
  RecurringType: Yup.string().required('Recurring type is required'),
  RecurringFrequency: Yup.string().required('Recurring frequency is required'),
  HoursNeeded: Yup.string(),
  UserName: Yup.string()
    .required('Required')
    .matches(stringRegex, 'Invalid Name'),
  UserEmail: Yup.string()
    .required('Required')
    .matches(emailRegex, 'Invalid Email'),
})

const roomValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const initialValues = {
  IsRecurring: false,
  RecurringType: 'Daily',
  RecurringFrequency: null,
  HoursNeeded: null,
  UserName: '',
  UserEmail: '',
}

const RentalReservation = ({ unauthenticated }) => {
  const history = useHistory()
  const { search, state } = useLocation()
  const { user } = useAuthSelector()
  const { id } = useParams()

  const query = new URLSearchParams(search)
  const reservationId = query.get('reservationId')

  const [hoursNeededFilled, setHoursFilled] = useState(false)
  const [durationsSelected, setSelectedDurations] = useState([])
  const [selectedDate, setSelectedDate] = useState(moment().add(1, 'day'))
  const [customDurations, setCustomDurations] = useState([])
  const [room, setRoom] = useState(null)
  const [reservation, setReservation] = useState(null)
  const [isUpdate, setUpdate] = useState(false)
  const [loading, setLoading] = useState(false)
  const [onSaveLoading, setSaveLoading] = useState(false)
  const [isFetchingData, setFetchingData] = useState(false)
  const [dayDuration, setDayDuration] = useState(null)
  const [alreadyReservedDurations, setAlreadyReservedDurations] = useState([])

  const [recurringDates, setRecurringDates] = useState([])

  const [isModal, setModal] = useState(false)

  const [form] = Form.useForm()

  Form.useWatch('RecurringType', form)
  Form.useWatch('IsRecurring', form)
  Form.useWatch('RecurringFrequency', form)
  Form.useWatch('HoursNeeded', form)

  const recurringFrequency = form.getFieldValue('RecurringFrequency')
  const frequencyType = form.getFieldValue('RecurringType')
  const isRecurring = form.getFieldValue('IsRecurring')
  const hoursNeeded = form.getFieldValue('HoursNeeded')

  const isWeeklyFrequency = form.getFieldValue('RecurringType') === 'Weekly'
  const isMonthlyFrequency = form.getFieldValue('RecurringType') === 'Monthly'
  const isQuaterlyFrequency = form.getFieldValue('RecurringType') === 'Quaterly'
  const isYearlyFrequency = form.getFieldValue('RecurringType') === 'Yearly'
  const isDailyFrequency = form.getFieldValue('RecurringType') === 'Daily'

  useEffect(() => {
    const fetchData = async () => {
      try {
        setFetchingData(true)
        const roomDetail = await getRoom(id)
        setRoom(roomDetail)
        if (reservationId) {
          const reservationDetail = await getReservation(reservationId)
          setReservation(reservationDetail)
        }
        setFetchingData(false)
      } catch (error) {
        setFetchingData(false)
      }
    }
    if (id) {
      fetchData()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (room) {
      onDateChange(selectedDate)
    }
  }, [room])

  useEffect(() => {
    if (!isRecurring) {
      form.setFieldValue('RecurringFrequency', null)
    }
  }, [isRecurring])

  useEffect(() => {
    setRecurringDates([
      {
        date: selectedDate.format(),
        isAvailable: checkIfDayIsAvailable(selectedDate.format()),
      },
      ...getDatesWithAvailability(),
    ])
    // eslint-disable-next-line
  }, [
    form.getFieldValue('RecurringType'),
    form.getFieldValue('IsRecurring'),
    form.getFieldValue('RecurringFrequency'),
    selectedDate,
  ])

  const onClickNext = () => {
    if (parseInt(hoursNeeded) > parseInt(room?.MaximumReservationHours)) {
      message.error(
        `Maximum of ${room?.MaximumReservationHours} hours can be booked for this room in a day`,
      )
      setHoursFilled(false)
    } else if (hoursNeeded) {
      setHoursFilled(true)
    } else {
      clearFormValuesOnDateChange()
      setSelectedDate(moment().add(1, 'day'))
      form.setFieldsValue({
        IsRecurring: false,
        RecurringType: 'Daily',
        RecurringFrequency: null,
      })
      message.destroy()
      message.error('Kindly enter number of hours needed for reservation')
      setHoursFilled(false)
    }
  }

  const clearFormValuesOnDateChange = () => {
    setSelectedDurations([])
    setCustomDurations([])
  }

  const onDateChange = (dateSelected) => {
    if (!moment(selectedDate).isBefore(moment())) {
      const day = moment(dateSelected).format('dddd').toLowerCase()
      let dayDurations = room?.Schedule[day]
      const updatedDurations = room?.Schedule[day].durations.map(
        (item, index) => ({
          ...item,
          Id: index + 1,
        }),
      )
      dayDurations = { ...dayDurations, durations: updatedDurations }
      setDayDuration(dayDurations)
      clearFormValuesOnDateChange()
    }
  }

  const goBackButtonClick = () => {
    if (state && state.urlData) {
      history.push({
        pathname: state.urlData.pathname,
        state: { key: state.urlData.activeKey },
      })
    } else {
      history.goBack()
    }
  }
  const [btnDisable, setbtnDisable] = useState(false)
  const onCustomDurationChange = (durations) => {
    setCustomDurations([...durations])
    const hasOverlap = isAnyOverlap(durations, alreadyReservedDurations)
    setbtnDisable(hasOverlap)
  }

  const getDates = () => {
    let dates = []
    if (recurringFrequency) {
      const currentDate = moment(selectedDate)
      if (isDailyFrequency) {
        for (let i = 0; i < recurringFrequency; i++) {
          dates.push(moment(currentDate.add(1, 'days')).format())
        }
      }
      if (isWeeklyFrequency) {
        for (let i = 0; i < recurringFrequency; i++) {
          dates.push(moment(currentDate.add(1, 'weeks')).format())
        }
      }
      if (isMonthlyFrequency) {
        for (let i = 0; i < recurringFrequency; i++) {
          dates.push(moment(currentDate.add(1, 'months')).format())
        }
      }
      if (isQuaterlyFrequency) {
        for (let i = 0; i < recurringFrequency; i++) {
          dates.push(moment(currentDate.add(1, 'quarters')).format())
        }
      }
      if (isYearlyFrequency) {
        for (let i = 0; i < recurringFrequency; i++) {
          dates.push(moment(currentDate.add(1, 'years')).format())
        }
      }
    }
    return dates
  }

  const checkIfDayIsAvailable = (dateSelected) => {
    const day = moment(dateSelected).format('dddd').toLowerCase()
    return room?.Schedule[day].open
  }

  const getDatesWithAvailability = () => {
    let dates = []
    if (recurringFrequency) {
      const currentDate = moment(selectedDate)
      if (isDailyFrequency) {
        for (let i = 0; i < recurringFrequency; i++) {
          const newDate = moment(currentDate.add(1, 'days')).format()
          dates.push({
            date: newDate,
            isAvailable: checkIfDayIsAvailable(newDate),
          })
        }
      }
      if (isWeeklyFrequency) {
        for (let i = 0; i < recurringFrequency; i++) {
          const newDate = moment(currentDate.add(1, 'weeks')).format()
          dates.push({
            date: newDate,
            isAvailable: checkIfDayIsAvailable(newDate),
          })
        }
      }
      if (isMonthlyFrequency) {
        for (let i = 0; i < recurringFrequency; i++) {
          const newDate = moment(currentDate.add(1, 'months')).format()
          dates.push({
            date: newDate,
            isAvailable: checkIfDayIsAvailable(newDate),
          })
        }
      }
      if (isQuaterlyFrequency) {
        for (let i = 0; i < recurringFrequency; i++) {
          const newDate = moment(currentDate.add(1, 'quarters')).format()
          dates.push({
            date: newDate,
            isAvailable: checkIfDayIsAvailable(newDate),
          })
        }
      }
      if (isYearlyFrequency) {
        for (let i = 0; i < recurringFrequency; i++) {
          const newDate = moment(currentDate.add(1, 'years')).format()
          dates.push({
            date: newDate,
            isAvailable: checkIfDayIsAvailable(newDate),
          })
        }
      }
    }
    return dates
  }

  const onFinish = async (values) => {
    const hours = getTotalHoursBooked()
    let selectedDurations = []
    if (customDurations.length > 0) {
      selectedDurations = customDurations
    } else {
      const allDayDurations = dayDuration?.durations
      selectedDurations = allDayDurations?.filter((item) =>
        durationsSelected.includes(item?.Id),
      )
      selectedDurations = selectedDurations.map((item) => {
        const updatedItem = item
        delete updatedItem?.Id
        return { ...updatedItem }
      })
    }
    try {
      setLoading(true)
      let payload = {}
      const newDates = recurringDates
        .slice(1)
        .map((item) => new Date(item.date))
      const myrecurringdates = [selectedDate.format(), ...newDates]

      if (isUpdate && reservationId) {
        payload = {
          Id: reservationId,
          Date: new Date(selectedDate),
          TotalHours: hours,
          TotalCost: hours * room?.PerHourRate,
          AcceptanceStatus: 'Pending',
          Durations: selectedDurations,
          IsRecurring: values.IsRecurring,
          RecurringType: values.IsRecurring ? values.RecurringType : null,
          RecurringFrequency: values.IsRecurring
            ? values.RecurringFrequency
            : 0,
          RoomCreatorEmail: room?.CreatorEmail,
          RoomName: room?.Name,
          RecurringDates: values.IsRecurring ? myrecurringdates : [],
        }
        await updateReservation(payload)
        goToRentals()
        setLoading(false)
        message.success('Reservation request updated')
      } else {
        payload = {
          Date: selectedDate,
          RoomId: room?.Id,
          CreatorId: unauthenticated ? null : user?.id,
          CreatorEmail: unauthenticated ? values?.UserEmail : user?.email,
          CreatorPhoneNumber: unauthenticated ? null : user?.phone,
          CreatorName: unauthenticated
            ? values?.UserName
            : `${user?.firstname} ${user?.lastname}`,
          OrganizationId: room?.OrganizationId,
          TotalHours: hours,
          TotalCost: hours * room.PerHourRate,
          AcceptanceStatus: 'Pending',
          Durations: selectedDurations,
          IsRecurring: values.IsRecurring,
          RecurringType: values.IsRecurring ? values.RecurringType : null,
          RecurringFrequency: values.IsRecurring
            ? values.RecurringFrequency
            : 0,
          RoomCreatorEmail: room?.CreatorEmail,
          RoomName: room?.Name,
          RecurringDates: values.IsRecurring ? myrecurringdates : [],
          NeedApproval: room?.NeedApproval,
        }
        await requestReservation(payload)
        if (room?.NeedApproval) {
          message.success('Reservation request sent')
          goToRentals()
        } else {
          message.success('Reservation request created. Pay to proceed further')
          if (unauthenticated) {
            history.goBack()
            setFetchingData(true)
            if (reservationId) {
              const reservationDetail = await getReservation(reservationId)
              setReservation(reservationDetail)
            }
            setFetchingData(false)
          } else {
            history.push('/settings/account?key=3')
          }
        }
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      message.error('Error requesting reservation')
    }
  }

  const goToRentals = () => {
    if (unauthenticated) {
      history.goBack()
    } else {
      history.push('/rentals')
    }
  }

  const getTotalHoursBooked = () => {
    if (customDurations.length) {
      if (isRecurring) {
        return getHoursFromDurations(customDurations) * recurringDates.length
      } else {
        return getHoursFromDurations(customDurations)
      }
    } else {
      const allDayDurations = dayDuration?.durations
      const selectedDurations = allDayDurations?.filter((item) =>
        durationsSelected.includes(item?.Id),
      )
      if (isRecurring) {
        return getHoursFromDurations(selectedDurations) * recurringDates.length
      } else {
        return getHoursFromDurations(selectedDurations)
      }
    }
  }

  const getTotalHoursBookedForOneDay = () => {
    if (customDurations.length) {
      return getHoursFromDurations(customDurations)
    } else {
      const allDayDurations = dayDuration?.durations
      const selectedDurations = allDayDurations?.filter((item) =>
        durationsSelected.includes(item?.Id),
      )
      return getHoursFromDurations(selectedDurations)
    }
  }

  const onSave = async () => {
    try {
      setSaveLoading(true)
      await acceptChangeRequest(reservation)
      message.success(
        'Reservation change request accepted. Pay to confirm your booking',
      )
      history.push('/rentals')
      setSaveLoading(false)
    } catch (error) {
      setSaveLoading(false)
      message.error('Error saving the reservation')
    }
  }

  const isCustomDurations = customDurations.length > 0
  const isClickedUpdate = reservation && !isUpdate
  const isConflictingAvailability =
    recurringDates.filter((item) => !item.isAvailable).length > 0
  const isDisable =
    getTotalHoursBookedForOneDay() <= 0 ||
    getTotalHoursBookedForOneDay() > room.MaximumReservationHours ||
    isConflictingAvailability
  const isHoursInNegative = getTotalHoursBookedForOneDay() < 0

  // Todo we need to improve this logic
  const areAllTimeSlotsAvailable = (dayDuration, customDurations, nonStop) => {
    if (nonStop) {
      return true
    }
    for (const customDuration of customDurations) {
      const requestedFrom = moment(customDuration.from, 'h:mm A')
      const requestedTo = moment(customDuration.to, 'h:mm A')
      let isAvailable = false

      for (const slot of dayDuration) {
        const availableFrom = moment(slot.from, 'h:mm A')
        const availableTo = moment(slot.to, 'h:mm A')

        if (
          requestedFrom.isSameOrAfter(availableFrom) &&
          requestedTo.isSameOrBefore(availableTo)
        ) {
          isAvailable = true
          break
        }
      }

      if (!isAvailable) {
        return false
      }
    }

    return true
  }

  const allSlotsAvailable = areAllTimeSlotsAvailable(
    dayDuration?.durations,
    customDurations,
    dayDuration?.nonstop,
  )

  useEffect(() => {
    const hasOverlap = isAnyOverlap(customDurations, alreadyReservedDurations)
    setbtnDisable(hasOverlap)
  }, [customDurations, alreadyReservedDurations])

  const isTimeOverlap = (time1, time2) => {
    const format = 'hh:mm A'
    const start1 = moment(time1.from, format)
    const end1 = moment(time1.to, format)
    const start2 = moment(time2.from, format)
    const end2 = moment(time2.to, format)

    return start1.isBefore(end2) && end1.isAfter(start2)
  }

  const isAnyOverlap = (selectedDurations, reservedDurations) => {
    for (let i = 0; i < selectedDurations.length; i++) {
      for (let j = 0; j < reservedDurations.length; j++) {
        if (isTimeOverlap(selectedDurations[i], reservedDurations[j])) {
          return true
        }
      }
    }
    return false
  }

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <div className="t-flex t-w-full t-justify-between">
          <div>
            <h6 className="mb-0">Reserve Rental Facility</h6>
            <p className="font-regular">Enter reservation details</p>
          </div>
          <div className="t-flex t-items-center t-space-x-4 t-mb-2">
            <Button
              type="primary"
              className="t-rounded-full t-flex t-items-center"
              onClick={goBackButtonClick}
              size="small"
            >
              <IoIosArrowBack />
              Back
            </Button>
          </div>
        </div>,
      ]}
    >
      <Skeleton loading={isFetchingData} active paragraph={{ rows: 18 }}>
        {room ? (
          <Form
            form={form}
            layout="vertical"
            key={0}
            onFinish={onFinish}
            name="reservation"
            initialValues={initialValues}
            scrollToFirstError
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault() // Prevent default form submission behavior
              }
            }}
          >
            <Row gutter={[24, 0]}>
              <Col span={24} className="t-my-1">
                <RoomDetail room={room} />
              </Col>
              <Col span={24} className="t-my-2">
                <Card>
                  <Row>
                    {unauthenticated && (
                      <>
                        <Col span={24}>
                          <Form.Item
                            name="UserName"
                            label="Name"
                            colon={false}
                            rules={[roomValidation]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            name="UserEmail"
                            label="Email"
                            colon={false}
                            rules={[roomValidation]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                    <Col span={24}>
                      <Form.Item
                        name="HoursNeeded"
                        label="How long is the room needed for?"
                        colon={false}
                        rules={[roomValidation]}
                      >
                        <Input
                          placeholder="How long is the room needed for?"
                          inputMode="numeric"
                          suffix="hours"
                          onChange={(e) =>
                            form.setFieldsValue({
                              HoursNeeded: e.target.value.replace(/\D/g, ''),
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} className="t-flex t-justify-end">
                      <Button type="primary" onClick={onClickNext}>
                        Next
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
              {(hoursNeededFilled || reservationId) && (
                <>
                  <ReservationDetail
                    reservation={reservation}
                    isUpdate={isUpdate}
                    isSaving={onSaveLoading}
                    onSave={onSave}
                    onUpdateClick={() => setUpdate(!isUpdate)}
                  />
                  {!isClickedUpdate && (
                    <>
                      <Col span={24}>
                        <Card className="t-my-2">
                          <Col span={24}>
                            <Title
                              level={5}
                              className="t-text-secondary-100 t-font-bold"
                            >
                              {isUpdate
                                ? 'Update reservation details'
                                : 'Enter reservation details'}
                            </Title>
                          </Col>
                          <SelectableCalender
                            room={room}
                            roomSchedule={room?.Schedule}
                            onDateChange={onDateChange}
                            day={dayDuration}
                            customDurations={customDurations}
                            onCustomDurationChange={onCustomDurationChange}
                            selectedDate={selectedDate}
                            setSelectedDate={setSelectedDate}
                            isHoursInNegative={isHoursInNegative}
                            reservationId={reservationId}
                            alreadyReservedDurations={alreadyReservedDurations}
                            setAlreadyReservedDurations={
                              setAlreadyReservedDurations
                            }
                          />
                          <Card className="t-my-3 t-py-1">
                            <Col span={24}>
                              <Form.Item
                                name="IsRecurring"
                                label="Recurring Reservation?"
                                colon={false}
                                rules={[roomValidation]}
                              >
                                <Switch checked={isRecurring} />
                              </Form.Item>
                            </Col>
                            {isRecurring && (
                              <Col span={24}>
                                <Row gutter={[24, 0]}>
                                  <Col span={12}>
                                    <Form.Item
                                      name="RecurringType"
                                      label="Recurring Type"
                                      colon={false}
                                      rules={[roomValidation]}
                                    >
                                      <Select
                                        placeholder="Recurring Type"
                                        style={{ width: '100%' }}
                                        onChange={() =>
                                          form.setFieldValue(
                                            'RecurringFrequency',
                                            1,
                                          )
                                        }
                                      >
                                        {recurringTypes.map((item, index) => (
                                          <Select.Option
                                            value={item}
                                            key={index}
                                          >
                                            {item}
                                          </Select.Option>
                                        ))}
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                  <Col span={12}>
                                    <Form.Item
                                      name="RecurringFrequency"
                                      label="Recurring Frequency"
                                      colon={false}
                                      rules={[roomValidation]}
                                    >
                                      <Select
                                        placeholder="Recurring Frequency"
                                        style={{ width: '100%' }}
                                      >
                                        {createFrequencyNumbers(
                                          frequencyType,
                                        ).map((item, index) => (
                                          <Select.Option
                                            value={item}
                                            key={index}
                                          >
                                            {item}
                                          </Select.Option>
                                        ))}
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Col>
                            )}
                          </Card>
                          <Card className="t-my-2">
                            <Col span={24}>
                              <Title level={5} className="t-text-secondary-100">
                                Room reservation submitting details
                              </Title>
                              <div className="t-w-full">
                                <p>
                                  You have reserved this room with below
                                  mentioned details. Kindly confirm the
                                  reservation date/dates, total hours booked,
                                  durations booked and total cost for all the
                                  reservation.
                                </p>
                                <div className="t-flex t-flex-col md:t-flex-row t-space-y-2 md:t-space-y-0 t-my-4">
                                  {isCustomDurations ? (
                                    <>
                                      {customDurations?.map((item) => (
                                        <Tag color="green">{`${item?.from} - ${item?.to}`}</Tag>
                                      ))}
                                    </>
                                  ) : (
                                    <>
                                      {dayDuration?.durations.map((item) => (
                                        <>
                                          {durationsSelected?.includes(
                                            item.Id,
                                          ) && (
                                            <Tag color="green">{`${item?.from} - ${item?.to}`}</Tag>
                                          )}
                                        </>
                                      ))}
                                    </>
                                  )}
                                </div>
                                <div className="t-my-2">
                                  <Title
                                    level={5}
                                    className="t-text-secondary-100 t-mr-2"
                                  >
                                    {isRecurring
                                      ? 'Recurring dates'
                                      : 'Reservation Date'}
                                  </Title>
                                  <div className="t-flex t-flex-wrap">
                                    {recurringDates.map((item, index) => (
                                      <div className="t-relative">
                                        <div>
                                          <Tag
                                            key={index}
                                            color={
                                              !item?.isAvailable
                                                ? 'gray'
                                                : 'orange'
                                            }
                                            className="t-my-2"
                                          >
                                            {formateDate(item?.date)}
                                          </Tag>
                                          {!item?.isAvailable && (
                                            <div className="t-absolute -t-right-1 -t-top-1">
                                              <Tooltip title="Day is not available">
                                                <BiBlock
                                                  fontSize="1.4rem"
                                                  className="t-text-gray-800 t-cursor-pointer"
                                                />
                                              </Tooltip>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                  {isConflictingAvailability && (
                                    <div className="t-text-red-500 t-font-bold">
                                      {`Recurring dates contain days where room is not open to book. Click below to start resolving the conflicts.`}
                                    </div>
                                  )}
                                </div>
                                {isConflictingAvailability && (
                                  <div className="t-my-2">
                                    <Button
                                      type="primary"
                                      size="small"
                                      onClick={() => setModal(!isModal)}
                                    >
                                      Resolve
                                    </Button>
                                  </div>
                                )}
                                <div className="t-flex t-justify-end">
                                  <Title
                                    level={5}
                                    className="t-text-secondary-100"
                                  >
                                    Total Hours :{' '}
                                    {isHoursInNegative
                                      ? 0
                                      : getTotalHoursBooked()}
                                  </Title>
                                </div>
                                <div className="t-flex t-justify-end">
                                  <Title
                                    level={5}
                                    className="t-text-secondary-100 t-font-bold"
                                  >
                                    Total Cost :{' '}
                                    {isHoursInNegative
                                      ? formatAmount(0)
                                      : formatAmount(
                                          getTotalHoursBooked() *
                                            room?.PerHourRate,
                                        )}
                                  </Title>
                                </div>
                                <div className="t-flex t-justify-end">
                                  <p className="t-text-secondary-100">
                                    Reservation cost is{' '}
                                    {formatAmount(
                                      getTotalHoursBooked() * room?.PerHourRate,
                                    )}
                                    . There is a 15% cancelation fee if you
                                    cancel this reservation later. Continue?
                                  </p>
                                </div>
                              </div>
                              {room?.NeedApproval && (
                                <div className="t-text-secondary-100 t-font-bold">
                                  {`Your request requires approval from ${room?.OrganizationName}. We
                            will contact you as soon as your request is
                            approved.`}
                                </div>
                              )}
                              {isHoursInNegative && (
                                <Col span={24} className="t-my-3">
                                  <div className="t-text-red-500 t-font-bold">
                                    You have selected an invalid duration
                                  </div>
                                </Col>
                              )}
                              {!allSlotsAvailable && (
                                <Col span={24} className="t-my-3">
                                  <div className="t-text-red-500 t-font-bold">
                                    You Selected wrong time duration
                                  </div>
                                </Col>
                              )}
                              <Col span={24}>
                                {getTotalHoursBookedForOneDay() >
                                  room?.MaximumReservationHours && (
                                  <div className="t-text-red-500 t-font-bold">{`Maximum of ${room?.MaximumReservationHours} hours can be booked for this room in a day`}</div>
                                )}
                              </Col>
                            </Col>
                          </Card>
                          <Col
                            span={24}
                            className="text-left t-space-x-2 t-mt-4"
                          >
                            <Button
                              type="primary"
                              className="px-25"
                              htmlType="submit"
                              disabled={
                                !allSlotsAvailable || isDisable || btnDisable
                              }
                              loading={loading}
                            >
                              {isUpdate && reservationId
                                ? loading
                                  ? 'Loading'
                                  : 'Update Rental Reservation'
                                : loading
                                ? 'Loading'
                                : 'Request Rental Reservation'}
                            </Button>
                            <Button
                              type="primary"
                              className="px-25"
                              onClick={() => history.goBack()}
                            >
                              Cancel
                            </Button>
                          </Col>
                        </Card>
                      </Col>
                    </>
                  )}
                </>
              )}
            </Row>
          </Form>
        ) : (
          <RoomNotFound />
        )}
      </Skeleton>
      <Resolver
        dates={recurringDates}
        setDates={setRecurringDates}
        roomSchedule={room?.Schedule}
        visible={isModal}
        onClose={() => setModal(!isModal)}
      />
    </Card>
  )
}

export { RentalReservation }
