import Api from 'api'
import { message } from 'antd'
import { uploadFile } from 'store/authSlice/authActions'
import { roomActions } from './roomReducer'

export const createRoom = (data, action) => async (dispatch) => {
  dispatch(roomActions.setLoading(true))
  try {
    let updatedData = data
    const imageFile = await uploadFile(updatedData.Image)
    updatedData = {
      ...updatedData,
      Image: {
        fileName: imageFile.fileName,
        fileMimeType: imageFile.fileMimeType,
      },
    }
    Api.post('/room', updatedData)
      .then(() => {
        message.success('Room Created successfully')
        dispatch(roomActions.setLoading(false))
        if (action) action()
      })
      .catch((error) => {
        console.log('error', error)
        dispatch(roomActions.setLoading(false))
      })
  } catch (error) {
    console.log('error', error)
    dispatch(roomActions.setLoading(false))
  }
}

export const updateRoom = (data, action) => async (dispatch) => {
  dispatch(roomActions.setLoading(true))
  try {
    let updatedData = data

    if (!updatedData?.Image?.fileName) {
      const imageFile = await uploadFile(updatedData.Image)
      updatedData = {
        ...updatedData,
        Image: {
          fileName: imageFile.fileName,
          fileMimeType: imageFile.fileMimeType,
        },
      }
    }
    Api.put('/room', updatedData)
      .then(() => {
        message.success('Room Updated successfully')
        dispatch(roomActions.setLoading(false))
        if (action) action()
      })
      .catch((error) => {
        console.log('error', error)
        dispatch(roomActions.setLoading(false))
      })
  } catch (error) {
    console.log('error', error)
    dispatch(roomActions.setLoading(false))
  }
}

export const getOrganizationRooms = (id) => async (dispatch) => {
  dispatch(roomActions.setLoading(true))
  Api.get(`/room/organizationRooms?id=${id}`)
    .then((res) => {
      if (Array.isArray(res?.data) && res?.data.length > 0) {
        dispatch(roomActions.setRooms(res.data))
      } else {
        dispatch(roomActions.setRooms([]))
      }
      dispatch(roomActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(roomActions.setRooms([]))
      dispatch(roomActions.setLoading(false))
    })
}

export const getNearByRooms = (positionData) => async (dispatch) => {
  dispatch(roomActions.setLoading(true))
  Api.post(`/room/nearBy`, positionData)
    .then((res) => {
      if (Array.isArray(res?.data) && res?.data.length > 0) {
        dispatch(roomActions.setRooms(res.data))
      } else {
        dispatch(roomActions.setRooms([]))
      }
      dispatch(roomActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(roomActions.setRooms([]))
      dispatch(roomActions.setLoading(false))
    })
}

export const getRoom = async (id) => {
  const response = await Api.get(`/component/room/${id}`)
  return response?.results
}

export const getReservation = async (id) => {
  const response = await Api.get(`/reservation/${id}`)
  return response.data
}

export const requestReservation = async (data) => {
  const response = await Api.post(`/component/room-reservation`, data)
  return response.results
}
export const updateReservation = async (data) => {
  const response = await Api.put(`/reservation/update`, data)
  return response.data
}

export const reservationAction = async (payload) => {
  const response = await Api.put(`/reservation`, payload)
  return response.data
}
export const changeReservationAction = async (payload) => {
  const response = await Api.put(`/reservation/changeReservation`, payload)
  return response.data
}
export const checkRoomAvailability = async (payload) => {
  const response = await Api.put(
    `/component/room-reservation/checkDayAvailability`,
    payload,
  )
  return response.results
}

export const getUserReservation = (id) => async (dispatch) => {
  dispatch(roomActions.setLoading(true))
  Api.get(`/reservation/userReservations?id=${id}`)
    .then((res) => {
      const sortedReservations = res.data?.sort((a, b) => {
        const dateA = new Date(a?.CreatedAt)
        const dateB = new Date(b?.CreatedAt)
        return dateB - dateA
      })
      dispatch(roomActions.setReservations(sortedReservations))
      dispatch(roomActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(roomActions.setReservations([]))
      dispatch(roomActions.setLoading(false))
    })
}

export const getOrganizationReservation = (orgId) => async (dispatch) => {
  dispatch(roomActions.setLoading(true))
  Api.get(`/reservation/organizationReservations?id=${orgId}`)
    .then((res) => {
      const sortedReservations = res.data?.sort((a, b) => {
        const dateA = new Date(a?.CreatedAt)
        const dateB = new Date(b?.CreatedAt)
        return dateB - dateA
      })
      dispatch(roomActions.setReservations(sortedReservations))
      dispatch(roomActions.setLoading(false))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(roomActions.setReservations([]))
      dispatch(roomActions.setLoading(false))
    })
}

export const getSubscriberPaymentInformation = async (orgId) => {
  const paymentInformation = await Api.get(
    `/event/account-info?organizationId=${orgId}`,
  )

  const result = {
    stripe: {
      isConnected:
        paymentInformation.defaultCategory?.stripeID &&
        paymentInformation.defaultCategory?.stripeOnBoarded,
      stripeId: paymentInformation.defaultCategory?.stripeID,
    },
    paypal: {
      isConnected: !!paymentInformation.defaultCategory?.paypalID,
      paypalId: paymentInformation.defaultCategory?.paypalID,
    },
    markup: Number(paymentInformation?.markup) || 1,
  }
  return result
}

export const stripePayment = (payload, setLoading, actions) => () => {
  setLoading(true)
  Api.post('/component/room-reservation/payment-stripe', payload)
    .then(() => {
      setLoading(false)
      if (actions) {
        actions()
      }
    })
    .catch((error) => {
      message.error(error?.response?.data?.error || 'An Error Occured')
      setLoading(false)
    })
}

export const paypalRentalPayment = (payload, setLoading, actions) => () => {
  setLoading(true)
  Api.post('/component/room-reservation/payment-paypal', payload)
    .then(() => {
      setLoading(false)
      if (actions) {
        actions()
      }
    })
    .catch((error) => {
      message.error(error?.response?.data?.error || 'An Error Occured')
      setLoading(false)
    })
}

export const requestRefund = async (data) => {
  const response = await Api.put(`/reservation/requestRefund`, data)
  return response
}

export const approveRefund = async (id) => {
  const response = await Api.put(`/reservation/approveRefund/${id}`)
  return response
}

export const getAllReservations = async () => {
  const response = await Api.get(`/reservation/allReservations`)
  return response
}

export const adminActionOnReservation = async (data, status) => {
  const payload = { Id: data?.Id, reservation: data, status }
  const response = await Api.put(`/reservation/adminTakeAction`, payload)
  return response
}

export const acceptChangeRequest = async (data) => {
  const payload = { Id: data?.Id, reservation: data }
  const response = await Api.put(
    `/reservation/acceptChangeReservation`,
    payload,
  )
  return response
}
