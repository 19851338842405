import React, { useState, useEffect } from 'react'
import {
  Card,
  Col,
  Input,
  Form,
  Row,
  Typography,
  Button,
  Switch,
  Skeleton,
  Divider,
} from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useRoomSelector } from 'store/roomSlice/roomReducer'
import { createRoom, getRoom, updateRoom } from 'store/roomSlice/roomActions'
import { sortScheduleByDays } from 'utils'
import { AmountInput, ImageUploader } from 'components/elements'
import { AutoCompleteAddress } from 'components/common'
import { DayDurations } from './DayDurations'

const { Title, Paragraph } = Typography

const schema = Yup.object().shape({
  Name: Yup.string().required('Room name is required'),
  Instructions: Yup.string(),
  Breadth: Yup.string().required().typeError('Room breadth is required'),
  Width: Yup.string().required().typeError('Room width is required'),
  Capacity: Yup.string().required().typeError('Capacity is required'),
  MaximumReservationHours: Yup.string()
    .required()
    .typeError('Maximum reservation hours is required')
    .test(
      'max-hours',
      'Maximum reservation hours cannot be greater than 24',
      (value) => {
        if (value) {
          const hours = parseInt(value, 10)
          return hours <= 24
        }
        return true
      },
    ),
  Available: Yup.boolean(),
  NeedApproval: Yup.boolean(),
  Image: Yup.mixed().typeError('Image is invalid'),
  PerHourRate: Yup.string().required().typeError('Per hour rate is required'),
  ShowOnPage: Yup.string().required('Show on landing page is required'),
})

const roomValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const initialValues = {
  Name: '',
  Instructions: '',
  Breadth: null,
  Width: null,
  Capacity: null,
  MaximumReservationHours: null,
  Available: false,
  NeedApproval: false,
  Image: null,
  ShowOnPage: false,
}

const roomScheduleInitialState = {
  monday: { dayName: 'monday', durations: [], open: false, nonstop: false },
  tuesday: { dayName: 'tuesday', durations: [], open: false, nonstop: false },
  wednesday: {
    dayName: 'wednesday',
    durations: [],
    open: false,
    nonstop: false,
  },
  thursday: { dayName: 'thursday', durations: [], open: false, nonstop: false },
  friday: { dayName: 'friday', durations: [], open: false, nonstop: false },
  saturday: {
    dayName: 'saturday',
    durations: [],
    open: false,
    nonstop: false,
  },
  sunday: { dayName: 'sunday', durations: [], open: false, nonstop: false },
}

const isValidSchedule = (schedule) => {
  const filteredSchedule = []
  Object.keys(schedule).forEach((key) => {
    const value = schedule[key]
    if (value.open) {
      filteredSchedule.push({
        day: key,
        ...value,
      })
    }
  })
  const filteredValues = filteredSchedule.filter(
    ({ open, nonstop, durations }) =>
      !(open && (nonstop || durations.length > 0)),
  )
  return filteredValues.length > 0 || filteredSchedule.length === 0
}

const CreateRoom = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { user, organization } = useAuthSelector()
  const { loading } = useRoomSelector()
  const { id } = useParams()
  const [schedule, setSchedule] = useState({ ...roomScheduleInitialState })
  const [isLoading, setLoading] = useState(false)

  Form.useWatch('Image', form)
  Form.useWatch('Available', form)
  Form.useWatch('NeedApproval', form)
  Form.useWatch('ShowOnPage', form)

  useEffect(() => {
    if (id) {
      fetchData()
    }
    // eslint-disable-next-line
  }, [id])

  const handleOrganizationAddresss = () => {
    form.setFieldsValue({
      mailingAddress: organization.Address1 && organization.Address1,
    })
    form.setFieldsValue({
      suite: `${organization.Address2}`,
    })
    form.setFieldsValue({ zipCode: organization.ZipCode })
    form.setFieldsValue({ city: organization.City })
    form.setFieldsValue({ state: organization.State })
    form.setFieldsValue({ country: organization.Country })
  }

  useEffect(() => {
    if (organization && organization?.ZipCode) {
      handleOrganizationAddresss()
    }
    // eslint-disable-next-line
  }, [organization, id])

  const fetchData = () => {
    const getRoomDetail = async () => {
      setLoading(true)
      try {
        const roomDetail = await getRoom(id)
        form.setFieldsValue({
          Name: roomDetail?.Name,
          PerHourRate: roomDetail?.PerHourRate,
          Width: roomDetail?.Width,
          Breadth: roomDetail?.Breadth,
          Capacity: roomDetail?.Capacity,
          Instructions: roomDetail?.Instructions,
          Available: roomDetail?.Available,
          MaximumReservationHours: roomDetail?.MaximumReservationHours,
          NeedApproval: roomDetail?.NeedApproval,
          Image: roomDetail?.Image,
          ShowOnPage: roomDetail.ShowOnPage ? roomDetail.ShowOnPage : false,
        })
        const updatedSchedule = sortScheduleByDays(roomDetail?.Schedule)
        setSchedule(updatedSchedule)
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
    getRoomDetail()
  }

  const onFinish = (values) => {
    let formValues = {
      ...values,
      Schedule: schedule,
      CreatorId: user.id,
      CreatorEmail: user.email,
      OrganizationId: organization.id,
      Address1: values.mailingAddress,
      Address2: values.suite,
      ZipCode: values.zipCode,
      City: values.city,
      LocationState: values.state,
      Country: values.country,
      Address: values.suite,
    }
    const action = () => {
      clearForm()
    }
    if (id) {
      formValues = { ...formValues, Id: id }
      dispatch(updateRoom(formValues, action))
    } else {
      dispatch(createRoom(formValues, action))
    }
  }

  const clearForm = () => {
    form.resetFields()
    onClearAllClick()
    history.push('/rentals')
  }

  const onChangeSchedule = (value) => {
    const scheduleClone = schedule
    scheduleClone[value.dayName] = value
    setSchedule({ ...scheduleClone })
  }

  const onClearAllClick = () => {
    setSchedule({ ...roomScheduleInitialState })
  }

  const onApplyToAll = (value) => {
    const scheduleClone = { ...schedule }
    Object.keys(scheduleClone).map((dayName) => {
      scheduleClone[dayName] = { ...value, dayName }
      return dayName
    })
    setSchedule({ ...scheduleClone })
  }

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <>
          <h6 className="mb-0">{id ? 'Update Room' : 'Create Room'}</h6>
          <p className="font-regular">Enter Information</p>
        </>,
      ]}
    >
      <Skeleton loading={isLoading} active paragraph={{ rows: 18 }}>
        <Form
          form={form}
          layout="vertical"
          key={0}
          onFinish={onFinish}
          name="room"
          initialValues={initialValues}
          scrollToFirstError
        >
          <Row gutter={[24, 0]}>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="Name"
                label="Name"
                colon={false}
                rules={[roomValidation]}
              >
                <Input placeholder="Room Name" />
              </Form.Item>
            </Col>

            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="PerHourRate"
                label="Per Hour Rate"
                colon={false}
                rules={[roomValidation]}
              >
                <AmountInput
                  placeholder="Per hour rate"
                  value={form.getFieldValue('PerHourRate')}
                  onChange={(value) =>
                    form.setFieldsValue({ PerHourRate: value })
                  }
                />
              </Form.Item>
            </Col>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="Width"
                label="Room Length"
                colon={false}
                rules={[roomValidation]}
              >
                <Input
                  placeholder="Length"
                  inputMode="numeric"
                  suffix="feet"
                  onChange={(e) =>
                    form.setFieldsValue({
                      Width: e.target.value.replace(/\D/g, ''),
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="Breadth"
                label="Room Breadth"
                colon={false}
                rules={[roomValidation]}
              >
                <Input
                  placeholder="Breadth"
                  inputMode="numeric"
                  suffix="feet"
                  onChange={(e) =>
                    form.setFieldsValue({
                      Breadth: e.target.value.replace(/\D/g, ''),
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="Capacity"
                label="Capacity"
                colon={false}
                rules={[roomValidation]}
              >
                <Input
                  placeholder="Room Capacity"
                  inputMode="numeric"
                  suffix="persons"
                  onChange={(e) =>
                    form.setFieldsValue({
                      Capacity: e.target.value.replace(/\D/g, ''),
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="MaximumReservationHours"
                label="Maximum Reservation Hours"
                colon={false}
                rules={[roomValidation]}
              >
                <Input
                  placeholder="MaximumReservationHours"
                  inputMode="numeric"
                  suffix="hours"
                  onChange={(e) =>
                    form.setFieldsValue({
                      MaximumReservationHours: e.target.value.replace(
                        /\D/g,
                        '',
                      ),
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col xl={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="Instructions"
                label="Instructions"
                colon={false}
                rules={[roomValidation]}
              >
                <Input.TextArea
                  placeholder="Room instructions"
                  autoSize={{ minRows: 3, maxRows: 3 }}
                />
              </Form.Item>
            </Col>
            <AutoCompleteAddress
              form={form}
              validation
              // nonEditable={sameAsOrg}
              isInitiallyValidated={id}
            />
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="Available"
                label="Room is available for reservation?"
                colon={false}
                rules={[roomValidation]}
              >
                <Switch checked={form.getFieldValue('Available')} />
              </Form.Item>
            </Col>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="NeedApproval"
                label="Room needs approval?"
                colon={false}
                rules={[roomValidation]}
              >
                <Switch checked={form.getFieldValue('NeedApproval')} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="ShowOnPage"
                label="Show on Landing Page"
                colon={false}
                rules={[roomValidation]}
              >
                <Switch checked={form.getFieldValue('ShowOnPage')} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <div className="t-flex t-justify-between">
                <div>
                  <Title level={4} className="t-text-secondary-100">
                    Room Availability Duration
                  </Title>
                  <Paragraph>
                    You need to define room availability for each day of week
                    for this room
                  </Paragraph>
                </div>
                <Button type="primary" onClick={onClearAllClick}>
                  Clear All
                </Button>
              </div>

              <div className="t-space-y-2 t-w-full t-pt-4 t-overflow-scroll">
                <div className="t-flex t-space-x-2 t-items-center t-px-2">
                  <div
                    className="t-flex t-space-x-2 t-items-center t-min-w-36"
                    style={{
                      minWidth: '9rem',
                    }}
                  >
                    <Title
                      level={5}
                      className="t-font-bold t-text-secondary-100"
                    >
                      Day
                    </Title>
                  </div>
                  <Title level={5} className="t-font-bold t-text-secondary-100">
                    Available Time slots
                  </Title>
                </div>
                <Divider />
                {Object.keys(schedule).map((day, index) => (
                  <DayDurations
                    key={index}
                    value={schedule[day]}
                    onChange={onChangeSchedule}
                    onApplyAll={() => onApplyToAll(schedule[day])}
                    updateSchedule={schedule}
                  />
                ))}
              </div>
            </Col>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item name="Image" label="Room Image" colon={false}>
                <ImageUploader
                  ratio={2 / 1}
                  defaultFile={
                    form.getFieldValue('Image') && form.getFieldValue('Image')
                  }
                  onFileChange={(file) =>
                    form.setFieldsValue({
                      Image: file?.originFileObj,
                    })
                  }
                />
                {/* <Upload
                  beforeUpload={(file) => {
                    if (file.size > 10000000) {
                      message.error('File must be less than 10mb')
                      return false
                    }
                    return true
                  }}
                  customRequest={(e) => form.setFieldsValue({ Image: e.file })}
                  maxCount={1}
                  showUploadList={false}
                  accept="image/*"
                >
                  <Button
                    icon={<UploadOutlined />}
                    style={{ marginRight: '1rem' }}
                  >
                    {form.getFieldValue('Image') ? 'Change' : 'Click to Upload'}
                  </Button>
                  {form.getFieldValue('Image') &&
                    form.getFieldValue('Image').name}
                </Upload> */}
              </Form.Item>
            </Col>
            <Col span={24}>
              <div className="text-left t-my-4 t-space-x-2">
                <Button
                  type="primary"
                  classname="t-w-full md:t-w-auto"
                  htmlType="submit"
                  loading={loading}
                  disabled={isValidSchedule(schedule)}
                >
                  {id ? 'Update Rental Facility' : 'Add Rental Facility'}
                </Button>
                <Button
                  type="default"
                  classname="t-w-full md:t-w-auto"
                  onClick={() => history.goBack()}
                >
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Skeleton>
    </Card>
  )
}

export { CreateRoom }
