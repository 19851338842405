import React, { useEffect, useState } from 'react'
import {
  Skeleton,
  Button,
  Card,
  Col,
  Row,
  Spin,
  Tooltip,
  Divider,
  Tag,
} from 'antd'
import {
  compareDateTimeWithNow,
  fetchMoreData,
  formateDateTime,
  searchEventCampaignRows,
} from 'utils'
import { awsconfig, config } from 'configs'
import { getNearByEvents } from 'store/eventSlice/eventActions'
import { useDispatch } from 'react-redux'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { eventActions, useEventSelector } from 'store/eventSlice/eventReducer'
import { useHistory } from 'react-router-dom'
import { ApiOutlined } from '@ant-design/icons'

import sample from 'assets/images/sample/sample1.png'
import InfiniteScroll from 'react-infinite-scroll-component'
import { BsFillCalendarCheckFill } from 'react-icons/bs'
import { RiRadioButtonLine } from 'react-icons/ri'
import { AllCampaignList } from './AllCampaignList'

function CampaignList({ searchText, sortKey }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const { user, organization, location } = useAuthSelector()
  const positionData = {
    lat: location?.lat,
    lon: location?.lng,
    zipCode: location?.zipcode,
  }
  const { loading, campaigns } = useEventSelector()

  const notEndedCampaigns = campaigns?.filter(
    (item) =>
      item.EndDate &&
      compareDateTimeWithNow(item.EndDate, 'before') &&
      item.IsPrivate === false,
  )
  const [current, setCurrent] = useState([])

  const searchedData = searchEventCampaignRows(notEndedCampaigns, searchText)

  useEffect(() => {
    setCurrent(searchedData.slice(0, config.itemFirstLoad))
    // eslint-disable-next-line
  }, [campaigns, searchText])

  useEffect(() => {
    const zipCode = location?.zipcode
    if (zipCode) {
      dispatch(getNearByEvents(positionData, sortKey))
    }
    // eslint-disable-next-line
  }, [dispatch, location, sortKey])

  const handleCampaignSelected = (event) => {
    dispatch(eventActions.setEvent(null))
    history.push(`campaigns/${event.Id}`)
  }

  const goToJoinScreen = (e, event) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(eventActions.setJoinedEventDetails(null))
    dispatch(eventActions.setEvent(event))
    history.push(`/campaigns/${event?.Id}/join-event`)
  }

  const goToControlScreen = (e, event) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(eventActions.setJoinedEventDetails(null))
    dispatch(eventActions.setEvent(event))
    history.push(`/campaigns/${event?.Id}/control-screen`)
  }

  const CutomDateFormater = (item) => {
    if (item.StartDate && compareDateTimeWithNow(item.StartDate, 'after')) {
      return (
        <div className="t-flex t-items-center t-space-x-2">
          <Tooltip title="Start At">
            <RiRadioButtonLine fontSize="1.1rem" className="t-text-green-700" />
          </Tooltip>
          <div className="card-tag t-font-thin t-text-secondary-100">
            Active
          </div>
        </div>
      )
    }
    return (
      <>
        <div className="t-flex t-items-center t-space-x-2">
          <Tooltip title="Start At">
            <BsFillCalendarCheckFill className="t-text-secondary-100" />
          </Tooltip>
          <div className="card-tag t-font-thin t-text-secondary-100">
            <Tooltip title="MM-DD-YYYY hh:mm A">
              {item?.StartDate && formateDateTime(item?.StartDate)}
            </Tooltip>
          </div>
        </div>
        {/* <div className="t-flex t-items-center t-space-x-2">
          <Tooltip title="End At">
            <BsFillCalendarXFill className="t-text-secondary-100" />
          </Tooltip>
          <div className="card-tag t-font-thin t-text-secondary-100">
            <Tooltip title="MM-DD-YYYY hh:mm A">
              {item?.EndDate && formateDateTime(item?.EndDate)}
            </Tooltip>
          </div>
        </div> */}
      </>
    )
  }

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
      <div
        className="t-flex t-flex-col t-justify-between t-h-full"
        style={{ minHeight: 'calc(100vh - 250px)' }}
      >
        <InfiniteScroll
          dataLength={current.length}
          next={() => fetchMoreData(current, setCurrent, searchedData)}
          hasMore={current.length < searchedData.length}
          loader={
            <div className="t-flex t-justify-center t-items-center">
              <Spin />
            </div>
          }
        >
          <Row gutter={[24, 24]} className="t-h-full">
            {current.length === 0 && (
              <>
                <div className="t-flex t-justify-center t-w-full">
                  <div className="t-flex t-flex-col t-items-center t-space-y-2">
                    <ApiOutlined
                      style={{ fontSize: '2rem' }}
                      className="t-text-secondary-100"
                    />
                    <p>No nearby campaigns found</p>
                  </div>
                </div>
                <Divider />
                <p className="t-ml-4 t-text-lg t-font-semibold">
                  All campaigns
                </p>
                <AllCampaignList sortKey={sortKey} />
              </>
            )}
            {current.map((item, index) => (
              <Col span={24} md={8} xl={8} key={index}>
                <Card
                  bordered
                  hoverable
                  cover={
                    <img
                      alt="example"
                      className="t-w-full t-h-48 t-object-cover t-rounded-md"
                      src={
                        item?.Flyer?.length
                          ? awsconfig.bucketUrl + item?.Flyer[0]?.fileName
                          : item?.Flyer?.fileName
                          ? awsconfig.bucketUrl + item?.Flyer?.fileName
                          : item?.OrganizationBackgroundImage
                          ? awsconfig.bucketUrl +
                            item?.OrganizationBackgroundImage?.fileName
                          : sample
                      }
                    />
                  }
                  onClick={() => handleCampaignSelected(item)}
                >
                  <div className="t-relative t-flex t-justify-end">
                    {item?.EventType === 'Campaign' &&
                      item?.CampaignSubType === 'Auction' && (
                        <Tag
                          color="purple"
                          className="t-absolute t-right-0 -t-top-[0.8rem]"
                        >
                          Auction
                        </Tag>
                      )}
                  </div>
                  <h5 className="t-text-2xl t-text-secondary-100 t-font-bold t-truncate">
                    <Tooltip title={item?.Title}>{item?.Title}</Tooltip>
                  </h5>
                  <div className="t-flex t-justify-between">
                    <div className="card-tag t-font-thin t-text-secondary-100">
                      {item?.OrganizationName}
                    </div>
                  </div>
                  <p className="t-line-clamp-1 t-text-sm">{item?.Details}</p>
                  <div>{CutomDateFormater(item)}</div>
                  <div className="t-flex t-justify-end t-mt-2 t-space-x-1 t-h-8">
                    {(item?.OrganizationId === organization?.id ||
                      (item?.PresenterEmail === user?.email &&
                        item?.AcceptanceStatus === 'Accepted')) && (
                      <Button
                        type="primary"
                        size="small"
                        className="t-rounded-full"
                        onClick={(e) => goToControlScreen(e, item)}
                      >
                        Control
                      </Button>
                    )}
                    {item.startEvent &&
                      item.StartDate &&
                      compareDateTimeWithNow(item.StartDate, 'after') && (
                        <Button
                          type="primary"
                          size="small"
                          className="t-rounded-full"
                          onClick={(e) => goToJoinScreen(e, item)}
                        >
                          Join
                        </Button>
                      )}
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </InfiniteScroll>
      </div>
    </Skeleton>
  )
}

export { CampaignList }
