import React from 'react'
import { CloseCircleFilled, PlusCircleOutlined } from '@ant-design/icons'
import { Card } from 'antd'

const styles = {
  fontSize: '24px',
  cursor: 'pointer',
}

const CollapsiblePanel = ({ isOpen, setToggle, title, children }) => (
  <Card
    title={title}
    extra={
      !isOpen ? (
        <PlusCircleOutlined onClick={setToggle} style={styles} />
      ) : (
        <CloseCircleFilled onClick={setToggle} style={styles} />
      )
    }
    headStyle={{
      borderBottom: `1px solid ${isOpen ? '#efefef' : 'white'}`,
    }}
  >
    {isOpen ? children : <></>}
  </Card>
)

export default CollapsiblePanel
