import React, { useState } from 'react'
import { Card, Input, Tooltip } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { MdDashboard } from 'react-icons/md'
import { RiDashboardLine } from 'react-icons/ri'
import { ComponentViewHeader } from 'components/common/componentView/components'
import { RentalList } from './components'

const RentalFacilities = () => {
  const [searchText, setSearchText] = useState('')
  const [rentalPriority, setRentalPriority] = useState(false)
  const handleView = () => {
    setRentalPriority(!rentalPriority)
  }

  return (
    <Card
      bordered={false}
      className="header-solid mb-24"
      title={
        <div className="t-flex t-flex-wrap t-justify-between">
          <ComponentViewHeader
            heading="Rental Facilities"
            subHeading="All Rentals"
            buttonLink="/settings/create-room"
          />
          <div className="t-flex t-justify-end t-items-center t-space-x-2 t-mt-4 sm:t-mt-0">
            <div className="header-control md:t-mt-0">
              <Input
                id="searchEvent"
                className="header-search t-w-48"
                placeholder="Search Capacity"
                prefix={<SearchOutlined />}
                onChange={(e) => {
                  setSearchText(e.target.value)
                }}
              />
            </div>
            {rentalPriority ? (
              <Tooltip title="All Rentals">
                <MdDashboard
                  className="t-cursor-pointer t-text-cyan-700 t-text-2xl"
                  onClick={handleView}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Nearby Rental">
                <RiDashboardLine
                  className="t-cursor-pointer t-text-cyan-700 t-text-2xl"
                  onClick={handleView}
                />
              </Tooltip>
            )}
          </div>
        </div>
      }
    >
      <div style={{ minHeight: 'calc(100vh - 205px)' }}>
        <RentalList searchText={searchText} rentalPriority={rentalPriority} />
      </div>
    </Card>
  )
}

export { RentalFacilities }
