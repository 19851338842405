import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Button, Tooltip, Skeleton, message } from 'antd'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useDispatch } from 'react-redux'
import {
  addFavourite,
  removeFavourite,
} from 'store/favouriteSlice/favouriteActions'
import { useFavouriteSelector } from 'store/favouriteSlice/favouriteReducer'
import { SVGIcon } from 'components'
import { useBusinessSelector } from 'store/businessSlice/businessReducer'
import { getBusinessDetails } from 'store/businessSlice/businessActions'
import {
  MdOutlineMessage,
  MdOutlineReportProblem,
  MdOutlineStarRate,
} from 'react-icons/md'
import Api from 'api'
import { BusinessProfile } from './components/BusinessProfile'
import { BusinessRateForm } from './components/BusinessRateForm'
import { BusinessContactForm } from './components/BusinessContactForm'

const BusinessView = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const { state } = useLocation()

  const { loading, business } = useBusinessSelector()
  const { loading: favLoading } = useFavouriteSelector()
  const { user } = useAuthSelector()
  const [isContactModalVisible, setIsContactModalVisible] = useState(false)
  const [isRateModalVisible, setIsRateModalVisible] = useState(false)

  const getBusinessByID = () => {
    dispatch(getBusinessDetails(params?.id))
  }

  useEffect(() => {
    if (params?.id) {
      getBusinessByID()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id])

  const handleAddFavourite = async (id) => {
    const payload = {
      ReferenceId: id,
      Username: user?.id,
      Type: 'business',
    }
    dispatch(addFavourite(payload, getBusinessByID))
  }

  const handleRemoveFavourite = async (id) => {
    dispatch(removeFavourite(id, getBusinessByID))
  }

  const handleBusinessContact = (data) => {
    setIsContactModalVisible(false)
    if (business) {
      const payload = {
        businessEmail: business.UserEmail,
        ...data,
      }
      // setLoading('custom')
      Api.post(`business/contact-business`, payload)
        .then((res) => {
          message.success(res.message)
          // setLoading('custom')
        })
        .catch((error) => {
          console.log('error', error)
          message.error(error?.message)
          // setLoading('custom')
        })
    } else {
      message.error('Cound not get Business data')
    }
  }

  const handleRating = (data) => {
    setIsRateModalVisible(false)
    if (business) {
      const payload = {
        userId: user?.id,
        businessEmail: business.UserEmail,
        ...data,
      }
      // setLoading('custom')
      Api.post(`business/rate-business`, payload)
        .then((res) => {
          message.success(res.message)
          // setLoading('custom')
        })
        .catch((error) => {
          console.log('error', error)
          message.error(error?.message)
          // setLoading('custom')
        })
    } else {
      message.error('Cound not get Business data')
    }
  }

  const organizationLoading = loading || favLoading

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <Card
            title={[
              <div className="t-flex t-justify-between t-py-0 t-my-0">
                <div className="t-flex t-items-center t-space-x-4" id="back">
                  <Button
                    type="primary"
                    className="t-rounded-full t-flex t-items-center"
                    onClick={() =>
                      state && state.urlData
                        ? history.push({
                            pathname: state.urlData.pathname,
                            state: { key: state.urlData.activeKey },
                          })
                        : history.goBack()
                    }
                    size="small"
                  >
                    <IoIosArrowBack />
                    Back
                  </Button>
                </div>
                <div className="t-flex t-items-center t-space-x-2 t-cursor-pointer">
                  <div
                    className={`${
                      organizationLoading &&
                      't-pointer-events-none t-opacity-50'
                    } ${!business && 't-pointer-events-none t-opacity-0'}`}
                  >
                    {business?.isFav ? (
                      <Tooltip title="Remove Favorite">
                        <AiFillHeart
                          fontSize="1.8rem"
                          id="remove-fav"
                          className="t-cursor-pointer t-text-red-500"
                          onClick={() => handleRemoveFavourite(business?.favId)}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Make Favorite">
                        <AiOutlineHeart
                          fontSize="1.8rem"
                          id="make-fav"
                          className="t-cursor-pointer t-text-secondary-100"
                          onClick={() => handleAddFavourite(business?.Id)}
                        />
                      </Tooltip>
                    )}
                  </div>
                  {business && business?.didBusiness && (
                    <Tooltip title="Rate Business">
                      <MdOutlineStarRate
                        fontSize="1.5rem"
                        className="t-cursor-pointer t-text-secondary-100"
                        onClick={() => setIsRateModalVisible(true)}
                      />
                    </Tooltip>
                  )}
                  {business && business?.didBusiness && (
                    <Tooltip title="Open Dispute">
                      <MdOutlineReportProblem
                        fontSize="1.5rem"
                        className="t-cursor-pointer t-text-secondary-100"
                        onClick={() => setIsContactModalVisible(true)}
                      />
                    </Tooltip>
                  )}
                  <Tooltip title="Contact Business">
                    <MdOutlineMessage
                      fontSize="1.5rem"
                      className="t-cursor-pointer t-text-secondary-100"
                      onClick={() => setIsContactModalVisible(true)}
                    />
                  </Tooltip>
                </div>
              </div>,
            ]}
            bordered={false}
            className="header-solid card-product t-p-0"
          >
            <Row
              gutter={[24, 0]}
              style={{ minHeight: 'calc(100vh - 200px)' }}
              className="t-p-1 md:t-p-4"
            >
              <Skeleton
                loading={organizationLoading}
                active
                paragraph={{ rows: 18 }}
              >
                {business ? (
                  <BusinessProfile
                    business={business}
                    // isContactModalVisible={isContactModalVisible}
                    // setIsContactModalVisible={setIsContactModalVisible}
                  />
                ) : (
                  <div className="t-w-full t-h-full t-flex t-justify-center t-items-center">
                    <div className="ant-result ant-result-404">
                      <SVGIcon />
                      <div className="ant-result-title">Invalid URL</div>
                      <div className="ant-result-subtitle">
                        Sorry, the page you visited does not exist.
                      </div>
                      <div className="ant-result-extra">
                        <Button
                          onClick={() => history.push('/businesses')}
                          type="primary"
                          size="small"
                        >
                          Goto Businesses
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </Skeleton>
            </Row>
          </Card>
        </Col>
      </Row>
      {isContactModalVisible && (
        <BusinessContactForm
          isModalVisible={isContactModalVisible}
          setIsModalVisible={setIsContactModalVisible}
          submit={(values) => handleBusinessContact(values)}
        />
      )}
      {isRateModalVisible && (
        <BusinessRateForm
          isModalVisible={isRateModalVisible}
          setIsModalVisible={setIsRateModalVisible}
          businessEmail={business?.UserEmail}
          submit={(values) => handleRating(values)}
        />
      )}
    </>
  )
}

export { BusinessView }
