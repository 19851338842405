import React, { useEffect, useState } from 'react'
import { Menu } from 'antd'
import { NavLink, useHistory, withRouter } from 'react-router-dom'
import { menuItems } from 'configs'
import { useDispatch } from 'react-redux'
import { getOrganization, logout } from 'store/authSlice/authActions'
import { useAuthSelector } from 'store/authSlice/authReducer'
import NavIcon from './NavIcon'
import NavBadge from './NavBadge'

const routes = [
  'dashboard',
  'organizations',
  'campaigns',
  'events',
  'programs',
  'services',
  'volunteer',
  'services',
  'campaigns',
]

const debounce = (callback, delay) => {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => callback(...args), delay)
  }
}

const NavContent = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { organization, user, invites } = useAuthSelector()
  const [openKeys, setOpenKeys] = useState([])
  const [rootKeys, setRootKeys] = useState([])

  const [windowWidth, setWindowWidth] = useState(window?.innerWidth)

  const userHaveInvites = invites.length > 0

  const handleResize = () => {
    debounce(() => setWindowWidth(window.innerWidth), 100)
  }

  useEffect(() => {
    if (user?.email) {
      dispatch(getOrganization(user.email))
    }
    // eslint-disable-next-line
  }, [user?.email])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    let updatedrootKeys = menuItems.items.map((item) => {
      if (item.children) {
        return [...item.children.map((child) => child.id)]
      }
      return false
    })
    updatedrootKeys = [].concat.apply([], updatedrootKeys)

    if (props.location) {
      const keys = props.location.pathname.split('/')
      if (!routes.includes(keys[keys.length - 1])) {
        keys.pop()
      }
      keys.splice(0, 1)
      setOpenKeys(keys)
    }
    setRootKeys(updatedrootKeys)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (windowWidth > 992) {
      if (openKeys.length === 0 && windowWidth > 992) {
        let updatedrootKeys = menuItems.items.map((item) => {
          if (item.children) {
            return [...item.children.map((child) => child.id)]
          }
          return false
        })
        updatedrootKeys = [].concat.apply([], updatedrootKeys)
        if (props.location) {
          const keys = props.location.pathname.split('/')
          if (!routes.includes(keys[keys.length - 1])) {
            keys.pop()
          }
          keys.splice(0, 1)
          setOpenKeys(keys)
        }
        setRootKeys(updatedrootKeys)
      }
    }
    // eslint-disable-next-line
  }, [openKeys.length, windowWidth])

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1)
    if (rootKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys)
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }

  const onLogoutClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    const move = () => {
      history.push('/')
    }
    dispatch(logout(move))
  }

  const renderItem = (navigation) => {
    if (!navigation) return <div></div>
    return navigation.map((item, index) => {
      switch (item.type) {
        case 'group':
          return (
            <React.Fragment key={index}>
              <Menu.Item
                className={`menu-item-header ${
                  item.className ? item.className : ''
                }`}
                key={item.id || index}
              >
                {item.title}
              </Menu.Item>
              {renderItem(item.children)}
            </React.Fragment>
          )
        case 'collapse':
          return (
            <Menu.SubMenu
              key={item.id || index}
              id={item.id}
              icon={
                item.icon
                  ? item.icon(
                      openKeys.includes(item.id),
                      window.sessionStorage.getItem('color_theam'),
                    )
                  : ''
              }
              title={item.title}
            >
              <Menu.ItemGroup key={`${item.id}1`}>
                {renderItem(item.children)}
              </Menu.ItemGroup>
            </Menu.SubMenu>
          )
        case 'item':
          if (item.target) {
            return (
              <Menu.Item key={item.id} className="catlog">
                <a
                  href={item.url}
                  className="nav-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <NavIcon items={item} />
                  <span className="label">{item.title}</span>
                  <NavBadge items={item} />
                </a>
              </Menu.Item>
            )
          }
          return (
            <>
              {item.button ? (
                <Menu.Item key={item.id} id="sidebar-subtitle-logout">
                  <NavLink
                    to={item.url}
                    onClick={onLogoutClick}
                    className="nav-link"
                    exact
                  >
                    <NavIcon items={item} />
                    <span className="label">{item.title}</span>
                    <NavBadge items={item} />
                  </NavLink>
                </Menu.Item>
              ) : (
                <>
                  {item.id === 'sidebar-subtitle-register-organization' ? (
                    <>
                      {!organization && (
                        <Menu.Item key={item.id} id={item.id}>
                          <NavLink to={item.url} className="nav-link" exact>
                            <NavIcon items={item} />
                            <span className="label">{item.title}</span>
                            <NavBadge items={item} />
                          </NavLink>
                        </Menu.Item>
                      )}
                    </>
                  ) : (
                    <>
                      {item.id === 'sidebar-subtitle-my-invites' ? (
                        <>
                          {userHaveInvites && (
                            <Menu.Item key={item.id} id={item.id}>
                              <NavLink to={item.url} className="nav-link" exact>
                                <NavIcon items={item} />
                                <span className="label">{item.title}</span>
                                <NavBadge items={item} />
                              </NavLink>
                            </Menu.Item>
                          )}
                        </>
                      ) : (
                        <Menu.Item key={item.id} id={item.id}>
                          <NavLink to={item.url} className="nav-link">
                            <NavIcon items={item} />
                            <span className="label">{item.title}</span>
                            <NavBadge items={item} />
                          </NavLink>
                        </Menu.Item>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )
        default:
          return false
      }
    })
  }

  return (
    <div className="navbar-content datta-scroll">
      <Menu
        theme="light"
        mode="inline"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        onClick={props.onClose}
      >
        {renderItem(props.navigation)}
      </Menu>
      <div style={{ padding: '4rem 0rem' }} />
    </div>
  )
}

export default withRouter(NavContent)
