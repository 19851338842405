/* eslint-disable */
import React, { useEffect } from 'react'
import { Icon } from 'components'
import { useHistory } from 'react-router-dom'
import { config } from 'configs'
import { subscribe } from './Webpush'
import { useRoles } from 'hooks'

const Splash = ({ text, setIsSplash }) => {
  const history = useHistory()
  const { isAdmin, isSubscriber, isDonor } = useRoles()

  useEffect(() => {
    setTimeout(() => {
      if (setIsSplash) {
        setIsSplash(false)
      } else {
        if (isAdmin) {
          history.push('/organizations')
        } else if (isSubscriber) {
          history.push('/home')
        } else if (isDonor) {
          history.push('/campaigns')
        } else {
          history.push('/campaigns')
        }
        subscribe()
      }
    }, config.splash_screen_seconds * 1000)
  }, [])

  return (
    <div className="t-h-screen t-flex t-flex-col t-justify-center t-items-center">
      <Icon src="pg_logo" />
      <p className="t-mt-4 t-text-3xl t-w-251 md:t-w-551 t-text-center font-palatino">
        {text || 'Welcome'}
      </p>
    </div>
  )
}

export { Splash }
