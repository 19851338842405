import React, { useEffect, useState } from 'react'
import { Switch, Button } from 'antd'
import { useHistory } from 'react-router-dom'
import { useRoles } from 'hooks'
import { Admin } from './admin'
import { DonorOrganizationsView } from './donor'

const OrganizationsList = () => {
  const history = useHistory()
  const [isAdminView, setAdminView] = useState(false)
  const { isAdmin, isSubscriber, isDonor } = useRoles()

  useEffect(() => {
    if (!isAdmin && isSubscriber) {
      history.push('/home')
    } else if (!isAdmin && isDonor) {
      history.push('/campaigns')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {isAdmin && (
        <div className="t-flex t-justify-between t-items-center t-mb-2 t-p-4 md:t-p-0">
          <Button
            type="primary"
            size="small"
            onClick={() =>
              history.push('/settings/manage-organization/organization-detail')
            }
          >
            Go To My Organization
          </Button>
          <div className="t-w-full t-flex t-justify-end t-mb-4 t-space-x-2 t-items-center">
            <h1 className="t-font-bold t-text-secondary-100">View As Admin</h1>
            <Switch
              title="View As Admin"
              checked={isAdminView}
              onChange={(checked) => setAdminView(checked)}
            />
          </div>
        </div>
      )}
      {isAdmin && isAdminView ? <Admin /> : <DonorOrganizationsView />}
    </>
  )
}

export { OrganizationsList }
