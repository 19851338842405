import React, { useState, useEffect } from 'react'
import {
  Card,
  Form,
  Input,
  Row,
  Col,
  Button,
  Select,
  Switch,
  message,
  Skeleton,
  DatePicker,
  Divider,
  Modal,
  notification,
  Tooltip,
} from 'antd'
import { eventValidation } from 'validations'
import {
  addEvent,
  getDonorsEmail,
  getEvent,
  getOrganizationTopKioskEvents,
  updateEvent,
} from 'store/eventSlice/eventActions'
import { useParams, useHistory } from 'react-router-dom'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { getUserOrganization } from 'store/organizationSlice/organizationActions'
import moment from 'moment'
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'
import Api from 'api'
import { signUpWithoutOtp } from 'store/authSlice/authActions'
import {
  compareDateTimeWithNow,
  disabledTime,
  formatUSAPhoneNumber,
  MaskPattern,
  numberWithCommas,
} from 'utils'
import MaskedInput from 'antd-mask-input'
import { MultiImageUploader } from 'components/elements/MultiImageUploader'
import { AuctionItems } from 'components/common/auction/AuctionItems'
import { AddItemsModal } from 'components/common/auction/AddItemsModal'
import { AmountInput } from 'components/elements'
import { BsInfoCircleFill } from 'react-icons/bs'
import { UpdatePresenter } from './UpdatePresenter'

// import ReCAPTCHA from "react-google-recaptcha";

const { TextArea } = Input
const { Option } = Select

const eventTypes = ['Fundraiser', 'Campaign']

const initialValues = {
  Title: '',
  EventType: 'Fundraiser',
  zipCode: '',
  TargetAmount: '',
  YoutubeLink: '',
  SpeakerName: '',
  SpeakerBio: '',
  monthlyPaymentAllowed: false,
  monthlyPaymentMonths: '',
  splitPaymentAllowed: false,
  splitPaymentMonths: '',
  EditAskedAmount: false,
  ShowDonatedAmountToDonor: true,
  ShowProgressBarToDonor: true,
  ShowOnPage: false,
  ShowOnKiosk: false,
  TopKioskEvent: false,
  ShowMailingAddress: true,
  Details: '',
  Presenter: 'Myself',
  PresenterName: '',
  PresenterPhoneCode: '',
  PresenterMobileNumber: '',
  PresenterEmail: '',
  StartDate: null,
  EndDate: null,
  IsPrivate: false,

  OrganizationId: '',
  refLink: `${window.location.origin}/presenter/accept`,
  lat: '',
  lon: '',
  startEvent: false,
  isSelfPresent: true,
  Flyer: [],
  // Presentation: null,

  PledgeReminders: 'Scheduled',
  MessagingFrequency: 'Monthly',
  MaximumMessages: 3,

  CampaignSubType: 'Fundraiser',

  PaymentCategoryAdded: false,
  PaymentCategories: [],

  DefaultDonationValue1: '25',
  DefaultDonationValue2: '50',
  DefaultDonationValue3: '100',
  DefaultDonationValue4: '225',
}

const CreateEvent = () => {
  const { user, location, organization } = useAuthSelector()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [fetching, setFetched] = useState(false)
  const [fetchingTopKioskEvent, setFetchingTopKioskEvent] = useState(false)
  const [topKioskEvent, setTopKioskEvent] = useState([])
  const [eventDetail, setEvent] = useState(null)
  const [donorEmails, setDonorEmails] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [donorsSelected, setDonorsSelected] = useState([])
  const [categories, setCategories] = useState([])

  // Aution states
  const [auctionItems, setAuctionItems] = useState(false)
  const [addItemsModal, setAddItemsModal] = useState(false)
  const [auctionItemsList, setAuctionItemsList] = useState([])

  // For storing default files
  const [defaultFyler, setDefaultFyler] = useState([])

  const params = useParams()
  const history = useHistory()

  Form.useWatch('TargetAmount', form)
  Form.useWatch('Presenter', form)
  Form.useWatch('monthlyPaymentAllowed', form)
  Form.useWatch('splitPaymentAllowed', form)
  Form.useWatch('Flyer', form)
  // Form.useWatch('Presentation', form)
  Form.useWatch('EventType', form)
  Form.useWatch('CampaignSubType', form)
  Form.useWatch('zipCode', form)
  Form.useWatch('EditAskedAmount', form)
  Form.useWatch('ShowDonatedAmountToDonor', form)
  Form.useWatch('ShowProgressBarToDonor', form)
  Form.useWatch('ShowOnPage', form)
  Form.useWatch('ShowOnKiosk', form)
  const isShowOnKiosk = Form.useWatch('ShowOnKiosk', form) === true
  Form.useWatch('TopKioskEvent', form)
  Form.useWatch('ShowMailingAddress', form)
  Form.useWatch('StartDate', form)
  Form.useWatch('EndDate', form)
  Form.useWatch('IsPrivate', form)
  const isPrivateCampaign = Form.useWatch('IsPrivate', form) === true
  const isScheduled = Form.useWatch('PledgeReminders', form) === 'Scheduled'
  Form.useWatch('PaymentCategoryAdded', form)
  Form.useWatch('PaymentCategories', form)
  const PaymentCategoriesValues = Form.useWatch('PaymentCategories', form)

  const getPaymentCategories = () => {
    Api.get(
      `paymentCategories/getOrganizationPaymentCategories/${organization?.id}`,
    )
      .then((res) => {
        const formatedCategories = res?.results
          ?.filter((item) => item.ActiveInd === 1)
          .map((item) => ({
            value: item.Id,
            label: item.Name,
          }))
        setCategories(formatedCategories)
      })
      .catch((error) => {
        console.log('error', error)
        message.error('An Error Occured in fetching payment categories')
      })
  }

  useEffect(() => {
    fetchDonorsEmails()
    getPaymentCategories()
    fetchTopKioskEvent()
    // eslint-disable-next-line
  }, [user?.email])

  useEffect(() => {
    if (!params.id && organization && organization?.ZipCode) {
      form.setFieldsValue({ zipCode: organization.ZipCode })
    }
    // eslint-disable-next-line
  }, [params.id, organization])

  useEffect(() => {
    const getData = async () => {
      setFetched(true)
      if (params.id) {
        const event = await getEvent(params.id)
        setEvent(event)
        if (event) {
          setPresenterValidate(true)
          setPresenterFound(true)

          setDonorsSelected(event?.SeedDonors || [])
          const dummyDefaultFlyer = event.Flyer
            ? event.Flyer.length
              ? event.Flyer
              : [event.Flyer]
            : []

          setDefaultFyler(dummyDefaultFlyer)

          form.setFieldsValue({
            Title: event.Title,
            EventType: event.EventType,
            zipCode: event.zipCode,
            TargetAmount: numberWithCommas(event.TargetAmount),
            YoutubeLink: event.YoutubeLink,
            SpeakerName: event.SpeakerName,
            SpeakerBio: event.SpeakerBio,
            monthlyPaymentAllowed: event.monthlyPaymentAllowed,
            monthlyPaymentMonths: event.monthlyPaymentMonths,
            splitPaymentAllowed: event.splitPaymentAllowed,
            splitPaymentMonths: event.splitPaymentMonths,
            EditAskedAmount: event.EditAskedAmount,
            ShowDonatedAmountToDonor: event.hasOwnProperty(
              'ShowDonatedAmountToDonor',
            )
              ? event.ShowDonatedAmountToDonor
              : true,
            ShowProgressBarToDonor: event.hasOwnProperty(
              'ShowProgressBarToDonor',
            )
              ? event.ShowProgressBarToDonor
              : true,
            ShowOnPage: event.ShowOnPage ? event.ShowOnPage : false,
            ShowOnKiosk: event.ShowOnKiosk ? event.ShowOnKiosk : false,
            TopKioskEvent: event.TopKioskEvent ? event.TopKioskEvent : false,
            ShowMailingAddress: event?.hasOwnProperty('ShowMailingAddress')
              ? event?.ShowMailingAddress
              : true,
            Details: event.Details,
            Presenter: event.Presenter,
            PresenterName: event.PresenterName,
            PresenterPhoneCode:
              event.isSelfPresent === false ? event.PresenterPhoneCode : '',
            PresenterMobileNumber:
              event.isSelfPresent === false
                ? formatUSAPhoneNumber(event.PresenterMobileNumber)
                : '',
            PresenterEmail: event.PresenterEmail,
            OrganizationId: event.OrganizationId,
            IsPrivate: !!event.IsPrivate,
            refLink: event.refLink,
            lat: event.lat,
            lon: event.lon,
            startEvent: event.startEvent,
            isSelfPresent: event.isSelfPresent,
            Flyer: event.Flyer
              ? event.Flyer.length
                ? event.Flyer
                : [event.Flyer]
              : [],
            Presentation: event.Presentation,
            StartDate: moment(event.StartDate),
            EndDate: event.EndDate ? moment(event.EndDate) : null,
            CampaignSubType: event?.CampaignSubType,
            PaymentCategoryAdded: event?.PaymentCategoryAdded ?? false,
            PaymentCategories:
              event?.PaymentCategories && event?.PaymentCategories,
          })
          if (
            event?.DefaultDonationValues &&
            event?.DefaultDonationValues.length === 4
          ) {
            form.setFieldsValue({
              DefaultDonationValue1: event?.DefaultDonationValues[0],
              DefaultDonationValue2: event?.DefaultDonationValues[1],
              DefaultDonationValue3: event?.DefaultDonationValues[2],
              DefaultDonationValue4: event?.DefaultDonationValues[3],
            })
          }
          if (event.AcceptanceStatus === 'Rejected') {
            notification.warning({
              message: 'Action Needed',
              description: (
                <div>
                  Presenter rejected to present event. Please update the
                  presenter
                </div>
              ),
              duration: 0,
            })
          }
        } else {
          message.error('Invalid Event Id')
          history.push('/settings/create-event/')
        }
      }
      setFetched(false)
    }
    getData()
  }, [params.id, history, form])

  useEffect(() => {
    const getDuplicateInformation = async () => {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const queryparams = Object.fromEntries(urlSearchParams.entries())
      if (
        Object.entries(queryparams).length > 0 &&
        queryparams.duplicate &&
        queryparams.id
      ) {
        setFetched(true)
        const event = await getEvent(queryparams.id)
        setEvent(event)
        if (event) {
          setPresenterValidate(true)
          setPresenterFound(true)

          setDonorsSelected(event?.SeedDonors || [])
          const dummyDefaultFlyer = event.Flyer
            ? event.Flyer.length
              ? event.Flyer
              : [event.Flyer]
            : []

          setDefaultFyler(dummyDefaultFlyer)

          form.setFieldsValue({
            Title: event.Title,
            EventType: event.EventType,
            zipCode: event.zipCode,
            TargetAmount: numberWithCommas(event.TargetAmount),
            YoutubeLink: event.YoutubeLink,
            SpeakerName: event.SpeakerName,
            SpeakerBio: event.SpeakerBio,
            monthlyPaymentAllowed: event.monthlyPaymentAllowed,
            monthlyPaymentMonths: event.monthlyPaymentMonths,
            splitPaymentAllowed: event.splitPaymentAllowed,
            splitPaymentMonths: event.splitPaymentMonths,
            EditAskedAmount: event.EditAskedAmount,
            ShowDonatedAmountToDonor: event.hasOwnProperty(
              'ShowDonatedAmountToDonor',
            )
              ? event.ShowDonatedAmountToDonor
              : true,
            ShowProgressBarToDonor: event.hasOwnProperty(
              'ShowProgressBarToDonor',
            )
              ? event.ShowProgressBarToDonor
              : true,
            ShowOnPage: event.ShowOnPage ? event.ShowOnPage : false,
            ShowOnKiosk: event.ShowOnKiosk ? event.ShowOnKiosk : false,
            TopKioskEvent: event.TopKioskEvent ? event.TopKioskEvent : false,
            ShowMailingAddress: event?.hasOwnProperty('ShowMailingAddress')
              ? event?.ShowMailingAddress
              : true,
            Details: event.Details,
            Presenter: event.Presenter,
            PresenterName: event.PresenterName,
            PresenterPhoneCode:
              event.isSelfPresent === false ? event.PresenterPhoneCode : '',
            PresenterMobileNumber:
              event.isSelfPresent === false
                ? formatUSAPhoneNumber(event.PresenterMobileNumber)
                : '',
            PresenterEmail: event.PresenterEmail,
            OrganizationId: event.OrganizationId,
            IsPrivate: !!event.IsPrivate,
            refLink: event.refLink,
            lat: event.lat,
            lon: event.lon,
            startEvent: event.startEvent,
            isSelfPresent: event.isSelfPresent,
            Flyer: event.Flyer
              ? event.Flyer.length
                ? event.Flyer
                : [event.Flyer]
              : [],
            // Presentation: event.Presentation,
            // StartDate: moment(event.StartDate),
            // EndDate: event.EndDate ? moment(event.EndDate) : null,
            CampaignSubType: event?.CampaignSubType,
            PaymentCategoryAdded: event?.PaymentCategoryAdded || false,
            PaymentCategories:
              event?.PaymentCategories && event?.PaymentCategories,
          })
          if (
            event?.DefaultDonationValues &&
            event?.DefaultDonationValues.length === 4
          ) {
            form.setFieldsValue({
              DefaultDonationValue1: event?.DefaultDonationValues[0],
              DefaultDonationValue2: event?.DefaultDonationValues[1],
              DefaultDonationValue3: event?.DefaultDonationValues[2],
              DefaultDonationValue4: event?.DefaultDonationValues[3],
            })
          }
          history.replace('/settings/create-event')
        } else {
          message.error('Invalid Event Id')
          history.push('/settings/create-event/')
        }
        setFetched(false)
      }
    }
    getDuplicateInformation()
    // eslint-disable-next-line
  }, [])

  const onFinish = async (values) => {
    // To check if campaign end date is after start date
    const { StartDate, EndDate } = values

    if (isCampaign && EndDate && moment(EndDate).isBefore(moment(StartDate))) {
      message.error('End date time must be after start date time')
      return
    }

    if (!isNewPresenter || (isNewPresenter && presenterValidate)) {
      setLoading(true)
      try {
        if (isNewPresenter && presenterFound === false) {
          const { PresenterPhoneCode, PresenterMobileNumber } = values
          // removing - from string
          const FormatedPresenterMobileNumber = PresenterMobileNumber?.replace(
            /\D/g,
            '',
          )
          // removing zero if it's the first charater in the string
          const trimPhoneNumber = FormatedPresenterMobileNumber?.replace(
            /^0+/,
            '',
          )
          const userPhone = PresenterPhoneCode + trimPhoneNumber
          const name = values?.PresenterName?.split(' ')
          await signUpWithoutOtp(userPhone, {
            family_name: name[1],
            given_name: name[0],
            email: values.PresenterEmail,
          })
        }
        const userOrganization = await getUserOrganization(user.email)
        if (params.id) {
          const data = {
            ...eventDetail,
            ...values,
            PresenterMobileNumber: values?.PresenterMobileNumber?.replace(
              /\D/g,
              '',
            ),
            TargetAmount: values.TargetAmount.replaceAll(/,/g, ''),
            MessagingFrequency: isScheduled ? values.MessagingFrequency : null,
            MaximumMessages: isScheduled ? values.MessagingFrequency : null,
            EndDate: values.EndDate ? values.EndDate : null,
            SeedDonors: donorsSelected,
            DefaultDonationValues: [
              values.DefaultDonationValue1,
              values.DefaultDonationValue2,
              values.DefaultDonationValue3,
              values.DefaultDonationValue4,
            ],
          }
          delete data.DefaultDonationValue1
          delete data.DefaultDonationValue2
          delete data.DefaultDonationValue3
          delete data.DefaultDonationValue4
          await updateEvent(data, user, defaultFyler)
          message.success('Event Updated Successfully')
          setLoading(false)
          if (data.EventType === 'Fundraiser') {
            history.push('/events')
          } else {
            history.push('/campaigns')
          }
        } else {
          const data = {
            ...values,
            PresenterMobileNumber: values?.PresenterMobileNumber?.replace(
              /\D/g,
              '',
            ),
            TargetAmount: values.TargetAmount.replaceAll(/,/g, ''),
            lat: location?.lat,
            lon: location?.lng,
            OrganizationId: userOrganization?.id,
            OrganizationName: userOrganization?.Name,
            EndDate: values.EndDate ? values.EndDate : null,
            SeedDonors: donorsSelected,
            OrganizationBackgroundImage:
              organization &&
              organization?.BackgroundImage &&
              organization.BackgroundImage,
            DefaultDonationValues: [
              values.DefaultDonationValue1,
              values.DefaultDonationValue2,
              values.DefaultDonationValue3,
              values.DefaultDonationValue4,
            ],
          }
          delete data.DefaultDonationValue1
          delete data.DefaultDonationValue2
          delete data.DefaultDonationValue3
          delete data.DefaultDonationValue4

          // if auction enabled
          if (
            (auctionItems === true && !isCampaign) ||
            (isCampaign && isAuctionCampaign)
          ) {
            data.AuctionItemsList = auctionItemsList
          }
          await addEvent(data, user, defaultFyler)
          message.success('Event Added Successfully')
          setLoading(false)
          form.resetFields()
          if (data.EventType === 'Fundraiser') {
            history.push('/events')
          } else {
            history.push('/campaigns')
          }
        }
      } catch (error) {
        setLoading(false)
        console.log('Error', error)
        message.error(error?.response?.data?.message || 'Something Went Wrong')
      }
    } else {
      message.error('Kindly Validate Presenter')
    }
  }

  const ismonthlyPaymentAllowed = form.getFieldValue('monthlyPaymentAllowed')
  const issplitPaymentAllowed = form.getFieldValue('splitPaymentAllowed')
  const isNewPresenter = form.getFieldValue('Presenter') === 'NewPresenter'
  const [presenterValidate, setPresenterValidate] = useState(false)
  const [presenterFound, setPresenterFound] = useState(false)
  const isCampaign = form.getFieldValue('EventType') === 'Campaign'
  const isAuctionCampaign = form.getFieldValue('CampaignSubType') === 'Auction'

  const buttonText = isCampaign
    ? loading
      ? params.id
        ? 'Updating Campaign'
        : 'Creating Campaign'
      : params.id
      ? 'Update Campaign'
      : 'Create Campaign'
    : loading
    ? params.id
      ? 'Updating Event'
      : 'Creating Event'
    : params.id
    ? 'Update Event'
    : 'Create Event'

  // const onCancel = () => {
  //   if (form.getFieldValue('EventType') === 'Fundraiser') {
  //     history.push('/events')
  //   } else {
  //     history.push('/campaigns')
  //   }
  // }

  const validatePresenter = () => {
    const { PresenterPhoneCode, PresenterMobileNumber } = form.getFieldsValue()
    // removing - from string
    const FormatedPresenterMobileNumber = PresenterMobileNumber?.replace(
      /\D/g,
      '',
    )
    // removing zero if it's the first charater in the string
    const trimPhoneNumber = FormatedPresenterMobileNumber?.replace(/^0+/, '')
    const phone = PresenterPhoneCode + trimPhoneNumber
    setLoading(true)
    Api.get(
      `/users/check-phone-number-with-info?phone=${encodeURIComponent(phone)}`,
    )
      .then(() => {
        setPresenterValidate(true)
        setPresenterFound(false)
        form.setFieldValue('PresenterName', '')
        form.setFieldValue('PresenterEmail', '')
        setLoading(false)
      })
      .catch((err) => {
        const success = err?.response?.data?.message
        const data = err?.response?.data?.values
        if (success === 'User Found') {
          form.setFieldValue('PresenterName', data.name)
          form.setFieldValue('PresenterEmail', data.email)
          setPresenterFound(true)
          setPresenterValidate(true)
          setLoading(false)
        } else {
          message.error('Something Went Wrong')
        }
      })
  }

  const fetchDonorsEmails = async () => {
    const donors = await getDonorsEmail(user?.email)
    setDonorEmails(donors?.result)
  }

  const fetchTopKioskEvent = async () => {
    setFetchingTopKioskEvent(true)
    try {
      const topEvents = await getOrganizationTopKioskEvents(organization?.id)
      if (topEvents && topEvents.length) {
        setTopKioskEvent(topEvents)
      }
      setFetchingTopKioskEvent(false)
    } catch (error) {
      console.error('error', error)
      setFetchingTopKioskEvent(false)
    }
  }

  const handleTargetAmountChange = (value) => {
    const newValue = value.replaceAll(/,/g, '')
    const val = newValue.replace(/\D/g, '')
    form.setFieldsValue({ TargetAmount: numberWithCommas(val) })
  }

  const handleInputChange = (value) => {
    form.setFieldsValue({ PresenterMobileNumber: value })
  }

  const handleTopKioskEventChange = (value) => {
    if (value === true) {
      if (topKioskEvent?.length > 2) {
        form.setFieldValue('TopKioskEvent', !value)
        message.destroy()
        message.error(
          'Top 3 fundraisers have already been selected. Please un-select a fundraiser and return to select this fundraiser',
        )
      }
    }
  }

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <>
          <h6 className="mb-0 t-text-2xl">
            {isCampaign
              ? params.id
                ? 'Update Campaign'
                : 'Add Campaign'
              : params.id
              ? 'Update Event'
              : 'Add Event'}
          </h6>
          <p className="font-regular">Enter Information</p>
        </>,
      ]}
    >
      <Skeleton
        loading={fetching || fetchingTopKioskEvent}
        active
        paragraph={{ rows: 18 }}
      >
        <Form
          form={form}
          layout="vertical"
          key={0}
          onFinish={onFinish}
          name="event"
          initialValues={initialValues}
          scrollToFirstError
        >
          <Row gutter={[24, 0]}>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="Title"
                label="Title"
                colon={false}
                rules={[eventValidation]}
              >
                <Input placeholder="Enter Title" />
              </Form.Item>
            </Col>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="EventType"
                label="Event Type"
                colon={false}
                rules={[eventValidation]}
              >
                <Select placeholder="Event Type" style={{ width: '100%' }}>
                  {eventTypes.map((item, index) => (
                    <Select.Option value={item} key={index}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="zipCode"
                label="Zip code"
                colon={false}
                rules={[eventValidation]}
              >
                <Input placeholder="e.g : 12345" />
              </Form.Item>
            </Col>
            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="TargetAmount"
                label="Target amount"
                colon={false}
                rules={[eventValidation]}
              >
                <Input
                  inputMode="numeric"
                  prefix="$"
                  placeholder="20000"
                  onChange={(e) => handleTargetAmountChange(e.target.value)}
                />
              </Form.Item>
            </Col>

            {isCampaign ? (
              <>
                <Col xl={12} md={12} xs={24} sm={24}>
                  <Form.Item
                    name="StartDate"
                    label="Start Date"
                    colon={false}
                    rules={[eventValidation]}
                  >
                    <DatePicker
                      format="MM-DD-YYYY hh:mm A"
                      showTime
                      minuteStep={15}
                      className="t-w-full"
                      disabledDate={(d) =>
                        !d || d.isBefore(moment().subtract(1, 'day'))
                      }
                      disabledTime={disabledTime}
                      onChange={(selectedDate) =>
                        !compareDateTimeWithNow(selectedDate, 'before') &&
                        form.setFieldsValue({
                          StartDate: moment(),
                        })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xl={12} md={12} xs={24} sm={24}>
                  <Form.Item
                    name="EndDate"
                    label="End Date"
                    colon={false}
                    rules={[eventValidation]}
                  >
                    <DatePicker
                      format="MM-DD-YYYY hh:mm A"
                      showTime
                      minuteStep={15}
                      className="t-w-full"
                      disabledDate={(d) =>
                        !d ||
                        d.isBefore(moment().subtract(1, 'day')) ||
                        d.isBefore(
                          moment(form.getFieldValue('StartDate')).startOf(
                            'day',
                          ),
                        )
                      }
                      disabledTime={disabledTime}
                      onChange={(selectedDate) =>
                        !compareDateTimeWithNow(selectedDate, 'before') &&
                        form.setFieldsValue({
                          EndDate: moment(),
                        })
                      }
                    />
                  </Form.Item>
                </Col>
              </>
            ) : (
              <Col xl={24} md={24} xs={24} sm={24}>
                <Form.Item
                  name="StartDate"
                  label="Start Date"
                  colon={false}
                  rules={[eventValidation]}
                >
                  <DatePicker
                    format="MM-DD-YYYY hh:mm A"
                    showTime
                    minuteStep={15}
                    className="t-w-full"
                    disabledDate={(d) =>
                      !d || d.isBefore(moment().subtract(1, 'day'))
                    }
                    disabledTime={disabledTime}
                    onChange={(selectedDate) =>
                      !compareDateTimeWithNow(selectedDate, 'before') &&
                      form.setFieldsValue({
                        StartDate: moment(),
                      })
                    }
                  />
                </Form.Item>
              </Col>
            )}

            <Col span={24}>
              <Form.Item
                name="YoutubeLink"
                label="Youtube Link"
                colon={false}
                rules={[eventValidation]}
              >
                <Input placeholder="Youtube Link" />
              </Form.Item>
            </Col>
            {!isCampaign && (
              <>
                <Col span={24}>
                  <Form.Item
                    name="SpeakerName"
                    label="Speaker name"
                    colon={false}
                    // rules={[eventValidation]}
                  >
                    <Input placeholder="Speaker name" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="SpeakerBio"
                    label="Speaker bio"
                    colon={false}
                    // rules={[eventValidation]}
                  >
                    <TextArea
                      placeholder="Speaker bio"
                      autoSize={{ minRows: 3, maxRows: 5 }}
                    />
                  </Form.Item>
                </Col>
              </>
            )}

            {!issplitPaymentAllowed && (
              <Col xl={12} md={12} xs={24} sm={24}>
                <Form.Item
                  name="monthlyPaymentAllowed"
                  label="Is Monthly Payment Allowed ?"
                  colon={false}
                  rules={[eventValidation]}
                  onChange={() =>
                    form.setFieldValue('splitPaymentAllowed', false)
                  }
                >
                  <Select
                    placeholder="Monthly Payment Allowed"
                    style={{ width: '100%' }}
                  >
                    <Select.Option value>Yes</Select.Option>
                    <Select.Option value={false}>No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            )}
            {ismonthlyPaymentAllowed && !issplitPaymentAllowed && (
              <Col xl={12} md={12} xs={24} sm={24}>
                <Form.Item
                  name="monthlyPaymentMonths"
                  label="Number of Months"
                  colon={false}
                  rules={[eventValidation]}
                >
                  <Input type="number" placeholder="Number of Months" />
                </Form.Item>
              </Col>
            )}
            {!ismonthlyPaymentAllowed && (
              <Col xl={12} md={12} xs={24} sm={24}>
                <Form.Item
                  name="splitPaymentAllowed"
                  label="Is Split Payment Allowed ?"
                  colon={false}
                  rules={[eventValidation]}
                >
                  <Select
                    placeholder="Split Payment Allowed"
                    style={{ width: '100%' }}
                    onChange={() =>
                      form.setFieldValue('monthlyPaymentAllowed', false)
                    }
                  >
                    <Select.Option value>Yes</Select.Option>
                    <Select.Option value={false}>No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            )}

            {!ismonthlyPaymentAllowed && issplitPaymentAllowed && (
              <Col xl={12} md={12} xs={24} sm={24}>
                <Form.Item
                  name="splitPaymentMonths"
                  label="Number of Months"
                  colon={false}
                  rules={[eventValidation]}
                >
                  <Input placeholder="Number of Months" />
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.Item
                name="Details"
                label={
                  isCampaign
                    ? 'Brief Campaign Description'
                    : 'Brief Event Description'
                }
                colon={false}
                rules={[eventValidation]}
              >
                <TextArea
                  placeholder="Tell us about the event"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                />
              </Form.Item>
            </Col>
            <Col xl={8} md={8} xs={24} sm={24}>
              <Form.Item
                name="EditAskedAmount"
                label="Allow user to edit amount"
                colon={false}
                rules={[eventValidation]}
              >
                <Switch checked={form.getFieldValue('EditAskedAmount')} />
              </Form.Item>
            </Col>
            <Col xl={8} md={8} xs={24} sm={24}>
              <Form.Item
                name="ShowDonatedAmountToDonor"
                label="Show Donated Amount"
                colon={false}
                rules={[eventValidation]}
              >
                <Switch
                  checked={form.getFieldValue('ShowDonatedAmountToDonor')}
                />
              </Form.Item>
            </Col>
            <Col xl={8} md={8} xs={24} sm={24}>
              <Form.Item
                name="ShowProgressBarToDonor"
                label="Show Progress Bar"
                colon={false}
                rules={[eventValidation]}
              >
                <Switch
                  checked={form.getFieldValue('ShowProgressBarToDonor')}
                />
              </Form.Item>
            </Col>
            {!isCampaign && (
              <>
                <Col span={24}>
                  <Form.Item
                    name="Presenter"
                    label="Who Will Present ?"
                    colon={false}
                    rules={[eventValidation]}
                  >
                    <Select placeholder="Presenter" style={{ width: '100%' }}>
                      <Select.Option value="NewPresenter">
                        New Presenter
                      </Select.Option>
                      <Select.Option value="Myself">Myself</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                {isNewPresenter && (
                  <>
                    <Divider />
                    <Col
                      span={24}
                      className="t-flex t-justify-between t-items-center"
                    >
                      <div className="t-flex t-font-bold">
                        Presenter Details
                        {!params.id && (
                          <>
                            {!presenterValidate ? (
                              <span className="t-ml-6 font-bold t-flex t-space-x-2 t-items-center">
                                <h1 className="t-text-orange-600 t-text-sm">
                                  Enter presenter information
                                </h1>
                                <AiFillCloseCircle
                                  fontSize="1.2rem"
                                  className="t-text-orange-600"
                                />
                              </span>
                            ) : presenterFound ? (
                              <span className="t-ml-6 font-bold t-flex t-space-x-2 t-items-center">
                                <h1 className="t-font-bold t-text-sm">
                                  Presenter Found
                                </h1>
                                <AiFillCheckCircle
                                  fontSize="1.2rem"
                                  className="t-text-green-600"
                                />
                              </span>
                            ) : (
                              <span className="t-ml-6 font-bold t-flex t-space-x-2 t-items-center">
                                <h1 className="t-font-bold t-text-sm">
                                  Presenter Not Found
                                </h1>
                                <AiFillCloseCircle
                                  fontSize="1.2rem"
                                  className="t-text-orange-600"
                                />
                              </span>
                            )}
                          </>
                        )}
                      </div>
                      {params.id ? (
                        <>
                          <Button
                            type="primary"
                            onClick={() => setIsModalVisible(true)}
                          >
                            Select New Presenter
                          </Button>
                          <Modal
                            title="Enter New Presenter Information"
                            open={isModalVisible}
                            onCancel={() => setIsModalVisible(false)}
                            footer={null}
                          >
                            <UpdatePresenter
                              form={form}
                              close={() => setIsModalVisible(false)}
                            />
                          </Modal>
                        </>
                      ) : (
                        <Button
                          type="primary"
                          loading={loading}
                          onClick={validatePresenter}
                        >
                          Validate Presenter
                        </Button>
                      )}
                    </Col>
                    <Col xl={12} md={12} xs={24} sm={24}>
                      <Form.Item
                        name="PresenterPhoneCode"
                        label="Select Country"
                        colon={false}
                        rules={[eventValidation]}
                      >
                        <Select
                          placeholder="Select Country Code"
                          style={{ width: '100%' }}
                          disabled={params.id}
                        >
                          <Select.Option value="+1">
                            United States/Canada (+1)
                          </Select.Option>
                          <Select.Option value="+54">
                            Argentina (+54)
                          </Select.Option>
                          <Select.Option value="+92">
                            Pakistan (+92)
                          </Select.Option>
                          <Select.Option value="+55">
                            Brazil (+55)
                          </Select.Option>
                          <Select.Option value="+58">
                            Venezuela, Bolivarian Republic of Venezuela (+58)
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xl={12} md={12} xs={24} sm={24}>
                      <Form.Item
                        name="PresenterMobileNumber"
                        label="Presenter Mobile Number"
                        colon={false}
                        rules={[eventValidation]}
                      >
                        <MaskedInput
                          disabled={params.id}
                          inputMode="numeric"
                          mask={MaskPattern}
                          onChange={(e) => {
                            handleInputChange(e.maskedValue)
                          }}
                        />
                      </Form.Item>
                    </Col>
                    {presenterValidate === true && (
                      <>
                        <Col xl={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            name="PresenterName"
                            label="Presenter Name"
                            colon={false}
                            rules={[eventValidation]}
                          >
                            <Input
                              placeholder="Enter Name"
                              disabled={presenterFound}
                            />
                          </Form.Item>
                        </Col>
                        <Col xl={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            name="PresenterEmail"
                            label="Presenter Email"
                            colon={false}
                            rules={[eventValidation]}
                          >
                            <Input
                              placeholder="Enter Email"
                              disabled={presenterFound}
                            />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                    <Divider />
                  </>
                )}
              </>
            )}
            {isCampaign && (
              <Col span={24}>
                <Form.Item
                  name="IsPrivate"
                  label="Campaign is private"
                  colon={false}
                  rules={[eventValidation]}
                >
                  <Switch checked={form.getFieldValue('IsPrivate')} />
                </Form.Item>
              </Col>
            )}

            <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="Flyer"
                label="Banner Image"
                colon={false}
                rules={[eventValidation]}
              >
                <MultiImageUploader
                  ratio={2 / 1}
                  defaultFiles={
                    form.getFieldValue('Flyer') && form.getFieldValue('Flyer')
                  }
                  onFileChange={(files) =>
                    form.setFieldsValue({
                      Flyer: files,
                    })
                  }
                />
              </Form.Item>
            </Col>
            {/* <Col xl={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="Presentation"
                label="Presentation"
                colon={false}
                rules={[eventValidation]}
              >
                <Upload
                  beforeUpload={(file) => {
                    if (file.size > 10000000) {
                      message.error('File must be less than 10mb')
                      return false
                    }
                    return true
                  }}
                  customRequest={(e) =>
                    form.setFieldsValue({ Presentation: e.file })
                  }
                  maxCount={1}
                  showUploadList={false}
                >
                  <Button
                    icon={<UploadOutlined />}
                    style={{ marginRight: '1rem' }}
                  >
                    {form.getFieldValue('Presentation')
                      ? 'Change'
                      : 'Click to Upload'}
                  </Button>
                  {form.getFieldValue('Presentation') &&
                    form.getFieldValue('Presentation').name}
                </Upload>
              </Form.Item>
            </Col> */}

            <Col span={24}>
              <div
                className="t-flex t-items-center t-space-x-2 t-mb-2"
                onClick={(e) => e.preventDefault()}
              >
                <p className="t-font-semibold">Default Values</p>
                <Tooltip title="Default values will be shown to donor as suggestion at the time of donation. Change it accourding to your preference">
                  <BsInfoCircleFill className="t-text-secondary-100 t-cursor-pointer" />
                </Tooltip>
              </div>
            </Col>
            <Col span={24}>
              <Row gutter={[24, 0]}>
                {Array.from({ length: 4 }, (__, index) => (
                  <Col xl={6} md={6} xs={24} sm={24} key={index}>
                    <Form.Item
                      name={`DefaultDonationValue${index + 1}`}
                      colon={false}
                      rules={[eventValidation]}
                    >
                      <AmountInput
                        placeholder={`Enter amount ${index + 1}`}
                        onChange={(value) =>
                          form.setFieldsValue({
                            [`DefaultDonationValue${index + 1}`]: value,
                          })
                        }
                      />
                    </Form.Item>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col span={12}>
              <Form.Item
                name="ShowOnKiosk"
                label="Show on Kiosk"
                colon={false}
                rules={[eventValidation]}
              >
                <Switch checked={form.getFieldValue('ShowOnKiosk')} />
              </Form.Item>
            </Col>
            {isShowOnKiosk && (
              <Col span={12}>
                <Form.Item
                  name="TopKioskEvent"
                  label="Add To Top 3 Kiosk Fundraisers"
                  colon={false}
                  rules={[eventValidation]}
                >
                  <Switch
                    checked={form.getFieldValue('TopKioskEvent')}
                    onChange={(value) => handleTopKioskEventChange(value)}
                  />
                </Form.Item>
              </Col>
            )}
            {!isPrivateCampaign && (
              <Col span={24}>
                <Form.Item
                  name="ShowOnPage"
                  label="Show on Landing Page"
                  colon={false}
                  rules={[eventValidation]}
                >
                  <Switch checked={form.getFieldValue('ShowOnPage')} />
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.Item
                name="ShowMailingAddress"
                label="Show Mailing Address"
                colon={false}
                rules={[eventValidation]}
              >
                <Switch checked={form.getFieldValue('ShowMailingAddress')} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="PaymentCategoryAdded"
                label="Add Payment Category"
                colon={false}
                rules={[eventValidation]}
              >
                <Switch checked={form.getFieldValue('PaymentCategoryAdded')} />
              </Form.Item>
            </Col>
            {form.getFieldValue('PaymentCategoryAdded') && (
              <Col xl={24} md={24} xs={24} sm={24}>
                <Form.Item
                  name="PaymentCategories"
                  label="Payment Categories"
                  colon={false}
                >
                  <Select
                    mode="multiple"
                    style={{
                      width: '100%',
                    }}
                    placeholder="Select Payment Categories"
                    options={categories}
                    {...(PaymentCategoriesValues?.length > 1 && {
                      open: false,
                    })}
                  />
                </Form.Item>
              </Col>
            )}
            {!params.id && (
              <>
                <Divider />
                <Col xl={24} md={24} xs={24} sm={24}>
                  <Form.Item
                    name="PledgeReminders"
                    label="Pledge Reminders"
                    colon={false}
                    rules={[eventValidation]}
                  >
                    <Select
                      placeholder="Select Pledge Reminders"
                      style={{ width: '100%' }}
                    >
                      <Select.Option value="Scheduled">Scheduled</Select.Option>
                      {/* <Select.Option value="Prompted">Prompted</Select.Option> */}
                      <Select.Option value="Manual">Manual</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                {isScheduled && (
                  <>
                    <Col xl={24} md={24} xs={24} sm={24}>
                      <Form.Item
                        name="MessagingFrequency"
                        label="Messaging Frequency"
                        colon={false}
                        rules={[eventValidation]}
                      >
                        <Select
                          placeholder="Select Messaging Frequency"
                          style={{ width: '100%' }}
                        >
                          <Select.Option value="Weekly">Weekly</Select.Option>
                          <Select.Option value="Monthly">Monthly</Select.Option>
                          <Select.Option value="Quarterly">
                            Quarterly
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xl={24} md={24} xs={24} sm={24}>
                      <Form.Item
                        name="MaximumMessages"
                        label="Maximum # of messages to send"
                        colon={false}
                        rules={[eventValidation]}
                      >
                        <Select
                          placeholder="Maximum # of messages to send"
                          style={{ width: '100%' }}
                        >
                          <Select.Option value="1">1</Select.Option>
                          <Select.Option value="2">2</Select.Option>
                          <Select.Option value="3">3</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </>
                )}
                <Divider />
              </>
            )}
            <Col span={24}>
              <Form.Item label="Invite Seed Donors" colon={false}>
                <Select
                  mode="tags"
                  size="middle"
                  placeholder="Invite Seed Donors"
                  onChange={(e) => setDonorsSelected([...e])}
                  style={{
                    width: '100%',
                  }}
                  value={donorsSelected}
                >
                  {donorEmails?.map((item) => (
                    <Option key={item.DonorEmail}>{item.DonorEmail}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {isCampaign && (
              <Col xl={24} md={24} xs={24} sm={24}>
                <Form.Item
                  name="CampaignSubType"
                  label="Auction Campaign"
                  // label="Campaign Type"
                  colon={false}
                  // rules={[eventValidation]}
                >
                  <Switch
                    checked={isAuctionCampaign}
                    onChange={(val) =>
                      val === true
                        ? form.setFieldValue('CampaignSubType', 'Auction')
                        : form.setFieldValue('CampaignSubType', 'Fundraiser')
                    }
                  />
                  {/* <Select placeholder="Campaign Type" style={{ width: '100%' }}>
                    {['Fundraiser', 'Auction'].map((item, index) => (
                      <Select.Option value={item} key={index}>
                        {item}
                      </Select.Option>
                    ))}
                  </Select> */}
                </Form.Item>
              </Col>
            )}
            <Divider />
            {!params.id && (
              <>
                {!isCampaign && (
                  <Col span={24}>
                    <Form.Item label="Enable Auction">
                      <Switch
                        checked={auctionItems}
                        onChange={() => setAuctionItems(!auctionItems)}
                      />
                    </Form.Item>
                  </Col>
                )}
                {((auctionItems && !isCampaign) ||
                  (isCampaign && isAuctionCampaign)) && (
                  <AuctionItems
                    auctionItemsList={auctionItemsList}
                    setAuctionItemsList={setAuctionItemsList}
                    setAddItemsModal={setAddItemsModal}
                  />
                )}
                <Divider />
              </>
            )}
            {/* <Col span={24}>
              <Form.Item name="recaptcha" rules={[eventValidation]}>
                <ReCAPTCHA sitekey="6LdSWKgZAAAAAKrrp6GFXldEv0I4vqO3yWrTkuNV" />
              </Form.Item>
            </Col> */}
            <Col span={24} className="text-left t-space-x-2">
              <Button
                loading={loading}
                type="primary"
                className="px-25"
                htmlType="submit"
              >
                {buttonText}
              </Button>
              <Button
                type="primary"
                className="px-25"
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
        <AddItemsModal
          visible={addItemsModal}
          close={() => setAddItemsModal(false)}
          auctionItemsList={auctionItemsList}
          setAuctionItemsList={setAuctionItemsList}
          isCampaign={isCampaign && isAuctionCampaign}
          defaultEndDate={
            form.getFieldValue('EndDate') && form.getFieldValue('EndDate')
          }
          defaultStartDate={
            form.getFieldValue('StartDate') && form.getFieldValue('StartDate')
          }
        />
      </Skeleton>
    </Card>
  )
}

export { CreateEvent }
