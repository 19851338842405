import React, { useEffect, useState } from 'react'
import { Card, Col, Tag, Skeleton, Menu, Dropdown, Tooltip } from 'antd'
import { MdModeEdit } from 'react-icons/md'
import { getOrganizationEvents } from 'store/eventSlice/eventActions'
import { useDispatch } from 'react-redux'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useEventSelector } from 'store/eventSlice/eventReducer'
import { useHistory, useLocation } from 'react-router-dom'
import { awsconfig, config } from 'configs'
import { BiRefresh, BiSort } from 'react-icons/bi'

import sample from 'assets/images/sample/sample1.png'
import { sortByStatus } from 'utils'

const MyEvents = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { loading, events } = useEventSelector()
  const { organization } = useAuthSelector()
  const [sortKey, setSortKey] = useState([])
  const { search } = useLocation()

  const fetchData = () => {
    dispatch(getOrganizationEvents(organization.id, sortKey))
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (search) {
      const urlSearchParams = new URLSearchParams(search)
      const { key } = Object.fromEntries(urlSearchParams.entries())
      if (key && key === 'Rejected')
        setSortKey(['Rejected', 'Accepted', 'Pending'])
    }
    // eslint-disable-next-line
  }, [])

  const menu = (
    <Menu>
      <Menu.Item
        key={1}
        onClick={() => setSortKey([])}
        style={
          sortKey.length === 0 && {
            backgroundColor: config.colors.primary,
            color: 'white',
          }
        }
      >
        Default
      </Menu.Item>
      <Menu.Item
        key={1}
        onClick={() => setSortKey(['Rejected', 'Accepted', 'Pending'])}
        style={
          sortKey.length > 0 &&
          sortKey[0] === 'Rejected' && {
            backgroundColor: config.colors.primary,
            color: 'white',
          }
        }
      >
        By Rejected Events
      </Menu.Item>
      <Menu.Item
        key={2}
        onClick={() => setSortKey(['Accepted', 'Rejected', 'Pending'])}
        style={
          sortKey.length > 0 &&
          sortKey[0] === 'Accepted' && {
            backgroundColor: config.colors.primary,
            color: 'white',
          }
        }
      >
        By Accepted Events
      </Menu.Item>
      <Menu.Item
        key={2}
        onClick={() => setSortKey(['Pending', 'Rejected', 'Accepted'])}
        style={
          sortKey.length > 0 &&
          sortKey[0] === 'Pending' && {
            backgroundColor: config.colors.primary,
            color: 'white',
          }
        }
      >
        By Pending Events
      </Menu.Item>
    </Menu>
  )

  const myevents = events.filter((event) => event?.EventType === 'Fundraiser')
  const sortedData = sortByStatus(myevents, 'AcceptanceStatus', sortKey)

  return (
    <Card
      title={[
        <div className="t-flex t-justify-between t-items-center">
          <div>
            <h4 className="mb-0">Event Presenters</h4>
            <p className="font-regular">
              Presenter response to my event invites
            </p>
          </div>
          <div className="t-flex t-justify-end t-items-center t-space-x-2">
            <Dropdown overlay={menu} placement="bottomLeft" arrow>
              <Tooltip title="Sort Filter">
                <BiSort
                  fontSize="1.7rem"
                  className="t-text-secondary-100 t-cursor-pointer"
                />
              </Tooltip>
            </Dropdown>
            <Tooltip title="Refresh">
              <BiRefresh
                className="t-text-secondary-100 t-cursor-pointer"
                fontSize="2.5rem"
                onClick={fetchData}
              />
            </Tooltip>
          </div>
        </div>,
      ]}
      className="t-mb-5"
    >
      <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
        <Col span={24}>
          {sortedData.length === 0 && (
            <Card className="t-h-96 t-w-full t-flex t-justify-center t-items-center">
              <div>No Events</div>
            </Card>
          )}
          {sortedData.map((item) => (
            <div className="t-flex t-justify-between t-items-center t-my-2 t-border t-rounded-md md:t-rounded-full t-p-3 t-shadow">
              <div className="t-flex t-flex-col sm:t-flex-row t-space-x-4 t-items-center t-space-y-4 md:t-space-y-0">
                <img
                  src={
                    item?.Flyer?.fileName
                      ? awsconfig.bucketUrl + item?.Flyer?.fileName
                      : sample
                  }
                  className="t-h-16 md:t-h-8 t-w-24 md:t-w-12 t-rounded-md md:t-rounded-full gallery-img shadow-3 img-contain"
                  alt="card-2"
                />
                <div className="t-flex t-flex-col t-justify-center">
                  <h1>{item?.Title}</h1>
                  <h1>
                    {`Presenter : ${item?.PresenterName}( ${item?.PresenterEmail} )`}
                  </h1>
                </div>
                <div>
                  {item?.AcceptanceStatus === 'Accepted' && (
                    <Tag color="green" className="font-bold">
                      Accepted
                    </Tag>
                  )}
                  {item?.AcceptanceStatus === 'Rejected' && (
                    <Tag color="red" className="font-bold">
                      Rejected
                    </Tag>
                  )}
                  {(item?.AcceptanceStatus === 'Pending' ||
                    !item?.AcceptanceStatus) && (
                    <Tag color="gray" className="font-bold">
                      Pending
                    </Tag>
                  )}
                </div>
              </div>
              <MdModeEdit
                onClick={() =>
                  history.push(`/settings/create-event/${item?.Id}`)
                }
                fontSize="1.5rem"
                className="t-text-secondary-100 t-cursor-pointer"
              />
            </div>
          ))}
        </Col>
      </Skeleton>
    </Card>
  )
}

export { MyEvents }
