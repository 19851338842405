import React, { useEffect, useState } from 'react'
import { Skeleton, Button, Spin, Card, Col, Row, Tooltip, Divider } from 'antd'
import { fetchMoreData, formateDateTime, searchEventCampaignRows } from 'utils'
import { config, awsconfig } from 'configs'
import { getNearByEvents } from 'store/eventSlice/eventActions'
import { useDispatch } from 'react-redux'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { eventActions, useEventSelector } from 'store/eventSlice/eventReducer'
import { useHistory } from 'react-router-dom'
import { CarryOutFilled } from '@ant-design/icons'
import sample from 'assets/images/sample/sample1.png'
import InfiniteScroll from 'react-infinite-scroll-component'
import { BsFillCalendarCheckFill } from 'react-icons/bs'
import { RiRadioButtonLine } from 'react-icons/ri'
import { AllEventList } from './AllEventList'

function EventList({ searchText, sortKey }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const { location, user, organization } = useAuthSelector()
  const positionData = {
    lat: location?.lat,
    lon: location?.lng,
    zipCode: location?.zipcode,
  }
  const { loading, events } = useEventSelector()
  const [current, setCurrent] = useState([])

  const notEndedEvents = events?.filter(
    (item) => item?.startEvent === undefined || item?.startEvent === true,
  )
  const searchedData = searchEventCampaignRows(notEndedEvents, searchText)

  useEffect(() => {
    setCurrent(searchedData.slice(0, config.itemFirstLoad))
    // eslint-disable-next-line
  }, [events, searchText])

  useEffect(() => {
    const zipCode = location?.zipcode
    if (zipCode) {
      dispatch(getNearByEvents(positionData, sortKey))
    }
    // eslint-disable-next-line
  }, [dispatch, location, sortKey])

  const handleEventSelected = (event) => {
    dispatch(eventActions.setEvent(null))
    history.push(`events/${event.Id}`)
  }

  const goToJoinScreen = (e, event) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(eventActions.setJoinedEventDetails(null))
    dispatch(eventActions.setEvent(event))
    history.push(`/events/${event?.Id}/join-event`)
  }

  const goToControlScreen = (e, event) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(eventActions.setJoinedEventDetails(null))
    dispatch(eventActions.setEvent(event))
    history.push(`/events/${event?.Id}/control-screen`)
  }

  const CutomDateFormater = (item) => {
    if (item?.startEvent) {
      return (
        <>
          <Tooltip title="Starter">
            <RiRadioButtonLine fontSize="1.1rem" className="t-text-green-700" />
          </Tooltip>
          <div className="card-tag t-font-thin t-text-secondary-100">
            Active
          </div>
        </>
      )
    }
    if (item?.startEvent === false) {
      return (
        <>
          <Tooltip title="Ended">
            <RiRadioButtonLine fontSize="1.1rem" className="t-text-red-700" />
          </Tooltip>
          <div className="card-tag t-font-thin t-text-secondary-100">Ended</div>
        </>
      )
    }
    return (
      <>
        <Tooltip title="Start At">
          <BsFillCalendarCheckFill className="t-text-secondary-100" />
        </Tooltip>
        <div className="card-tag t-font-thin t-text-secondary-100">
          <Tooltip title="MM-DD-YYYY hh:mm A">
            {item?.StartDate && formateDateTime(item?.StartDate)}
          </Tooltip>
        </div>
      </>
    )
  }

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
      <div className="t-flex t-flex-col t-justify-between t-h-full">
        <InfiniteScroll
          dataLength={current.length}
          next={() => fetchMoreData(current, setCurrent, searchedData)}
          hasMore={current.length < searchedData.length}
          loader={
            <div className="t-flex t-justify-center t-items-center">
              <Spin />
            </div>
          }
        >
          <Row gutter={[24, 24]} className="t-h-full">
            {current.length === 0 && (
              <>
                <div className="t-flex t-justify-center t-w-full">
                  <div className="t-flex t-flex-col t-items-center t-space-y-2">
                    <CarryOutFilled
                      style={{ fontSize: '4rem' }}
                      className="t-text-secondary-100"
                    />
                    <p>No nearby events found</p>
                  </div>
                </div>
                <Divider />
                <p className="t-ml-4 t-text-lg t-font-semibold">All Events</p>
                <AllEventList />
              </>
            )}
            {current.map((item, index) => (
              <Col span={24} md={8} xl={8} key={index}>
                <Card
                  bordered
                  hoverable
                  cover={
                    <img
                      alt="example"
                      className="t-w-full t-h-48 t-object-cover t-rounded-md"
                      src={
                        item?.Flyer?.length
                          ? awsconfig.bucketUrl + item?.Flyer[0]?.fileName
                          : item?.Flyer?.fileName
                          ? awsconfig.bucketUrl + item?.Flyer?.fileName
                          : item?.OrganizationBackgroundImage
                          ? awsconfig.bucketUrl +
                            item?.OrganizationBackgroundImage?.fileName
                          : sample
                      }
                    />
                  }
                  onClick={() => handleEventSelected(item)}
                >
                  <h5 className="t-text-2xl t-text-secondary-100 t-font-bold t-truncate">
                    <Tooltip title={item?.Title}>{item?.Title}</Tooltip>
                  </h5>
                  <div className="card-tag t-font-thin t-text-secondary-100">
                    <Tooltip title="Organization Name">
                      {item?.OrganizationName}
                    </Tooltip>
                  </div>
                  <p className="t-line-clamp-1 t-text-sm">{item?.Details}</p>
                  <div className="t-flex t-items-center t-space-x-2">
                    {CutomDateFormater(item)}
                  </div>

                  <div className="t-flex t-justify-end t-mt-2 t-space-x-1 t-h-8">
                    {(item?.OrganizationId === organization?.id ||
                      (item?.PresenterEmail === user?.email &&
                        item?.AcceptanceStatus === 'Accepted')) && (
                      <Button
                        type="primary"
                        size="small"
                        className="t-rounded-full"
                        onClick={(e) => goToControlScreen(e, item)}
                      >
                        Control
                      </Button>
                    )}
                    {item.startEvent && (
                      <Button
                        type="primary"
                        size="small"
                        className="t-rounded-full"
                        onClick={(e) => goToJoinScreen(e, item)}
                      >
                        Join
                      </Button>
                    )}
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </InfiniteScroll>
      </div>
    </Skeleton>
  )
}

export { EventList }
