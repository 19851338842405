import { Button, Skeleton, Spin, Form, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { RiBankFill } from 'react-icons/ri'
import { FaCheckCircle } from 'react-icons/fa'
import { MdCancel, MdOutlineDone, MdSmsFailed } from 'react-icons/md'
import Api, { makeRequest } from 'api'
import { useZipCode } from 'hooks'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { getUserOrganization } from 'store/organizationSlice/organizationActions'
import { Icon } from 'components'
import { OrganizationRegistrationForm } from './OrganizationRegistrationForm'

const OrganizationRegistrationV2 = () => {
  const [page, setPage] = useState(1)
  const [selectedOrg, setSelectedOrg] = useState('')
  const [EINResult, setEINResult] = useState()
  const [einMessage, setEinMessage] = useState(false)
  const [isAllowed, setIsAllowed] = useState(false)
  const [loadingLocation, setLoadingLocation] = useState(false)
  const [isOrgAlready, setOrgAlready] = useState(false)
  const [loading, setLoading] = useState(false)
  const [einLoad, setEinLoad] = useState(false)

  const { user } = useAuthSelector()
  const { isLoading, isAcccessGranted, isSuccess, lat, lng } = useZipCode()
  const history = useHistory()
  const addPage = () => {
    if (page < 2 && selectedOrg === 1) {
      setPage((prevPage) => prevPage + 1)
      setSelectedOrg(' ')
    } else if (page === 2 && EINResult?.data?.organization) {
      setPage((prevPage) => prevPage + 1)
    }
  }
  const deletePage = () => {
    if (page === 1) {
      history.push('/campaigns')
    } else if (page > 1) {
      setPage((prevPage) => prevPage - 1)
      setEINResult()
    }
  }

  const handleOrgType = (key) => {
    setSelectedOrg(key)
  }

  useEffect(() => {
    // Reset EIN result and message when navigating back to this page
    if (page === 2) {
      setEINResult(null)
      setEinMessage(false)
    }
  }, [page])

  const handleChange = (value) => {
    if (value.length === 0) {
      setEinMessage(false)
      setEINResult(null)
      return
    }

    setEinLoad(true)
    setEinMessage(true)
    makeRequest('get', `/component/organization/get-ein-info/${value}`)
      .then((res) => {
        setEINResult(res)
        setEinLoad(false)
      })
      .catch((err) => {
        setEINResult(null)
        console.error(err)
        setEinLoad(false)
      })
  }
  const handleSubmission = () => {
    history.push('/organizations')
  }
  const getLocation = () => {
    setLoadingLocation(true)
    Api.put('donor/getZipCodeByPosition', { lat, lon: lng })
      .then((res) => {
        const resCountry = res?.results?.country
        setLoadingLocation(false)
        const allowedCountry = ['US', 'Canada']
        const condition = allowedCountry.includes(resCountry)
        // setCountry(resCountry)
        setIsAllowed(condition)
      })
      .catch(() => setLoadingLocation(false))
  }
  useEffect(() => {
    getLocation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lat, lng])

  const getAlreadtOrganization = async () => {
    const isOrgAlreadyPresent = await getUserOrganization(user?.email)
    if (isOrgAlreadyPresent) {
      setOrgAlready(isOrgAlreadyPresent)
      setLoading(false)
    }
  }
  useEffect(() => {
    getAlreadtOrganization()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <>
      {!isLoading && isAcccessGranted && isSuccess ? (
        <Skeleton loading={false} active paragraph={{ rows: 18 }}>
          {/* // Todo !isAllowed this is for testing */}
          {isAllowed ? (
            <Skeleton
              loading={loadingLocation || loading}
              active
              paragraph={{ rows: 18 }}
            >
              {/* // Todo isOrgAlready this is for testing */}
              {!isOrgAlready ? (
                <div
                  className="t-w-full t-flex t-justify-center t-items-center"
                  style={{ height: 'calc(74vh)' }}
                >
                  <div className="t-flex t-flex-col t-justify-center t-items-center">
                    <MdSmsFailed
                      fontSize="3rem"
                      className="t-text-secondary-100"
                    />
                    <p className="roboto-font">
                      Your account has already registered organization. It is
                      still in review.
                    </p>
                  </div>
                </div>
              ) : (
                <div className="t-flex t-flex-col">
                  <div className="t-w-full t-flex t-flex-col md:t-flex-row t-items-center t-border-b t-border-gray-400 t-justify-center md:t-justify-between">
                    <Icon
                      src="pg_logo"
                      width={100}
                      height="auto"
                      className="md:t-ml-20 t-my-6"
                    />
                  </div>
                  <div className="t-flex t-flex-col lg:t-flex-row t-bg-white t-rounded-md t-m-8">
                    {/* Header */}
                    {page !== 4 && (
                      <div className="lg:t-w-[60%] t-w-[100%]">
                        <h1 className="t-font-semibold t-text-3xl md:t-text-4xl t-text-gray-800 t-mt-12 t-text-center lg:t-text-left lg:t-ml-20 roboto-font">
                          {page !== 4 && 'Register Your Account'}
                        </h1>
                        {page !== 4 && (
                          <div className="t-flex t-flex-col t-mt-12">
                            <h1 className="t-text-lg t-text-gray-800 t-font-semibold t-text-center lg:t-text-left lg:t-ml-20 roboto-font">
                              {page === 1
                                ? 'Step 1'
                                : page === 2
                                ? 'Step 2'
                                : page === 3
                                ? 'Step 3'
                                : ''}
                            </h1>
                            <h1 className="t-text-base t-text-gray-600 t-text-center lg:t-text-left lg:t-ml-20">
                              {page === 1
                                ? 'Select Account Type'
                                : page === 2
                                ? 'Search For Your Organization'
                                : page === 3
                                ? 'Validate Organization Details'
                                : ''}
                            </h1>
                          </div>
                        )}
                      </div>
                    )}
                    {/* Main */}
                    {page !== 4 && (
                      <div className="t-flex t-flex-col t-justify-center t-items-center lg:t-w-[40%] t-w-[100%]">
                        {page !== 4 && (
                          <div className="t-w-[70%]">
                            <h1 className="t-mt-12 t-text-base t-text-right montserrat-font t-font-medium">
                              {page}/3
                            </h1>
                            <div
                              className="t-flex t-flex-row t-border t-bg-gray-100 t-border-gray-300 t-w-[100%] t-border-none t-rounded-md "
                              // style={{ borderRadius: '16px 2px 16px 2px' }}
                            >
                              <div
                                className={`${
                                  page === 1 || page === 2 || page === 3
                                    ? 't-bg-[#2e97de]'
                                    : 't-bg-[#e5e7e9]'
                                } t-h-3 ${
                                  page === 1
                                    ? 't-w-[33%]'
                                    : page === 2
                                    ? 't-w-[66%]'
                                    : page === 3
                                    ? 't-w-[100%]'
                                    : ''
                                } t-rounded-md `}
                              ></div>
                            </div>
                          </div>
                        )}
                        <div className="t-flex t-flex-col t-mt-7 md:t-mt-12 t-w-[70%]">
                          {/*  Option Div */}
                          {page === 1 && (
                            <>
                              <div
                                className={`${
                                  selectedOrg === 1
                                    ? 't-bg-[#e6f4ff] t-border-2 t-border-[#2e97de]'
                                    : 't-bg-[#fafafa] t-border t-border-gray-300'
                                }  t-w-[100%] t-my-2 t-cursor-pointer t-mb-7 t-rounded-md`}
                                onClick={() => handleOrgType(1)}
                              >
                                <h1 className="t-text-black t-text-base lg:t-text-lg t-py-6 t-text-center montserrat-font t-font-medium">
                                  {' '}
                                  A Nonprofit Organization
                                </h1>
                              </div>
                              <h6 className="t-bg-[#fffbe6] t-text-[#874d00] t-py-1 t-text-sm t-w-[35%] t-text-center t-self-end t-rounded-md t-mb-[-12px] t-z-10 t-mr-[-4px] montserrat-font t-font-medium">
                                Comming Soon
                              </h6>
                              <div
                                className={`${
                                  selectedOrg === 2
                                    ? 't-bg-[#0F88C9] t-border-2 t-border-[#2e97de]'
                                    : 't-bg-[#fafafa] t-border t-border-gray-300'
                                }  t-w-[100%] t-cursor-pointer t-rounded-md t-mb-7`}
                                // onClick={() => handleOrgType(2)}
                              >
                                <h1 className="t-text-black t-text-base lg:t-text-lg t-py-6 t-text-center montserrat-font t-font-medium">
                                  {' '}
                                  A For-Profit Organization
                                </h1>
                              </div>
                              <h6 className="t-bg-[#fffbe6] t-text-[#874d00] t-py-1 t-text-sm t-w-[35%] t-text-center t-self-end t-rounded-md t-mb-[-12px] t-z-10 t-mr-[-4px] montserrat-font t-font-medium">
                                Comming Soon
                              </h6>
                              <div
                                className={`${
                                  selectedOrg === 3
                                    ? 't-bg-[#0F88C9] t-border-2 t-border-[#2e97de]'
                                    : 't-bg-[#fafafa] t-border t-border-gray-300'
                                }  t-w-[100%] t-cursor-pointer t-rounded-md`}
                                // onClick={() => handleOrgType(3)}
                              >
                                <h1 className="t-text-black t-text-base lg:t-text-lg t-py-6 t-text-center montserrat-font t-font-medium">
                                  Myself To Raise Funds
                                </h1>
                              </div>
                            </>
                          )}

                          {/* Organization Found by EIN */}
                          {page === 2 && (
                            <div>
                              <Form
                                name="basic"
                                layout="vertical"
                                labelCol={{
                                  span: 12,
                                }}
                                wrapperCol={{
                                  span: 24,
                                }}
                                className="t-w-[100%] t-m-auto roboto-font"
                                initialValues={{
                                  remember: true,
                                }}
                                autoComplete="off"
                              >
                                <Form.Item label="Enter EIN" name="ein">
                                  <Input
                                    prefix={<RiBankFill />}
                                    placeholder="Enter EIN"
                                    onChange={(e) =>
                                      handleChange(e.target.value)
                                    }
                                    suffix={
                                      einLoad ? (
                                        <Spin className="t-text-cyan-600" />
                                      ) : EINResult !== null ? (
                                        EINResult?.data ? (
                                          <FaCheckCircle className="t-text-green-500" />
                                        ) : (
                                          <MdCancel />
                                        )
                                      ) : null
                                    }
                                  />
                                </Form.Item>
                              </Form>
                              {einMessage && (
                                <div className="t-border-none">
                                  <h1
                                    className={`t-text-base ${
                                      EINResult !== null && EINResult?.data
                                        ? 't-text-green-400'
                                        : 't-text-red-400'
                                    } montserrat-font t-font-medium`}
                                  >
                                    {EINResult !== null && EINResult?.data
                                      ? 'Organization Found!'
                                      : 'Organization Not Found!'}
                                  </h1>
                                </div>
                              )}
                              {EINResult !== null &&
                                EINResult?.data?.organization && (
                                  <div className="t-flex t-flex-col t-justify-center t-mb-14 t-border t-mt-4 t-rounded-md">
                                    <h1 className="t-text-lg t-text-gray-700 t-px-6 t-py-2 montserrat-font t-font-medium">
                                      {EINResult?.data?.organization?.name}
                                    </h1>
                                  </div>
                                )}
                            </div>
                          )}
                        </div>

                        {/*  Registration Form */}
                        {page === 3 && (
                          <>
                            <OrganizationRegistrationForm
                              einData={EINResult?.data}
                              setPage={setPage}
                            />
                          </>
                        )}

                        {/* Button Section */}
                        {page !== 3 && page !== 4 && (
                          <div className="t-flex t-flex-col t-mt-8 t-w-[70%]">
                            <div className="t-flex t-flex-row t-mb-8 t-mt-2">
                              <Button
                                className="t-w-[50%] sm:t-w-[100%] t-text-center t-text-base t-mx-4 t-h-[50px]"
                                onClick={deletePage}
                              >
                                Cancel
                              </Button>
                              <Button
                                className={`t-w-[50%] sm:t-w-[100%] t-text-center t-text-base t-mx-2 t-border-none t-h-[50px] ${
                                  selectedOrg === 1 || EINResult?.data
                                    ? 't-bg-[#2e97de] t-text-white hover:t-border-cyan-600 hover:t-border'
                                    : 't-bg-gray-300 t-text-black hover:t-border-gray-400 hover:t-border'
                                } `}
                                onClick={addPage}
                              >
                                Next
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {/* Success Message Div */}
                    {page === 4 && (
                      <div className="t-flex t-flex-col t-justify-center t-items-center t-mb-8 t-mt-8 md:t-mt-12 t-m-auto t-w-[80%]">
                        <span className="t-bg-[#0F88C9] t-rounded-full t-w-[80px] t-h-[80px] t-flex t-justify-center t-items-center">
                          <MdOutlineDone className="t-text-white t-text-5xl t-font-bold" />
                        </span>
                        <h1 className="t-text-2xl md:t-text-4xl t-font-medium t-my-6 roboto-font">
                          We Have Received Your Request
                        </h1>
                        <p className="t-text-lg t-font-base t-mt-3 t-mb-6 t-text-center t-px-3 md:t-px-12 lg:t-px-20 roboto-font">
                          We have received your registration request. Please
                          allow 24-48 hours for review. Once approved, you will
                          be sent an email and/or a text message to continue
                          setting up your profile.
                        </p>
                        <Button
                          className="t-bg-[#2e97de] t-px-8 t-text-base t-mx-3 t-text-white hover:t-border-cyan-700 hover:t-border hover:t-bg-cyan-700"
                          onClick={handleSubmission}
                        >
                          Close
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Skeleton>
          ) : (
            <div
              className="t-w-full t-flex t-justify-center t-items-center"
              style={{ height: 'calc(74vh)' }}
            >
              <div className="t-flex t-flex-col t-justify-center t-items-center">
                <MdSmsFailed fontSize="3rem" className="t-text-secondary-100" />
                <p>This Feature is not available in your country</p>
              </div>
            </div>
          )}
        </Skeleton>
      ) : (
        <Skeleton loading={isLoading} active paragraph={{ rows: 18 }}>
          <div
            className="t-w-full t-flex t-justify-center t-items-center"
            style={{ height: 'calc(74vh)' }}
          >
            <div className="t-flex t-flex-col t-justify-center t-items-center">
              <MdSmsFailed fontSize="3rem" className="t-text-secondary-100" />
              <p>
                {isAcccessGranted
                  ? 'Error in getting location'
                  : 'Please Allow Location Access to continue'}
              </p>
            </div>
          </div>
        </Skeleton>
      )}
    </>
  )
}

export { OrganizationRegistrationV2 }
