import React, { useEffect, useState } from 'react'
import { Avatar, Card, message, Skeleton, Switch, Table, Tag } from 'antd'
import { awsconfig, config } from 'configs'
import Api from 'api'

function SoldItems({ type, parentId }) {
  const [loading, setLoading] = useState(true)
  const [eventSoldItems, setEventSoldItems] = useState()

  const getEventSoldItems = () => {
    if (parentId) {
      const data = { id: parentId, type }
      Api.put('/auction/getEventSoldItems', data)
        .then((res) => {
          const eventAuctionItems = res?.result
          setEventSoldItems(eventAuctionItems)
          setLoading(false)
        })
        .catch((error) => {
          console.log('error', error)
          setLoading(false)
        })
    }
  }

  useEffect(() => {
    if (parentId) {
      getEventSoldItems()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onClick = async (id, status) => {
    const data = {
      id,
      status,
    }
    Api.put('auction/updateSoldItemDeliveryStatus', data)
      .then(() => {
        getEventSoldItems(parentId)
        message.success('Item Status Changed')
      })
      .catch((error) => {
        console.log('error', error)
        message.error(error?.response?.data?.message || 'Something went wrong')
      })
  }

  const columns = [
    {
      // title: 'Image',
      dataIndex: 'itemImage',
      className: 'ant-table-row-cell-break-word',
      render: (_, { itemImage }) => (
        <Avatar
          src={itemImage?.fileName && awsconfig.bucketUrl + itemImage?.fileName}
          alt="photo"
          className="mr-10"
          size={48}
          shape="square"
        />
      ),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Winner Name',
      dataIndex: 'winnerName',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Winning Bid',
      dataIndex: 'winnerBid',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Payment',
      dataIndex: 'paymentStatus',
      className: 'ant-table-row-cell-break-word',
      render: (paymentStatus) => (
        <>
          {paymentStatus === 'Completed' ? (
            <Tag color="green" className="font-bold">
              {paymentStatus}
            </Tag>
          ) : (
            <Tag color="red" className="font-bold">
              {paymentStatus}
            </Tag>
          )}
        </>
      ),
    },
    {
      title: 'Delivered',
      dataIndex: 'deliveryStatus',
      className: 'ant-table-row-cell-break-word',
      render: (_, { Id, deliveryStatus, paymentStatus }) => (
        <Switch
          disabled={paymentStatus !== 'Completed'}
          checked={deliveryStatus}
          onChange={(value) => onClick(Id, value)}
        />
      ),
    },
  ]

  return (
    <Card className="t-w-full">
      <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
        <Table
          columns={columns}
          dataSource={eventSoldItems || []}
          tableLayout="auto"
          scroll={{ x: 500 }}
          bordered={false}
          pagination={{
            pageSize: config.rowsPerPage,
            showSizeChanger: false,
          }}
        />
      </Skeleton>
    </Card>
  )
}

export { SoldItems }
