import React from 'react'
import { Skeleton } from 'antd'

const ChartSkeleton = () => (
  <div className="t-w-1/2 t-pr-2">
    <div className="chart-skeleton-title">
      <Skeleton.Input
        style={{ width: 200, marginBottom: 24 }}
        active
        size="small"
      />
    </div>
    <div className="chart-skeleton">
      <Skeleton.Input
        style={{ width: '100%', marginBottom: 8 }}
        active
        size="small"
      />
      {[...Array(5)].map((e, i) => (
        <div key={i} className="chart-skeleton-row">
          <Skeleton.Input
            style={{ width: '10%', marginRight: 8 }}
            active
            size="small"
          />
          <Skeleton.Input style={{ width: '85%' }} active size="small" />
        </div>
      ))}
    </div>
  </div>
)

export default ChartSkeleton
