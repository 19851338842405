import moment from 'moment'
import 'moment-timezone'
import zipcodes from 'zipcodes'
import { awsconfig, config } from 'configs'
import sample from 'assets/images/sample/sample1.png'
import sampleBusinessProfile from 'assets/images/sample/business-profile.png'
// import jsPDF from 'jspdf'
// import 'jspdf-autotable'

export const formateDateTime = (date) => {
  const mydate = new Date(date)
  return moment(mydate).format('MM-DD-YYYY hh:mm A')
}

export const formateDate = (date) => {
  const mydate = new Date(date)
  return moment(mydate).format('MM-DD-YYYY')
}

export const formateDateTimeToLocal = (date, format, timezone) => {
  // format ["datetime","date","time"]
  let myTimeZone = null
  if (timezone) {
    myTimeZone = timezone
  } else {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
    myTimeZone = timeZone
  }
  const mydate = moment(date).tz(myTimeZone)
  switch (format) {
    case 'datetime':
      return mydate.format('MM-DD-YYYY hh:mm A')
    case 'date':
      return mydate.format('MM-DD-YYYY')
    case 'time':
      return mydate.format('hh:mm A')
    default:
      return mydate.format('MM-DD-YYYY hh:mm A')
  }
}

const convertToCSV = (data, columns, headerMapping) => {
  let csvString = ''

  // Create CSV headers
  const headers = columns.map((column) => headerMapping[column] || column)
  csvString += `${headers.join(',')}\r\n`

  // Create rows for each object
  data.forEach((row) => {
    const rowData = columns.map((column) => {
      const cellData = row[column]
      // Check if cellData is a string and contains commas, if so wrap in quotes
      return typeof cellData === 'string' && cellData.includes(',')
        ? `"${cellData}"`
        : cellData
    })
    csvString += `${rowData.join(',')}\r\n`
  })
  return csvString
}

export const downloadCSV = (data, columns, headerMapping, csvName) => {
  const csvData = convertToCSV(data, columns, headerMapping, csvName)
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.setAttribute('download', csvName.replace(/\s+/g, '').trim())
  document.body.appendChild(link) // Required for Firefox
  link.click()
  document.body.removeChild(link) // Clean up
}

export const formatDate = (dateString) => {
  const options = { day: 'numeric', month: 'long', year: 'numeric' }
  const event = new Date(dateString)
  return event
    .toLocaleDateString('en-GB', options)
    .replace(/(\d+)(st|nd|rd|th)/, '$1')
}

export const formatDisplayName = (fieldName) =>
  fieldName.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())

export const generateMonths = () => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const currentMonth = new Date().getMonth()
  return monthNames
    .slice(currentMonth, monthNames.length)
    .concat(monthNames.slice(0, currentMonth))
}

export const generateMonthsByDate = () => {
  const months = []
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 6; i++) {
    // Generates months in the format YYYY-MM, e.g., '2023-12'
    const month = moment().add(i, 'months').format('YYYY-MM')
    months.push(month)
  }
  return months
}

export const organizationRemainingTrialDays = (startDate, durationInDays) => {
  let remainingDays = null
  if (startDate && durationInDays) {
    const currentDate = moment().startOf('day')
    const trialStartDate = moment(startDate).startOf('day')
    const trialEndDate = moment(trialStartDate)
      .add(durationInDays, 'days')
      .startOf('day')
    remainingDays = trialEndDate.diff(currentDate, 'days')
  }
  return remainingDays
}

export const imageSizeValidation = (file) => {
  const sizeInKb = file?.size / 1024
  // if (sizeInKb < config.imageMinSizeLimitInKb) {
  //   return { isValid: false, errorType: 'min' }
  // }
  if (sizeInKb > config.imageMaxSizeLimitInKb) {
    return { isValid: false, errorType: 'max' }
  }

  return { isValid: true }
}

export const addProtocolToURL = (url) => {
  let newUrl = url
  const protocolRegex = /^(https?:\/\/)/i

  if (!protocolRegex.test(url)) {
    // Protocol is missing, add "http://" by default
    newUrl = `http://${url}`
  }

  return newUrl
}

export const paginate = (array, PAGE_NUMBER) =>
  array?.slice(
    (PAGE_NUMBER - 1) * config.rowsPerPage,
    PAGE_NUMBER * config.rowsPerPage,
  )

export const searchRows = (rows, searchText) => {
  if (searchText) {
    return rows?.filter(({ Name }) =>
      Name?.toLowerCase().includes(searchText.toLowerCase()),
    )
  }
  return rows
}

export const searchRentals = (rows, searchText) => {
  if (searchText) {
    return rows?.filter(({ Capacity }) =>
      Capacity?.toLowerCase().includes(searchText.toLowerCase()),
    )
  }
  return rows
}

export const searchReports = (rows, searchText) => {
  if (searchText) {
    return rows?.filter(({ Name }) =>
      Name?.toLowerCase().includes(searchText.toLowerCase()),
    )
  }
  return rows
}

export const searchOrganizations = (rows, searchText) => {
  if (searchText) {
    return rows?.filter(
      ({ Name, Description }) =>
        Name?.toLowerCase().includes(searchText.toLowerCase()) ||
        Description?.toLowerCase().includes(searchText.toLowerCase()),
    )
  }
  return rows
}

export const searchBusinesses = (rows, searchText) => {
  if (searchText) {
    return rows?.filter(
      ({ Name, SearchKeywords, Description, Services }) =>
        Name?.toLowerCase().includes(searchText.toLowerCase()) ||
        SearchKeywords?.some((item) =>
          item.toLowerCase().includes(searchText.toLowerCase()),
        ) ||
        Description?.toLowerCase().includes(searchText.toLowerCase()) ||
        Services?.some((item) =>
          item?.Title.toLowerCase().includes(searchText.toLowerCase()),
        ),
    )
  }
  return rows
}

export const searchReservations = (rows, searchText) => {
  if (searchText) {
    return rows?.filter(
      ({ RoomName, OrganizationName, CreatorEmail }) =>
        RoomName?.toLowerCase().includes(searchText.toLowerCase()) ||
        CreatorEmail?.toLowerCase().includes(searchText.toLowerCase()) ||
        OrganizationName?.toLowerCase().includes(searchText.toLowerCase()),
    )
  }
  return rows
}

export const searchEventCampaignRows = (rows, searchText) => {
  if (searchText) {
    return rows?.filter(
      ({ SpeakerName, SpeakerBio, Title, Details }) =>
        Title?.toLowerCase().includes(searchText.toLowerCase()) ||
        SpeakerName?.toLowerCase().includes(searchText.toLowerCase()) ||
        SpeakerBio?.toLowerCase().includes(searchText.toLowerCase()) ||
        Details?.toLowerCase().includes(searchText.toLowerCase()),
    )
  }
  return rows
}

export const searchProgramServiceRows = (rows, searchText) => {
  if (searchText) {
    return rows?.filter(
      ({ Description, Title }) =>
        Title?.toLowerCase().includes(searchText.toLowerCase()) ||
        Description?.toLowerCase().includes(searchText.toLowerCase()),
    )
  }
  return rows
}

export const searchVolunteerRows = (rows, searchText) => {
  if (searchText) {
    return rows?.filter(
      ({ Description, Title }) =>
        Title?.toLowerCase().includes(searchText.toLowerCase()) ||
        Description?.toLowerCase().includes(searchText.toLowerCase()),
    )
  }
  return rows
}

export const searchAuctionRows = (rows, searchText) => {
  if (searchText) {
    return rows?.filter(
      ({ Details, Title }) =>
        Title?.toLowerCase().includes(searchText.toLowerCase()) ||
        Details?.toLowerCase().includes(searchText.toLowerCase()),
    )
  }
  return rows
}

export const searchDonnorRows = (rows, searchText) => {
  if (searchText) {
    return rows?.filter(({ DonorName }) =>
      DonorName?.toLowerCase().includes(searchText.toLowerCase()),
    )
  }
  return rows
}

export const searchYTDDonationsRows = (rows, searchText) => {
  if (searchText) {
    return rows?.filter(
      ({ DonorName, DonorEmail, DonorPhoneNumber }) =>
        DonorName?.toLowerCase().includes(searchText.toLowerCase()) ||
        DonorEmail?.toLowerCase().includes(searchText.toLowerCase()) ||
        DonorPhoneNumber?.toLowerCase().includes(searchText.toLowerCase()),
    )
  }
  return rows
}

export const searchCommunityMasterListRows = (rows, searchText) => {
  if (searchText) {
    return rows?.filter(
      ({ DonorName, DonorEmail, DonorPhoneNumber, Status }) =>
        DonorName?.toLowerCase().includes(searchText.toLowerCase()) ||
        DonorEmail?.toLowerCase().includes(searchText.toLowerCase()) ||
        DonorPhoneNumber?.toLowerCase().includes(searchText.toLowerCase()) ||
        Status?.toLowerCase().includes(searchText.toLowerCase()),
    )
  }
  return rows
}

export const searchBusinessListingsRows = (rows, searchText) => {
  if (searchText) {
    return rows?.filter(({ BusinessName }) =>
      BusinessName?.toLowerCase().includes(searchText.toLowerCase()),
    )
  }
  return rows
}

export const formatAmount = (amount) => {
  const numberAmount = Number(amount)
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  return formatter.format(numberAmount || 0)
}

export const formatAmountWithOutDecimal = (amount) => {
  const amountAsNumber = parseFloat(amount)
  const formattedAmount = amountAsNumber.toLocaleString()
  return `$${formattedAmount}`
}

export const fetchMoreData = (current, setCurrent, data) => {
  setTimeout(() => {
    setCurrent(data.slice(0, current.length + config.itemPerLoad))
  }, 1000)
}

export const numberWithCommas = (number) => {
  if (!number) return ''
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const calculateRatio = (number1, number2) => `${number1} : ${number2}`

export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1)

export const mobileShareNavigator = async (title, url) => {
  await window.navigator.share({ title, url })
}

export const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    window.navigator.userAgent,
  )

export const timeInAgo = (date) => {
  if (date) {
    return moment(moment.utc(date)).local().fromNow()
  }
  return ''
}

export const timeInAgoOrActive = (date) => {
  if (date) {
    const now = moment()
    const condition = now.isBefore(date)
    if (condition) {
      return moment(moment.utc(date)).local().fromNow()
    }
    return 'Active'
  }
  return ''
}

export const sortByDate = (list, attribute) => {
  const data = list?.sort((a, b) => {
    const dateA = new Date(a[attribute])
    const dateB = new Date(b[attribute])
    return dateB - dateA
  })
  return data
}

export const sortByStatus = (list, attribute, statusOrder) => {
  const data = list?.sort(
    (a, b) =>
      statusOrder.indexOf(a[attribute]) - statusOrder.indexOf(b[attribute]),
  )
  return data
}

export const sortByZipcode = (list, attribute, zipCode) => {
  const updatedList = list.map((item) => {
    const distance = zipcodes.distance(item[attribute], zipCode)
    return { ...item, distance }
  })
  const data = updatedList?.sort((a, b) => {
    const dataA = new Date(a.distance)
    const dataB = new Date(b.distance)
    return dataA - dataB
  })
  return data
}

export const sortByAttendence = (list, attribute) => {
  const data = list?.sort((a, b) => {
    const nameA = a[attribute] && a[attribute].length ? a[attribute].length : 0
    const nameB = b[attribute] && b[attribute].length ? b[attribute].length : 0
    if (nameA < nameB) {
      return -1 // a should come before b in the sorted order
    }
    if (nameA > nameB) {
      return 1 // a should come after b in the sorted order
    }
    return 0
  })
  return data
}

export const sortByString = (list, attribute) => {
  const data = list?.sort((a, b) => {
    const nameA = a[attribute].toUpperCase()
    const nameB = b[attribute].toUpperCase()
    if (nameA < nameB) {
      return -1 // a should come before b in the sorted order
    }
    if (nameA > nameB) {
      return 1 // a should come after b in the sorted order
    }
    return 0
  })
  return data
}

export const compareDateTimeWithNow = (date, check) => {
  const now = moment()
  const datetime = moment(date)
  let condition
  if (check === 'after') {
    condition = now.isAfter(datetime)
  } else {
    condition = now.isBefore(datetime)
  }
  return condition
}

const disabledHours = (selected) => {
  const hours = []
  if (selected && selected.isSame(moment(), 'day')) {
    for (let i = 0; i < moment().hour(); i += 1) hours.push(i)
  }
  return hours
}

const disabledMinutes = (selected) => {
  const selectedHour = selected?.hour()
  const minutes = []
  if (selected && selected.isSame(moment(), 'day')) {
    if (selectedHour === moment().hour()) {
      for (let i = 0; i < moment().minute(); i += 1) {
        minutes.push(i)
      }
    }
  }
  return minutes
}

export const disabledTime = (selected) => ({
  disabledHours: () => disabledHours(selected),
  disabledMinutes: () => disabledMinutes(selected),
  // disabledSeconds: () => [55, 56],
})
/* eslint-disable */
export const createArray = (n) => {
  const array = Array(n)
    .fill()
    .map((_x, i) => i + 1)
  return array
}

/* eslint-disable */
export const getHoursFromDurations = (durations) => {
  const format12To24Hour = (time) => {
    const [timePart, modifier] = time.split(' ')
    let [hours, minutes] = timePart.split(':')

    if (hours === '12') {
      hours = '00'
    }

    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12
    }

    return `${hours}:${minutes}`
  }

  const format12To24HourDuration = (duration) => {
    return {
      from: format12To24Hour(duration.from),
      to: format12To24Hour(duration.to),
    }
  }

  let totalMinutes = 0

  durations?.map((item) => {
    const singleDuration = format12To24HourDuration(item)
    const fromHour = parseInt(singleDuration?.from.split(':')[0])
    const toHour = parseInt(singleDuration?.to.split(':')[0])
    const fromMinutes = parseInt(singleDuration?.from.split(':')[1])
    const toMinutes = parseInt(singleDuration?.to.split(':')[1])

    const startMinutes = fromHour * 60 + fromMinutes
    const endMinutes = toHour * 60 + toMinutes

    totalMinutes += endMinutes - startMinutes
  })

  const totalHours = totalMinutes / 60
  return parseFloat(totalHours.toFixed(2))
}

export const getDatesFromFrequency = (
  startDate,
  recurringType,
  recurringFrequency,
) => {
  let dates = []
  if (recurringFrequency) {
    const currentDate = moment(startDate)
    if (recurringType === 'Weekly') {
      for (let i = 0; i < recurringFrequency; i++) {
        dates.push(moment(currentDate.add(1, 'weeks')).format())
      }
    }
    if (recurringType === 'Monthly') {
      for (let i = 0; i < recurringFrequency; i++) {
        dates.push(moment(currentDate.add(1, 'months')).format())
      }
    }
    if (recurringType === 'Quaterly') {
      for (let i = 0; i < recurringFrequency; i++) {
        dates.push(moment(currentDate.add(1, 'quarters')).format())
      }
    }
    if (recurringType === 'Yearly') {
      for (let i = 0; i < recurringFrequency; i++) {
        dates.push(moment(currentDate.add(1, 'years')).format())
      }
    }
  }
  return dates
}

export const createFrequencyNumbers = (frequencyType) => {
  switch (frequencyType) {
    case 'Daily':
      return createArray(365)
    case 'Weekly':
      return createArray(52)
    case 'Monthly':
      return createArray(12)
    case 'Quaterly':
      return createArray(3)
    case 'Yearly':
      return createArray(1)
    default:
      return createArray(52)
  }
}

export const format12To24HourDuration = (duration) => {
  const { from, to } = duration
  return {
    from: format12To24HourValue(from),
    to: format12To24HourValue(to),
  }
}

export const format12To24HourValue = (text) => {
  const [value, merdiem] = text.split(' ')
  let [hours, minutes] = value.split(':')
  if (hours === '12') {
    hours = '00'
  }
  if (merdiem === 'pm') {
    hours = parseInt(hours, 10) + 12
  }
  return `${hours}:${minutes}`
}

export const htmlCode = (url, text) => {
  return `
  <div>
        <button
          id="myBtn"
          style="
            background-color: #14B1E0;
            padding: 10px;
            border: 1px solid white;
            color: white;
            font-size: bold;
            border-radius: 5px;
          "
        >
          ${text}
        </button>
        <div
          id="myModal"
          style="
            display: none;
            position: fixed;
            z-index: 1;
            padding-top: 20px;
            left: 0;
            top: 0;
            width: 100vw;
            height: 100vh;
            overflow: auto;
            background-color: rgb(0, 0, 0);
            background-color: rgba(0, 0, 0, 0.4);
          "
        >
          <div
            style="
              background-color: #fefefe;
              margin: auto;
              padding: 20px;
              border: 1px solid #888;
              width: 80vw;
              height: 80vh;
              border-radius: 12px;
            "
          >
            <span
              style="
                color: #aaaaaa;
                float: right;
                font-size: 28px;
                font-weight: bold;
                cursor: pointer;
              "
              class="close"
              >
              &times;
              </span>
            <div >
              <!-- <div style="position: relative;">
                  <div
                  id="loading_extensia_iframe"
                  style="
                    left: 0;
                    position: absolute;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    align-items: center;
                    display: flex;
                    justify-content: center;
                  "
                >
                  Loading
                </div>
              </div> -->
              <iframe
                style="width: 100%; height: calc(80vh - 40px);"
                src=${url}
                title="Donate through Extensia"
                id="iframe-1"
                onload="document.getElementById('loading_extensia_iframe').style.display = 'none'"
              >
              </iframe>
            </div>
          </div>
        </div>
        <script>
          var modal = document.getElementById('myModal')
          var btn = document.getElementById('myBtn')
          var span = document.getElementsByClassName('close')[0]
          span.addEventListener('click', function handleClick(){
              modal.style.display = "none";
          })
          btn.onclick = function () {
            modal.style.display = 'block'
          }
          window.onclick = function (event) {
            if (event.target == modal) {
              modal.style.display = 'none'
            }
          }
        </script>
      </div>
      `
}

export const checkIfMinutesInDurations = (durations, hour) => {
  let isBetween = false
  for (let j = 0; j < durations.length; j++) {
    const singleDuration = durations[j]
    const from = format12To24HourValue(singleDuration.from)
    const to = format12To24HourValue(singleDuration.to)
    const fromHour = parseInt(from.split(':')[0])
    const toHour = parseInt(to.split(':')[0])
    if (fromHour < hour && toHour > hour) {
      isBetween = true
    }
  }
  return isBetween
}

export const sortScheduleByDays = (schedule) => {
  const updatedSchedule = {
    monday: schedule['monday'],
    tuesday: schedule['tuesday'],
    wednesday: schedule['wednesday'],
    thursday: schedule['thursday'],
    friday: schedule['friday'],
    saturday: schedule['saturday'],
    sunday: schedule['sunday'],
  }
  return updatedSchedule
}

export const getTagColor = (status) => {
  switch (status) {
    case 'Completed':
      return 'green'
    case 'Rejected':
      return 'red'
    case 'Pending':
      return 'gray'
    case 'Processing':
      return 'orange'
    case 'Failed':
      return 'red'
    default:
      return 'green'
  }
}

export const formatUSAPhoneNumber = (Number) => {
  const PhoneNumber = Number.replace(/^0+/, '')
  const firstThree = PhoneNumber.substring(0, 3)
  const secondThree = PhoneNumber.substring(3, 6)
  const lastFour = PhoneNumber.substring(6, PhoneNumber.length)
  const phone = `(${firstThree}) ${secondThree}-${lastFour}`
  return phone
}

export const calculateComission = (amount, markup) => {
  const markUp = markup || 1
  return ((markUp * amount) / 100).toFixed(2)
}

export const REGEXS = {
  amountRegex: new RegExp('^[-+]?[0-9]*.?[0-9]+([eE][-+]?[0-9]+)?$'),
  numberRegex: new RegExp('[0-9]|,'),
  zipcodeRegex: '^[0-9]{5}(?:-[0-9]{4})?$',
  phoneRegex: '^[0-9-() ]{14,15}$',
  taxIDRegex:
    '^((?!11-1111111)(?!22-2222222)(?!33-3333333)(?!44-4444444)(?!55-5555555)(?!66-6666666)(?!77-7777777)(?!88-8888888)(?!99-9999999)(?!12-3456789)(?!00-[0-9]{7})([0-9]{2}-[0-9]{7}))*$',
  websiteRegex: new RegExp(
    '^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ),
}

export const TaxIdFormating = (taxId) => {
  if (taxId) {
    return `${taxId?.toString()?.slice(0, 2)}-${taxId
      ?.toString()
      ?.slice(2, 5)} ${taxId?.toString()?.slice(5)}`
  }
}

export const MaskPattern = '(000) 000-0000'

export const getAverageRating = (ratingList) => {
  if (ratingList && ratingList.length > 0) {
    const totalRatings = ratingList.reduce(
      (acc, ratingObj) => acc + ratingObj.Rating,
      0,
    )

    const averageRating = totalRatings / ratingList.length
    return averageRating
  }
  return 0
}

export const getEstimatedProcessingFee = (markup, amount) => {
  const processingPercentage =
    Number(config.processing_fee_percentage) + Number(markup)
  const processingAmount = (processingPercentage / 100) * Number(amount)
  return processingAmount
}

export const getFormatedPhone = (countryCode, phoneNumber) => {
  let phone = null
  if (countryCode && phoneNumber) {
    const formatedPhoneNumber = phoneNumber?.replace(/\D/g, '')
    const trimPhoneNumber = formatedPhoneNumber.replace(/^0+/, '')
    phone = countryCode + trimPhoneNumber
  }
  return phone
}

export const csvComponentReportMapping = (caseType) => {
  let result = {
    fileName: '',
    csvColumns: [],
    csvHeaderMapping: {},
  }
  switch (caseType) {
    case 'summary':
      result.fileName = 'Performance Summary Report'
      result.csvColumns = [
        'goalAmount',
        'Donations',
        'Pledges',
        'noOfAttendies',
      ]
      result.csvHeaderMapping = {
        goalAmount: 'Goal Amount',
        Donations: 'Donated Amount',
        Pledges: 'Pledged Amount',
        noOfAttendies: 'Number of Attendees',
      }
      break

    case 'details':
      result.fileName = 'Performance Details Report'
      result.csvColumns = [
        'goalAmount',
        'Donations',
        'Pledges',
        'ratioOfDonationsToPedges',
        'noOfAttendies',
        'NoOfDonations',
        'NoOfPledges',
      ]
      result.csvHeaderMapping = {
        goalAmount: 'Goal Amount',
        Donations: 'Donated Amount',
        Pledges: 'Pledged Amount',
        ratioOfDonationsToPedges: 'Ratio of Donation to Pledges',
        NoOfDonations: 'Number of Donations',
        NoOfPledges: 'Number of Pledges',
        noOfAttendies: 'Number of Attendees',
      }
      break

    case 'statistics':
      result.fileName = 'Event Statistics Report'
      result.csvColumns = [
        'goalAchieved',
        'ratioOfDonationsToPedges',
        'percentageOfCashDonations',
        'percentageOfCreditCardDonations',
        'percentageOfBankDebitDonations',
        'percentageOfCheckDonations',
        'percentageOfRemoteDonations',
        'averageDonations',
      ]
      result.csvHeaderMapping = {
        goalAchieved: '% of Goal Achieved',
        ratioOfDonationsToPedges: 'Ratio of Donation to Pledges',
        percentageOfCashDonations: '% of Cash Donation',
        percentageOfCreditCardDonations: '% of Card Donation',
        percentageOfBankDebitDonations: '% of Bank Donation',
        percentageOfCheckDonations: '% of Check Donation',
        percentageOfRemoteDonations: '% of Remote Donation',
        averageDonations: 'Average Donation Size',
      }
      break

    case 'pledges':
      result.fileName = 'Pledge Commitments Report'
      result.csvColumns = [
        'DonorName',
        'DonorPhoneNumber',
        'DonorEmail',
        'Amount',
        'ScheduleDate',
        'IsMonthly',
        'IsSplit',
        'PledgeStatus',
      ]
      result.csvHeaderMapping = {
        DonorName: 'Name',
        DonorEmail: 'Email',
        DonorPhoneNumber: 'Phone Number',
        Amount: 'Amount Pledged',
        ScheduleDate: 'Pledge Date',
        IsMonthly: 'Monthly Recurring',
        IsSplit: 'Split Payment',
        PledgeStatus: 'Status',
      }
      break

    case 'donations':
      result.fileName = 'Donor Contributions Report'
      result.csvColumns = [
        'DonorName',
        'DonorPhoneNumber',
        'DonorEmail',
        'DonorZipCode',
        'PaymentDate',
        'PaymentDate',
        'Amount',
      ]
      result.csvHeaderMapping = {
        DonorName: 'Donor Name',
        DonorEmail: 'Donor Email',
        DonorPhoneNumber: 'Donor Phone Number',
        DonorZipCode: 'Donor Zip Code',
        PaymentDate: 'Thank you Email Sent',
        PaymentDate: 'Than You SMS Sent',
        Amount: 'Amount',
      }
      break

    case 'donationStats':
      result.fileName = 'Donation Statistics Report'
      result.csvColumns = [
        'Donations',
        'TotalPledges',
        'Total',
        'Cash',
        'Checks',
        'Credit',
        'Pledges',
      ]
      result.csvHeaderMapping = {
        Donations: 'Total Donations',
        TotalPledges: 'Total Pledges',
        Total: 'Total Donation Amount',
        Cash: 'Total Cash Amount',
        Checks: 'Total Checks Amount',
        Credit: 'Total Credit Amount',
        Pledges: 'Total Pledges Amount',
      }
      break
    case 'program-service-detail':
      result.fileName = 'Performance Detail Report'
      result.csvColumns = [
        'ProgServiceName',
        'DonorName',
        'DonorEmail',
        'Amount',
        'PaymentDate',
      ]
      result.csvHeaderMapping = {
        ProgServiceName: 'Title',
        DonorName: 'Name',
        DonorEmail: 'Email',
        Amount: 'Amount',
        PaymentDate: 'PaymentDate',
      }
      break

    case 'rental':
      result.fileName = 'Performance Rental Detail Report'
      result.csvColumns = [
        'CreatorName',
        'CreatorPhoneNumber',
        'CreatorEmail',
        'GrossAmount',
        'PaymentDate',
        'PaymentType',
        'PaymentMethod',
      ]
      result.csvHeaderMapping = {
        CreatorName: 'Name',
        CreatorPhoneNumber: 'Phone Number',
        CreatorEmail: 'Email',
        GrossAmount: 'Amount',
        PaymentDate: 'PaymentDate',
        PaymentType: 'PaymentT ype',
        PaymentMethod: 'Payment Method',
      }
      break
    case 'vp':
      result.fileName = 'Performance Volunteer Opportunities Detail Report'
      result.csvColumns = [
        'firstName',
        'lastName',
        'phoneNumber',
        'email',
        'title',
        'Schedule',
        'ZipCode',
      ]
      result.csvHeaderMapping = {
        firstName: 'First Name',
        lastName: 'Last Name',
        phoneNumber: 'Phone Number',
        email: 'Email',
        title: 'Title',
        Schedule: 'Schedule Date',
        ZipCode: 'ZipCode',
      }
      break

    default:
      throw new Error('Invalid case type')
  }

  return result
}

export const flattenObject = (obj) => {
  const result = {}

  for (const key in obj) {
    // Check if the property is an object and not null or an array
    if (
      typeof obj[key] === 'object' &&
      obj[key] !== null &&
      !Array.isArray(obj[key])
    ) {
      // Recursively flatten the object and add its properties to the result
      const flattenedChild = flattenObject(obj[key])
      for (const childKey in flattenedChild) {
        result[childKey] = flattenedChild[childKey]
      }
    } else {
      // If it's not an object, add it directly to the result
      result[key] = obj[key]
    }
  }

  return result
}

// generating pdf on frontend based on report type

/*
const formatDataForPDF = (data, reportType) => {
  switch (reportType) {
    case 'summary':
      const PSdata = convertPerformanceSummaryDataFormat(data)
      const headerPs = Object.keys(PSdata)
      const bodyPs = Object.values(PSdata)
      return { header: headerPs, body: bodyPs }
    case 'details':
      const PDdata = convertPerformanceDetailsDataFormat(data)
      const headerPd = Object.keys(PDdata)
      const bodyPd = Object.values(PDdata)
      return { header: headerPd, body: bodyPd }
    case 'statistics':
      const ESData = convertEventStatisticsData(data)
      const headerEs = Object.keys(ESData)
      const bodyEs = Object.values(ESData)
      return { header: headerEs, body: bodyEs }
    case 'pledges':
      const PCdata = convertPledgeCommitsData(data)
      const headerPc = PCdata?.headers
      const bodyPc = PCdata?.body
      return { header: headerPc, body: bodyPc }

    case 'donations':
      const DCdata = convertDonorContributionsData(data)
      const headerDc = DCdata?.headers
      const bodyDc = DCdata?.body
      return { header: headerDc, body: bodyDc }

    case 'donationStats':
      const DSdata = convertDonationStatisticsDataFormat(data)
      const headerDs = Object.keys(DSdata)
      const bodyDs = Object.values(DSdata)
      return { header: headerDs, body: bodyDs }
    default:
      return []
  }

  function convertPerformanceSummaryDataFormat(data) {
    return {
      'Goal Amount': `${formatAmount(data?.goalAmount)}`,
      'Donated Amount': `${formatAmount(data?.total?.Donations)}`,
      'Pledged Amount': `${formatAmount(data?.total?.Pledges)}`,
      'Number of Attendees': data?.noOfAttendies,
      'Event Cost': formatAmount(data?.total?.Donations * 0.02),
    }
  }
}

function convertPerformanceDetailsDataFormat(originalData) {
  return {
    'Goal Amount': `${formatAmount(originalData?.goalAmount)}`,
    'Donated Amount': `${formatAmount(originalData?.total?.Donations)} (${
      originalData?.onlineDonationsPercentage
    }% online)`,
    'Pledged Amount': `${formatAmount(originalData?.total?.Pledges)} (${
      originalData?.onlinePledgesPercentage
    }% online)`,
    'Ratio of Donation to Pledges': originalData?.ratioOfDonationsToPedges,
    'Number of Donations': originalData?.total?.NoOfDonations,
    'Number of Pledges': originalData?.total?.NoOfPledges,
    'Number of Attendees': originalData?.noOfAttendies,
    'Event Cost': formatAmount(originalData?.total?.Donations * 0.02), // Assuming this is a static value
  }
}

function convertEventStatisticsData(originalData) {
  return {
    '% of Goal Achieved': originalData?.goalAchieved,
    'Ratio of Donation to Pledges': originalData?.ratioOfDonationsToPedges,
    '% of Cash Donation': originalData?.percentageOfCashDonations,
    '% of Card Donation': originalData?.percentageOfCreditCardDonations,
    '% of Bank Donation': originalData?.percentageOfBankDebitDonations,
    '% of Check Donation': originalData?.percentageOfCheckDonations,
    'Average Donation Size': `${formatAmount(originalData?.averageDonations)}`,
    '% of Remote Donation': originalData?.percentageOfRemoteDonations,
  }
}

function convertPledgeCommitsData(pledges) {
  const headers = [
    'Name',
    'Phone Number',
    'Email',
    'Amount Pledged',
    'Pledge Date',
    'Monthly Recurring',
    'Split Payment',
    'Status',
  ]

  const body = pledges.map((pledge) => [
    pledge?.DonorName,
    pledge?.DonorPhoneNumber,
    pledge?.DonorEmail,
    formatAmount(pledge?.Amount),
    moment(pledge?.ScheduleDate).format('dddd[,] DD/MM/YYYY [at] hh:mm A'),
    pledge?.IsMonthly ? 'Yes' : 'No',
    pledge?.IsSplit ? 'Yes' : 'No',
    pledge?.PledgeStatus === 'Completed'
      ? 'Donated Successfully'
      : 'Coming Soon',
  ])

  return { headers, body }
}

function convertDonorContributionsData(data) {
  const headers = [
    'Name',
    'Phone Number',
    'Email',
    'Donor ZipCode',
    'Thank You Email Sent',
    'Thank You SMS Sent',
    'Amount',
  ]

  const body = data.map((data) => [
    data?.DonorName,
    data?.DonorPhoneNumber,
    data?.DonorEmail,
    data?.DonorZipCode,
    moment(data?.PaymentDate).format('dddd[,] DD/MM/YYYY [at] hh:mm A'),
    moment(data?.PaymentDate).format('dddd[,] DD/MM/YYYY [at] hh:mm A'),
    data?.GrossAmount,
  ])

  return { headers, body }
}

function convertDonationStatisticsDataFormat(originalData) {
  return {
    'Total Donations': originalData?.Donations,
    'Total Pledges': originalData?.TotalPledges,
    'Total Donation Amount': formatAmount(originalData?.Total),
    'Total Cash Amount': formatAmount(originalData?.Cash),
    'Total Checks Amount': formatAmount(originalData?.Checks),
    'Total Credit Amount': formatAmount(originalData?.Credit),
    'Total Pledges Amount': formatAmount(originalData?.Pledges),
  }
}

export const generateComponentPDF = (fileName, heading, data, reportType) => {
  // eslint-disable-next-line new-cap
  const doc = new jsPDF()

  doc.setFontSize(18)
  doc.text(heading, 10, 10)

  const formattedData = formatDataForPDF(data, reportType)
  doc.autoTable({
    startY: 20,
    head: [formattedData?.header],
    body:
      reportType === 'pledges' || reportType === 'donations'
        ? formattedData?.body
        : [formattedData?.body],
  })

  doc.save(`${fileName}.pdf`)
}

*/

const getImageUrl = (fileName) => awsconfig.bucketUrl + fileName

export const getEventImageUrl = (eventFlyer, organizationBackgroundImage) => {
  let imageUrl

  // if event flyer is present, use that else use organization background image
  if (eventFlyer) {
    if (eventFlyer?.length > 0) {
      imageUrl =
        eventFlyer[0]?.fileName || organizationBackgroundImage?.fileName
    } else {
      imageUrl = eventFlyer?.fileName || organizationBackgroundImage?.fileName
    }
  } else {
    imageUrl = organizationBackgroundImage?.fileName
  }

  // if image url is not present, use sample image
  if (imageUrl) {
    imageUrl = getImageUrl(imageUrl)
  } else {
    imageUrl = sample
  }

  return imageUrl
}

export const getBusinessBackgoundImageUrl = (
  image,
  organizationBackgroundImage,
) => {
  let imageUrl

  // if business background image is present, use that else use organization background image
  if (image) {
    imageUrl = image?.fileName || organizationBackgroundImage?.fileName
  } else {
    imageUrl = organizationBackgroundImage?.fileName
  }

  // if image url is not present, use sample image
  if (imageUrl) {
    imageUrl = getImageUrl(imageUrl)
  } else {
    imageUrl = sample
  }

  return imageUrl
}

export const getBusinessProfilePictureUrl = (image) => {
  let imageUrl

  // if profile picture is present, use that else use sample business profile picture
  if (image && image?.fileName) {
    imageUrl = image?.fileName
  }

  if (imageUrl) {
    imageUrl = getImageUrl(imageUrl)
  } else {
    imageUrl = sampleBusinessProfile
  }

  return imageUrl
}

export const getStripeConnectOAuthLink = ({ caller }) => {
  const clientId = awsconfig.stripe_connect_client_id
  // Redirect URI should match in the Stripe dashboard
  const redirectUri = `${window.location.origin}${config.stripeRedirectUri}`
  const stringifiedData = JSON.stringify({ caller })
  const link = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${clientId}&scope=read_write&redirect_uri=${redirectUri}&state=${stringifiedData}`
  return link
}

export const getIsEventPledgeConversion = (item) => {
  const condition =
    item?.TransactionType === 'event' || item?.TransactionType === 'campaign'
  return condition
}

const setDefaultDonationValues = (values) => {
  if (values && values?.length > 0) {
    return values
  } else {
    return ['25', '50', '100', '225']
  }
}

export const getDataFromDonationType = ({ item, donationType }) => {
  let organization = null
  let event = null
  let eventRelatedItem = null
  let defaultDonationValues = null

  switch (donationType) {
    case 'organization-donation':
    case 'organization-pledge':
      // item is organization
      organization = item
      defaultDonationValues = setDefaultDonationValues(
        item?.DefaultDonationValues,
      )
      break
    case 'migrated-donation':
      // item is migrated donation
      organization = item?.organization
      defaultDonationValues = setDefaultDonationValues(
        item?.DefaultDonationValues,
      )
      break
    case 'event-donation':
    case 'seed-donation':
    case 'event-pledge':
      // item is event
      organization = item?.organization
      event = item
      defaultDonationValues = setDefaultDonationValues(
        item?.DefaultDonationValues,
      )
      break
    case 'pledge-conversion':
      // item is event reletad
      const isEventPledgeConversion = getIsEventPledgeConversion(item)
      organization = isEventPledgeConversion
        ? item?.event?.organization
        : item?.organization
      event = isEventPledgeConversion ? item?.event : null
      eventRelatedItem = item
      defaultDonationValues = setDefaultDonationValues(
        isEventPledgeConversion
          ? item?.event?.DefaultDonationValues
          : item?.organization?.DefaultDonationValues,
      )
      break
    default:
      break
  }
  return { organization, event, eventRelatedItem, defaultDonationValues }
}

export const getCodeAndNumberFromPhoneNumber = (phone) => {
  const regex = /^\+(\d{1,3})(\d{10})$/
  const match = phone.match(regex)

  let valid = false
  let countryCode = ''
  let phoneNumber = ''
  if (match) {
    valid = true
    const countrycode = `+${match[1]}`
    const phonenumber = match[2]
    const formattedPhoneNumber = `(${phonenumber.slice(
      0,
      3,
    )}) ${phonenumber.slice(3, 6)}-${phonenumber.slice(6)}`
    countryCode = countrycode
    phoneNumber = formattedPhoneNumber
  }

  return { valid, countryCode, phoneNumber }
}

export const getSplitNamesFromFullName = (fullName) => {
  if (!fullName) return { firstname: '', lastname: '' }
  const names = fullName.split(' ')
  const firstname = names[0]
  const lastname = names.slice(1).join(' ')
  return { firstname, lastname }
}

export const getFormattedPaymentAccountInfo = (category, markup) => {
  return {
    Id: category?.Id,
    stripe: {
      isConnected: category?.stripeID && category?.stripeOnBoarded,
      stripeId: category?.stripeID,
    },
    paypal: {
      isConnected: !!category?.paypalID,
      paypalId: category?.paypalID,
    },
    markup: Number.isNaN(Number(markup)) ? 1 : Number(markup),
  }
}

export const isPaymentCategoriesAdded = (check, list) => {
  if (check && list?.length > 0) {
    return true
  }
  return false
}

export const justFormatDate = (dateString) => {
  const options = { day: 'numeric', month: 'long', year: 'numeric' }
  const event = new Date(dateString)
  return event
    .toLocaleDateString('en-GB', options)
    .replace(/(\d+)(st|nd|rd|th)/, '$1')
}

export const justFormatTime = (timeString) => {
  const time = new Date(timeString)
  const options = { timeZone: 'UTC' } // Set timezone to UTC
  const formattedTime = time.toLocaleTimeString(undefined, options)
  return formattedTime
}

export const searchFundraisersRows = (rows, searchText) => {
  if (searchText) {
    return rows?.filter(
      ({ title, description }) =>
        title?.toLowerCase().includes(searchText.toLowerCase()) ||
        description?.toLowerCase().includes(searchText.toLowerCase()),
    )
  }
  return rows
}
