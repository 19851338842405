import React from 'react'
import { Col, Tag, Button, Card } from 'antd'
import { formatAmount, formateDate } from 'utils'

const ReservationDetail = ({
  reservation,
  isUpdate,
  onUpdateClick,
  onSave,
  isSaving,
}) => (
  <>
    {reservation && (
      <Col span={24} className="t-mt-4">
        <Card className="t-w-full">
          <Col xl={24} md={24} xs={24} sm={24} className="t-mt-4">
            <p className="t-text-secondary-100 t-mb-4 t-text-lg t-mr-2">
              Reservation date is{' '}
              <span className="t-text-secondary-100 t-font-bold t-text-lg">
                {formateDate(reservation?.Date)}
              </span>
            </p>
          </Col>
          <Col xl={24} md={24} xs={24} sm={24}>
            <h1 className="t-font-bold t-text-secondary-100">Durations</h1>
            <div className="t-flex t-flex-wrap t-my-2">
              {reservation?.Durations?.map((item) => (
                <Tag color="green">{`${item?.from} - ${item?.to}`}</Tag>
              ))}
            </div>
          </Col>
          {reservation.IsRecurring && (
            <Col xl={24} md={24} xs={24} sm={24}>
              <h1 className="t-font-bold t-text-secondary-100">
                Recurring Dates
              </h1>
              <div className="t-flex t-flex-wrap t-my-2">
                {reservation?.RecurringDates?.map((item) => (
                  <Tag color="orange">{`${formateDate(item)}`}</Tag>
                ))}
              </div>
            </Col>
          )}
          <Col xl={24} md={24} xs={24} sm={24}>
            <h1 className=" t-text-secondary-100">
              {`You have made a reservation for ${
                reservation?.TotalHours
              } hours with a total cost of ${formatAmount(
                reservation?.TotalCost,
              )}`}
            </h1>
          </Col>
          {reservation?.AcceptanceStatus === 'ChangeRequested' && (
            <Col xl={24} md={24} xs={24} sm={24}>
              <h1 className=" t-text-red-500 t-mb-4">
                Room owner has made a change date request. Kindly see the
                details and make approriate changes and update the reservation
                to send the request to room owner for review.
              </h1>
            </Col>
          )}
          <Col
            xl={24}
            md={24}
            xs={24}
            sm={24}
            className="t-flex t-justify-end t-w-full t-space-x-2"
          >
            <Button
              type="primary"
              loading={isSaving}
              size="small"
              onClick={onUpdateClick}
            >
              {isUpdate
                ? 'Click to close update reservation'
                : 'Click to open update reservation'}
            </Button>
            {!isUpdate && (
              <Button
                loading={isSaving}
                type="primary"
                size="small"
                onClick={onSave}
              >
                Accept Change
              </Button>
            )}
          </Col>
        </Card>
      </Col>
    )}
  </>
)

export { ReservationDetail }
