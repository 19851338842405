import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Skeleton, Spin, Tooltip } from 'antd'
import { CarryOutFilled } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { fetchMoreData, searchEventCampaignRows } from 'utils'
import { config, awsconfig } from 'configs'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { AiFillEye } from 'react-icons/ai'
import { getMyEvents, updateEventStatus } from 'store/eventSlice/eventActions'
import { eventActions, useEventSelector } from 'store/eventSlice/eventReducer'

import sample from 'assets/images/sample/sample1.png'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useHistory } from 'react-router-dom'

function InactiveList({ searchText, sortKey }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const { location, organization } = useAuthSelector()
  const { loading, inactiveEvents } = useEventSelector()

  const positionData = {
    lat: location?.lat,
    lon: location?.lng,
    zipCode: location?.zipcode,
  }

  const filteredInactive = inactiveEvents.filter(
    (event) => event?.OrganizationId === organization.id,
  )

  const [current, setCurrent] = useState([])

  const searchedData = searchEventCampaignRows(filteredInactive, searchText)

  useEffect(() => {
    setCurrent(searchedData.slice(0, config.itemFirstLoad))
    // eslint-disable-next-line
  }, [inactiveEvents, searchText])

  useEffect(() => {
    dispatch(getMyEvents(sortKey))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortKey])

  const handleUpdateEvent = (event) => {
    dispatch(updateEventStatus(event?.Id, event?.ActiveInd, positionData))
  }
  const handleEventSelected = (event) => {
    dispatch(eventActions.setJoinedEventDetails(null))
    dispatch(eventActions.setEvent(null))
    history.push(`events/${event.Id}`)
  }

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
      <div className="t-flex t-flex-col t-justify-between t-h-full">
        <InfiniteScroll
          dataLength={current.length}
          next={() => fetchMoreData(current, setCurrent, searchedData)}
          hasMore={current.length < searchedData.length}
          loader={
            <div className="t-flex t-justify-center t-items-center">
              <Spin />
            </div>
          }
        >
          <Row gutter={[24, 24]} className="t-h-full">
            {current.length === 0 && (
              <div
                className="t-h-96 t-flex t-justify-center t-items-center t-w-full"
                style={{ minHeight: 'calc(100vh - 300px)' }}
              >
                <div className="t-flex t-flex-col t-items-center t-space-y-2">
                  <CarryOutFilled
                    style={{ fontSize: '2rem' }}
                    className="t-text-secondary-100"
                  />
                  <p>No records found</p>
                </div>
              </div>
            )}
            {current.map((item, index) => (
              <Col span={24} md={8} xl={8} key={index}>
                <Card
                  bordered
                  hoverable
                  cover={
                    <img
                      alt="example"
                      className="t-w-full t-h-48 t-object-cover t-rounded-md"
                      src={
                        item?.Flyer?.length
                          ? awsconfig.bucketUrl + item?.Flyer[0]?.fileName
                          : item?.Flyer?.fileName
                          ? awsconfig.bucketUrl + item?.Flyer?.fileName
                          : item?.OrganizationBackgroundImage
                          ? awsconfig.bucketUrl +
                            item?.OrganizationBackgroundImage?.fileName
                          : sample
                      }
                    />
                  }
                  onClick={() => handleEventSelected(item)}
                >
                  <div className="t-flex t-justify-between t-items-center">
                    <h5 className="t-text-2xl t-text-secondary-100 t-font-bold t-truncate">
                      <Tooltip title={item?.Title}>{item?.Title}</Tooltip>
                    </h5>

                    <AiFillEye
                      fontSize="2rem"
                      className="t-cursor-pointer t-text-secondary-100"
                      onClick={() => handleUpdateEvent(item)}
                    />
                  </div>

                  <div className="t-flex t-justify-between">
                    <div className="card-tag t-font-thin t-text-secondary-100">
                      {item?.OrganizationName}
                    </div>
                  </div>
                  <p className="t-line-clamp-1 t-text-sm">{item?.Details}</p>
                </Card>
              </Col>
            ))}
          </Row>
        </InfiniteScroll>
      </div>
    </Skeleton>
  )
}

export { InactiveList }
