import React, { useEffect, useRef, useState } from 'react'
import { awsconfig } from 'configs'
import { Button, Col, message } from 'antd'
import {
  PayPalButtons,
  usePayPalScriptReducer,
  PayPalScriptProvider,
} from '@paypal/react-paypal-js'
import {
  generateBillingAggrementApprovalLink,
  savePaypalPaymentLog,
} from 'store/paymentSlice/paymentActions'
import {
  calculateComission,
  getDataFromDonationType,
  getEstimatedProcessingFee,
} from 'utils'
import { eventActions, useEventSelector } from 'store/eventSlice/eventReducer'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useDispatch } from 'react-redux'

const Paypal = ({
  form,
  itemType,
  item,
  subscriberPaymentInfo,
  getPaypalId,
  getSelectedPaymentCategoryId,
  onSubmit,
  donating,
  isEventRelated,
  isOrganizationRelated,
}) => {
  const { organization, event } = getDataFromDonationType({
    item,
    donationType: itemType,
  })
  const dispatch = useDispatch()
  const { user, location } = useAuthSelector()
  const { joinedEventDetails } = useEventSelector()
  const [loading, setLoading] = useState(false)
  const isRepeat = form.getFieldValue('repeatMonths')

  /* eslint-disable */
  const onFinish = async () => {
    try {
      const data = await form.validateFields()

      try {
        const typedAmount = data.askedAmount
        const includeEventCost = data.includeEventCosts
        const { markup } = subscriberPaymentInfo
        const processingAmount = getEstimatedProcessingFee(markup, typedAmount)
        const totalDonation = includeEventCost
          ? Number(typedAmount) + processingAmount
          : typedAmount

        const { countryCode, phoneNumber } = data

        let phone = null

        if (countryCode && phoneNumber) {
          const formatedPhoneNumber = phoneNumber?.replace(/\D/g, '')
          const trimPhoneNumber = formatedPhoneNumber.replace(/^0+/, '')
          phone = countryCode + trimPhoneNumber
        }

        let repeatPayment = {
          split: {
            splitDonation: false,
            months: null,
          },
          repeat: {
            repeatMonths: isMigratedDonation
              ? true
              : Boolean(data.repeatMonths),
            months: isMigratedDonation
              ? data.repeatnoOfMonths
              : data.repeatMonths
              ? data.repeatnoOfMonths || null
              : null,
          },
        }

        // // checking the allowed split months
        // if (repeatPayment.split.splitDonation) {
        //   if (
        //     Number(repeatPayment.split.months) > Number(event?.splitPaymentMonths)
        //   ) {
        //     return message.error(
        //       `You can't split the donation for more than ${event?.splitPaymentMonths} months`,
        //     )
        //   }
        // }

        // checking the allowed repeat months
        if (repeatPayment.repeat.repeatMonths) {
          // Condition For Event
          if (
            isEventRelated &&
            Number(repeatPayment.repeat.months) >
              Number(event?.monthlyPaymentMonths)
          ) {
            return message.error(
              `You can't repeat the donation for more than ${event?.monthlyPaymentMonths} months`,
            )
          }

          // Condition For Organization
          if (
            isOrganizationRelated &&
            Number(repeatPayment.repeat.months) > Number(36)
          ) {
            return message.error(
              `You can't repeat the donation for more than ${36} months`,
            )
          }
        }

        setLoading(true)
        const merchantAccountId = getPaypalId()
        const selectedPaymentCategoryId = getSelectedPaymentCategoryId()
        const payload = {
          merchantAccount: merchantAccountId,
          paymentCategory: selectedPaymentCategoryId,
          commission: subscriberPaymentInfo?.markup,
          amount: totalDonation,
          includeEventCost: includeEventCost || false,
          eventCostAmount: includeEventCost ? processingAmount.toFixed(2) : 0,
          typedAmount,
          user: {
            email: data.email,
            firstname: data.firstName,
            lastname: data.lastName,
            phone,
            countryCode: data.countryCode,
            phoneNumber: data.phoneNumber?.replace(/\D/g, ''),
          },
          event: isEventRelated ? event : null,
          organization: isOrganizationRelated ? organization : null,
          location: user ? location : null,
          joinedEventDetails: {
            isRemote: user ? joinedEventDetails?.isRemote : true,
            tableName: user ? joinedEventDetails?.tableName : null,
            donateAnonymously: data?.isAnonymous,
          },
          repeatPayment,
        }
        if (isMigratedDonation) {
          payload.recurringDonorId = item?.Id
        }

        // set the data to redux
        dispatch(eventActions.setPaypalBillingAggrementData(payload))
        const response = await generateBillingAggrementApprovalLink(
          merchantAccountId,
        )
        const link = response?.result?.links[0]?.href
        window.open(link, '_self')
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log('error', error)
        const err =
          error?.response?.data?.message ||
          'Something went wrong in generating agreement'
        message.error(err)
      }
    } catch (error) {
      console.log('Error:', error)
    }
  }

  const isMigratedDonation = itemType === 'migrated-donation'
  return (
    <div>
      <Col span={24} className="t-p-0">
        {loading || donating ? (
          <Button
            loading={loading || donating}
            type="primary"
            className="t-w-full"
            htmlType="button"
          >
            Donating
          </Button>
        ) : (
          <>
            {isRepeat ? (
              <Button
                loading={loading}
                type="primary"
                className="t-w-full"
                htmlType="button"
                onClick={onFinish}
              >
                Schedule PayPal Payment
              </Button>
            ) : (
              <>
                <PaypalProvider
                  form={form}
                  amount={form.getFieldValue('askedAmount')}
                  setLoading={setLoading}
                  subscriberPaymentInfo={subscriberPaymentInfo}
                  getPaypalId={getPaypalId}
                  onSubmit={onSubmit}
                />
                <PaypalProvider
                  form={form}
                  amount={form.getFieldValue('askedAmount')}
                  setLoading={setLoading}
                  isVenmo
                  subscriberPaymentInfo={subscriberPaymentInfo}
                  getPaypalId={getPaypalId}
                  onSubmit={onSubmit}
                />
              </>
            )}
          </>
        )}
      </Col>
    </div>
  )
}

const PaypalProvider = (props) => {
  const { getPaypalId } = props
  const paypalId = getPaypalId()
  return (
    <PayPalScriptProvider
      options={{
        'client-id': awsconfig.paypal_client_id,
        'merchant-id': paypalId,
        components: 'buttons',
        currency: 'USD',
      }}
    >
      <PaypalSinglePaymentButton {...props} />
    </PayPalScriptProvider>
  )
}

const PaypalSinglePaymentButton = ({
  form,
  amount,
  setLoading,
  isVenmo,
  subscriberPaymentInfo,
  onSubmit,
  getPaypalId,
}) => {
  usePayPalScriptReducer()

  const donationAmount = useRef(0)
  const commissionAmount = useRef(0)

  useEffect(() => {
    const includeCost = form.getFieldValue('includeEventCosts')
    if (includeCost) {
      const processingAmount = getEstimatedProcessingFee(
        subscriberPaymentInfo?.markup,
        amount,
      )
      const totalDonation = Number(amount) + processingAmount
      const formatedAmount = Number(totalDonation).toFixed(2)
      donationAmount.current = formatedAmount
    } else {
      const formatedAmount = Number(amount).toFixed(2)
      donationAmount.current = formatedAmount
    }
    commissionAmount.current = calculateComission(
      Number(donationAmount.current),
      subscriberPaymentInfo?.markup,
    )
  }, [amount, form.getFieldValue('includeEventCosts')])

  return (
    <PayPalButtons
      fundingSource={isVenmo ? 'venmo' : 'paypal'}
      style={{ layout: 'vertical', label: 'donate' }}
      // disabled={!amount}
      createOrder={async (data, actions) => {
        const isValid = await form.validateFields()
        if (isValid) {
          return actions.order
            .create({
              meta: {
                partner_attribution_id: awsconfig.paypal_bncode,
                'PayPal-Partner-Attribution-ID': awsconfig.paypal_bncode,
              },
              purchase_units: [
                {
                  amount: {
                    value: Number(donationAmount.current),
                    breakdown: {
                      item_total: {
                        currency_code: 'USD',
                        value: Number(donationAmount.current),
                      },
                    },
                  },
                  payment_instruction: {
                    disbursement_mode: 'INSTANT',
                    platform_fees: [
                      {
                        amount: {
                          currency_code: 'USD',
                          value: commissionAmount.current,
                        },
                      },
                    ],
                  },
                  items: [
                    {
                      name: 'Event',
                      quantity: '1',
                      unit_amount: {
                        currency_code: 'USD',
                        value: Number(donationAmount.current),
                      },
                      category: 'DONATION',
                    },
                  ],
                },
              ],
            })
            .then((orderId) => orderId)
            .catch((error) => {
              throw new Error(
                'Request is not well-formed, syntactically incorrect, or violates schema',
              )
            })
        } else {
          throw new Error('Fill the form')
        }
      }}
      onApprove={(data, actions) =>
        actions.order
          .capture()
          .then(async (details) => {
            setLoading(true)
            const paypalId = getPaypalId()
            const capturedAmount = details?.purchase_units[0]?.amount?.value
            const paymentId =
              details?.purchase_units[0]?.payments.captures[0].id
            try {
              const payload = {
                amount: capturedAmount,
                paymentId,
                merchantId: paypalId,
              }
              onSubmit(payload)
              setLoading(false)
            } catch (err) {
              message.error(err?.message || 'Something went wrong')
              setLoading(false)
            }
          })
          .catch((error) => {
            throw new Error('Paypal failed to capture the payment')
          })
      }
      onError={async (error) => {
        if (!error === 'Fill the form') {
          message.error('Could not able to process your payment')
          const payload = {
            type: 'PAYPAL_SEED_DONATION_CAMPAIGN',
            status: 'FAILURE',
            message: error?.message,
            action: 'Paypal payment for auction item',
          }
          await savePaypalPaymentLog(payload)
        }
      }}
      onCancel={() => message.info('Payment is cancelled')}
    />
  )
}

export { Paypal }
