import React, { useEffect, useState } from 'react'
import { Button, Skeleton } from 'antd'
import { useDispatch } from 'react-redux'
import { eventActions, useEventSelector } from 'store/eventSlice/eventReducer'
import {
  getEvent,
  getSubscriberMarkupInfo,
  getSubscriberPaymentInformation,
} from 'store/eventSlice/eventActions'
import { useHistory, useParams } from 'react-router-dom'
import { SVGIcon } from 'components'
import { BeforeJoinForm, EventDetail } from './components'

const JoinEvent = () => {
  const [isJoined, setJoined] = useState(false)
  const { joinedEventDetails } = useEventSelector()
  const [event, setEvent] = useState()
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()

  const getEventById = async () => {
    try {
      const eventDetail = await getEvent(params?.id)
      setEvent(eventDetail)
      setLoading(false)
    } catch (error) {
      console.log('error', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (params?.id) {
      getEventById()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id])

  useEffect(() => {
    if (event) {
      dispatch(getSubscriberPaymentInformation(event?.OrganizationId))
      dispatch(getSubscriberMarkupInfo(event?.OrganizationId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event])

  const joinEvent = () => {
    setJoined(true)
    history.push(history.location.pathname)
  }

  useEffect(() => {
    const isDirect = new URLSearchParams(history.location.search).get('direct')
    if (isDirect) {
      joinEvent()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const moveNext = (values) => {
    dispatch(eventActions.setJoinedEventDetails(values))
    setJoined(true)
  }

  useEffect(() => {
    if (!joinedEventDetails) {
      setJoined(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [joinedEventDetails])
  return (
    <Skeleton loading={loading} active paragraph={{ rows: 10 }}>
      {event ? (
        <>
          {event?.EventType !== 'Campaign' &&
          !isJoined &&
          !joinedEventDetails ? (
            <BeforeJoinForm moveNext={moveNext} />
          ) : (
            <EventDetail />
          )}
        </>
      ) : (
        <div className="ant-result ant-result-404">
          <SVGIcon />
          <div className="ant-result-title">An Error Occurred</div>
          <div className="ant-result-subtitle">
            Sorry, the could not get event details.
          </div>
          <div className="ant-result-extra">
            <Button
              onClick={() => window.location.reload(false)}
              type="primary"
              size="small"
            >
              Try Again
            </Button>
          </div>
        </div>
      )}
    </Skeleton>
  )
}

export { JoinEvent }
