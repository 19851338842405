import React, { useEffect, useState } from 'react'
import { Card, Skeleton, Col, Tag, Button, Popconfirm, message } from 'antd'
import { MdModeEdit, MdHomeWork } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useRoomSelector } from 'store/roomSlice/roomReducer'
import { getUserReservation, requestRefund } from 'store/roomSlice/roomActions'
import { formateDate } from 'utils'
import moment from 'moment'
import { AiFillEye } from 'react-icons/ai'
// import { PaymentModal } from './PaymentModal'
import { ViewReservation } from './ViewReservation'

const MyReservations = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { organization, user } = useAuthSelector()

  const [isLoading, setLoading] = useState(false)
  // const [visible, setVisible] = useState(false)
  const [viewModal, setViewModal] = useState(false)
  const [selected, setSelected] = useState(null)
  const { loading, reservations } = useRoomSelector()

  useEffect(() => {
    dispatch(getUserReservation(user?.id))
    // eslint-disable-next-line
  }, [])

  // const onClickPay = (reservation) => {
  //   setSelected(reservation)
  //   setVisible(!visible)
  // }

  // const onPaid = () => {
  //   dispatch(getUserReservation(user?.email))
  // }

  const onConfirmRefund = async (reservation) => {
    setLoading(true)
    try {
      await requestRefund(reservation)
      message.success('Refund request sent')
      dispatch(getUserReservation(organization?.id))
    } catch (error) {
      message.error('Error sending refund request')
    }
    setLoading(true)
  }

  const onViewReservation = (reservationId) => {
    const reservation = reservations.find((item) => item?.Id === reservationId)
    setSelected(reservation)
    setViewModal(!viewModal)
  }

  return (
    <Card
      title={[
        <>
          <h4 className="mb-0">My Rentals</h4>
          <p className="font-regular">My reservations</p>
        </>,
      ]}
      className="t-mb-5"
    >
      <div style={{ minHeight: 'calc(100vh - 320px)' }}>
        <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
          <Col span={24}>
            {reservations.length === 0 && (
              <Card className="t-h-96 t-w-full t-flex t-justify-center t-items-center">
                <div>No Reservations</div>
              </Card>
            )}
            {reservations.map((item) => {
              let scheduleDate = moment(item?.Date)
              scheduleDate = moment([
                scheduleDate.year(),
                scheduleDate.month(),
                scheduleDate.date(),
              ])
              let currentDate = moment()
              currentDate = moment([
                currentDate.year(),
                currentDate.month(),
                currentDate.date(),
              ])
              const days = scheduleDate.diff(currentDate, 'days')
              const isEditableDatePassed = days > 0
              const isRequestPending = item?.AcceptanceStatus === 'Pending'
              const isRequestRejected = item?.AcceptanceStatus === 'Rejected'
              const isRequestAccepted = item?.AcceptanceStatus === 'Accepted'
              const isChangeRequestPending =
                item?.AcceptanceStatus === 'ChangeRequested'
              const isRefundRequestPending =
                item?.RefundRequestStatus === 'Pending'
              const isRefundRequestApproved =
                item?.RefundRequestStatus === 'Approved'
              const isPaid = item?.PaymentStatus === 'Completed'
              const isPaymentProcessing = item?.PaymentStatus === 'Processing'
              return (
                <div className="t-flex t-justify-between t-items-center t-my-2 t-border t-rounded-md t-p-3 t-shadow">
                  <div className="t-flex t-flex-col sm:t-flex-row t-space-x-4 t-items-center t-space-y-4 md:t-space-y-0">
                    <MdHomeWork
                      fontSize="1.5rem"
                      className="t-text-secondary-100 t-cursor-pointer"
                    />
                    <div className="t-flex t-flex-col t-justify-center">
                      <h1 className="t-text-secondary-100 t-font-bold">
                        {`Reserved room (${item?.RoomName}) of organization (${item?.OrganizationName})`}
                      </h1>
                      <h1>{`Reserved date ${formateDate(item?.Date)}`}</h1>
                    </div>
                  </div>
                  <div className="t-flex t-space-x-2 t-items-center">
                    {isRequestPending && (
                      <Tag color="gray" className="font-bold">
                        Pending
                      </Tag>
                    )}
                    {isRequestAccepted && !isPaid && !isPaymentProcessing && (
                      <>
                        <Tag color="green" className="font-bold">
                          Accepted
                        </Tag>
                        <Button
                          type="primary"
                          size="small"
                          // onClick={() => onClickPay(item)}
                          onClick={() =>
                            history.push(`/payment/room/${item?.Id}`)
                          }
                        >
                          Pay
                        </Button>
                      </>
                    )}
                    {isRequestRejected && (
                      <Tag color="red" className="font-bold">
                        Rejected
                      </Tag>
                    )}
                    {isRefundRequestPending && (
                      <Tag color="orange" className="font-bold">
                        Refund requested
                      </Tag>
                    )}
                    {isRefundRequestApproved && (
                      <Tag color="blue" className="font-bold">
                        Refund Approved
                      </Tag>
                    )}
                    {isChangeRequestPending && (
                      <Tag color="purple" className="font-bold">
                        Reservation date changed
                      </Tag>
                    )}
                    {!isPaid &&
                      isEditableDatePassed &&
                      !isPaymentProcessing && (
                        <MdModeEdit
                          onClick={() =>
                            history.push(
                              `/rentals/${item?.RoomId}?reservationId=${item?.Id}`,
                            )
                          }
                          fontSize="1.5rem"
                          className="t-text-secondary-100 t-cursor-pointer"
                        />
                      )}
                    {isPaid &&
                      !isRefundRequestPending &&
                      !isRefundRequestApproved && (
                        <>
                          <Tag color="green" className="font-bold">
                            Paid
                          </Tag>
                          <Popconfirm
                            title="Are you sure you want to cancel the reservation？"
                            okText="Cancel and request a refund?"
                            cancelText="No"
                            placement="left"
                            loading={isLoading}
                            onConfirm={() => onConfirmRefund(item)}
                          >
                            <Button type="primary" size="small">
                              Cancel
                            </Button>
                          </Popconfirm>
                        </>
                      )}
                    {isPaymentProcessing && (
                      <Tag color="gray" className="font-bold">
                        Payment Processing
                      </Tag>
                    )}
                    <AiFillEye
                      fontSize="1.5rem"
                      className="t-text-secondary-100 t-cursor-pointer"
                      onClick={() => onViewReservation(item?.Id)}
                    />
                  </div>
                </div>
              )
            })}
          </Col>
        </Skeleton>
      </div>
      {/* <PaymentModal
        visible={visible}
        close={() => setVisible(!visible)}
        reservation={selected}
        refresh={onPaid}
      /> */}
      <ViewReservation
        visible={viewModal}
        reservation={selected}
        setVisible={() => setViewModal(!viewModal)}
      />
    </Card>
  )
}

export { MyReservations }
