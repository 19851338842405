import React, { useState, useEffect } from 'react'
import {
  Card,
  Form,
  Input,
  Row,
  Col,
  Button,
  Select,
  message,
  Skeleton,
  DatePicker,
  Divider,
  Modal,
  notification,
  Radio,
  Typography,
  Checkbox,
} from 'antd'
import { CheckCircleFilled } from '@ant-design/icons'
import { fundraiserValidation } from 'validations'
import { getDonorsEmail } from 'store/eventSlice/eventActions'
import { useParams, useHistory } from 'react-router-dom'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { getUserOrganization } from 'store/organizationSlice/organizationActions'
import moment from 'moment'
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'
import Api from 'api'
import { signUpWithoutOtp } from 'store/authSlice/authActions'
import {
  compareDateTimeWithNow,
  disabledTime,
  formatUSAPhoneNumber,
  MaskPattern,
  numberWithCommas,
} from 'utils'
import MaskedInput from 'antd-mask-input'
import { MultiImageUploader } from 'components/elements/MultiImageUploader'
import { AuctionItems } from 'components/common/auction/AuctionItems'
import { AddItemsModal } from 'components/common/auction/AddItemsModal'
import { AmountInput } from 'components/elements'
import {
  addFundraiser,
  getFundraiser,
  updateFundraiser,
} from 'store/fundraiserSlice/fundraiserActions'
import { UpdatePresenter } from './UpdatePresenter'

import './style.css'
import CollapsiblePanel from './CollapsiblePanel'

// import ReCAPTCHA from "react-google-recaptcha";

const { TextArea } = Input
const { Option } = Select

const paymentMethods = [
  {
    label: 'Credit/Debit Card',
    value: 'card',
  },
  {
    label: 'PayPal',
    value: 'paypal',
  },
  {
    label: 'ACH Debit',
    value: 'us_bank_account',
  },
  {
    label: 'Cash App',
    value: 'cashapp',
  },
]

const initialValues = {
  title: '',
  description: '',
  targetAmount: '',
  startDate: null,
  endDate: null,
  fundraiserType: 'Fundraiser',
  campaignSubType: 'Auction',
  youtubeLink: null,
  zipCode: '',
  flyer: [],
  enablePaymentCategories: false,
  paymentCategories: [],
  enableRecurringPayment: false,
  enableSplitPayment: false,
  recurringFrequency: [],
  splitFrequency: ['months'],
  enableCustomAmount: false,
  enableDonationNotes: false,
  coverProcessingFee: false,
  lockFeePayment: false,
  enablePledgeReminder: false,
  pledgeInterval: 'Weekly',
  pledgeMaxMessagesCap: 3,
  enableAuction: false,
  auctionItemsList: [],
  requireConstituentLogin: false,
  requireConstituentFirstAndLastName: false,
  requireConstituentMailingAddress: false,
  requireConstituentEmail: false,
  allowConstituentAnonymity: false,
  enableSeedDonor: false,
  seedDonors: [],
  requireSeedDonorLogin: false,
  requireSeedDonorFirstAndLastName: false,
  requireSeedDonorMailingAddress: false,
  requireSeedDonorEmail: false,
  enableVolunteer: false,
  volunteers: [],
  volunteerSlot: 1,
  trackVolunteerTime: false,
  requireVolunteerLogin: false,
  requireVolunteerFirstAndLastName: false,
  requireVolunteerMailingAddress: false,
  requireVolunteerEmail: false,
  volunteerAllocationHours: 1,
  enableSellTickets: false,
  siteOccupancyForTicket: 1,
  ticketPurchaseCap: 1,
  printableTickets: false,
  showOnLandingPage: false,
  showOnKiosk: false,
  featureOnKiosk: false,
  showDonatedAmount: false,
  showProgressBar: false,
  enableEmcee: false,
  presenter: 'Myself',
  presenterName: '',
  presenterPhoneCode: '+1',
  presenterMobileNumber: '',
  presenterEmail: '',
  enableSpeaker: false,
  speakerName: '',
  speakerBio: '',
  isPrivate: false,
  refLink: `${window.location.origin}/presenter/accept`,
  startEvent: false,
  isSelfPresent: true,
  DefaultDonationValue1: '25',
  DefaultDonationValue2: '50',
  DefaultDonationValue3: '100',
  DefaultDonationValue4: '225',
  allowedPaymentMethods: paymentMethods.map((p) => p.value),
}

const CreateFundraiser = () => {
  const { user, location, organization } = useAuthSelector()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [fetching, setFetched] = useState(false)
  const [fetchingTopKioskEvent] = useState(false)
  const [fundraiserDetail, setFundraiser] = useState(null)
  const [donorEmails, setDonorEmails] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [donorsSelected, setDonorsSelected] = useState([])
  const [categories, setCategories] = useState([])
  const [isCollapsed, setIsCollapsed] = useState({
    about: true,
    otherSettings: false,
    paymentSetup: false,
    paymentOptions: false,
    auctionItems: false,
    constituentInfo: false,
    seedDonorInfo: false,
    communityReach: false,
    selectEmcee: false,
    selectSpeakers: false,
    ticketSales: false,
  })

  // Aution states
  const [addItemsModal, setAddItemsModal] = useState(false)
  const [auctionItemsList, setAuctionItemsList] = useState([])

  // For storing default files
  const [defaultFyler, setDefaultFyler] = useState([])

  const params = useParams()
  const history = useHistory()

  Form.useWatch('enablePaymentCategories', form)
  Form.useWatch('enableRecurringPayment', form)
  Form.useWatch('enablePledgeReminder', form)
  Form.useWatch('enableSeedDonor', form)
  Form.useWatch('enableEmcee', form)
  Form.useWatch('presenter', form)
  Form.useWatch('enableAuction', form)
  Form.useWatch('startDate', form)
  Form.useWatch('endDate', form)
  Form.useWatch('allowedPaymentMethods', form)
  Form.useWatch('enableSellTickets', form)
  Form.useWatch('siteOccupancyForTicket', form)
  Form.useWatch('ticketPurchaseCap', form)

  const getPaymentCategories = () => {
    Api.get(
      `paymentCategories/getOrganizationPaymentCategories/${organization?.id}`,
    )
      .then((res) => {
        const formatedCategories = res?.results
          ?.filter((item) => item.ActiveInd === 1)
          .map((item) => ({
            value: item.Id,
            label: item.Name,
          }))
        setCategories(formatedCategories)
        form.setFieldValue(
          'paymentCategories',
          formatedCategories.map((c) => c.value),
        )
      })
      .catch((error) => {
        console.log('error', error)
        message.error('An Error Occured in fetching payment categories')
      })
  }

  useEffect(() => {
    fetchDonorsEmails()
    getPaymentCategories()
    // eslint-disable-next-line
  }, [user?.email])

  useEffect(() => {
    if (!params.id && organization && organization?.ZipCode) {
      form.setFieldsValue({ zipCode: organization.ZipCode })
    }
    // eslint-disable-next-line
  }, [params.id, organization])

  const enableAllCards = React.useCallback(() => {
    const newState = Object.keys(isCollapsed).reduce((acc, key) => {
      acc[key] = true
      return acc
    }, {})
    setIsCollapsed(newState)
  }, [setIsCollapsed, isCollapsed])

  useEffect(() => {
    const getData = async () => {
      setFetched(true)
      if (params.id) {
        const fundraiser = await getFundraiser(params.id)
        setFundraiser(fundraiser)
        if (fundraiser) {
          setPresenterValidate(true)
          setPresenterFound(true)

          setDonorsSelected(fundraiser?.SeedDonors || [])
          const dummyDefaultFlyer = fundraiser.flyer
            ? fundraiser.flyer.length
              ? fundraiser.flyer
              : [fundraiser.flyer]
            : []

          setDefaultFyler(dummyDefaultFlyer)

          fundraiser.startDate = moment(fundraiser.startDate)
          fundraiser.endDate = fundraiser.endDate
            ? moment(fundraiser.endDate)
            : null
          fundraiser.presenterPhoneCode =
            fundraiser.isSelfPresent === false
              ? fundraiser.presenterPhoneCode
              : ''
          fundraiser.presenterMobileNumber =
            fundraiser.isSelfPresent === false
              ? formatUSAPhoneNumber(fundraiser.presenterMobileNumber)
              : ''

          fundraiser.paymentCategories = fundraiser.paymentCategories.map(
            (c) => c.iD,
          )

          enableAllCards()

          form.setFieldsValue({
            ...fundraiser,
          })
          if (
            fundraiser?.defaultDonationValues &&
            fundraiser?.defaultDonationValues.length === 4
          ) {
            form.setFieldsValue({
              DefaultDonationValue1: fundraiser?.defaultDonationValues[0],
              DefaultDonationValue2: fundraiser?.defaultDonationValues[1],
              DefaultDonationValue3: fundraiser?.defaultDonationValues[2],
              DefaultDonationValue4: fundraiser?.defaultDonationValues[3],
            })
          }
          if (fundraiser.acceptanceStatus === 'Rejected') {
            notification.warning({
              message: 'Action Needed',
              description: (
                <div>
                  Emcee rejected to present event. Please update the emcee
                </div>
              ),
              duration: 0,
            })
          }
        } else {
          message.error('Invalid Fundraiser Id')
          history.push('/settings/create-fundraiser/')
        }
      }
      setFetched(false)
    }
    getData()
  }, [params.id, history, form, enableAllCards])

  const onFinish = async (vals) => {
    // To check if campaign end date is after start date
    const { startDate, endDate } = vals

    if (endDate && moment(endDate).isBefore(moment(startDate))) {
      message.error('End date time must be after start date time')
      return
    }

    const markupData = {
      ...initialValues,
      ...vals,
    }

    const values = markupData

    if (
      values.enableEmcee === true &&
      !presenterValidate &&
      values.presenter !== 'Myself'
    ) {
      message.error('Kindly Validate the Emcee')
    }

    setLoading(true)
    try {
      if (values.presenter !== 'Myself' && presenterFound === false) {
        const { presenterPhoneCode, presenterMobileNumber } = values
        // removing - from string
        const FormatedPresenterMobileNumber = presenterMobileNumber?.replace(
          /\D/g,
          '',
        )
        // removing zero if it's the first charater in the string
        const trimPhoneNumber = FormatedPresenterMobileNumber?.replace(
          /^0+/,
          '',
        )
        const userPhone = presenterPhoneCode + trimPhoneNumber
        const name = values?.presenterName?.split(' ')
        await signUpWithoutOtp(userPhone, {
          family_name: name[1],
          given_name: name[0],
          email: values.presenterEmail,
        })
      }
      const userOrganization = await getUserOrganization(user.email)
      if (params.id) {
        const data = {
          ...fundraiserDetail,
          ...values,
          presenterMobileNumber: values?.presenterMobileNumber?.replace(
            /\D/g,
            '',
          ),
          targetAmount: values.targetAmount.replaceAll(/,/g, ''),
          lat: location?.lat,
          lon: location?.lng,
          organizationId: userOrganization?.id,
          organizationName: userOrganization?.Name,
          organizationBackgroundImage:
            organization &&
            organization?.BackgroundImage &&
            organization.BackgroundImage,
          defaultDonationValues: [
            values.DefaultDonationValue1,
            values.DefaultDonationValue2,
            values.DefaultDonationValue3,
            values.DefaultDonationValue4,
          ],
        }
        delete data.DefaultDonationValue1
        delete data.DefaultDonationValue2
        delete data.DefaultDonationValue3
        delete data.DefaultDonationValue4

        await updateFundraiser(data, user, defaultFyler)
        notification.success({
          message: 'Fundraiser Updated',
          description: <div>Fundraiser updated successfully.</div>,
          duration: 0,
          icon: (
            <CheckCircleFilled
              style={{
                color: '#00b894',
              }}
            />
          ),
        })
        setLoading(false)
        history.push('/fundraisers')
      } else {
        const data = {
          ...markupData,
          isSelfPresent: values.presenter === initialValues.presenter,
          presenterMobileNumber: values?.presenterMobileNumber?.replace(
            /\D/g,
            '',
          ),
          targetAmount: values.targetAmount.replaceAll(/,/g, ''),
          lat: location?.lat,
          lon: location?.lng,
          organizationId: userOrganization?.id,
          organizationName: userOrganization?.Name,
          organizationBackgroundImage:
            organization &&
            organization?.BackgroundImage &&
            organization.BackgroundImage,
          defaultDonationValues: [
            values.DefaultDonationValue1,
            values.DefaultDonationValue2,
            values.DefaultDonationValue3,
            values.DefaultDonationValue4,
          ],
        }
        delete data.DefaultDonationValue1
        delete data.DefaultDonationValue2
        delete data.DefaultDonationValue3
        delete data.DefaultDonationValue4

        // if auction enabled
        data.auctionItemsList = auctionItemsList
        await addFundraiser(data, user, defaultFyler)
        notification.success({
          message: 'Fundraiser Created',
          description: <div>Fundraiser created successfully.</div>,
          duration: 0,
          icon: (
            <CheckCircleFilled
              style={{
                color: '#00b894',
              }}
            />
          ),
        })
        setLoading(false)
        form.resetFields()
        history.push('/fundraisers')
      }
    } catch (error) {
      setLoading(false)
      console.log('Error', error)
      message.error(error?.response?.data?.message || 'Something Went Wrong')
    }
  }

  const [presenterValidate, setPresenterValidate] = useState(false)
  const [presenterFound, setPresenterFound] = useState(false)

  const buttonText = loading
    ? params.id
      ? 'Updating Fundraiser'
      : 'Creating Fundraiser'
    : params.id
    ? 'Update Fundraiser'
    : 'Create Fundraiser'

  const validatePresenter = () => {
    const { presenterPhoneCode, presenterMobileNumber } = form.getFieldsValue()
    if (presenterMobileNumber === '') {
      message.error('Please enter emcee mobile number.')
      return false
    }
    // removing - from string
    const FormatedPresenterMobileNumber = presenterMobileNumber?.replace(
      /\D/g,
      '',
    )
    // removing zero if it's the first charater in the string
    const trimPhoneNumber = FormatedPresenterMobileNumber?.replace(/^0+/, '')
    const phone = presenterPhoneCode + trimPhoneNumber
    setLoading(true)
    Api.get(
      `/users/check-phone-number-with-info?phone=${encodeURIComponent(phone)}`,
    )
      .then(() => {
        setPresenterValidate(true)
        setPresenterFound(false)
        form.setFieldValue('presenterName', '')
        form.setFieldValue('presenterEmail', '')
        setLoading(false)
      })
      .catch((err) => {
        const success = err?.response?.data?.message
        const data = err?.response?.data?.values
        if (success === 'User Found') {
          form.setFieldValue('presenterName', data.name)
          form.setFieldValue('presenterEmail', data.email)
          setPresenterFound(true)
          setPresenterValidate(true)
          setLoading(false)
        } else {
          message.error('Something Went Wrong')
        }
      })
    return null
  }

  const fetchDonorsEmails = async () => {
    const donors = await getDonorsEmail(user?.email)
    setDonorEmails(donors?.result)
  }

  const handleTargetAmountChange = (value) => {
    const newValue = value.replaceAll(/,/g, '')
    const val = newValue.replace(/\D/g, '')
    form.setFieldsValue({ targetAmount: numberWithCommas(val) })
  }

  const handleChangeCollapsed = (key) => {
    const cloned = { ...isCollapsed }
    cloned[key] = !isCollapsed[key]
    setIsCollapsed(cloned)
  }

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <>
          <h6 className="mb-0 t-text-2xl">
            {params.id ? 'Update Fundraiser' : 'Add Fundraiser'}
          </h6>
          <p className="font-regular">Enter Information</p>
        </>,
      ]}
    >
      <Skeleton
        loading={fetching || fetchingTopKioskEvent}
        active
        paragraph={{ rows: 18 }}
      >
        <Form
          form={form}
          layout="vertical"
          key={0}
          onFinish={onFinish}
          name="fundraiser"
          initialValues={initialValues}
          scrollToFirstError
        >
          <Row>
            <Col xl={8} md={8} xs={0} sm={0}>
              <Typography.Title level={5}>About Fundraiser</Typography.Title>
            </Col>

            <Col xl={12} md={12} xs={24} sm={24}>
              <CollapsiblePanel
                isOpen={isCollapsed.about}
                setToggle={() => handleChangeCollapsed('about')}
                title="About Fundraiser"
              >
                <Col span={24}>
                  <Form.Item
                    name="title"
                    label="Title"
                    colon={false}
                    rules={[fundraiserValidation]}
                  >
                    <Input placeholder="Enter Title" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="description"
                    label="Description"
                    colon={false}
                    rules={[fundraiserValidation]}
                  >
                    <TextArea
                      placeholder="Tell us about the fundraiser"
                      autoSize={{ minRows: 3, maxRows: 5 }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="targetAmount"
                    label="Target amount"
                    colon={false}
                    rules={[fundraiserValidation]}
                  >
                    <Input
                      inputMode="numeric"
                      prefix="$"
                      placeholder="20000"
                      onChange={(e) => handleTargetAmountChange(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="startDate"
                    label="Start Date"
                    colon={false}
                    rules={[fundraiserValidation]}
                  >
                    <DatePicker
                      format="MM-DD-YYYY hh:mm A"
                      showTime
                      minuteStep={15}
                      className="t-w-full"
                      disabledDate={(d) =>
                        !d || d.isBefore(moment().subtract(1, 'day'))
                      }
                      disabledTime={disabledTime}
                      onChange={(selectedDate) =>
                        !compareDateTimeWithNow(selectedDate, 'before') &&
                        form.setFieldsValue({
                          StartDate: moment(),
                        })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="endDate"
                    label="End Date"
                    colon={false}
                    rules={[fundraiserValidation]}
                  >
                    <DatePicker
                      format="MM-DD-YYYY hh:mm A"
                      showTime
                      minuteStep={15}
                      className="t-w-full"
                      disabledDate={(d) =>
                        !d ||
                        d.isBefore(moment().subtract(1, 'day')) ||
                        d.isBefore(
                          moment(form.getFieldValue('StartDate')).startOf(
                            'day',
                          ),
                        )
                      }
                      disabledTime={disabledTime}
                      onChange={(selectedDate) =>
                        !compareDateTimeWithNow(selectedDate, 'before') &&
                        form.setFieldsValue({
                          EndDate: moment(),
                        })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="zipCode"
                    label="Location"
                    colon={false}
                    rules={[fundraiserValidation]}
                  >
                    <Input placeholder="e.g : 12345" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="youtubeLink"
                    label="Youtube Link"
                    colon={false}
                    rules={[fundraiserValidation]}
                  >
                    <Input placeholder="Youtube Link" />
                  </Form.Item>
                </Col>
                <Row>
                  <Col span={16}>
                    <Typography.Title level={5}>
                      Private Fundraiser?
                    </Typography.Title>
                  </Col>
                  <Col span={8} className="text-right">
                    <Form.Item
                      name="isPrivate"
                      colon={false}
                      rules={[fundraiserValidation]}
                    >
                      <Radio.Group buttonStyle="solid" size="small">
                        <Radio.Button value>Yes</Radio.Button>
                        <Radio.Button value={false}>No</Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                <Col span={24}>
                  <Form.Item
                    name="flyer"
                    label="Banner Image"
                    colon={false}
                    rules={[fundraiserValidation]}
                  >
                    <MultiImageUploader
                      ratio={2 / 1}
                      defaultFiles={
                        form.getFieldValue('flyer') &&
                        form.getFieldValue('flyer')
                      }
                      onFileChange={(files) =>
                        form.setFieldsValue({
                          flyer: files,
                        })
                      }
                    />
                  </Form.Item>
                </Col>
              </CollapsiblePanel>
            </Col>
          </Row>

          <Row className="t-mt-6">
            <Col xl={8} md={8} xs={0} sm={0}>
              <Typography.Title level={5}>More Settings</Typography.Title>
            </Col>

            <Col xl={12} md={12} xs={24} sm={24}>
              <CollapsiblePanel
                isOpen={isCollapsed.otherSettings}
                setToggle={() => handleChangeCollapsed('otherSettings')}
                title="Other Settings"
              ></CollapsiblePanel>
            </Col>
          </Row>

          {isCollapsed.otherSettings ? (
            <>
              <Row className="t-mt-6">
                <Col xl={8} md={8} xs={0} sm={0}>
                  <Typography.Title level={5}>Payment Setup</Typography.Title>
                </Col>

                <Col xl={12} md={12} xs={24} sm={24}>
                  <CollapsiblePanel
                    isOpen={isCollapsed.paymentSetup}
                    setToggle={() => handleChangeCollapsed('paymentSetup')}
                    title="Payment Setup"
                  >
                    <Row>
                      <Col span={24}>
                        <Typography.Title level={5}>
                          Preset Button Amounts
                        </Typography.Title>
                      </Col>
                      {Array.from({ length: 4 }, (__, index) => (
                        <Col xl={12} md={12} xs={24} sm={24} key={index}>
                          <Form.Item
                            name={`DefaultDonationValue${index + 1}`}
                            colon={false}
                            rules={[fundraiserValidation]}
                          >
                            <AmountInput
                              placeholder={`Enter amount ${index + 1}`}
                              onChange={(value) =>
                                form.setFieldsValue({
                                  [`DefaultDonationValue${index + 1}`]: value,
                                })
                              }
                            />
                          </Form.Item>
                        </Col>
                      ))}
                      <Divider />
                    </Row>

                    <Row>
                      <Col span={16}>
                        <Typography.Title level={5}>
                          Use Payment Categories?
                        </Typography.Title>
                      </Col>
                      <Col span={8} className="text-right">
                        <Form.Item
                          name="enablePaymentCategories"
                          colon={false}
                          rules={[fundraiserValidation]}
                        >
                          <Radio.Group buttonStyle="solid" size="small">
                            <Radio.Button value>Yes</Radio.Button>
                            <Radio.Button value={false}>No</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col
                        span={
                          form.getFieldValue('enablePaymentCategories') ? 24 : 0
                        }
                      >
                        <Form.Item
                          name="paymentCategories"
                          colon={false}
                          rules={[fundraiserValidation]}
                        >
                          <Checkbox.Group options={categories} />
                        </Form.Item>
                      </Col>
                      <Divider />
                    </Row>

                    <Row>
                      <Col span={16}>
                        <Typography.Title level={5}>
                          Allow Recurring Payments?
                        </Typography.Title>
                      </Col>
                      <Col span={8} className="text-right">
                        <Form.Item
                          name="enableRecurringPayment"
                          colon={false}
                          rules={[fundraiserValidation]}
                        >
                          <Radio.Group buttonStyle="solid" size="small">
                            <Radio.Button value>Yes</Radio.Button>
                            <Radio.Button value={false}>No</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col
                        span={
                          form.getFieldValue('enableRecurringPayment') ? 24 : 0
                        }
                      >
                        <Form.Item
                          name="recurringFrequency"
                          label="Select Frequencies"
                          colon={false}
                          rules={[fundraiserValidation]}
                        >
                          <Checkbox.Group
                            options={[
                              'Daily',
                              'Weekly',
                              'Monthly',
                              'Quarterly',
                            ].map((d) => ({
                              label: d,
                              value: d.toLowerCase(),
                            }))}
                          />
                        </Form.Item>
                      </Col>
                      <Divider />
                    </Row>

                    <Row>
                      <Col span={16}>
                        <Typography.Title level={5}>
                          Allow Split Payments?
                        </Typography.Title>
                      </Col>
                      <Col span={8} className="text-right">
                        <Form.Item
                          name="enableSplitPayment"
                          colon={false}
                          rules={[fundraiserValidation]}
                        >
                          <Radio.Group buttonStyle="solid" size="small">
                            <Radio.Button value>Yes</Radio.Button>
                            <Radio.Button value={false}>No</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Divider />
                    </Row>

                    <Row>
                      <Col span={16}>
                        <Typography.Title level={5}>
                          Allowed Payment Methods
                        </Typography.Title>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="allowedPaymentMethods"
                          colon={false}
                          rules={[fundraiserValidation]}
                        >
                          <Checkbox.Group options={paymentMethods} />
                        </Form.Item>
                      </Col>
                      <Divider />
                    </Row>
                  </CollapsiblePanel>
                </Col>
              </Row>

              <Row className="t-mt-6">
                <Col xl={8} md={8} xs={0} sm={0}>
                  <Typography.Title level={5}>
                    More Payment Options
                  </Typography.Title>
                </Col>

                <Col xl={12} md={12} xs={24} sm={24}>
                  <CollapsiblePanel
                    isOpen={isCollapsed.paymentOptions}
                    setToggle={() => handleChangeCollapsed('paymentOptions')}
                    title="More Payment Options"
                  >
                    <Row>
                      <Col span={16}>
                        <Typography.Title level={5}>
                          Allow Custom Amount?
                        </Typography.Title>
                      </Col>
                      <Col span={8} className="text-right">
                        <Form.Item
                          name="enableCustomAmount"
                          colon={false}
                          rules={[fundraiserValidation]}
                        >
                          <Radio.Group buttonStyle="solid" size="small">
                            <Radio.Button value>Yes</Radio.Button>
                            <Radio.Button value={false}>No</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Divider />
                    </Row>

                    <Row>
                      <Col span={16}>
                        <Typography.Title level={5}>
                          Allow Donation Notes?
                        </Typography.Title>
                      </Col>
                      <Col span={8} className="text-right">
                        <Form.Item
                          name="enableDonationNotes"
                          colon={false}
                          rules={[fundraiserValidation]}
                        >
                          <Radio.Group buttonStyle="solid" size="small">
                            <Radio.Button value>Yes</Radio.Button>
                            <Radio.Button value={false}>No</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Divider />
                    </Row>

                    <Row>
                      <Col span={16}>
                        <Typography.Title level={5}>
                          Cover Processing Fees?
                        </Typography.Title>
                      </Col>
                      <Col span={8} className="text-right">
                        <Form.Item
                          name="coverProcessingFee"
                          colon={false}
                          rules={[fundraiserValidation]}
                        >
                          <Radio.Group buttonStyle="solid" size="small">
                            <Radio.Button value>Yes</Radio.Button>
                            <Radio.Button value={false}>No</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Divider />
                    </Row>

                    <Row>
                      <Col span={16}>
                        <Typography.Title level={5}>
                          Lock Fee Payment?
                        </Typography.Title>
                      </Col>
                      <Col span={8} className="text-right">
                        <Form.Item
                          name="lockFeePayment"
                          colon={false}
                          rules={[fundraiserValidation]}
                        >
                          <Radio.Group buttonStyle="solid" size="small">
                            <Radio.Button value>Yes</Radio.Button>
                            <Radio.Button value={false}>No</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Divider />
                    </Row>

                    <Row>
                      <Col span={16}>
                        <Typography.Title level={5}>
                          Allow Pledges?
                        </Typography.Title>
                      </Col>
                      <Col span={8} className="text-right">
                        <Form.Item
                          name="enablePledgeReminder"
                          colon={false}
                          rules={[fundraiserValidation]}
                        >
                          <Radio.Group buttonStyle="solid" size="small">
                            <Radio.Button value>Yes</Radio.Button>
                            <Radio.Button value={false}>No</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col
                        span={
                          form.getFieldValue('enablePledgeReminder') ? 24 : 0
                        }
                        className="t-mt-2"
                      >
                        <Form.Item
                          name="pledgeInterval"
                          label="Pledge Reminders?"
                          colon={false}
                          rules={[fundraiserValidation]}
                        >
                          <Checkbox.Group
                            options={['Weekly', 'Monthly', 'Quarterly'].map(
                              (d) => ({ label: d, value: d.toLowerCase() }),
                            )}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        span={
                          form.getFieldValue('enablePledgeReminder') ? 24 : 0
                        }
                        className="t-mt-2"
                      >
                        <Form.Item
                          name="pledgeMaxMessagesCap"
                          label="Maximum # of messages to send"
                          colon={false}
                          rules={[fundraiserValidation]}
                        >
                          <Select
                            placeholder="Maximum # of messages to send"
                            style={{ width: '100%' }}
                          >
                            <Select.Option value="1">1</Select.Option>
                            <Select.Option value="2">2</Select.Option>
                            <Select.Option value="3">3</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </CollapsiblePanel>
                </Col>
              </Row>

              {!params.id ? (
                <Row className="t-mt-6">
                  <Col xl={8} md={8} xs={0} sm={0}>
                    <Typography.Title level={5}>Auction Items</Typography.Title>
                  </Col>

                  <Col xl={12} md={12} xs={24} sm={24}>
                    <CollapsiblePanel
                      isOpen={isCollapsed.auctionItems}
                      setToggle={() => handleChangeCollapsed('auctionItems')}
                      title="Add Auction Items"
                    >
                      <Row>
                        <Col span={16}>
                          <Typography.Title level={5}>
                            Enable Auctions?
                          </Typography.Title>
                        </Col>
                        <Col span={8} className="text-right">
                          <Form.Item
                            name="enableAuction"
                            colon={false}
                            rules={[fundraiserValidation]}
                          >
                            <Radio.Group buttonStyle="solid" size="small">
                              <Radio.Button value>Yes</Radio.Button>
                              <Radio.Button value={false}>No</Radio.Button>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>

                      {form.getFieldValue('enableAuction') ? (
                        <Row>
                          <Col span={24}>
                            <AuctionItems
                              auctionItemsList={auctionItemsList}
                              setAuctionItemsList={setAuctionItemsList}
                              setAddItemsModal={setAddItemsModal}
                            />
                          </Col>
                        </Row>
                      ) : null}
                    </CollapsiblePanel>
                  </Col>
                </Row>
              ) : null}

              <Row className="t-mt-6">
                <Col xl={8} md={8} xs={0} sm={0}>
                  <Typography.Title level={5}>
                    Constituent Info.
                  </Typography.Title>
                </Col>

                <Col xl={12} md={12} xs={24} sm={24}>
                  <CollapsiblePanel
                    isOpen={isCollapsed.constituentInfo}
                    setToggle={() => handleChangeCollapsed('constituentInfo')}
                    title="Constituent Information"
                  >
                    <Row>
                      <Col span={16}>
                        <Typography.Title level={5}>
                          Require Login?
                        </Typography.Title>
                      </Col>
                      <Col span={8} className="text-right">
                        <Form.Item
                          name="requireConstituentLogin"
                          colon={false}
                          rules={[fundraiserValidation]}
                        >
                          <Radio.Group buttonStyle="solid" size="small">
                            <Radio.Button value>Yes</Radio.Button>
                            <Radio.Button value={false}>No</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Divider />
                    </Row>

                    <Row>
                      <Col span={16}>
                        <Typography.Title level={5}>
                          Require First/Last Name?
                        </Typography.Title>
                      </Col>
                      <Col span={8} className="text-right">
                        <Form.Item
                          name="requireConstituentFirstAndLastName"
                          colon={false}
                          rules={[fundraiserValidation]}
                        >
                          <Radio.Group buttonStyle="solid" size="small">
                            <Radio.Button value>Yes</Radio.Button>
                            <Radio.Button value={false}>No</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Divider />
                    </Row>

                    <Row>
                      <Col span={16}>
                        <Typography.Title level={5}>
                          Require Mailing Address?
                        </Typography.Title>
                      </Col>
                      <Col span={8} className="text-right">
                        <Form.Item
                          name="requireConstituentMailingAddress"
                          colon={false}
                          rules={[fundraiserValidation]}
                        >
                          <Radio.Group buttonStyle="solid" size="small">
                            <Radio.Button value>Yes</Radio.Button>
                            <Radio.Button value={false}>No</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Divider />
                    </Row>

                    <Row>
                      <Col span={16}>
                        <Typography.Title level={5}>
                          Require Email?
                        </Typography.Title>
                      </Col>
                      <Col span={8} className="text-right">
                        <Form.Item
                          name="requireConstituentEmail"
                          colon={false}
                          rules={[fundraiserValidation]}
                        >
                          <Radio.Group buttonStyle="solid" size="small">
                            <Radio.Button value>Yes</Radio.Button>
                            <Radio.Button value={false}>No</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Divider />
                    </Row>

                    <Row>
                      <Col span={16}>
                        <Typography.Title level={5}>
                          Allow Anonymity?
                        </Typography.Title>
                      </Col>
                      <Col span={8} className="text-right">
                        <Form.Item
                          name="allowConstituentAnonymity"
                          colon={false}
                          rules={[fundraiserValidation]}
                        >
                          <Radio.Group buttonStyle="solid" size="small">
                            <Radio.Button value>Yes</Radio.Button>
                            <Radio.Button value={false}>No</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                  </CollapsiblePanel>
                </Col>
              </Row>

              <Row className="t-mt-6">
                <Col xl={8} md={8} xs={0} sm={0}>
                  <Typography.Title level={5}>
                    Seed Donor Info.
                  </Typography.Title>
                </Col>

                <Col xl={12} md={12} xs={24} sm={24}>
                  <CollapsiblePanel
                    isOpen={isCollapsed.seedDonorInfo}
                    setToggle={() => handleChangeCollapsed('seedDonorInfo')}
                    title="Seed Donor Information"
                  >
                    <Row>
                      <Col span={16}>
                        <Typography.Title level={5}>
                          Invite Seed Donors?
                        </Typography.Title>
                      </Col>
                      <Col span={8} className="text-right">
                        <Form.Item
                          name="enableSeedDonor"
                          colon={false}
                          rules={[fundraiserValidation]}
                        >
                          <Radio.Group buttonStyle="solid" size="small">
                            <Radio.Button value>Yes</Radio.Button>
                            <Radio.Button value={false}>No</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Col span={form.getFieldValue('enableSeedDonor') ? 24 : 0}>
                      <Form.Item
                        label="Search Donors"
                        name="seedDonors"
                        colon={false}
                      >
                        <Select
                          mode="tags"
                          size="middle"
                          placeholder="Search by name"
                          style={{
                            width: '100%',
                          }}
                          value={donorsSelected}
                        >
                          {donorEmails?.map((item) => (
                            <Option key={item.DonorEmail}>
                              {item.DonorName}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    {form.getFieldValue('enableSeedDonor') ? (
                      <>
                        <Row>
                          <Col span={16}>
                            <Typography.Title level={5}>
                              Require Login?
                            </Typography.Title>
                          </Col>
                          <Col span={8} className="text-right">
                            <Form.Item
                              name="requireSeedDonorLogin"
                              colon={false}
                              rules={[fundraiserValidation]}
                            >
                              <Radio.Group buttonStyle="solid" size="small">
                                <Radio.Button value>Yes</Radio.Button>
                                <Radio.Button value={false}>No</Radio.Button>
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row>
                          <Col span={16}>
                            <Typography.Title level={5}>
                              Require First/Last Name?
                            </Typography.Title>
                          </Col>
                          <Col span={8} className="text-right">
                            <Form.Item
                              name="requireSeedDonorFirstAndLastName"
                              colon={false}
                              rules={[fundraiserValidation]}
                            >
                              <Radio.Group buttonStyle="solid" size="small">
                                <Radio.Button value>Yes</Radio.Button>
                                <Radio.Button value={false}>No</Radio.Button>
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row>
                          <Col span={16}>
                            <Typography.Title level={5}>
                              Require Mailing Address?
                            </Typography.Title>
                          </Col>
                          <Col span={8} className="text-right">
                            <Form.Item
                              name="requireSeedDonorMailingAddress"
                              colon={false}
                              rules={[fundraiserValidation]}
                            >
                              <Radio.Group buttonStyle="solid" size="small">
                                <Radio.Button value>Yes</Radio.Button>
                                <Radio.Button value={false}>No</Radio.Button>
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row>
                          <Col span={16}>
                            <Typography.Title level={5}>
                              Require Email?
                            </Typography.Title>
                          </Col>
                          <Col span={8} className="text-right">
                            <Form.Item
                              name="requireSeedDonorEmail"
                              colon={false}
                              rules={[fundraiserValidation]}
                            >
                              <Radio.Group buttonStyle="solid" size="small">
                                <Radio.Button value>Yes</Radio.Button>
                                <Radio.Button value={false}>No</Radio.Button>
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    ) : null}
                  </CollapsiblePanel>
                </Col>
              </Row>

              <Row className="t-mt-6">
                <Col xl={8} md={8} xs={0} sm={0}>
                  <Typography.Title level={5}>Ticket Sales</Typography.Title>
                </Col>

                <Col xl={12} md={12} xs={24} sm={24}>
                  <CollapsiblePanel
                    isOpen={isCollapsed.ticketSales}
                    setToggle={() => handleChangeCollapsed('ticketSales')}
                    title="Ticket Sales"
                  >
                    <Row>
                      <Col span={16}>
                        <Typography.Title level={5}>
                          Sell Tickets?
                        </Typography.Title>
                      </Col>
                      <Col span={8} className="text-right">
                        <Form.Item
                          name="enableSellTickets"
                          colon={false}
                          rules={[fundraiserValidation]}
                        >
                          <Radio.Group buttonStyle="solid" size="small">
                            <Radio.Button value>Yes</Radio.Button>
                            <Radio.Button value={false}>No</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Divider />
                    </Row>

                    {form.getFieldValue('enableSellTickets') ? (
                      <>
                        <Row>
                          <Col span={16}>
                            <Typography.Title level={5}>
                              Site Occupancy?
                            </Typography.Title>
                          </Col>
                          <Col span={8} className="text-right">
                            <Form.Item
                              name="siteOccupancyForTicket"
                              colon={false}
                              rules={[fundraiserValidation]}
                            >
                              <Input.Group compact>
                                <Button
                                  type="primary"
                                  onClick={() =>
                                    form.setFieldValue(
                                      'siteOccupancyForTicket',
                                      form.getFieldValue(
                                        'siteOccupancyForTicket',
                                      ) - 1,
                                    )
                                  }
                                >
                                  -
                                </Button>
                                <Input
                                  type="number"
                                  style={{ width: '60px', textAlign: 'center' }}
                                  value={
                                    form.getFieldValue(
                                      'siteOccupancyForTicket',
                                    ) <= 0
                                      ? 1
                                      : form.getFieldValue(
                                          'siteOccupancyForTicket',
                                        )
                                  }
                                  onChange={(e) =>
                                    form.setFieldValue(
                                      'siteOccupancyForTicket',
                                      e.target.value,
                                    )
                                  }
                                />
                                <Button
                                  type="primary"
                                  onClick={() =>
                                    form.setFieldValue(
                                      'siteOccupancyForTicket',
                                      form.getFieldValue(
                                        'siteOccupancyForTicket',
                                      ) + 1,
                                    )
                                  }
                                >
                                  +
                                </Button>
                              </Input.Group>
                            </Form.Item>
                          </Col>
                          <Divider />
                        </Row>

                        <Row>
                          <Col span={16}>
                            <Typography.Title level={5}>
                              Max Per Purchase?
                            </Typography.Title>
                          </Col>
                          <Col span={8} className="text-right">
                            <Form.Item
                              name="ticketPurchaseCap"
                              colon={false}
                              rules={[fundraiserValidation]}
                            >
                              <Input.Group compact>
                                <Button
                                  type="primary"
                                  onClick={() =>
                                    form.setFieldValue(
                                      'ticketPurchaseCap',
                                      form.getFieldValue('ticketPurchaseCap') -
                                        1,
                                    )
                                  }
                                >
                                  -
                                </Button>
                                <Input
                                  type="number"
                                  style={{ width: '60px', textAlign: 'center' }}
                                  value={
                                    form.getFieldValue('ticketPurchaseCap') <= 0
                                      ? 1
                                      : form.getFieldValue('ticketPurchaseCap')
                                  }
                                  onChange={(e) =>
                                    form.setFieldValue(
                                      'ticketPurchaseCap',
                                      e.target.value,
                                    )
                                  }
                                />
                                <Button
                                  type="primary"
                                  onClick={() =>
                                    form.setFieldValue(
                                      'ticketPurchaseCap',
                                      form.getFieldValue('ticketPurchaseCap') +
                                        1,
                                    )
                                  }
                                >
                                  +
                                </Button>
                              </Input.Group>
                            </Form.Item>
                          </Col>
                          <Divider />
                        </Row>

                        <Row>
                          <Col span={16}>
                            <Typography.Title level={5}>
                              Printable Tickets?
                            </Typography.Title>
                          </Col>
                          <Col span={8} className="text-right">
                            <Form.Item
                              name="printableTickets"
                              colon={false}
                              rules={[fundraiserValidation]}
                            >
                              <Radio.Group buttonStyle="solid" size="small">
                                <Radio.Button value>Yes</Radio.Button>
                                <Radio.Button value={false}>No</Radio.Button>
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    ) : null}
                  </CollapsiblePanel>
                </Col>
              </Row>

              <Row className="t-mt-6">
                <Col xl={8} md={8} xs={0} sm={0}>
                  <Typography.Title level={5}>Community Reach</Typography.Title>
                </Col>

                <Col xl={12} md={12} xs={24} sm={24}>
                  <CollapsiblePanel
                    isOpen={isCollapsed.communityReach}
                    setToggle={() => handleChangeCollapsed('communityReach')}
                    title="Community Reach"
                  >
                    <Row>
                      <Col span={16}>
                        <Typography.Title level={5}>
                          Show On Landing Page?
                        </Typography.Title>
                      </Col>
                      <Col span={8} className="text-right">
                        <Form.Item
                          name="showOnLandingPage"
                          colon={false}
                          rules={[fundraiserValidation]}
                        >
                          <Radio.Group buttonStyle="solid" size="small">
                            <Radio.Button value>Yes</Radio.Button>
                            <Radio.Button value={false}>No</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Divider />
                    </Row>

                    <Row>
                      <Col span={16}>
                        <Typography.Title level={5}>
                          Show On Kiosk?
                        </Typography.Title>
                      </Col>
                      <Col span={8} className="text-right">
                        <Form.Item
                          name="showOnKiosk"
                          colon={false}
                          rules={[fundraiserValidation]}
                        >
                          <Radio.Group buttonStyle="solid" size="small">
                            <Radio.Button value>Yes</Radio.Button>
                            <Radio.Button value={false}>No</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Divider />
                    </Row>

                    <Row>
                      <Col span={16}>
                        <Typography.Title level={5}>
                          Feature on Kiosk?
                        </Typography.Title>
                      </Col>
                      <Col span={8} className="text-right">
                        <Form.Item
                          name="featureOnKiosk"
                          colon={false}
                          rules={[fundraiserValidation]}
                        >
                          <Radio.Group buttonStyle="solid" size="small">
                            <Radio.Button value>Yes</Radio.Button>
                            <Radio.Button value={false}>No</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Divider />
                    </Row>

                    <Row>
                      <Col span={16}>
                        <Typography.Title level={5}>
                          Show Donated Amount?
                        </Typography.Title>
                      </Col>
                      <Col span={8} className="text-right">
                        <Form.Item
                          name="showDonatedAmount"
                          colon={false}
                          rules={[fundraiserValidation]}
                        >
                          <Radio.Group buttonStyle="solid" size="small">
                            <Radio.Button value>Yes</Radio.Button>
                            <Radio.Button value={false}>No</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Divider />
                    </Row>

                    <Row>
                      <Col span={16}>
                        <Typography.Title level={5}>
                          Show Progress Bar?
                        </Typography.Title>
                      </Col>
                      <Col span={8} className="text-right">
                        <Form.Item
                          name="showProgressBar"
                          colon={false}
                          rules={[fundraiserValidation]}
                        >
                          <Radio.Group buttonStyle="solid" size="small">
                            <Radio.Button value>Yes</Radio.Button>
                            <Radio.Button value={false}>No</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                  </CollapsiblePanel>
                </Col>
              </Row>

              <Row className="t-mt-6">
                <Col xl={8} md={8} xs={0} sm={0}>
                  <Typography.Title level={5}>Select Emcee</Typography.Title>
                </Col>

                <Col xl={12} md={12} xs={24} sm={24}>
                  <CollapsiblePanel
                    isOpen={isCollapsed.selectEmcee}
                    setToggle={() => handleChangeCollapsed('selectEmcee')}
                    title="Select Emcee"
                  >
                    <Row>
                      <Col span={16}>
                        <Typography.Title level={5}>
                          Select Emcee?
                        </Typography.Title>
                      </Col>
                      <Col span={8} className="text-right">
                        <Form.Item
                          name="enableEmcee"
                          colon={false}
                          rules={[fundraiserValidation]}
                        >
                          <Radio.Group buttonStyle="solid" size="small">
                            <Radio.Button value>Yes</Radio.Button>
                            <Radio.Button value={false}>No</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col
                        span={form.getFieldValue('enableEmcee') ? 24 : 0}
                        className="t-mt-2"
                      >
                        <Form.Item
                          name="presenter"
                          label="Who will Present?"
                          colon={false}
                          rules={[fundraiserValidation]}
                        >
                          <Select style={{ width: '100%' }}>
                            <Select.Option value="Myself">Myself</Select.Option>
                            <Select.Option value="NewPresenter">
                              New Emcee
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>

                      {form.getFieldValue('presenter') !== 'Myself' &&
                      form.getFieldValue('enableEmcee') === true ? (
                        <>
                          <Col span={24}>
                            <Form.Item
                              name="presenterPhoneCode"
                              label="Select Country"
                              colon={false}
                              rules={[fundraiserValidation]}
                            >
                              <Select
                                placeholder="Select Country Code"
                                style={{ width: '100%' }}
                                disabled={params.id}
                              >
                                <Select.Option value="+1">
                                  United States/Canada (+1)
                                </Select.Option>
                                <Select.Option value="+54">
                                  Argentina (+54)
                                </Select.Option>
                                <Select.Option value="+92">
                                  Pakistan (+92)
                                </Select.Option>
                                <Select.Option value="+55">
                                  Brazil (+55)
                                </Select.Option>
                                <Select.Option value="+58">
                                  Venezuela, Bolivarian Republic of Venezuela
                                  (+58)
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item
                              name="presenterMobileNumber"
                              label="Emcee Mobile Number"
                              colon={false}
                              rules={[fundraiserValidation]}
                            >
                              <Input.Group compact>
                                <MaskedInput
                                  disabled={params.id}
                                  inputMode="numeric"
                                  mask={MaskPattern}
                                  onChange={(e) => {
                                    form.setFieldValue(
                                      'presenterMobileNumber',
                                      e.maskedValue,
                                    )
                                  }}
                                  status={!presenterValidate ? 'warning' : ''}
                                  suffix={
                                    !presenterValidate ? (
                                      <AiFillCloseCircle
                                        fontSize="1.2rem"
                                        className="t-text-orange-600"
                                      />
                                    ) : (
                                      <AiFillCheckCircle
                                        fontSize="1.2rem"
                                        className="t-text-green-600"
                                      />
                                    )
                                  }
                                />
                                {params.id ? (
                                  <>
                                    <Button
                                      type="primary"
                                      onClick={() => setIsModalVisible(true)}
                                    >
                                      Select New Emcee
                                    </Button>
                                    <Modal
                                      title="Enter New Emcee Information"
                                      open={isModalVisible}
                                      onCancel={() => setIsModalVisible(false)}
                                      footer={null}
                                    >
                                      <UpdatePresenter
                                        form={form}
                                        close={() => setIsModalVisible(false)}
                                      />
                                    </Modal>
                                  </>
                                ) : (
                                  <Button
                                    type="primary"
                                    loading={loading}
                                    onClick={validatePresenter}
                                  >
                                    Validate Emcee
                                  </Button>
                                )}
                              </Input.Group>
                            </Form.Item>
                          </Col>
                        </>
                      ) : null}
                      {presenterValidate === true &&
                        form.getFieldValue('presenter') !== 'Myself' && (
                          <>
                            <Col span={24}>
                              <Form.Item
                                name="presenterName"
                                label="Emcee Name"
                                colon={false}
                                rules={[fundraiserValidation]}
                              >
                                <Input
                                  placeholder="Enter Name"
                                  disabled={presenterFound}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item
                                name="presenterEmail"
                                label="Emcee Email"
                                colon={false}
                                rules={[fundraiserValidation]}
                              >
                                <Input
                                  placeholder="Enter Email"
                                  disabled={presenterFound}
                                />
                              </Form.Item>
                            </Col>
                          </>
                        )}
                    </Row>
                  </CollapsiblePanel>
                </Col>
              </Row>

              <Row className="t-mt-6">
                <Col xl={8} md={8} xs={0} sm={0}>
                  <Typography.Title level={5}>Select Speakers</Typography.Title>
                </Col>

                <Col xl={12} md={12} xs={24} sm={24}>
                  <CollapsiblePanel
                    isOpen={isCollapsed.selectSpeakers}
                    setToggle={() => handleChangeCollapsed('selectSpeakers')}
                    title="Select Speakers"
                  >
                    <Col span={24}>
                      <Form.Item
                        name="speakerName"
                        label="Speaker Name"
                        colon={false}
                        rules={[fundraiserValidation]}
                      >
                        <Input placeholder="Enter speaker name" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="speakerBio"
                        label="Speaker Bio"
                        colon={false}
                        rules={[fundraiserValidation]}
                      >
                        <TextArea
                          placeholder="Enter speaker bio"
                          autoSize={{ minRows: 3, maxRows: 5 }}
                        />
                      </Form.Item>
                    </Col>
                  </CollapsiblePanel>
                </Col>
              </Row>
            </>
          ) : null}

          <Col
            xl={20}
            md={20}
            xs={24}
            sm={24}
            className="text-right t-space-x-2 t-mt-6"
          >
            <Button
              type="ghost"
              className="px-25"
              onClick={() => history.goBack()}
            >
              Cancel
            </Button>
            <Button
              loading={loading}
              type="primary"
              className="px-25"
              htmlType="submit"
            >
              {buttonText}
            </Button>
          </Col>
        </Form>

        <AddItemsModal
          visible={addItemsModal}
          close={() => setAddItemsModal(false)}
          auctionItemsList={auctionItemsList}
          setAuctionItemsList={setAuctionItemsList}
          isCampaign
          defaultEndDate={form.getFieldValue('endDate')}
          defaultStartDate={form.getFieldValue('startDate')}
        />
      </Skeleton>
    </Card>
  )
}
export { CreateFundraiser }
