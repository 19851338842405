import React, { useEffect } from 'react'
import { Skeleton, Button, Card, Col, Row, Tooltip } from 'antd'
import { searchEventCampaignRows } from 'utils'
import { awsconfig } from 'configs'
import { getAllEvents } from 'store/eventSlice/eventActions'
import { useDispatch } from 'react-redux'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { eventActions, useEventSelector } from 'store/eventSlice/eventReducer'
import { useHistory } from 'react-router-dom'
import { CarryOutFilled } from '@ant-design/icons'
import sample from 'assets/images/sample/sample1.png'

const PresenterEvents = ({ searchText, sortKey }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { user, organization } = useAuthSelector()
  const { loading, events } = useEventSelector()

  useEffect(() => {
    dispatch(getAllEvents())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortKey])

  const handleEventSelected = (event) => {
    dispatch(eventActions.setJoinedEventDetails(null))
    dispatch(eventActions.setEvent(null))
    history.push(`events/${event.Id}`)
  }

  const goToJoinScreen = (e, event) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(eventActions.setJoinedEventDetails(null))
    dispatch(eventActions.setEvent(event))
    history.push(`/events/${event?.Id}/join-event`)
  }

  const goToControlScreen = (e, event) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(eventActions.setJoinedEventDetails(null))
    dispatch(eventActions.setEvent(event))
    history.push(`/events/${event?.Id}/control-screen`)
  }

  const myEvents = events.filter(
    (item) =>
      item.PresenterEmail === user.email &&
      (item.AcceptanceStatus === 'Pending' ||
        item.AcceptanceStatus === 'Accepted'),
  )

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
      <div className="t-flex t-flex-col t-justify-between t-h-full">
        <Row gutter={[24, 24]} className="t-h-full">
          {searchEventCampaignRows(myEvents, searchText).length === 0 && (
            <div className="t-h-96 t-flex t-justify-center t-items-center t-w-full">
              <div className="t-flex t-flex-col t-items-center t-space-y-2">
                <CarryOutFilled
                  style={{ fontSize: '4rem' }}
                  className="t-text-secondary-100"
                />
                <p>No records found</p>
              </div>
            </div>
          )}
          {searchEventCampaignRows(myEvents, searchText).map((item, index) => (
            <Col span={24} md={8} xl={8} key={index}>
              <Card
                bordered
                hoverable
                cover={
                  <img
                    alt="example"
                    className="t-w-full t-h-48 t-object-cover t-rounded-md"
                    src={
                      item?.Flyer?.length
                        ? awsconfig.bucketUrl + item?.Flyer[0]?.fileName
                        : item?.Flyer?.fileName
                        ? awsconfig.bucketUrl + item?.Flyer?.fileName
                        : item?.OrganizationBackgroundImage
                        ? awsconfig.bucketUrl +
                          item?.OrganizationBackgroundImage?.fileName
                        : sample
                    }
                  />
                }
                onClick={() => handleEventSelected(item)}
              >
                <h5 className="t-text-2xl t-text-secondary-100 t-font-bold t-truncate">
                  <Tooltip title={item?.Title}>{item?.Title}</Tooltip>
                </h5>
                <div className="t-flex t-justify-between">
                  <div className="card-tag t-font-thin t-text-secondary-100">
                    {item?.OrganizationName}
                  </div>
                </div>
                <p className="t-line-clamp-1 t-text-sm">{item?.Details}</p>
                <div className="t-flex t-justify-end t-mt-2 t-space-x-1 t-h-8">
                  {(item?.OrganizationId === organization?.id ||
                    (item?.PresenterEmail === user?.email &&
                      item?.AcceptanceStatus === 'Accepted')) && (
                    <Button
                      type="primary"
                      size="small"
                      className="t-rounded-full"
                      onClick={(e) => goToControlScreen(e, item)}
                    >
                      Control
                    </Button>
                  )}
                  {item.startEvent && (
                    <Button
                      type="primary"
                      size="small"
                      className="t-rounded-full"
                      onClick={(e) => goToJoinScreen(e, item)}
                    >
                      Join
                    </Button>
                  )}
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </Skeleton>
  )
}

export { PresenterEvents }
