import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Row, Skeleton, Divider, Tooltip } from 'antd'
import { ShoppingCartOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import {
  compareDateTimeWithNow,
  fetchMoreData,
  formateDateTime,
  searchVolunteerRows,
} from 'utils'
import { awsconfig, config } from 'configs'
import { useHistory } from 'react-router-dom'
import { geNearByAuction } from 'store/auctionSlice/auctionActions'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useAuthSelector } from 'store/authSlice/authReducer'
import {
  auctionActions,
  useAuctionSelector,
} from 'store/auctionSlice/auctionReducer'
import sample from 'assets/images/sample/sample1.png'
import { BsFillCalendarCheckFill } from 'react-icons/bs'
import { RiRadioButtonLine } from 'react-icons/ri'
import { AllAuctionList } from './AllAuctionList'

const AuctionList = ({ searchText, sortKey }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { organization, location } = useAuthSelector()
  const positionData = {
    lat: location?.lat,
    lon: location?.lng,
    zipCode: location?.zipcode,
  }
  const { loading, auctions } = useAuctionSelector()
  const [current, setCurrent] = useState([])

  const searchedData = searchVolunteerRows(auctions, searchText)

  useEffect(() => {
    setCurrent(searchedData.slice(0, config.itemFirstLoad))
    // eslint-disable-next-line
  }, [auctions, searchText])

  useEffect(() => {
    const zipCode = location?.zipcode
    if (zipCode) {
      dispatch(geNearByAuction(positionData, sortKey))
    }
    // eslint-disable-next-line
  }, [dispatch, location, sortKey])

  const handleAuctionSelected = (e, auction) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(auctionActions.setAuction(null))
    history.push(`auction/${auction.Id}`)
  }

  const goToJoinScreen = (e, auction) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(auctionActions.setAuction(auction))
    history.push(`/auction/${auction?.Id}/join-auction`)
  }

  const goToControlScreen = (e, auction) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(auctionActions.setAuction(auction))
    history.push(`/auction/${auction?.Id}/control-screen`)
  }

  const CutomDateFormater = (item) => {
    if (item.StartDate && compareDateTimeWithNow(item.StartDate, 'after')) {
      return (
        <div className="t-flex t-items-center t-space-x-2">
          <Tooltip title="Start At">
            <RiRadioButtonLine fontSize="1.1rem" className="t-text-green-700" />
          </Tooltip>
          <div className="card-tag t-font-thin t-text-secondary-100">
            Active
          </div>
        </div>
      )
    }
    return (
      <>
        <div className="t-flex t-items-center t-space-x-2">
          <Tooltip title="Start At">
            <BsFillCalendarCheckFill className="t-text-secondary-100" />
          </Tooltip>
          <div className="card-tag t-font-thin t-text-secondary-100">
            <Tooltip title="MM-DD-YYYY hh:mm A">
              {item?.StartDate && formateDateTime(item?.StartDate)}
            </Tooltip>
          </div>
        </div>
      </>
    )
  }

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
      <div className="t-flex t-flex-col t-justify-between t-h-full">
        <InfiniteScroll
          dataLength={current.length}
          next={() => fetchMoreData(current, setCurrent, searchedData)}
          hasMore={current.length < searchedData.length}
          loader={
            <div className="t-flex t-justify-center t-items-center">
              {/* <Spin /> */}
            </div>
          }
        >
          <Row gutter={[24, 24]} id="volunteerlist">
            {current.length === 0 && (
              <>
                <div className="t-flex t-justify-center t-items-center t-w-full">
                  <div className="t-flex t-flex-col t-items-center t-space-y-2">
                    <ShoppingCartOutlined
                      style={{ fontSize: '2rem' }}
                      className="t-text-secondary-100"
                    />
                    <p>No nearby auctions found</p>
                  </div>
                </div>
                <Divider />
                <p className="t-ml-4 t-text-lg t-font-semibold">All Auctions</p>
                <AllAuctionList searchText={searchText} sortKey={sortKey} />
              </>
            )}
            {current.map((item, index) => (
              <Col span={24} lg={8} className="mb-24" key={index}>
                <Card
                  bordered
                  hoverable
                  cover={
                    <img
                      alt="example"
                      className="t-w-full t-h-48 t-object-contain t-rounded-md"
                      src={
                        item?.Flyer?.fileName
                          ? awsconfig.bucketUrl + item?.Flyer?.fileName
                          : item?.OrganizationBackgroundImage
                          ? awsconfig.bucketUrl +
                            item?.OrganizationBackgroundImage?.fileName
                          : sample
                      }
                    />
                  }
                  onClick={(e) => handleAuctionSelected(e, item)}
                >
                  {/* <div className="t-relative t-flex t-justify-end">
                    {item?.EventType === 'Campaign' &&
                      item?.CampaignSubType === 'Auction' && (
                        <Tag
                          color="purple"
                          className="t-absolute t-right-0 -t-top-[0.8rem]"
                        >
                          Auction
                        </Tag>
                      )}
                  </div> */}
                  <h5 className="t-text-2xl t-text-secondary-100 t-font-bold t-truncate">
                    <Tooltip title={item?.Title}>{item?.Title}</Tooltip>
                  </h5>
                  <div className="t-flex t-justify-between">
                    <div className="card-tag t-font-thin t-text-secondary-100">
                      {item?.OrganizationName}
                    </div>
                  </div>
                  <p className="t-line-clamp-1 t-text-sm">{item?.Details}</p>
                  <div>{CutomDateFormater(item)}</div>
                  <div className="t-flex t-justify-end t-mt-2 t-space-x-1 t-h-8">
                    {item?.OrganizationId === organization?.id && (
                      <Button
                        type="primary"
                        size="small"
                        className="t-rounded-full"
                        onClick={(e) => goToControlScreen(e, item)}
                      >
                        Control
                      </Button>
                    )}
                    {item.StartDate &&
                      compareDateTimeWithNow(item.StartDate, 'after') && (
                        <Button
                          type="primary"
                          size="small"
                          className="t-rounded-full"
                          onClick={(e) => goToJoinScreen(e, item)}
                        >
                          Join
                        </Button>
                      )}
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </InfiniteScroll>
      </div>
    </Skeleton>
  )
}

export { AuctionList }
