/* eslint-disable */
import React from 'react'
import { withRouter } from 'react-router'
import { config, menuItems } from 'configs'
import { useSelector } from 'react-redux'
import NavLogo from './navLogo'
import NavContent from './navContent'
import { BankFilled } from '@ant-design/icons'

const filterRoutesByRole = (mymenu, roles) => {
  let newRoutes = mymenu.map((menu) => ({
    ...menu,
    children: menu.children.filter((child) => {
      if (child.type === 'collapse') {
        return true
      }
      return child.roles.some((role) => roles.includes(role))
    }),
  }))

  const isSubscriber = roles.includes(config.roles.subscriber)
  if (isSubscriber) {
    const settingsIndex = newRoutes.findIndex(
      (route) => route.title === 'Settings',
    )
    newRoutes.splice(settingsIndex, 0, {
      id: 'sidebar-title-organization-pages1',
      title: 'organization',
      className: 'd-none',
      type: 'group',
      children: [
        {
          id: 'sidebar-title-organization-pages',
          title: 'organization',
          className: 'd-none',
          type: 'group',
          role: [config.roles.subscriber],
          children: [
            {
              id: 'das1',
              title: <span className="label">My Organization</span>,
              type: 'collapse',
              icon: (isSelected, color) => (
                <BankFilled
                  className="icon"
                  style={{
                    background: isSelected ? color : '',
                  }}
                />
              ),
              roles: [config.roles.subscriber],
              children: [
                {
                  id: 'sidebar-subtitle-organization-home',
                  title: 'Home',
                  type: 'item',
                  url: '/home',
                  roles: [config.roles.subscriber, config.roles.admin],
                },
                {
                  id: 'sidebar-subtitle-organization-detail',
                  title: 'About',
                  type: 'item',
                  url: '/settings/manage-organization/organization-detail',
                  roles: [
                    config.roles.admin,
                    config.roles.donor,
                    config.roles.presenter,
                    config.roles.subscriber,
                    config.roles.business,
                  ],
                },
                {
                  id: 'sidebar-subtitle-manage-payments-accounts',
                  title: 'Setup Payments',
                  type: 'item',
                  url: '/settings/manage-payments-accounts',
                  roles: [config.roles.subscriber],
                },
                {
                  id: 'sidebar-subtitle-manage-association-requests',
                  title: 'Listing Requests',
                  type: 'item',
                  url: '/settings/manage-organization/listing-requests',
                  roles: [config.roles.subscriber],
                },
                {
                  id: 'sidebar-subtitle-manage-businesses-configuration',
                  title: 'B2B Program',
                  type: 'item',
                  url: '/settings/manage-organization/my-businesses-configuration',
                  roles: [config.roles.subscriber],
                },
                {
                  id: 'sidebar-subtitle-manage-my-community',
                  title: 'My Community',
                  type: 'item',
                  url: '/settings/manage-organization/my-community',
                  roles: [
                    config.roles.admin,
                    config.roles.donor,
                    config.roles.presenter,
                    config.roles.subscriber,
                    config.roles.business,
                  ],
                },
                // {
                //   id: 'sidebar-subtitle-manage-my-reports',
                //   title: 'My Reports',
                //   type: 'item',
                //   url: '/settings/manage-organization/my-reports',
                // },
                {
                  id: 'sidebar-subtitle-manage-my-events',
                  title: 'Event Presenters',
                  type: 'item',
                  url: '/settings/manage-organization/my-events',
                  roles: [
                    config.roles.admin,
                    config.roles.donor,
                    config.roles.presenter,
                    config.roles.subscriber,
                    config.roles.business,
                  ],
                },
                {
                  id: 'sidebar-subtitle-manage-my-rooms',
                  title: 'My Rooms',
                  type: 'item',
                  url: '/settings/manage-organization/my-rooms',
                  roles: [
                    config.roles.admin,
                    config.roles.donor,
                    config.roles.presenter,
                    config.roles.subscriber,
                    config.roles.business,
                  ],
                },
                {
                  id: 'sidebar-subtitle-manage-my-reservations',
                  title: 'My Reservations',
                  type: 'item',
                  url: '/settings/manage-organization/my-reservations',
                  roles: [
                    config.roles.admin,
                    config.roles.donor,
                    config.roles.presenter,
                    config.roles.subscriber,
                    config.roles.business,
                  ],
                },
                {
                  id: 'sidebar-subtitle-manage-my-subscription',
                  title: 'My Subscription',
                  type: 'item',
                  url: '/settings/manage-subscription',
                  roles: [
                    config.roles.admin,
                    config.roles.donor,
                    config.roles.presenter,
                    config.roles.subscriber,
                    config.roles.business,
                  ],
                },
              ],
            },
          ],
        },
      ],
    })
  }

  newRoutes = newRoutes.map((menu) => ({
    ...menu,
    children: menu.children.map((child) => {
      if (child.type === 'collapse') {
        return {
          ...child,
          children: child.children.filter((inside) =>
            inside.roles.some((role) => roles.includes(role)),
          ),
        }
      }
      return child
    }),
  }))

  return newRoutes
}

const Navigation = withRouter((props) => {
  const user = useSelector((state) => state?.auth?.user)
  const roles = user?.roles || []
  return (
    <>
      <NavLogo
        collapsed={props.collapsed}
        collapseMenu={props.collapseMenu}
        windowWidth={props.windowWidth}
        onToggleNavigation={props.onToggleNavigation}
      />
      <hr />
      <NavContent
        collapsed={props.collapsed}
        navigation={filterRoutesByRole(menuItems.items, roles)}
        handleSidebarColor={props.handleSidebarColor}
        currentSelected={props.currentSelected}
        onClose={props.onClose}
      />
    </>
  )
})

export { Navigation }
