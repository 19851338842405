import {
  Button,
  Col,
  Form,
  Input,
  // InputNumber,
  Row,
  Select,
  Upload,
  message,
} from 'antd'
import React, { useState } from 'react'
import { MdDelete, MdOutlineFileUpload } from 'react-icons/md'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { registerOrganization } from 'store/organizationSlice/organizationActions'
import { fetchUserDetail, validateOrgUrl } from 'store/authSlice/authActions'
import { useDispatch } from 'react-redux'
import { organizationRegistrationValidation } from 'validations/organizationRegistration'
import { MaskPattern, TaxIdFormating } from 'utils'
import { MaskedInput } from 'antd-mask-input'
import { CustomizeImageUploader } from './CustomizeImageUploader'
import { AutoCompleteAddress } from './AutoCompleteAddress'

const { TextArea } = Input

const initialValues = {
  Name: '',
  OrganizationType: '',
  NonProfit: false,
  Designation: '',
  TaxId: '',
  PhoneNumber: '',
  OrgDomain: '',
  Website: '',
  Description: '',
  IdentityType: '',
  IdentityFile: [],
  W9Form: null,
  ShowRentalsPublicly: false,
  FundedOutside: false,
  BackgroundImage: null,
  Logo: null,
  DefaultDonationValue1: '25',
  DefaultDonationValue2: '50',
  DefaultDonationValue3: '100',
  DefaultDonationValue4: '225',
  KioskDonationTitle: '',
  KioskAmount1: '25',
  KioskAmount2: '50',
  KioskAmount3: '100',
  ShowMailingAddress: false,
  PaymentCategoryAdded: false,
  PaymentCategories: [],
  internationallyFunded: false,
}

const organizationTypes = [
  'Art Gallery',
  'Auction House',
  'Educational Institution',
  'Employee Benefit Association or Funds',
  'Fraternal Societies',
  'Government Entity',
  'Healthcare Institutions',
  'Labor Organization',
  'Law Enforcement',
  'Political Organization',
  'Religious Organization',
  'Social Clubs',
  'Social Welfare',
  'Sporting Venue',
  'Trade Association',
  'Veterans Organization',
  'Other',
]

const OrganizationRegistrationForm = ({ einData, setPage }) => {
  const { user } = useAuthSelector()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [buttonLoading, setButtonLoading] = useState(false)
  const [fileList, setFileList] = useState([]) // State for file list
  const { address, city, state, zipcode } = einData?.organization

  const validateUrl = async (url) => {
    setButtonLoading(true)
    if (url) {
      try {
        const valid = await validateOrgUrl(url, user?.id)
        if (valid) {
          message.destroy()
          message.success('Validated Organization Domain successfully')
        } else {
          message.destroy()
          message.error('Organization Domain is already taken')
        }
        setButtonLoading(false)
        return valid
      } catch (error) {
        message.destroy()
        message.error(error?.response?.data?.message || 'Validation error')
        setButtonLoading(false)
        return false
      }
    }
    return false
  }
  const onFinish = async (values) => {
    const isUrlValid = await validateUrl(values.orgDomain)
    if (!isUrlValid) {
      return
    }
    try {
      const data = {
        Name: einData?.organization?.name,
        OrganizationType: values?.organizationType,
        NonProfit: true,
        TaxId: einData?.organization?.ein,
        Address1: values.streetAddress,
        Address2: `${address} ${city} ${state} ${zipcode}`,
        ZipCode: einData?.organization?.zipcode,
        City: einData?.organization?.city,
        State: einData?.organization?.state,
        Country: values?.country,
        PhoneNumber: values?.phoneNumber,
        OrgDomain: values?.orgDomain?.toLowerCase(),
        Website: values?.website,
        Description: values?.description,
        IdentityType: values?.identityType,
        IdentityFile: values?.photoID,
        W9Form: values?.W9Form,
        ShowRentalsPublicly: false,
        FundedOutside: values?.internationallyFunded === 'Yes',
        BackgroundImage: values.backgroundImage,
        Logo: values?.logo,
        DefaultDonationValues: ['25', '50', '100', '225'],
        KioskDonationTitle: values?.kioskDonationTitle,
        KioskAmounts: ['25', '50', '100', '225'],
        ShowMailingAddress: false,
        PaymentCategoryAdded: false,
        PaymentCategories: [],
        Subscriber: user?.id,
        Email: user?.email,
        CreatedBy: user?.email,
      }
      if (values.W9Form) {
        data.W9Form = values.W9Form
      }

      setButtonLoading(true)
      await registerOrganization(data)
      message.success('Organization Registration Request Sent')
      setButtonLoading(false)
      dispatch(fetchUserDetail())
      setPage(4)
    } catch (error) {
      message.error(error.response?.data?.message || 'Something Went Wrong')
      setButtonLoading(false)
    }
  }

  const handleBack = () => {
    setPage(2)
  }

  // useEffect(() => {
  //   form.setFieldsValue({
  //     streetAddress: `${address} ${city} ${state} ${zipcode}`,
  //   })
  // }, [einData, form, address, city, state, zipcode])

  const handleInputChange = (value) => {
    form.setFieldsValue({ PhoneNumber: value })
  }
  const handleDownload = () => {
    const pdfUrl = '/documents/fw9.pdf'
    const link = document.createElement('a')
    link.href = pdfUrl
    link.download = 'W9-template.pdf'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const handleFileChange = ({ fileList: newFileList }) =>
    setFileList(newFileList) // Renamed parameter

  const handleDelete = () => {
    setFileList([])
    form.setFieldValue({ W9Form: null })
  }

  return (
    <div className="t-mx-2 lg:t-mr-7 lg:t-ml-3 t-w-[75%]">
      <Form
        form={form}
        name="basic"
        layout="vertical"
        labelCol={{
          span: 10,
        }}
        wrapperCol={{
          span: 24,
        }}
        className="t-w-[100%] t-m-auto ant-roboto-font"
        initialValues={initialValues}
        onFinish={onFinish}
        autoComplete="off"
      >
        <div className="t-flex t-flex-row t-justify-between t-border-b t-border-b-gray-500 t-mt-8 t-mb-8">
          <h1 className="t-text-base t-font-semibold t-text-black roboto-font">
            {einData?.organization?.name}
          </h1>
          <h1 className="t-text-sm t-text-black t-ml-28 sm:t-ml-32 roboto-font">
            <strong>EIN:</strong> {TaxIdFormating(einData?.organization?.ein)}
          </h1>
        </div>

        <Form.Item
          label="Organization Type*"
          name="organizationType"
          rules={[organizationRegistrationValidation]}
        >
          <Select placeholder="Select organization type">
            {organizationTypes?.map((item) => (
              <Select.Option value={item} key={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <AutoCompleteAddress
          form={form}
          validation
          AddressData={{
            streetAddress: `${einData?.organization.address} ${einData?.organization.city} ${einData?.organization.state} ${einData?.organization.zipcode}`,
          }}
        />
        {/* <Form.Item
          label="Street Address*"
          name="streetAddress"
          defaultValue={`${address} ${city} ${state} ${zipcode}`}
          rules={[organizationRegistrationValidation]}
        >
          <Input placeholder="Enter you street address" />
        </Form.Item> */}
        <Form.Item
          label="Phone Number*"
          name="phoneNumber"
          rules={[organizationRegistrationValidation]}
        >
          <MaskedInput
            inputMode="numeric"
            mask={MaskPattern}
            onChange={(e) => {
              handleInputChange(e.target.value)
            }}
          />
        </Form.Item>
        <Form.Item
          label="Custom URL Prefix*"
          name="orgDomain"
          rules={[organizationRegistrationValidation]}
        >
          <Input placeholder="extensia" />
        </Form.Item>
        <Form.Item
          label="Website"
          name="website"
          rules={[organizationRegistrationValidation]}
        >
          <Input placeholder="https://extensia.cloud" />
        </Form.Item>
        <Form.Item
          label="Internationally Funded*"
          name="internationallyFunded"
          rules={[organizationRegistrationValidation]}
        >
          <Select placeholder="Select Type" defaultValue="">
            <Select.Option value="" disabled>
              Select Type
            </Select.Option>
            <Select.Option value="Yes">Yes</Select.Option>
            <Select.Option value="No">No</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Description*"
          name="description"
          rules={[organizationRegistrationValidation]}
        >
          <TextArea rows={2} placeholder="Enter the description" />
        </Form.Item>
        <h1 className="t-text-black t-text-base t-font-medium t-mt-8 t-border-b t-border-b-gray-500 roboto-font">
          Update Files:
        </h1>
        <h6 className="t-text-sm t-font-normal">Max. File Size: 512KB</h6>
        <Form.Item
          label="Logo*"
          name="logo"
          className="t-mt-4"
          rules={[organizationRegistrationValidation]}
        >
          <CustomizeImageUploader
            defaultFile={
              form.getFieldValue('Logo') && form.getFieldValue('Logo')
            }
            onFileChange={(file) =>
              form.setFieldsValue({
                logo: file?.originFileObj,
              })
            }
          />
        </Form.Item>
        <Form.Item
          name="backgroundImage"
          rules={[organizationRegistrationValidation]}
        >
          <div className="t-flex t-flex-col">
            <div className="t-flex t-flex-row t-justify-between t-mb-2">
              <h1 className="t-text-[14px] t-font-semibold roboto-font">
                Background Image*
              </h1>
              <h1 className="t-text-[12px]">Size ratio 16:9</h1>
            </div>
            <CustomizeImageUploader
              defaultFile={
                form.getFieldValue('backgroundImage') &&
                form.getFieldValue('backgroundImage')
              }
              onFileChange={(file) =>
                form.setFieldsValue({
                  backgroundImage: file?.originFileObj,
                })
              }
            />
          </div>
        </Form.Item>
        <Form.Item name="W9Form" rules={[organizationRegistrationValidation]}>
          <div className="t-flex t-flex-col">
            <div className="t-flex t-flex-row t-justify-between t-mb-2">
              <h1 className="t-text-[14px] t-font-semibold roboto-font">
                From W4*
              </h1>
              <h1
                className="t-text-[12px] t-cursor-pointer hover:t-underline"
                onClick={handleDownload}
              >
                Download template
              </h1>
            </div>
            <Upload
              maxCount={1}
              customRequest={(e) => form.setFieldsValue({ W9Form: e.file })}
              showUploadList={false}
              onChange={handleFileChange} // Track file changes
            >
              {fileList?.length === 0 ? (
                <Button className="t-flex t-justify-center t-items-center t-shadow-none t-w-[100%] ">
                  <MdOutlineFileUpload className="t-text-2xl t-mr-2" />
                  Upload
                </Button>
              ) : (
                <Button className="t-flex t-justify-center t-items-center t-shadow-none t-w-[100%]">
                  <MdDelete
                    onClick={handleDelete}
                    className="t-text-2xl t-mr-2"
                  />
                  {fileList[0]?.name}
                </Button>
              )}
            </Upload>
          </div>
        </Form.Item>
        <Form.Item name="photoID" rules={[organizationRegistrationValidation]}>
          <div className="t-flex t-flex-col">
            <div className="t-flex t-flex-row t-justify-between t-mb-2">
              <h1 className="t-text-[14px] t-font-semibold roboto-font">
                Photo ID*
              </h1>
              <h1 className="t-text-[12px]">Passport / Drivers License</h1>
            </div>
            <CustomizeImageUploader
              defaultFile={
                form.getFieldValue('photoID') && form.getFieldValue('photoID')
              }
              onFileChange={(file) =>
                form.setFieldsValue({
                  photoID: file?.originFileObj,
                })
              }
            />
          </div>
        </Form.Item>
        <Row className="t-flex t-justify-center t-item-center t-mt-8">
          <Col sm={6} md={8} lg={10} className="t-mx-3">
            <Form.Item>
              <Button
                htmlType="button" // Changed to button to prevent form submission
                className="t-w-[100%] t-text-base t-px-5 t-py-2 t-h-[50px]"
                onClick={handleBack}
              >
                Cancel
              </Button>
            </Form.Item>
          </Col>
          <Col sm={6} md={8} lg={10} className="t-mx-3">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="t-w-[100%] t-bg-[#2e97de] t-px-7 t-text-base t-py-2 t-h-[50px]"
                loading={buttonLoading}
              >
                Next
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export { OrganizationRegistrationForm }
