import { REGEXS } from 'utils'
import * as Yup from 'yup'

const zipCodeRegex = '^[0-9]{5}(?:-[0-9]{4})?$'
const websiteRegex = new RegExp(
  '^(https?:\\/\\/)?' +
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
    '((\\d{1,3}\\.){3}\\d{1,3}))' +
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
    '(\\?[;&a-z\\d%_.~+=-]*)?' +
    '(\\#[-a-z\\d_]*)?$',
  'i',
)
const phoneNumberValidation = '^[0-9-() ]{14,15}$'

const numberValidation = new RegExp('[0-9]|,')
const amountValidation = REGEXS.amountRegex

const schema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  description: Yup.string()
    .required('Description is required')
    .min(10, 'Description should be atleast 10 character'),
  targetAmount: Yup.string()
    .required('Target amount is required')
    .matches(numberValidation, 'Invalid amount'),
  startDate: Yup.date()
    .typeError('Invalid Date')
    .required('Start Date is required'),
  endDate: Yup.date().typeError('Invalid Date').nullable(),
  fundraiserType: Yup.string()
    .required('Type is required')
    .default(() => 'Fundraiser'),
  campaignSubType: Yup.string()
    .required('Type is required')
    .default(() => 'Auction'),
  zipCode: Yup.string()
    .required('Zip code is required')
    .matches(zipCodeRegex, 'Invalid Zip code e.g 12345'),
  flyer: Yup.mixed().typeError('Flyer is not a file'),
  enablePaymentCategories: Yup.boolean().required('This is required'),
  paymentCategories: Yup.array().min(
    1,
    'At least one payment category is required.',
  ),
  enableRecurringPayment: Yup.boolean().required('This is required'),
  enableSplitPayment: Yup.boolean().required('This is required'),
  recurringFrequency: Yup.mixed().nullable(),
  splitFrequency: Yup.mixed().nullable(),
  enableCustomAmount: Yup.boolean().required('This is required'),
  enableDonationNotes: Yup.boolean().required('This is required'),
  coverProcessingFee: Yup.boolean().required('This is required'),
  lockFeePayment: Yup.boolean().required('This is required'),
  enablePledgeReminder: Yup.boolean().required('This is required'),
  pledgeInterval: Yup.mixed().nullable(),
  pledgeMaxMessagesCap: Yup.number().nullable(),
  enableAuction: Yup.boolean().required('This is required'),
  auctionItemsList: Yup.mixed().nullable(),
  requireConstituentLogin: Yup.boolean().required('This is required'),
  requireConstituentFirstAndLastName:
    Yup.boolean().required('This is required'),
  requireConstituentMailingAddress: Yup.boolean().required('This is required'),
  requireConstituentEmail: Yup.boolean().required('This is required'),
  allowConstituentAnonymity: Yup.boolean().required('This is required'),
  enableSeedDonor: Yup.boolean().required('This is required'),
  seedDonors: Yup.mixed()
    .nullable()
    .default(() => []),
  requireSeedDonorLogin: Yup.boolean().required('This is required'),
  requireSeedDonorFirstAndLastName: Yup.boolean().required('This is required'),
  requireSeedDonorMailingAddress: Yup.boolean().required('This is required'),
  requireSeedDonorEmail: Yup.boolean().required('This is required'),
  enableVolunteer: Yup.boolean().required('This is required'),
  volunteers: Yup.mixed().nullable(),
  volunteerSlot: Yup.number().nullable(),
  trackVolunteerTime: Yup.boolean().required('This is required'),
  requireVolunteerLogin: Yup.boolean().required('This is required'),
  requireVolunteerFirstAndLastName: Yup.boolean().required('This is required'),
  requireVolunteerMailingAddress: Yup.boolean().required('This is required'),
  requireVolunteerEmail: Yup.boolean().required('This is required'),
  volunteerAllocationHours: Yup.number().nullable(),
  enableSellTickets: Yup.boolean().required('This is required'),
  siteOccupancyForTicket: Yup.number().min(
    1,
    'Site occupancy cannot be less than 1.',
  ),
  ticketPurchaseCap: Yup.number().min(1, 'Purchase cap cannot be less than 1.'),
  printableTickets: Yup.boolean().required('This is required'),
  showOnLandingPage: Yup.boolean().required('This is required'),
  showOnKiosk: Yup.boolean().required('This is required'),
  featureOnKiosk: Yup.boolean().required('This is required'),
  showDonatedAmount: Yup.boolean().required('This is required'),
  showProgressBar: Yup.boolean().required('This is required'),
  enableEmcee: Yup.boolean().required('This is required'),
  presenter: Yup.string().required('Presenter is required'),
  presenterName: Yup.string().required('Presenter name is required'),
  presenterPhoneCode: Yup.string().required('Presenter phone code is required'),
  allowedPaymentMethods: Yup.array().min(
    1,
    'At least one payment method is required.',
  ),
  enableSpeaker: Yup.boolean().required('This is required'),
  speakerName: Yup.string().nullable(),
  speakerBio: Yup.string().nullable(),
  isPrivate: Yup.boolean().required('This is required'),
  presenterMobileNumber: Yup.string()
    .required('Presenter phone number is required')
    .matches(phoneNumberValidation, 'Invalid Phone number e.g 1234567891'),
  presenterEmail: Yup.string()
    .required('Presenter email is required')
    .email('Email is invalid'),
  DefaultDonationValue1: Yup.string()
    .required('Amount is Required')
    .matches(amountValidation, 'Invalid amount'),
  DefaultDonationValue2: Yup.string()
    .required('Amount is Required')
    .matches(amountValidation, 'Invalid amount'),
  DefaultDonationValue3: Yup.string()
    .required('Amount is Required')
    .matches(amountValidation, 'Invalid amount'),
  DefaultDonationValue4: Yup.string()
    .required('Amount is Required')
    .matches(amountValidation, 'Invalid amount'),
  youtubeLink: Yup.string()
    .nullable()
    .test(
      'youtube-link',
      'Invalid youtube url e.g https://www.youtube.com/watch?v=Ke90Tje7VS0',
      (value) => value === '' || value === null || websiteRegex.test(value),
    ),
})

const fundraiserValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { fundraiserValidation }
