import { Button, Col, Form, Input, Row, Select, Skeleton, message } from 'antd'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { makeRequest } from 'api'
import { organizationDeviceValidation } from 'validations/updateDevice'

const Devices = () => {
  const [activeKeys, setActiveKeys] = useState([])
  const [loading, setLoading] = useState(true)
  const [btnLoading, setBtnLoading] = useState(false)
  const { organization } = useAuthSelector()
  const [form] = Form.useForm()

  const history = useHistory()

  const onFinish = async (values) => {
    setBtnLoading(true)
    const data = {
      KioskDisplay: values.KioskDisplay === 'Yes',
      KioskDonationTitle: values.KioskDonationTitle,
      KioskRequireEmailAddress: values.KioskRequireEmailAddress === 'Yes',
      KioskRequireStreetAddress: values.KioskRequireStreetAddress === 'Yes',
      KioskRequireName: values.KioskRequireName === 'Yes',
      KioskIncludeProcessFeesByDefault:
        values.KioskIncludeProcessFeesByDefault === 'Yes',
      KioskShowQuickPathFundraiser:
        values.KioskShowQuickPathFundraiser === 'Yes',
      KioskShowEvent: values.KioskShowEvent === 'Yes',
      RequireEmailAddress: values.RequireEmailAddress === 'Yes',
      RequireStreetAddress: values.RequireStreetAddress === 'Yes',
      DefaultDonationValues: [
        values.donationValue0?.replace('$', ''),
        values.donationValue1?.replace('$', ''),
        values.donationValue2?.replace('$', ''),
        values.donationValue3?.replace('$', ''),
      ],
      PaymentCategories: activeKeys,
      PaymentCategoryAdded: true,
    }
    try {
      const response = await makeRequest(
        'patch',
        '/webapp/organization/devices',
        { organizationId: organization.id },
        data,
      )
      message.success('Device updated successfully!', response)
      history.push('/settings/manage-organization/organization-detail')
      setBtnLoading(false)
    } catch (error) {
      message.error('Failed to update Device.')
    }
  }

  const onFinishFailed = (errorInfo) => {
    message.error('Failed', errorInfo)
  }

  const handleBack = () => {
    history.push('/organizations')
  }

  const handleActiveKey = (key) => {
    setActiveKeys((prevKey) => {
      if (prevKey.includes(key)) {
        return prevKey.filter((x) => x !== key)
      }
      return [...prevKey, key]
    })
  }

  const getDeviceData = () => {
    setLoading(true)
    makeRequest('get', '/webapp/organization?type=devices', {
      organizationId: organization?.id,
    })
      .then((res) => {
        setLoading(false)
        if (res && res.item) {
          const donationValues = {}
          res.item.KioskAmounts.forEach((value, index) => {
            donationValues[`donationValue${index}`] = `$${value}`
          })
          form.setFieldsValue({
            KioskDisplay: res.item.KioskDisplay ? 'Yes' : 'No',
            KioskDonationTitle: res.item.KioskDonationTitle,
            KioskShowEvent: res.item.KioskShowEvent ? 'Yes' : 'No',
            KioskShowQuickPathFundraiser: res.item.KioskShowQuickPathFundraiser
              ? 'Yes'
              : 'No',
            KioskIncludeProcessFeesByDefault: res.item
              .KioskIncludeProcessFeesByDefault
              ? 'Yes'
              : 'No',
            KioskRequireName: res.item.KioskRequireName ? 'Yes' : 'No',
            KioskRequireStreetAddress: res.item.KioskRequireStreetAddress
              ? 'Yes'
              : 'No',
            KioskRequireEmailAddress: res.item.KioskRequireEmailAddress
              ? 'Yes'
              : 'No',
            ...donationValues,
            PaymentCategories: res.item.PaymentCategories || [],
          })
          setActiveKeys(res.item.PaymentCategories || [])
        } else {
          message.error('No data found or invalid response format')
        }
      })
      .catch((error) => {
        setLoading(false)
        console.error('Error fetching device data:', error)
        message.error('Something went wrong!')
      })
  }

  useEffect(() => {
    if (organization?.id) {
      getDeviceData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization?.id])

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 16 }}>
      <div className="t-mx-2 lg:t-mr-7 lg:t-ml-3 t-mt-4">
        <Form
          name="basic"
          layout="vertical"
          labelCol={{
            span: 12,
          }}
          wrapperCol={{
            span: 24,
          }}
          className="t-w-[80%] sm:t-w-[100%] t-m-auto ant-roboto-font"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            label="Display"
            name="KioskDisplay"
            rules={[organizationDeviceValidation]}
          >
            <Select>
              <Select.Option value="Yes">Yes</Select.Option>
              <Select.Option value="No">No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Kiosk Title Text* "
            name="KioskDonationTitle"
            rules={[organizationDeviceValidation]}
          >
            <Input placeholder="Support Operations" />
          </Form.Item>
          <Form.Item
            label="Show Events"
            name="KioskShowEvent"
            rules={[organizationDeviceValidation]}
          >
            <Select>
              <Select.Option value="Yes">Yes</Select.Option>
              <Select.Option value="No">No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Show Quick Path Fundraiser"
            name="KioskShowQuickPathFundraiser"
            rules={[organizationDeviceValidation]}
          >
            <Select>
              <Select.Option value="Yes">Yes</Select.Option>
              <Select.Option value="No">No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Include Processing Fees By Default"
            name="KioskIncludeProcessFeesByDefault"
            rules={[organizationDeviceValidation]}
          >
            <Select>
              <Select.Option value="Yes">Yes</Select.Option>
              <Select.Option value="No">No</Select.Option>
            </Select>
          </Form.Item>
          <div className="t-flex t-flex-row t-justify-between t-border-b t-border-b-gray-500 t-mt-8 t-mb-8">
            <h1 className="t-text-base t-font-semibold t-text-black t-pb-1 roboto-font">
              Payment Page Settings:
            </h1>
          </div>
          <Form.Item
            label="Require Name"
            name="KioskRequireName"
            rules={[organizationDeviceValidation]}
          >
            <Select>
              <Select.Option value="Yes">Yes</Select.Option>
              <Select.Option value="No">No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Require Street Address"
            name="KioskRequireStreetAddress"
            rules={[organizationDeviceValidation]}
          >
            <Select>
              <Select.Option value="Yes">Yes</Select.Option>
              <Select.Option value="No">No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Require Email Address"
            name="KioskRequireEmailAddress"
            rules={[organizationDeviceValidation]}
          >
            <Select>
              <Select.Option value="Yes">Yes</Select.Option>
              <Select.Option value="No">No</Select.Option>
            </Select>
          </Form.Item>
          <div className="t-flex t-flex-row t-justify-between t-border-b t-border-b-gray-500 t-mt-8 t-mb-8">
            <h1 className="t-text-base t-font-semibold t-text-black t-pb-1 roboto-font">
              Org Donation Button Amount:
            </h1>
          </div>
          <Row className="t-flex t-justify-center t-item-center t-mt-4">
            <Col span={8} className="t-mx-6">
              <Form.Item
                name="donationValue0"
                rules={[organizationDeviceValidation]}
              >
                <Input className="t-text-lg t-text-center t-py-8" />
              </Form.Item>
            </Col>
            <Col span={8} className="t-mx-6">
              <Form.Item
                name="donationValue1"
                rules={[organizationDeviceValidation]}
              >
                <Input className="t-text-lg t-text-center t-py-8" />
              </Form.Item>
            </Col>
          </Row>
          <Row className="t-flex t-justify-center t-item-center t-mt-4">
            <Col span={8} className="t-mx-6">
              <Form.Item
                name="donationValue2"
                rules={[organizationDeviceValidation]}
              >
                <Input className="t-text-lg t-text-center t-py-8" />
              </Form.Item>
            </Col>
            <Col span={8} className="t-mx-6">
              <Form.Item
                name="donationValue3"
                rules={[organizationDeviceValidation]}
              >
                <Input className="t-text-lg t-text-center t-py-8" />
              </Form.Item>
            </Col>
          </Row>
          <div className="t-flex t-flex-row t-justify-between t-border-b t-border-b-gray-500 t-mt-8 t-mb-8">
            <h1 className="t-text-base t-font-semibold t-text-black t-pb-1 roboto-font">
              Active Payment Categories:
            </h1>
          </div>
          {[
            'Operations',
            'Sadaqah-Zakaat',
            'Construction',
            'School',
            'Fitrah',
            'School Projects',
          ].map((item) => (
            <Form.Item key={item} rules={[organizationDeviceValidation]}>
              <div
                onClick={() => handleActiveKey(item)}
                className={`t-w-[90%] t-m-auto t-rounded-md t-my-2 t-cursor-pointer ${
                  activeKeys.includes(item)
                    ? 't-bg-[#e6f4ff] t-border-2 t-border-[#2e97de]'
                    : 't-bg-[#f3f3f3]'
                }`}
              >
                <h1 className="t-text-lg t-font-medium t-text-center t-py-4 roboto-font">
                  {item}
                </h1>
              </div>
            </Form.Item>
          ))}
          <Row className="t-flex t-justify-center lg:t-justify-end t-item-center t-mt-8">
            <Col span={8} className="t-mx-3">
              <Form.Item>
                <Button
                  htmlType="button"
                  className="t-w-[85%] t-text-[14px]"
                  onClick={handleBack}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={btnLoading}
                  className="t-w-[85%] t-text-[14px] t-bg-[#2e97de]"
                >
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Skeleton>
  )
}

export { Devices }
