import React, { useEffect, useState } from 'react'
import {
  Row,
  Button,
  List,
  Dropdown,
  Input,
  message,
  Badge,
  Avatar,
  Tooltip,
  Tag,
} from 'antd'
import {
  AimOutlined,
  DollarCircleFilled,
  BankFilled,
  SendOutlined,
  CloseOutlined,
  ArrowRightOutlined,
  AuditOutlined,
} from '@ant-design/icons'

import { useDispatch } from 'react-redux'
import {
  fetchUserDetail,
  getAddressByZipcode,
  logout,
} from 'store/authSlice/authActions'
import { HiMenu } from 'react-icons/hi'
import { IoMdLogOut } from 'react-icons/io'
import { MdAccountCircle } from 'react-icons/md'
// import { BiRefresh } from 'react-icons/bi'
import { AiFillContacts } from 'react-icons/ai'
import { useHistory, useLocation } from 'react-router-dom'
import { authActions, useAuthSelector } from 'store/authSlice/authReducer'
import { config } from 'configs'
import { FormatedNotifications } from 'components/elements'
import { getUserNotifications } from 'store/notificationSlice/notificationActions'
import { useNotificationSelector } from 'store/notificationSlice/notificationReducer'
import { BiRefresh } from 'react-icons/bi'
import { organizationRemainingTrialDays } from 'utils'

const HeaderTop = (props) => {
  const { onPress, toggleCollasped } = props

  const dispatch = useDispatch()
  const history = useHistory()
  const routerLocation = useLocation()
  const { location, user, customLocation, organization } = useAuthSelector()
  const { notifications } = useNotificationSelector()
  const [zipcode, setZipcode] = useState()
  const [open, setOpen] = useState(false)
  const [showNotification, setShowNotification] = useState(false)

  const isSubscriber = user?.roles.includes(config?.roles?.subscriber)
  const isBusiness = user?.roles.includes(config?.roles?.business)

  const nameCapitals =
    user?.firstname.charAt(0).toUpperCase() +
    user?.lastname.charAt(0).toUpperCase()

  useEffect(() => {
    dispatch(fetchUserDetail())
    dispatch(getUserNotifications())
    // eslint-disable-next-line
  }, [routerLocation])

  useEffect(() => {
    if (location?.zipcode) {
      setZipcode(location.zipcode)
    }
  }, [location])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const move = () => {
    history.push('/')
  }

  const refreshUser = async () => {
    const showSuccessMessage = () => {
      message.success('User Information Updated Successfully')
    }
    dispatch(fetchUserDetail(showSuccessMessage))
  }

  const goToURL = (url) => {
    setOpen(false)
    history.push(url)
  }

  const daysRemaining = organizationRemainingTrialDays(
    organization?.TrialStartDate,
    organization?.TrialDuration,
  )

  const redirectOnSubcriptionPage = () => {
    history.push('/settings/manage-subscription')
  }

  const menuProfile = (
    <List
      min-width="100%"
      className="header-notifications-dropdown t-bg-white t-shadow-xl t-rounded-xl t-p-4"
      itemLayout="horizontal"
    >
      <List.Item
        className={`t-pt-2 t-px-4 t-m-0 t-pb-6 t-flex t-justify-between t-space-x-4 ${
          daysRemaining ? 't-h-[6.5rem]' : 't-h-[6rem]'
        }`}
      >
        <div className="t-flex t-flex-col t-cursor-pointer t-h-full">
          <p className="t-font-bold text-4xl t-text-secondary-100 t-uppercase">
            {`${user?.firstname} ${user?.lastname}`}
          </p>
          <p className="t-font-bold text-sm">{user?.phone}</p>
          <p className="t-font-bold text-sm">{user?.email}</p>
          {organization?.SubscriptionStatus === 'Active' && (
            <div>
              <Tag color="green" className="font-boldy">
                Active
              </Tag>
            </div>
          )}
          {organization?.SubscriptionStatus === 'Pending' && (
            <>
              {daysRemaining && (
                <div className="t-my-1">
                  {daysRemaining > 0 ? (
                    <>
                      <Tag color="grey" className="font-bold">
                        Trial ending in {daysRemaining} day
                        {daysRemaining !== 1 ? 's' : ''}
                      </Tag>
                      <Tag
                        color="#3BCAED"
                        className="font-bold t-cursor-pointer"
                        onClick={redirectOnSubcriptionPage}
                      >
                        Subscribe
                      </Tag>
                    </>
                  ) : (
                    <>
                      <Tag color="red" className="font-bold">
                        Trial has ended
                      </Tag>
                      <Tag
                        color="#3BCAED"
                        className="font-bold t-cursor-pointer"
                        onClick={redirectOnSubcriptionPage}
                      >
                        Subscribe
                      </Tag>
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </div>
        <div className="t-h-full t-flex t-justify-start">
          <Tooltip title="Refresh Account Information">
            <BiRefresh
              className="t-text-secondary-100 t-cursor-pointer"
              fontSize="1.5rem"
              onClick={refreshUser}
            />
          </Tooltip>
        </div>
      </List.Item>
      {/* <List.Item className="t-space-x-2 t-flex">
        {user?.roles.map((item, index) => (
          <div
            key={index}
            className="t-p-1 t-rounded-lg t-bg-slate-400 t-text-black t-font-bold t-shadow-lg t-text-xs"
          >
            {item}
          </div>
        ))}
      </List.Item> */}
      <List.Item
        className="t-py-0 t-px-4 t-m-1"
        id="header-dropdown-my-account"
      >
        <div
          className="t-flex t-items-center t-space-x-3 t-cursor-pointer"
          onClick={() => goToURL('/settings/account')}
        >
          <MdAccountCircle
            fontSize="1.5rem"
            className="t-text-secondary-100 t-w-4"
          />
          <p>My Account</p>
        </div>
      </List.Item>
      {isSubscriber && (
        <List.Item
          className="t-py-1 t-px-4 t-m-1"
          id="header-dropdown-setup-payments"
        >
          <div
            className="t-flex t-items-center t-space-x-3 t-cursor-pointer"
            onClick={() => goToURL('/settings/manage-payments-accounts')}
          >
            <DollarCircleFilled
              fontSize="1.5rem"
              className="t-text-secondary-100 t-w-4"
            />
            <p>Setup Payments</p>
          </div>
        </List.Item>
      )}
      {isSubscriber ? (
        <List.Item
          className="t-py-1 t-px-4 t-m-1"
          id="header-dropdown-my-organization"
        >
          <div
            className="t-flex t-items-center t-space-x-3 t-cursor-pointer"
            onClick={() =>
              goToURL('/settings/manage-organization/organization-detail')
            }
          >
            <BankFilled
              fontSize="1.5rem"
              className="t-text-secondary-100 t-w-4"
            />
            <p>My Organization</p>
          </div>
        </List.Item>
      ) : (
        <List.Item
          className="t-py-1 t-px-4 t-m-1"
          id="header-dropdown-my-organization"
        >
          <div
            className="t-flex t-items-center t-space-x-3 t-cursor-pointer"
            onClick={() => goToURL('/settings/register-organization')}
          >
            <BankFilled
              fontSize="1.5rem"
              className="t-text-secondary-100 t-w-4"
            />
            <p>Register Your Organization</p>
          </div>
        </List.Item>
      )}
      {isBusiness ? (
        <List.Item
          className="t-py-1 t-px-4 t-m-1"
          id="header-dropdown-my-organization"
        >
          <div
            className="t-flex t-items-center t-space-x-3 t-cursor-pointer"
            onClick={() =>
              goToURL('/settings/manage-organization/business-detail')
            }
          >
            <BankFilled
              fontSize="1.5rem"
              className="t-text-secondary-100 t-w-4"
            />
            <p>My Business</p>
          </div>
        </List.Item>
      ) : (
        <List.Item
          className="t-py-1 t-px-4 t-m-1"
          id="header-dropdown-my-organization"
        >
          <div
            className="t-flex t-items-center t-space-x-3 t-cursor-pointer"
            onClick={() => goToURL('/settings/register-business')}
          >
            <AuditOutlined
              fontSize="1.5rem"
              className="t-text-secondary-100 t-w-4"
            />
            <p>Register Your Business</p>
          </div>
        </List.Item>
      )}

      <List.Item
        className="t-py-1 t-px-4 t-m-1"
        id="header-dropdown-contact-us"
      >
        <div
          className="t-flex t-items-center t-space-x-3 t-cursor-pointer"
          onClick={() => goToURL('/settings/contact-us')}
        >
          <AiFillContacts
            fontSize="1.5rem"
            className="t-text-secondary-100 t-w-4"
          />
          <p>Contact Us</p>
        </div>
      </List.Item>
      <List.Item className="t-py-1 t-px-4 t-m-1" id="header-dropdown-logout">
        <div
          className="t-flex t-items-center t-space-x-3 t-cursor-pointer"
          onClick={() => dispatch(logout(move))}
        >
          <IoMdLogOut
            fontSize="1.5rem"
            className="t-text-secondary-100 t-w-4"
          />
          <p>Logout</p>
        </div>
      </List.Item>
    </List>
  )

  const handleLocationChange = () => {
    if (zipcode.length === 5) {
      dispatch(getAddressByZipcode(zipcode))
    } else {
      message.error('Please enter a valid zipcode')
    }
  }

  const bell = [
    <svg
      width="30"
      height="30"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M10 2C6.68632 2 4.00003 4.68629 4.00003 8V11.5858L3.29292 12.2929C3.00692 12.5789 2.92137 13.009 3.07615 13.3827C3.23093 13.7564 3.59557 14 4.00003 14H16C16.4045 14 16.7691 13.7564 16.9239 13.3827C17.0787 13.009 16.9931 12.5789 16.7071 12.2929L16 11.5858V8C16 4.68629 13.3137 2 10 2Z"
        fill={config.colors.primary}
      ></path>
      <path
        d="M10 18C8.34315 18 7 16.6569 7 15H13C13 16.6569 11.6569 18 10 18Z"
        fill={config.colors.primary}
      ></path>
    </svg>,
  ]

  const data = FormatedNotifications(notifications) || []
  const firstThree = data?.slice(0, 3)

  const menu = (
    <div className="ant-dropdown-menu ant-dropdown-menu-split header-notifications-dropdown t-mt-2">
      <List
        min-width="100%"
        itemLayout="horizontal"
        dataSource={firstThree}
        locale={{ emptyText: 'No new notifications' }}
        renderItem={(item) => (
          <List.Item
            className={`${
              item.read === false && `t-bg-slate-200 t-rounded-md`
            }`}
          >
            <List.Item.Meta
              avatar={<Avatar shape="square" src={item.avatar} />}
              title={item.title}
              description={item.description}
            />
          </List.Item>
        )}
      />
      {data && data.length > 0 && (
        <div className="t-flex t-justify-end">
          <Button
            type="link"
            className="t-cursor-pointer"
            onClick={() => {
              setShowNotification(false)
              history.push('/settings/notifications')
            }}
          >
            See More <ArrowRightOutlined />
          </Button>
        </div>
      )}
    </div>
  )

  const isUnRead = data.some((item) => item.read === false)
  return (
    <Row
      justify="space-between"
      className="t-flex t-items-center t-px-0 sm:t-px-1 md:t-px-3"
    >
      <Button type="link" className="desktop-toggler" onClick={toggleCollasped}>
        <HiMenu fontSize="1.7rem" className="t-text-secondary-100" />
      </Button>
      <Button type="link" className="mobile-toggler" onClick={onPress}>
        <HiMenu fontSize="1.7rem" className="t-text-secondary-100" />
      </Button>
      <Row>
        {/* <div className="t-hidden md:t-flex md:t-items-center t-cursor-pointer t-ml-2">
          <Button onClick={() => history.push('/settings/manage-subscription')}>
            Start Free Trial
          </Button>
        </div> */}
        <div className="header-control">
          <Input
            value={zipcode}
            className="header-search"
            placeholder="Fetching location ..."
            prefix={<AimOutlined />}
            suffix={
              customLocation === false ? (
                <SendOutlined
                  className="t-cursor-pointer"
                  onClick={() => handleLocationChange()}
                />
              ) : (
                <CloseOutlined
                  onClick={() => dispatch(authActions.setCustomLocation(false))}
                />
              )
            }
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleLocationChange()
              }
            }}
            onChange={(e) => setZipcode(e.target.value)}
          />
        </div>
        <div className="t-flex t-items-center t-cursor-pointer t-ml-2">
          <Badge dot={isUnRead} style={{ width: '10px', height: '10px' }}>
            <Dropdown
              overlay={menu}
              open={showNotification}
              onOpenChange={() => setShowNotification(!showNotification)}
              overlayStyle={{ position: 'fixed' }}
            >
              <div
                className="ant-dropdown-link"
                onClick={() => setShowNotification(!showNotification)}
              >
                {bell}
              </div>
            </Dropdown>
          </Badge>
        </div>
        <div className="t-flex t-items-center t-ml-2 t-cursor-pointer">
          <Dropdown
            overlay={menuProfile}
            open={open}
            onOpenChange={() => setOpen(!open)}
            overlayStyle={{ position: 'fixed' }}
          >
            <div
              id="header-profile-avatar"
              className="t-flex t-items-center t-justify-center t-bg-primary-100 t-text-white t-rounded-full t-w-10 t-h-10 t-font-bold t-tracking-wide t-shadow-lg t-select-none"
              onClick={() => setOpen(!open)}
            >
              {nameCapitals}
            </div>
          </Dropdown>
        </div>
      </Row>
      {/* <Row className="md:t-hidden">
        <div className="t-flex t-items-center t-cursor-pointer t-ml-2">
          <Button onClick={() => history.push('/settings/manage-subscription')}>
            Start Free Trial
          </Button>
        </div>
      </Row> */}
    </Row>
  )
}

export { HeaderTop }
