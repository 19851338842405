import React, { useEffect } from 'react'
import { Button, Card, Col, Row, Skeleton, Avatar } from 'antd'
import { BankFilled } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { getAllOrganizations } from 'store/organizationSlice/organizationActions'
import { searchOrganizations } from 'utils'
import {
  organizationActions,
  useOrganizationSelector,
} from 'store/organizationSlice/organizationReducer'
import { useHistory } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import { awsconfig, config } from 'configs'
import { useAuthSelector } from 'store/authSlice/authReducer'

const AllOrganizationList = ({ searchText }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { allOrganizations, allLastEvaluatedKey } = useOrganizationSelector()
  const { location } = useAuthSelector()

  useEffect(() => {
    const zipCode = location?.zipcode
    if (zipCode) {
      dispatch(getAllOrganizations(zipCode))
    }
    // eslint-disable-next-line
  }, [location])

  const fetchMoreData = () => {
    const zipCode = location?.zipcode
    if (zipCode) {
      dispatch(getAllOrganizations(zipCode, allLastEvaluatedKey))
    }
  }

  const handleOrganizationSelected = (organization) => {
    dispatch(organizationActions.setOrganization(null))
    history.push(`organizations/${organization.id}`)
  }

  return (
    <Skeleton loading={false} active paragraph={{ rows: 18 }}>
      <div
        className="t-flex t-flex-col t-justify-between t-h-full t-w-full t-px-2"
        style={{ minHeight: 'calc(100vh - 270px)' }}
      >
        <InfiniteScroll
          dataLength={
            searchOrganizations(allOrganizations, searchText)?.length || 0
          }
          next={() => fetchMoreData()}
          hasMore={allLastEvaluatedKey !== null}
          loader={
            <div className="t-flex t-justify-center t-items-center">
              {/* <Spin /> */}
            </div>
          }
        >
          <Row gutter={[24, 24]} className="t-h-full">
            {(searchOrganizations(allOrganizations, searchText)?.length ||
              0) === 0 && (
              <div
                className="t-h-96 t-flex t-justify-center t-items-center t-w-full"
                style={{ minHeight: 'calc(100vh - 300px)' }}
              >
                <div className="t-flex t-flex-col t-items-center t-space-y-2">
                  <BankFilled
                    style={{ fontSize: '4rem' }}
                    className="t-text-secondary-100"
                  />
                  <p>No records found</p>
                </div>
              </div>
            )}
            {searchOrganizations(allOrganizations, searchText)?.map(
              (item, index) => (
                <Col span={24} lg={8} className="mb-24" key={index}>
                  <Card
                    onClick={() => handleOrganizationSelected(item)}
                    bodyStyle={{ paddingTop: '14px' }}
                    className="card-project-2 header-solid t-cursor-pointer"
                    title={[
                      <Row gutter={[24, 24]} key={0}>
                        <Col className="t-w-2/12">
                          {item?.Logo?.fileName ? (
                            <img
                              src={awsconfig.bucketUrl + item?.Logo?.fileName}
                              alt="card-2"
                              className="t-h-10 t-w-full t-rounded-lg shadow-3 t-object-cover"
                            />
                          ) : (
                            <Avatar
                              src={<BankFilled />}
                              style={{
                                width: '40px',
                                height: '40px',
                                lineHeight: '74px',
                                borderRadius: '8px',
                                padding: '1rem',
                                backgroundColor: config.colors.primary,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            />
                          )}
                        </Col>
                        <Col className="t-flex t-justify-between t-flex-col t-w-10/12">
                          <h6 className="t-font-bold t-text-2lg t-capitalize t-text-secondary-100 t-line-clamp-2">
                            {item?.Name || 'No Title Found'}
                          </h6>
                          <h6 className="t-font-normal t-text-xs t-capitalize">
                            {/* ${item.Address1 ? `${item.Address1}, ` : ''} */}
                            {`${item.City}, ${item.State}`}
                          </h6>
                        </Col>
                      </Row>,
                    ]}
                  >
                    <p className="t-line-clamp-1 t-text-sm">
                      {item?.Description}
                    </p>
                    <div className="t-flex t-justify-end t-mt-4">
                      <Button
                        type="link"
                        size="small"
                        className="t-rounded-full"
                      >
                        See More
                      </Button>
                    </div>
                  </Card>
                </Col>
              ),
            )}
          </Row>
        </InfiniteScroll>
      </div>
    </Skeleton>
  )
}

export { AllOrganizationList }
