import React, { useEffect } from 'react'
import { Button, Card, Col, Row, Skeleton, Divider, Tag, Rate } from 'antd'
import { BankFilled } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import {
  getAverageRating,
  getBusinessProfilePictureUrl,
  searchBusinesses,
} from 'utils'
import { useHistory } from 'react-router-dom'
import { useAuthSelector } from 'store/authSlice/authReducer'
import InfiniteScroll from 'react-infinite-scroll-component'
import { getNearbyBusinessesByZip } from 'store/businessSlice/businessActions'
import { useBusinessSelector } from 'store/businessSlice/businessReducer'
import { AllBusinessesList } from './AllBusinessesList'

const BusinessList = ({ searchText }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { location } = useAuthSelector()
  const { loading, businesses, lastEvaluatedKey } = useBusinessSelector()

  useEffect(() => {
    const zipCode = location?.zipcode
    if (zipCode) {
      dispatch(getNearbyBusinessesByZip(zipCode))
    }
  }, [dispatch, location])

  const fetchMoreData = () => {
    const zipCode = location?.zipcode
    if (zipCode) {
      dispatch(getNearbyBusinessesByZip(zipCode, lastEvaluatedKey))
    }
  }

  const handleBusinessSelected = (business) => {
    history.push(`businesses/${business.Id}`)
  }

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
      <div
        className="t-flex t-flex-col t-justify-between t-h-full"
        style={{ minHeight: 'calc(100vh - 270px)' }}
      >
        <InfiniteScroll
          dataLength={searchBusinesses(businesses, searchText)?.length || 0}
          next={() => fetchMoreData()}
          hasMore={lastEvaluatedKey !== null}
          loader={
            <div className="t-flex t-justify-center t-items-center">
              {/* <Spin /> */}
            </div>
          }
        >
          <Row gutter={[24, 24]} className="t-h-full">
            {(searchBusinesses(businesses, searchText)?.length || 0) === 0 && (
              <>
                <div
                  className="t-flex t-justify-center t-items-center t-w-full"
                  // style={{ minHeight: 'calc(100vh - 300px)' }}
                >
                  <div className="t-flex t-flex-col t-items-center t-space-y-2">
                    <BankFilled
                      style={{ fontSize: '4rem' }}
                      className="t-text-secondary-100"
                    />
                    <p>No nearby business found</p>
                  </div>
                </div>
                <Divider />
                <p className="t-ml-4 t-text-lg t-font-semibold">
                  All Businesses
                </p>
                <AllBusinessesList searchText={searchText} />
              </>
            )}
            {searchBusinesses(businesses, searchText)?.map((item, index) => (
              <Col span={24} lg={8} className="mb-24" key={index}>
                <Card
                  onClick={() => handleBusinessSelected(item)}
                  bodyStyle={{ paddingTop: '14px' }}
                  className="card-project-2 header-solid t-cursor-pointer"
                  title={[
                    <div className="t-flex t-items-center t-space-x-2" key={0}>
                      <div className="t-w-12 t-min-w-[3rem] t-h-12">
                        <img
                          src={getBusinessProfilePictureUrl(
                            item?.ProfilePicture,
                          )}
                          alt="card-2"
                          className="t-h-full t-w-full t-rounded-lg t-shadow-md t-object-contain"
                        />
                      </div>
                      <Col className="t-flex t-justify-between t-flex-col t-w-10/12">
                        <h6 className="t-font-bold t-text-2lg t-capitalize t-text-secondary-100 t-line-clamp-2">
                          {item?.Name || 'No Title Found'}
                        </h6>
                        <h6 className="t-font-normal t-text-xs t-capitalize">
                          {/* ${item.Address1 ? `${item.Address1}, ` : ''} */}
                          {`${item.City}, ${item.State}`}
                        </h6>
                        <div>
                          <Rate
                            disabled
                            defaultValue={getAverageRating(item?.Rating)}
                          />
                        </div>
                      </Col>
                    </div>,
                  ]}
                >
                  <p className="t-line-clamp-1 t-text-sm">
                    {item?.Description}
                  </p>
                  <div className="t-w-full t-flex t-justify-end">
                    {item?.ServiceCoverage === 'Global' ? (
                      <Tag color="orange">Global</Tag>
                    ) : (
                      <Tag color="purple">Local</Tag>
                    )}
                  </div>
                  <hr />
                  <div className="t-flex t-justify-end t-mt-4">
                    <Button type="link" size="small" className="t-rounded-full">
                      See More
                    </Button>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </InfiniteScroll>
      </div>
    </Skeleton>
  )
}

export { BusinessList }
