/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Skeleton, Card, Button } from 'antd'
import Api from 'api'
import moment from 'moment'
import { formatAmount, sortByDate } from 'utils'
import { ArrowRightOutlined } from '@ant-design/icons'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { InfiniteScrollContainer } from 'components/elements'

function DonorContributionsReport({ id, setPdfData, setInception }) {
  const [loading, setLoading] = useState()
  const itemsFirstLoad = 3
  const [report, setReport] = useState()
  const [current, setCurrent] = useState([])
  const [selected, setSelected] = useState()

  useEffect(() => {
    setLoading(true)
    Api.get(`adminReports/donorContribution/${id}`)
      .then((res) => {
        const sortedData = sortByDate(res?.results, 'PaymentDate')
        setReport(sortedData)
        setCurrent(sortedData.slice(0, itemsFirstLoad))
        setPdfData(sortedData)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
    // eslint-disable-next-line
  }, [])

  return (
    <Skeleton loading={loading} paragraph={{ rows: 12 }}>
      {report && report.length > 0 ? (
        <>
          {selected === undefined ? (
            <>
              <div className="t-flex t-items-center t-mb-8">
                <h1 className="t-font-semibold t-mx-2">
                  Total Number of Donations{' '}
                </h1>
                <p className="t-font-semibold">{report.length}</p>
              </div>
              <div>
                <InfiniteScrollContainer
                  data={report}
                  current={current}
                  setCurrent={setCurrent}
                  itemsFirstLoad={itemsFirstLoad}
                  height="265px"
                >
                  <>
                    {current.map((item, index) => (
                      <Card
                        className="mb-24 header-solid list-settings-sessions"
                        bordered={false}
                        key={index}
                      >
                        <div className="t-flex t-justify-between t-items-center">
                          <p>{item.DonorName}</p>
                          <div className="t-flex t-items-center t-space-x-2">
                            <p>{formatAmount(item.Amount)}</p>
                            <Button
                              type="link"
                              onClick={() => {
                                setInception(true)
                                setSelected(item)
                              }}
                            >
                              See More <ArrowRightOutlined />
                            </Button>
                          </div>
                        </div>
                      </Card>
                    ))}
                  </>
                </InfiniteScrollContainer>
              </div>
            </>
          ) : (
            <Single
              item={selected}
              setSelected={setSelected}
              setInception={setInception}
            />
          )}
        </>
      ) : (
        <div>No Data Found</div>
      )}
    </Skeleton>
  )
}

function Single({ item, setSelected, setInception }) {
  return (
    <>
      <div className="t-flex t-items-center t-space-x-4 t-mb-4">
        <IoMdArrowRoundBack
          fontSize="1.5rem"
          className="t-cursor-pointer t-min-w-[1.5rem]"
          onClick={() => {
            setSelected()
            setInception(false)
          }}
        />
        <h5 className="font-semibold">
          <span className="t-capitalize t-whitespace-normal">
            {item.EventName} - {item.DonorName}
          </span>
        </h5>
      </div>
      <Card
        className="mb-24 header-solid list-settings-sessions"
        bordered={false}
      >
        <div className="t-space-y-4">
          <div className="t-flex t-items-center t-justify-between">
            <p>Donor Name</p>
            {item.DonorName}
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <p>Phone Number</p>
            {item.DonorPhoneNumber}
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <p>Email Address</p>
            {item?.DonorEmail}
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <p>Zip or Postal Code</p>
            {item.DonorZipCode || 'N/A'}
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <p>Amount Donated</p>
            {formatAmount(item.Amount)}
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <p>Thank You Email Sent</p>
            {moment(item.PaymentDate).format('dddd[,] DD/MM/YYYY [at] hh:mm A')}
          </div>
          <div className="t-flex t-items-center t-justify-between">
            <p>Thank You SMS Sent</p>
            {moment(item.PaymentDate).format('dddd[,] DD/MM/YYYY [at] hh:mm A')}
          </div>
        </div>
      </Card>
    </>
  )
}

export default DonorContributionsReport
