import React, { useEffect, useState } from 'react'
import { Card, Col, Tag, Skeleton, Modal, Typography, Button } from 'antd'
// import { MdModeEdit } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import { awsconfig } from 'configs'
import sample from 'assets/images/sample/sample1.png'
import { useDispatch } from 'react-redux'
import { getOrganizationRooms } from 'store/roomSlice/roomActions'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useRoomSelector } from 'store/roomSlice/roomReducer'
import { htmlCode, mobileShareNavigator, isMobile } from 'utils'
import { CopyBlock, dracula } from 'react-code-blocks'
import { ShareModal } from '../../../../../../components/elements/ShareModal'

const { Title } = Typography

const MyRooms = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { organization } = useAuthSelector()
  const { loading, rooms } = useRoomSelector()
  const [embedURLModalVisible, setEmbedURLModalVisible] = useState(false)
  const [rentalId, setRentalId] = useState('')
  const [isShare, setShare] = useState(false)
  const changeModal = (id) => {
    setRentalId(id)
    setEmbedURLModalVisible(!embedURLModalVisible)
  }

  const shareVolunteer = async (name, id) => {
    setRentalId(id)
    if (isMobile) {
      await mobileShareNavigator(
        name,
        `${window.location.origin}/room-rental-view/${rentalId}`,
      )
    } else {
      setShare(true)
    }
  }

  useEffect(() => {
    dispatch(getOrganizationRooms(organization?.id))
    // eslint-disable-next-line
  }, [])

  return (
    <Card
      title={[
        <div>
          <h4 className="mb-0">My Rooms</h4>
          <p className="font-regular">Manage Rooms</p>
        </div>,
      ]}
      className="t-mb-5"
    >
      <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
        <Col span={24}>
          {(!rooms || rooms?.length === 0) && (
            <Card className="t-h-96 t-w-full t-flex t-justify-center t-items-center">
              <div>No Rooms </div>
            </Card>
          )}
          {rooms?.map((item) => (
            <div className="t-flex t-justify-between t-items-center t-my-2 t-border t-rounded-md t-p-3 t-shadow">
              <div className="t-flex t-flex-col sm:t-flex-row t-space-x-4 t-items-center t-space-y-4 md:t-space-y-0">
                <img
                  src={
                    item?.Image?.fileName
                      ? awsconfig.bucketUrl + item?.Image?.fileName
                      : sample
                  }
                  className="t-h-16 md:t-h-8 t-w-24 md:t-w-12 t-rounded-md gallery-img shadow-3 img-contain"
                  alt="card-2"
                />
                <div className="t-flex t-flex-col t-justify-center">
                  <h1 className="t-text-secondary-100 t-font-bold">{`${item.Name} (${item.Breadth} × ${item.Width}) ft`}</h1>
                  <h1>{`Room capacity is ${item.Capacity}`}</h1>
                </div>
                <div>
                  {item.Available ? (
                    <Tag color="green" className="font-bold">
                      Open
                    </Tag>
                  ) : (
                    <Tag color="red" className="font-bold">
                      Closed
                    </Tag>
                  )}
                </div>
              </div>
              <div className="t-flex t-flex-row">
                <Button
                  type="primary"
                  onClick={() => shareVolunteer(item?.Name, item?.Id)}
                  className="t-my-2 t-mx-2"
                >
                  Share
                </Button>

                <Button
                  type="primary"
                  onClick={() => changeModal(item?.Id)}
                  className="t-my-2 t-mx-2"
                >
                  Embed Code
                </Button>
                <Button
                  type="primary"
                  onClick={() =>
                    history.push(`/settings/create-room/${item?.Id}`)
                  }
                  className="t-my-2 t-mx-2"
                >
                  Edit
                </Button>
                {/* <MdModeEdit
                  onClick={() =>
                    history.push(`/settings/create-room/${item?.Id}`)
                  }
                  fontSize="1.5rem"
                  className="t-text-secondary-100 t-cursor-pointer"
                /> */}
              </div>
            </div>
          ))}
        </Col>
      </Skeleton>
      <ShareModal
        url={`${window.location.origin}/room-rental-view/${rentalId}`}
        visible={isShare}
        onClose={() => setShare(false)}
      />
      <Modal
        centered
        title="Embed URL"
        width={600}
        open={embedURLModalVisible}
        onCancel={changeModal}
        footer={null}
      >
        <div>
          <div className="t-my-2">
            <Title level={4}>Copy iframe code</Title>
            <CopyBlock
              language="html"
              text={`
              <iframe
              style="width: 100%; height: 100%"
              src=${window.location.origin}/room-rental-view/${rentalId}
              title="Register through Extensia"
              ></iframe>`}
              theme={dracula}
              wrapLines
              codeBlock
            />
          </div>
          <div className="t-my-2">
            <Title level={4}>Copy code to paste in the website</Title>
            <CopyBlock
              language="html"
              text={htmlCode(
                `${window.location.origin}/room-rental-view/${rentalId}`,
                'Register',
              )}
              theme={dracula}
              wrapLines
              codeBlock
            />
          </div>
        </div>
      </Modal>
    </Card>
  )
}

export { MyRooms }
