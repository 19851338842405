import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Row, Skeleton, Avatar } from 'antd'
import { BankFilled, BookFilled } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { fetchMoreData, searchProgramServiceRows } from 'utils'
import {
  getFavouriteList,
  removeFavourite,
} from 'store/favouriteSlice/favouriteActions'
import { useFavouriteSelector } from 'store/favouriteSlice/favouriteReducer'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { TbHeartMinus } from 'react-icons/tb'
import { useHistory } from 'react-router-dom'
import { programActions } from 'store/programSlice/programReducer'
import { config } from 'configs'
import InfiniteScroll from 'react-infinite-scroll-component'

const FavouriteList = ({ searchText, sortKey }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { user, roles } = useAuthSelector()
  const { loading, programs } = useFavouriteSelector()
  const [current, setCurrent] = useState([])
  const searchedData = searchProgramServiceRows(programs, searchText)

  useEffect(() => {
    setCurrent(searchedData.slice(0, config.itemFirstLoad))
    // eslint-disable-next-line
  }, [programs, searchText])

  const fetchData = async () => {
    const payload = {
      Username: user?.id,
      Type: 'program',
      isSub: roles?.includes('subscriber') ? 1 : 0,
    }
    dispatch(getFavouriteList(payload, sortKey))
  }
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, [dispatch, roles, sortKey])

  const handleProgramSelected = (e, program) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(programActions.setProgram(null))
    history.push(`programs/${program.ProgramId}`)
  }

  const hanleRemoveFavourite = (e, id) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(removeFavourite(id, fetchData))
  }

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
      <div className="t-flex t-flex-col t-justify-between t-h-full">
        <InfiniteScroll
          dataLength={current.length}
          next={() => fetchMoreData(current, setCurrent, searchedData)}
          hasMore={current.length < searchedData.length}
          loader={
            <div className="t-flex t-justify-center t-items-center">
              {/* <Spin /> */}
            </div>
          }
        >
          <Row gutter={[24, 24]}>
            {current.length === 0 && (
              <div className="t-h-96 t-flex t-justify-center t-items-center t-w-full">
                <div className="t-flex t-flex-col t-items-center t-space-y-2">
                  <BookFilled
                    style={{ fontSize: '2rem' }}
                    className="t-text-secondary-100"
                  />
                  <p>No records found</p>
                </div>
              </div>
            )}
            {current.map((item, index) => (
              <Col span={24} lg={8} className="mb-24" key={index}>
                <Card
                  bodyStyle={{ paddingTop: '14px' }}
                  bordered={false}
                  className="card-project-2 header-solid t-cursor-pointer"
                  title={[
                    <Row gutter={[24, 24]} key={0}>
                      <Col span={4}>
                        <Avatar
                          src={<BankFilled />}
                          style={{
                            width: '25px',
                            height: '25px',
                            lineHeight: '74px',
                            borderRadius: '8px',
                            padding: '16px',
                            backgroundColor: config.colors.primary,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        />
                      </Col>
                      <Col span={16} className="t-overflow-hidden">
                        <h6 className="font-semibold mb-10 t-w-64 t-truncate">
                          {item.Title}
                        </h6>
                      </Col>
                      <Col span={4} className="ml-auto pt-10">
                        <TbHeartMinus
                          fontSize="1.2rem"
                          className="t-cursor-pointer"
                          onClick={(e) => hanleRemoveFavourite(e, item.favId)}
                        />
                      </Col>
                    </Row>,
                  ]}
                  onClick={(e) => handleProgramSelected(e, item)}
                >
                  <p className="t-truncate">{item.Description}</p>
                  <hr />
                  <div className="t-flex t-justify-end t-mt-2">
                    <Button
                      type="link"
                      size="small"
                      className="t-rounded-full"
                      onClick={(e) => handleProgramSelected(e, item)}
                    >
                      See More
                    </Button>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </InfiniteScroll>
      </div>
    </Skeleton>
  )
}

export { FavouriteList }
