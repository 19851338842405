import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  Col,
  Row,
  Skeleton,
  Avatar,
  Tooltip,
  Tag,
  Divider,
} from 'antd'
import { BookFilled } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { fetchMoreData, searchProgramServiceRows } from 'utils'
import { useHistory } from 'react-router-dom'
import {
  programActions,
  useProgramSelector,
} from 'store/programSlice/programReducer'
import { getNearByPrograms } from 'store/programSlice/programActions'
import InfiniteScroll from 'react-infinite-scroll-component'
import { config } from 'configs'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { AllProgramList } from './AllProgramList'

const ProgramList = ({ searchText }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { location } = useAuthSelector()
  const positionData = {
    lat: location?.lat,
    lon: location?.lng,
    zipCode: location?.zipcode,
  }
  const { loading, programs } = useProgramSelector()
  const [current, setCurrent] = useState([])

  useEffect(() => {
    setCurrent(programs.slice(0, config.itemFirstLoad))
    // eslint-disable-next-line
  }, [programs])

  useEffect(() => {
    const zipCode = location?.zipcode
    if (zipCode) {
      dispatch(getNearByPrograms(positionData))
    }
    // eslint-disable-next-line
  }, [dispatch, location])

  const handleProgramSelected = (e, program) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(programActions.setProgram(null))
    history.push(`/programs/${program.ProgramId}`)
  }

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
      <div className="t-flex t-flex-col t-justify-between t-h-full">
        <InfiniteScroll
          dataLength={searchProgramServiceRows(current, searchText).length}
          next={() => fetchMoreData(current, setCurrent, programs)}
          hasMore={current.length < programs.length}
          loader={
            <div className="t-flex t-justify-center t-items-center">
              {/* <Spin /> */}
            </div>
          }
        >
          <Row gutter={[24, 24]}>
            {searchProgramServiceRows(current, searchText, 'Name').length ===
              0 && (
              <>
                <div className="t-flex t-justify-center t-items-center t-w-full">
                  <div className="t-flex t-flex-col t-items-center t-space-y-2">
                    <BookFilled
                      style={{ fontSize: '2rem' }}
                      className="t-text-secondary-100"
                    />
                    <p>No nearby programs found</p>
                  </div>
                </div>
                <Divider />
                <p className="t-ml-4 t-text-lg t-font-semibold">All Programs</p>
                <AllProgramList searchText={searchText} />
              </>
            )}
            {searchProgramServiceRows(current, searchText, 'Name').map(
              (item, index) => (
                <Col span={24} lg={8} className="mb-24" key={index}>
                  <Card
                    bodyStyle={{ paddingTop: '14px' }}
                    bordered={false}
                    className="card-project-2 header-solid t-cursor-pointer"
                    title={[
                      <Row gutter={[24, 24]} key={0}>
                        <Col>
                          <Avatar
                            src={<BookFilled />}
                            style={{
                              width: '25px',
                              height: '25px',
                              lineHeight: '74px',
                              borderRadius: '8px',
                              padding: '16px',
                              backgroundColor: config.colors.primary,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          />
                        </Col>
                        <Col>
                          <h6 className="font-semibold t-w-64 t-truncate">
                            {item.Title ? item.Title : 'No Title Found'}
                          </h6>
                          <div className="t-space-y-1">
                            <Tooltip title="Organization Name">
                              <p className="t-text-sm t-truncate">
                                {item?.OrganizationName}
                              </p>
                            </Tooltip>
                            <Tooltip title="Delivery Type">
                              <div className="card-tag t-font-thin t-text-secondary-100 t-text-sm">
                                {item?.DeliveryLocation}
                              </div>
                            </Tooltip>
                          </div>
                        </Col>
                      </Row>,
                    ]}
                    onClick={(e) => handleProgramSelected(e, item)}
                  >
                    <p className="t-truncate">
                      {item.Description
                        ? item.Description
                        : 'No Description Found'}
                    </p>
                    <div className="t-w-full t-flex t-justify-end">
                      {item?.ProgramType === 'Class' ? (
                        <Tag color="orange">Class</Tag>
                      ) : (
                        <Tag color="purple">Custom</Tag>
                      )}
                    </div>
                    <hr />
                    <div className="t-flex t-justify-end t-mt-2">
                      <Button
                        type="link"
                        size="small"
                        className="t-rounded-full"
                        onClick={(e) => handleProgramSelected(e, item)}
                      >
                        See More
                      </Button>
                    </div>
                  </Card>
                </Col>
              ),
            )}
          </Row>
        </InfiniteScroll>
      </div>
    </Skeleton>
  )
}

export { ProgramList }
