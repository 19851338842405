import React from 'react'
import { useRoles } from 'hooks'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Button } from 'antd'

const ComponentViewHeader = ({
  heading,
  subHeading,
  buttonLabel,
  buttonLink,
  createTapButtonLink,
  type,
}) => {
  const history = useHistory()
  const { isSubscriber } = useRoles()

  return (
    <div>
      <div className="t-flex t-items-center t-space-x-2">
        <h6 className="font-semibold t-text-2xl">{heading}</h6>
        {isSubscriber &&
          (type === 'fundraiser' ? (
            <div className="t-flex t-flex-col sm:t-flex-wrap  md:t-flex-row">
              <Button
                type="primary"
                size="small"
                onClick={() => history.push(buttonLink)}
                className="t-mx-2 t-mb-1"
              >
                {buttonLabel || 'Add New'}
              </Button>
              <Button
                type="primary"
                size="small"
                onClick={() => history.push(createTapButtonLink)}
                className="t-mx-2 t-mb-1"
              >
                {buttonLabel || 'Add ClickTap '}
              </Button>
            </div>
          ) : (
            <Button
              type="primary"
              size="small"
              onClick={() => history.push(buttonLink)}
            >
              {buttonLabel || 'Create New'}
            </Button>
          ))}
      </div>
      <p>{subHeading}</p>
    </div>
  )
}

export { ComponentViewHeader }
