/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Tooltip } from 'antd'
import { DurationSelector } from './DurationSelector'
// import { createArray, format12To24HourValue } from 'utils'
import { FiPlus } from 'react-icons/fi'
import { MdDelete } from 'react-icons/md'

const DayDurations = ({
  durations,
  onChange,
  availableDurations,
  isAlwaysOpen,
  alreadyReservedDurations,
}) => {
  const [isEditableIndex, setIsEditableIndex] = useState(null)
  const addDuration = () => {
    const updatedItem = [...durations, { from: '00:00 am', to: '00:00 am' }]
    setIsEditableIndex(updatedItem?.length - 1)
    onChange(updatedItem)
  }

  const onDeleteClick = (index) => {
    let durationsCloned = [...durations]
    delete durationsCloned[index]
    durationsCloned = durationsCloned.filter((element) => element)
    setIsEditableIndex(durationsCloned?.length - 1)
    onChange(durationsCloned)
  }

  const onDurationChangeFinished = (updatedValue, index) => {
    const durationsCloned = [...durations]
    durationsCloned[index] = updatedValue
    onChange(durationsCloned)
  }

  useEffect(() => {
    // Trigger addDuration only once on initial mount
    if (!durations.length) {
      addDuration()
    }
  }, [durations])

  useEffect(() => {
    if (durations.length && isEditableIndex === null) {
      setIsEditableIndex(durations.length - 1)
    }
  }, [])
  return (
    <div className="t-flex t-space-x-2 t-items-center t-py-2 t-w-full">
      <div className="t-flex t-flex-col">
        {durations.map((element, index) => (
          <div className="t-flex t-flex-row">
            <div className="t-flex t-flex-col">
              <DurationSelector
                onDurationChange={(updatedDuration) =>
                  onDurationChangeFinished(updatedDuration, index)
                }
                value={element}
                // disabledHours={getSelectedHours(
                //   durations,
                //   alreadyReservedDurations,
                // )}
                alreadyReservedDurations={alreadyReservedDurations}
                // disabledMinutes={getDisableMinutes}
                isAlwaysOpen={isAlwaysOpen}
                key={index}
                durations={durations}
                onDeleteClick={() => onDeleteClick(index)}
                // getUnavailableHours={getUnavailableHours(isAlwaysOpen)}
                indexValue={index}
                isEditable={isEditableIndex === index}
              />
            </div>
            {index === durations.length - 1 && (
              <div className="t-px-2 t-flex t-flex-row t-mt-2">
                {durations.length < 4 && (
                  <Tooltip title="Add New Duration">
                    <FiPlus
                      onClick={addDuration}
                      className="t-text-secondary-100 t-cursor-pointer t-px-2 t-text-4xl t-mt-2"
                    />
                  </Tooltip>
                )}
                {durations.length > 1 && (
                  <Tooltip title="Delete Duration">
                    <MdDelete
                      className="t-text-red-500 t-cursor-pointer t-px-2 t-text-4xl t-mt-2"
                      onClick={() => onDeleteClick(index)}
                    />
                  </Tooltip>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
      {/* {durations.length < 4 && (
        <div className="t-px-2">
          <Tooltip title="Add New Duration">
            <GrAddCircle
              onClick={addDuration}
              fontSize="1.5rem"
              className="t-text-secondary-100 t-cursor-pointer"
            />
          </Tooltip>
        </div>
      )} */}
    </div>
  )
}

export { DayDurations }
