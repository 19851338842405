import React, { useEffect } from 'react'
import { Row, Col, Card, Button, Tooltip, Skeleton, Popconfirm } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { useOrganizationSelector } from 'store/organizationSlice/organizationReducer'
import { IoIosArrowBack } from 'react-icons/io'
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useDispatch } from 'react-redux'
import {
  addFavourite,
  removeFavourite,
} from 'store/favouriteSlice/favouriteActions'
import { useFavouriteSelector } from 'store/favouriteSlice/favouriteReducer'
import {
  associateOrganizationRequest,
  getOrganizationDetails,
} from 'store/organizationSlice/organizationActions'
import { SVGIcon } from 'components'
import { BsLink45Deg } from 'react-icons/bs'
import { config } from 'configs'
import { OrganizationProfile } from './components/OrganizationProfile'

const OrganizationView = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()

  const { loading, organization } = useOrganizationSelector()
  const { loading: favLoading } = useFavouriteSelector()
  const { user } = useAuthSelector()
  const isBusiness = user?.roles.includes(config?.roles?.business)

  const getOrganizationByID = () => {
    dispatch(getOrganizationDetails(params?.id, user?.id))
  }

  useEffect(() => {
    if (user?.email) {
      getOrganizationByID()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.email, params?.id])

  const handleAddFavourite = async (id) => {
    const payload = {
      ReferenceId: id,
      Username: user?.id,
      Type: 'organization',
      // Data needed for adding into community list
      email: user.email,
      DonorName: `${user?.firstname} ${user?.lastname}`,
      DonorPhoneNumber: user.phone,
      OrganizationId: organization?.id,
      OrganizationName: organization?.Name,
      OrganizationEmail: organization?.CreatedBy,
    }
    dispatch(addFavourite(payload, getOrganizationByID))
  }

  const handleRemoveFavourite = async (id) => {
    dispatch(removeFavourite(id, getOrganizationByID))
  }

  const handleAssociationRequest = async (id) => {
    dispatch(associateOrganizationRequest(id, organization?.id, user?.id))
  }

  const organizationLoading = loading || favLoading

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <Card
            title={[
              <div className="t-flex t-justify-between t-py-0 t-my-0">
                <div className="t-flex t-items-center t-space-x-4" id="back">
                  <Button
                    type="primary"
                    className="t-rounded-full t-flex t-items-center"
                    onClick={() => history.push('/organizations')}
                    size="small"
                  >
                    <IoIosArrowBack />
                    Back
                  </Button>
                </div>
                <div>
                  <div
                    className={`t-flex t-space-x-2 ${
                      organizationLoading &&
                      't-pointer-events-none t-opacity-50'
                    } ${!organization && 't-pointer-events-none t-opacity-0'}`}
                  >
                    {organization?.isFav ? (
                      <Tooltip title="Remove Favorite">
                        <AiFillHeart
                          fontSize="1.8rem"
                          id="remove-fav"
                          className="t-cursor-pointer t-text-red-500"
                          onClick={() =>
                            handleRemoveFavourite(organization?.favId)
                          }
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Make Favorite">
                        <AiOutlineHeart
                          fontSize="1.8rem"
                          id="make-fav"
                          className="t-cursor-pointer t-text-secondary-100"
                          onClick={() => handleAddFavourite(organization?.id)}
                        />
                      </Tooltip>
                    )}
                    {isBusiness && organization?.canAssociate && (
                      <Popconfirm
                        title="A request to list your business with this organization will be sent for approval. Continue?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() =>
                          handleAssociationRequest(organization?.id)
                        }
                      >
                        <Tooltip title="Send Association Request">
                          <BsLink45Deg
                            fontSize="2rem"
                            className="t-cursor-pointer t-text-secondary-100"
                          />
                        </Tooltip>
                      </Popconfirm>
                    )}
                  </div>
                </div>
              </div>,
            ]}
            bordered={false}
            className="header-solid card-product t-p-0"
          >
            <Row
              gutter={[24, 0]}
              style={{ minHeight: 'calc(100vh - 200px)' }}
              className="t-p-1 md:t-p-4"
            >
              <Skeleton
                loading={organizationLoading}
                active
                paragraph={{ rows: 18 }}
              >
                {organization ? (
                  <OrganizationProfile organization={organization} />
                ) : (
                  <div className="t-w-full t-h-full t-flex t-justify-center t-items-center">
                    <div className="ant-result ant-result-404">
                      <SVGIcon />
                      <div className="ant-result-title">Invalid URL</div>
                      <div className="ant-result-subtitle">
                        Sorry, the page you visited does not exist.
                      </div>
                      <div className="ant-result-extra">
                        <Button
                          onClick={() => history.push('/organizations')}
                          type="primary"
                          size="small"
                        >
                          Goto Organizations
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </Skeleton>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export { OrganizationView }
