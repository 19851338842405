import React, { useState } from 'react'
import {
  Input,
  Button,
  Card,
  Form,
  Row,
  Col,
  Typography,
  Modal,
  message,
  Steps,
  Divider,
} from 'antd'
// import { BsYoutube } from 'react-icons/bs'
import { useParams } from 'react-router-dom'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { CopyBlock, dracula } from 'react-code-blocks'
import { htmlCode, REGEXS } from 'utils'
// import { AmountInput } from 'components/elements'
import { FaStopCircle } from 'react-icons/fa'
import { AiFillPlayCircle } from 'react-icons/ai'
import { LoadingOutlined, SendOutlined } from '@ant-design/icons'
import {
  fundraiserActions,
  useFundraiserSelector,
} from 'store/fundraiserSlice/fundraiserReducer'
import {
  getFundraiser,
  startStopFundraiserApi,
  startStopFundraisingApi,
  updateYoutubeLink,
} from 'store/fundraiserSlice/fundraiserActions'

const { Title } = Typography
const { amountValidation, websiteRegex } = REGEXS

const schema = Yup.object().shape({
  AskedAmount: Yup.string()
    .required('Asked amount is Required')
    .matches(amountValidation, 'Invalid amount'),
  YoutubeLink: Yup.string()
    .required('Youtube link is required')
    .matches(
      websiteRegex,
      'Invalid website url e.g https://www.youtube.com/watch?v=Ke90Tje7VS0',
    ),
})

const askedAmountValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

// const initialValues = {}

const Control = ({ setVisible, setDecidingModal }) => {
  // const [askedAmountloading, setAskedAmountLoading] = useState(false)
  const [updateYoutubeloading, setYoutubeLoading] = useState(false)
  const [embedURLModalVisible, setEmbedURLModalVisible] = useState(false)
  // const [form] = Form.useForm()
  const [form1] = Form.useForm()
  const { id } = useParams()
  const { user } = useAuthSelector()
  const { fundraiser, fundraiserAuctionItems } = useFundraiserSelector()
  const dispatch = useDispatch()

  const onVideoLinkSubmit = async (values) => {
    setYoutubeLoading(true)
    try {
      const data = {
        youtubeLink: values.YoutubeLink,
        createdBy: user?.email,
        organizationId: fundraiser?.organizationId,
      }
      await updateYoutubeLink(id, data)
      const fundraiserDetail = await getFundraiser(id)
      dispatch(fundraiserActions.setFundraiser(fundraiserDetail))
      form1.resetFields()
      message.success('Youtube Link Updated Successfully')
      setYoutubeLoading(false)
    } catch (error) {
      setYoutubeLoading(false)
      message.error('Something went wrong')
    }
  }

  const isCampaign = true
  const isPrivateCampaign = fundraiser?.isPrivate

  const changeModal = () => setEmbedURLModalVisible(!embedURLModalVisible)

  const [isLoading, setLoading] = useState(false)

  const stopFundraiser = async () => {
    if (fundraiserAuctionItems && fundraiserAuctionItems?.length > 0) {
      message.warning('End auction first')
      setDecidingModal(true)
    } else {
      try {
        setLoading(true)
        await startStopFundraiserApi(fundraiser?.iD, {
          organizationId: fundraiser.organizationId,
          status: 0,
        })
        const detaila = await getFundraiser(fundraiser?.iD)
        dispatch(fundraiserActions.setFundraiser(detaila))
        message.success('Fundraiser Stopped Successfully')
        setLoading(false)
      } catch (error) {
        message.error('Something went wrong')
        setLoading(false)
      }
    }
  }
  const startFundraiser = async () => {
    try {
      setLoading(true)
      // start Event
      await startStopFundraiserApi(fundraiser?.iD, {
        organizationId: fundraiser.organizationId,
        status: 1,
      })
      const detailb = await getFundraiser(fundraiser?.iD)
      dispatch(fundraiserActions.setFundraiser(detailb))
      message.success('Fundraiser Started Successfully')
      setLoading(false)
      return null
    } catch (error) {
      message.error('Something went wrong')
      setLoading(false)
      return null
    }
  }

  const startFundraising = async () => {
    setLoading(true)
    try {
      await startStopFundraisingApi(fundraiser?.iD, {
        organizationId: fundraiser.organizationId,
        status: 'Started',
      })
      const detailF = await getFundraiser(fundraiser?.iD)
      dispatch(fundraiserActions.setFundraiser(detailF))
      setLoading(false)
    } catch (error) {
      message.error('Error starting fundraising')
      setLoading(false)
    }
  }

  const stopFundraising = async () => {
    setLoading(true)
    try {
      await startStopFundraisingApi(fundraiser?.iD, {
        organizationId: fundraiser.organizationId,
        status: 'Not-Started',
      })
      const eventDetailF = await getFundraiser(fundraiser?.iD)
      dispatch(fundraiserActions.setFundraiser(eventDetailF))
      setLoading(false)
    } catch (error) {
      message.error('Error stopping fundraising')
      setLoading(false)
    }
  }

  const isFundraisingNotStarted = fundraiser?.fundraiserStatus === 'Not-Started'
  const isFundraisingStarted = fundraiser?.fundraiserStatus === 'Started'
  const isFundraisingStopped = fundraiser?.fundraiserStatus === 'Stopped'

  const contentStep2 = (
    <Col xs={24} sm={24} md={24} xl={24}>
      {isLoading ? (
        <LoadingOutlined
          style={{ fontSize: '2rem' }}
          className="t-text-green-500"
        />
      ) : (
        <div>
          {fundraiser?.startFundraiser ? (
            <div className="t-flex t-items-center t-space-x-4">
              <h6>Stop Fundraiser</h6>
              <FaStopCircle
                fontSize="2.5rem"
                className="t-text-red-400 t-cursor-pointer"
                onClick={stopFundraiser}
              />
            </div>
          ) : (
            <div className="t-flex t-items-center t-space-x-4">
              <h6>Start Fundraiser</h6>
              <AiFillPlayCircle
                fontSize="2.5rem"
                className="t-text-secondary-100 t-cursor-pointer"
                onClick={startFundraiser}
              />
            </div>
          )}
        </div>
      )}
    </Col>
  )
  const contentStep3 = (
    <Col xs={24} sm={24} md={12} xl={12}>
      {isFundraisingNotStarted && (
        <Row span={24} lg={24} xl={24} className="t-mb-4">
          <div className="t-flex t-justify-between t-items-center t-border-2 t-w-full t-rounded-lg t-shadow-lg t-p-2 t-bg-yellow-100">
            <p className="t-font-semibold t-text-md">
              Fundraising is not yet started
            </p>
            <Button
              type="primary"
              loading={isLoading}
              onClick={startFundraising}
            >
              Start Fundraising
            </Button>
          </div>
        </Row>
      )}
      {isFundraisingStarted && (
        <div className="t-py-2 t-w-full t-flex">
          <Button type="primary" loading={isLoading} onClick={stopFundraising}>
            Stop Fundraising
          </Button>
        </div>
      )}
      {isFundraisingStopped && (
        <div className="t-py-2 t-w-full t-flex">
          <Button type="primary" loading={isLoading} onClick={startFundraising}>
            Start Fundraising Again
          </Button>
        </div>
      )}
    </Col>
  )

  return (
    <Card className="t-w-full">
      <div className="t-space-y-2">
        <Col xl={24} md={24} xs={24} sm={24}>
          <Steps
            direction="vertical"
            items={[
              {
                title: 'Start/Stop Event',
                status: 'process',
                description: contentStep2,
              },
              {
                title: 'Start/Stop Fundraiser',
                status: 'process',
                description: contentStep3,
              },
            ]}
          />
        </Col>
        <Divider />
        <p className="t-font-semibold t-text-md">Update Youtube Link</p>
        <Row className="t-hidden md:t-flex md:t-items-center t-space-x-1 t-space-y-1">
          <div className="t-mt-1 t-ml-1">
            <Form
              form={form1}
              layout="vertical"
              key={0}
              onFinish={onVideoLinkSubmit}
            >
              <Form.Item
                name="YoutubeLink"
                // label="Youtube Link"
                colon={false}
                rules={[askedAmountValidation]}
                className="t-m-0"
              >
                <Input
                  placeholder="Enter link"
                  suffix={
                    updateYoutubeloading ? (
                      <LoadingOutlined
                      // style={{ fontSize: '2rem' }}
                      // className="t-text-green-500"
                      />
                    ) : (
                      <SendOutlined
                        className="t-cursor-pointer"
                        onClick={() => form1.submit()}
                      />
                    )
                  }
                />
              </Form.Item>
            </Form>
          </div>

          <Button type="primary" onClick={() => setVisible(true)}>
            Send Pledge Reminders
          </Button>
          <>
            {isCampaign ? (
              <a
                href={
                  isPrivateCampaign
                    ? `${window.location.origin}/donation/${id}`
                    : `${window.location.origin}/fundraisers/${id}/join-fundraiser`
                }
                className="t-font-bold t-text-secondary-100"
                target="_blank"
                rel="noreferrer"
              >
                <Button type="primary">Open Main Screen</Button>
              </a>
            ) : (
              <a
                href={`${window.location.origin}/fundraisers/${id}/join-fundraiser`}
                className="t-font-bold t-text-secondary-100"
                target="_blank"
                rel="noreferrer"
              >
                <Button type="primary">Open Main Screen</Button>
              </a>
            )}
          </>
          <Button type="primary" onClick={changeModal}>
            Embed Code
          </Button>
        </Row>
      </div>
      <Modal
        centered
        title="Embed URL"
        width={600}
        open={embedURLModalVisible}
        onCancel={changeModal}
        footer={null}
      >
        <div>
          <div className="t-my-2">
            <Title level={4}>Copy iframe code</Title>
            <CopyBlock
              language="html"
              text={`
              <iframe
              style="width: 100%; height: 100%"
              src=${window.location.origin}/donation/${fundraiser.iD}
              title="Donate through Extensia"
              ></iframe>`}
              theme={dracula}
              wrapLines
              codeBlock
            />
          </div>
          <div className="t-my-2">
            <Title level={4}>Copy code to paste in the website</Title>
            <CopyBlock
              language="html"
              text={htmlCode(
                `${window.location.origin}/donation/${fundraiser.iD}`,
                'Donate',
              )}
              theme={dracula}
              wrapLines
              codeBlock
            />
          </div>
        </div>
      </Modal>
    </Card>
  )
}

export { Control }
