import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Row, Skeleton, Spin, Tooltip } from 'antd'
import { useDispatch } from 'react-redux'
import { useFavouriteSelector } from 'store/favouriteSlice/favouriteReducer'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { TbHeartMinus } from 'react-icons/tb'
import { fetchMoreData, searchFundraisersRows } from 'utils'
import { config, awsconfig } from 'configs'
import { CarryOutFilled } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import {
  getFavouriteFundraisers,
  removeFavourite,
} from 'store/favouriteSlice/favouriteActions'

import sample from 'assets/images/sample/sample1.png'
import InfiniteScroll from 'react-infinite-scroll-component'
import { fundraiserActions } from 'store/fundraiserSlice/fundraiserReducer'

function FavouriteList({ searchText, sortKey }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const { user, organization, roles } = useAuthSelector()
  const { loading, fundraisers } = useFavouriteSelector()
  const [current, setCurrent] = useState([])

  const searchedData = searchFundraisersRows(fundraisers, searchText)

  useEffect(() => {
    setCurrent(searchedData.slice(0, config.itemFirstLoad))
    // eslint-disable-next-line
  }, [fundraisers, searchText])

  const fetchData = async () => {
    const payload = {
      Username: user?.id,
      Type: 'fundraiser',
      isSub: roles?.includes('subscriber') ? 1 : 0,
    }
    dispatch(getFavouriteFundraisers(payload, sortKey))
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, [dispatch, roles, sortKey])

  const handleEventSelected = (item) => {
    dispatch(fundraiserActions.setJoinedFundraiserDetails(null))
    dispatch(fundraiserActions.setFundraiser(null))
    history.push(`fundraisers/${item.iD}`)
  }

  const hanleRemoveFavourite = (e, id) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(removeFavourite(id, fetchData))
  }

  const goToJoinScreen = (e, item) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(fundraiserActions.setJoinedFundraiserDetails(null))
    dispatch(fundraiserActions.setFundraiser(item))
    history.push(`/fundraisers/${item?.iD}/join-fundraiser`)
  }

  const goToControlScreen = (e, item) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(fundraiserActions.setJoinedFundraiserDetails(null))
    dispatch(fundraiserActions.setFundraiser(item))
    history.push(`/fundraisers/${item?.iD}/control-screen`)
  }

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
      <div className="t-flex t-flex-col t-justify-between t-h-full">
        <InfiniteScroll
          dataLength={current.length}
          next={() => fetchMoreData(current, setCurrent, searchedData)}
          hasMore={current.length < searchedData.length}
          loader={
            <div className="t-flex t-justify-center t-items-center">
              <Spin />
            </div>
          }
        >
          <Row gutter={[24, 24]} className="t-h-full">
            {current.length === 0 && (
              <div
                className="t-h-96 t-flex t-justify-center t-items-center t-w-full"
                style={{ minHeight: 'calc(100vh - 300px)' }}
              >
                <div className="t-flex t-flex-col t-items-center t-space-y-2">
                  <CarryOutFilled
                    style={{ fontSize: '2rem' }}
                    className="t-text-secondary-100"
                  />
                  <p>No records found</p>
                </div>
              </div>
            )}
            {current.map((item, index) => (
              <Col span={24} md={8} xl={8} key={index}>
                <Card
                  bordered
                  hoverable
                  cover={
                    <img
                      alt="example"
                      className="t-w-full t-h-48 t-object-cover t-rounded-md"
                      src={
                        item?.flyer?.length
                          ? awsconfig.bucketUrl + item?.flyer[0]?.fileName
                          : item?.flyer?.fileName
                          ? awsconfig.bucketUrl + item?.flyer?.fileName
                          : item?.OrganizationBackgroundImage
                          ? awsconfig.bucketUrl +
                            item?.OrganizationBackgroundImage?.fileName
                          : sample
                      }
                    />
                  }
                  onClick={() => handleEventSelected(item)}
                >
                  <div className="t-flex t-justify-between t-items-center">
                    <h5 className="t-text-2xl t-text-secondary-100 t-font-bold t-truncate">
                      <Tooltip title={item?.title}>{item?.title}</Tooltip>
                    </h5>

                    <TbHeartMinus
                      fontSize="2rem"
                      className="t-cursor-pointer t-text-secondary-100"
                      onClick={(e) => hanleRemoveFavourite(e, item?.favId)}
                    />
                  </div>

                  <div className="t-flex t-justify-between">
                    <div className="card-tag t-font-thin t-text-secondary-100">
                      {item?.organizationName}
                    </div>
                  </div>
                  <p className="t-line-clamp-1 t-text-sm">
                    {item?.description}
                  </p>
                  <div className="t-flex t-justify-end t-mt-2 t-space-x-1 t-h-8">
                    {(item?.organizationId === organization?.id ||
                      (item?.presenterEmail === user?.email &&
                        item?.acceptanceStatus === 'Accepted')) && (
                      <Button
                        type="primary"
                        size="small"
                        className="t-rounded-full"
                        onClick={(e) => goToControlScreen(e, item)}
                      >
                        Control
                      </Button>
                    )}
                    {item.startFundraiser && (
                      <Button
                        type="primary"
                        size="small"
                        className="t-rounded-full"
                        onClick={(e) => goToJoinScreen(e, item)}
                      >
                        Join
                      </Button>
                    )}
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </InfiniteScroll>
      </div>
    </Skeleton>
  )
}

export { FavouriteList }
