import React, { useEffect } from 'react'
import { Button, Card, Col, Row, Skeleton, Avatar, Divider } from 'antd'
import { BankFilled } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { getNearbyOrganizationsByZip } from 'store/organizationSlice/organizationActions'
import { searchOrganizations } from 'utils'
import {
  organizationActions,
  useOrganizationSelector,
} from 'store/organizationSlice/organizationReducer'
import { useHistory } from 'react-router-dom'
import { useAuthSelector } from 'store/authSlice/authReducer'
import InfiniteScroll from 'react-infinite-scroll-component'
import { awsconfig, config } from 'configs'
import { AllOrganizationList } from './AllOrganizationList'

const OrganizationList = ({ searchText }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { location } = useAuthSelector()
  const { loading, organizations, lastEvaluatedKey } = useOrganizationSelector()

  useEffect(() => {
    const zipCode = location?.zipcode
    if (zipCode) {
      dispatch(getNearbyOrganizationsByZip(zipCode))
    }
  }, [dispatch, location])

  const fetchMoreData = () => {
    const zipCode = location?.zipcode
    if (zipCode) {
      dispatch(getNearbyOrganizationsByZip(zipCode, lastEvaluatedKey))
    }
  }

  const handleOrganizationSelected = (organization) => {
    dispatch(organizationActions.setOrganization(null))
    history.push(`organizations/${organization.id}`)
  }

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
      <div
        className="t-flex t-flex-col t-justify-between t-h-full"
        style={{ minHeight: 'calc(100vh - 270px)' }}
      >
        <InfiniteScroll
          dataLength={
            searchOrganizations(organizations, searchText)?.length || 0
          }
          next={() => fetchMoreData()}
          hasMore={lastEvaluatedKey !== null}
          loader={
            <div className="t-flex t-justify-center t-items-center">
              {/* <Spin /> */}
            </div>
          }
        >
          <Row gutter={[24, 24]} className="t-h-full">
            {(searchOrganizations(organizations, searchText)?.length || 0) ===
              0 && (
              <>
                <div
                  className="t-flex t-justify-center t-items-center t-w-full"
                  // style={{ minHeight: 'calc(100vh - 300px)' }}
                >
                  <div className="t-flex t-flex-col t-items-center t-space-y-2">
                    <BankFilled
                      style={{ fontSize: '4rem' }}
                      className="t-text-secondary-100"
                    />
                    <p>No nearby organization found</p>
                  </div>
                </div>
                <Divider />
                <p className="t-ml-4 t-text-lg t-font-semibold">
                  All Organizations
                </p>
                <AllOrganizationList searchText={searchText} />
              </>
            )}
            {searchOrganizations(organizations, searchText)?.map(
              (item, index) => (
                <Col span={24} lg={8} className="mb-24" key={index}>
                  <Card
                    onClick={() => handleOrganizationSelected(item)}
                    bodyStyle={{ paddingTop: '14px' }}
                    className="card-project-2 header-solid t-cursor-pointer"
                    title={[
                      <div
                        className="t-flex t-items-center t-space-x-2"
                        key={0}
                      >
                        <div className="t-w-12 t-min-w-[3rem] t-h-12">
                          {item?.Logo?.fileName ? (
                            <img
                              src={awsconfig.bucketUrl + item?.Logo?.fileName}
                              alt="card-2"
                              className="t-h-full t-w-full t-rounded-lg t-shadow-md t-object-contain"
                            />
                          ) : (
                            <Avatar
                              src={<BankFilled />}
                              style={{
                                width: '100%',
                                height: '100%',
                                lineHeight: '74px',
                                borderRadius: '8px',
                                padding: '1rem',
                                backgroundColor: config.colors.primary,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            />
                          )}
                        </div>
                        <Col className="t-flex t-justify-between t-flex-col t-w-10/12">
                          <h6 className="t-font-bold t-text-2lg t-capitalize t-text-secondary-100 t-line-clamp-2">
                            {item?.Name || 'No Title Found'}
                          </h6>
                          <h6 className="t-font-normal t-text-xs t-capitalize">
                            {/* ${item.Address1 ? `${item.Address1}, ` : ''} */}
                            {`${item.City}, ${item.State}`}
                          </h6>
                        </Col>
                      </div>,
                    ]}
                  >
                    <p className="t-line-clamp-1 t-text-sm">
                      {item?.Description}
                    </p>
                    <div className="t-flex t-justify-end t-mt-4">
                      <Button
                        type="link"
                        size="small"
                        className="t-rounded-full t-text-secondary-100"
                      >
                        See More
                      </Button>
                    </div>
                  </Card>
                </Col>
              ),
            )}
          </Row>
        </InfiniteScroll>
      </div>
    </Skeleton>
  )
}

export { OrganizationList }
