import * as Yup from 'yup'

const stringRegex = /^[A-Za-z ]+$/
const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi

const schema = Yup.object().shape({
  firstName: Yup.string()
    .required('Required')
    .matches(stringRegex, 'Invalid First Name'),
  lastName: Yup.string()
    .required('Required')
    .matches(stringRegex, 'Invalid Last Name'),
  email: Yup.string().required('Required').matches(emailRegex, 'Invalid Email'),
  recaptcha: Yup.string().required('Please complete recaptcha to proceed'),
})

const signUpValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { signUpValidation }
