export * from './ComponentViewHeader'

export const getComponentOptions = (list) => {
  const isAll = list?.includes('all')
  const isMy = list?.includes('my')
  const isInactive = list?.includes('inactive')
  const isFavorite = list?.includes('favorite')
  const isPresent = list?.includes('presenter')
  return { isAll, isMy, isInactive, isFavorite, isPresent }
}

export const getComponentInitialValues = (list) => {
  const { isAll, isMy, isInactive, isFavorite, isPresent } =
    getComponentOptions(list)

  const initialValues = {}
  if (isAll) initialValues.all = false
  if (isMy) initialValues.my = false
  if (isInactive) initialValues.inactive = false
  if (isFavorite) initialValues.favorite = false
  if (isPresent) initialValues.presenter = false

  return initialValues
}

export const getComponentCurrentView = (isView) => {
  if (isView.all) return 'all'
  if (isView.my) return 'my'
  if (isView.inactive) return 'inactive'
  if (isView.favorite) return 'favorite'
  if (isView.presenter) return 'presenter'
  return 'all'
}

export const getComponentSubHeading = (isViews, heading) => {
  if (isViews.favorite) return `Favorite ${heading}`
  if (isViews.inactive) return `Inactive ${heading}`
  if (isViews.my) return `My ${heading}`
  if (isViews.presenter) return `Presenter ${heading}`
  return `All ${heading}`
}
