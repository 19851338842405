import React, { useEffect, useState } from 'react'
import { Card, Skeleton } from 'antd'
import { useEventSelector } from 'store/eventSlice/eventReducer'
import { config } from 'configs'
import { formatAmount } from 'utils'
import moment from 'moment'
import { InfiniteScrollContainer } from 'components/elements'

const Donations = () => {
  const { loading, eventDetail } = useEventSelector()
  const [data, setData] = useState([])
  const [current, setCurrent] = useState([])

  useEffect(() => {
    if (eventDetail?.donations?.length > 0) {
      setData(eventDetail?.donations)
      setCurrent(eventDetail?.donations?.slice(0, config.rowsPerPage))
    } else {
      setData([])
      setCurrent([])
    }
    // eslint-disable-next-line
  }, [eventDetail])

  const PaymentMethodText = (text) => {
    let updatedtext = text
    if (updatedtext === 'Paypal' || updatedtext === 'Stripe') {
      updatedtext = 'Credit Card'
    }
    return updatedtext
  }

  return (
    <Card className="t-w-full">
      <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
        <div className="t-h-80 t-overflow-scroll md:t-overflow-auto">
          {data?.length === 0 && (
            <div className="t-flex t-justify-center t-items-center t-h-full t-w-full">
              No donations recorded. Support a cause today.
            </div>
          )}
          {data.length > 0 && (
            <InfiniteScrollContainer
              data={data}
              current={current}
              setCurrent={setCurrent}
              height="256px"
              itemsFirstLoad={config.rowsPerPage}
            >
              <>
                {current.map((item, index) => {
                  const includeEventCost = item?.contributeToEventCost
                  return (
                    <div
                      key={index}
                      className="t-w-full t-rounded-2xl t-h-12 t-p-3 t-bg-yellow-100 t-text-secondary-100 t-mb-2 t-flex t-items-center t-shadow-md t-justify-between"
                    >
                      <div className="t-flex t-space-x-2 t-items-center">
                        <div className="t-w-8 t-h-8 t-rounded-full t-flex t-items-center t-justify-center t-bg-primary-100 t-p-2 t-shadow-lg t-text-white t-font-bold">
                          {item.ItemType === 'AuctionItem'
                            ? item.winnerName.substring(0, 1).toUpperCase()
                            : item.DonorName
                            ? item.DonorName.substring(0, 1).toUpperCase()
                            : ''}
                        </div>
                        <h1 className="t-font-bold t-text-xs md:t-text-sm">
                          {item.ItemType === 'AuctionItem'
                            ? `${item.winnerName} won an auction item`
                            : item.PaymentMethod === 'Pledge'
                            ? `${
                                item.IsDonatingAnonymously
                                  ? 'Anonymous'
                                  : item.DonorName
                              } made a pledge`
                            : `${
                                item.IsDonatingAnonymously
                                  ? 'Anonymous'
                                  : item.DonorName
                              } donated via ${PaymentMethodText(
                                item.PaymentMethod,
                              )}`}
                        </h1>
                      </div>
                      <div className="t-flex t-flex-col">
                        {item.ItemType === 'AuctionItem' ? (
                          <h2 className="t-font-bold t-text-secondary-100 t-text-right t-text-xs md:t-text-sm">
                            {item?.winnerBid && formatAmount(item?.winnerBid)}
                          </h2>
                        ) : (
                          <h2 className="t-font-bold t-text-secondary-100 t-text-right t-text-xs md:t-text-sm">
                            {includeEventCost
                              ? item?.DonatedAmount
                                ? formatAmount(item?.DonatedAmount)
                                : formatAmount(item.Amount)
                              : formatAmount(item.Amount)}
                          </h2>
                        )}
                        {item.ItemType !== 'AuctionItem' && (
                          <h2 className="t-text-gray-800 t-italic t-text-xs md:t-text-sm t-whitespace-nowrap t-text-right">
                            {moment(moment.utc(item.PaymentDate))
                              .local()
                              .fromNow()}
                          </h2>
                        )}
                      </div>
                    </div>
                  )
                })}
              </>
            </InfiniteScrollContainer>
          )}
        </div>
      </Skeleton>
    </Card>
  )
}

export { Donations }
