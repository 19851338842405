import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Row, Skeleton, Tooltip } from 'antd'
import { ShoppingCartOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { compareDateTimeWithNow, fetchMoreData, searchAuctionRows } from 'utils'
import {
  getFavouriteAuctions,
  removeFavourite,
} from 'store/favouriteSlice/favouriteActions'
import { useFavouriteSelector } from 'store/favouriteSlice/favouriteReducer'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { TbHeartMinus } from 'react-icons/tb'
import { useHistory } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import { awsconfig, config } from 'configs'
import { auctionActions } from 'store/auctionSlice/auctionReducer'
import sample from 'assets/images/sample/sample1.png'

function FavouriteList({ searchText, sortKey }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const { user, organization, roles } = useAuthSelector()
  const { loading, auctions } = useFavouriteSelector()
  const [current, setCurrent] = useState([])

  const searchedData = searchAuctionRows(auctions, searchText)

  useEffect(() => {
    setCurrent(searchedData.slice(0, config.itemFirstLoad))
    // eslint-disable-next-line
  }, [auctions, searchText])

  const fetchData = async () => {
    const payload = {
      Username: user?.id,
      Type: 'auction',
      isSub: roles?.includes('subscriber') ? 1 : 0,
    }
    dispatch(getFavouriteAuctions(payload, sortKey))
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, [dispatch, roles, sortKey])

  const handleAuctionsSelected = (e, auction) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(auctionActions.setAuction(null))
    history.push(`auction/${auction.Id}`)
  }

  const hanleRemoveFavourite = (e, id) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(removeFavourite(id, fetchData))
  }

  const goToJoinScreen = (e, auction) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(auctionActions.setAuction(auction))
    history.push(`/auction/${auction?.Id}/join-auction`)
  }

  const goToControlScreen = (e, auction) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(auctionActions.setAuction(auction))
    history.push(`/auction/${auction?.Id}/control-screen`)
  }

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
      <div className="t-flex t-flex-col t-justify-between t-h-full">
        <InfiniteScroll
          dataLength={current.length}
          next={() => fetchMoreData(current, setCurrent, searchedData)}
          hasMore={current.length < searchedData.length}
          loader={
            <div className="t-flex t-justify-center t-items-center">
              {/* <Spin /> */}
            </div>
          }
        >
          <Row gutter={[24, 24]}>
            {current.length === 0 && (
              <div className="t-h-96 t-flex t-justify-center t-items-center t-w-full">
                <div className="t-flex t-flex-col t-items-center t-space-y-2">
                  <ShoppingCartOutlined
                    style={{ fontSize: '2rem' }}
                    className="t-text-secondary-100"
                  />
                  <p>No records found</p>
                </div>
              </div>
            )}
            {current.map((item, index) => (
              <Col span={24} lg={8} className="mb-24" key={index}>
                <Card
                  bordered
                  hoverable
                  cover={
                    <img
                      alt="example"
                      className="t-w-full t-h-48 t-object-cover t-rounded-md"
                      src={
                        item?.Flyer?.fileName
                          ? awsconfig.bucketUrl + item?.Flyer?.fileName
                          : item?.OrganizationBackgroundImage
                          ? awsconfig.bucketUrl +
                            item?.OrganizationBackgroundImage?.fileName
                          : sample
                      }
                    />
                  }
                  onClick={(e) => handleAuctionsSelected(e, item)}
                >
                  <div className="t-flex t-justify-between t-items-center">
                    <h5 className="t-text-2xl t-text-secondary-100 t-font-bold t-truncate">
                      <Tooltip title={item?.Title}>{item?.Title}</Tooltip>
                    </h5>
                    <TbHeartMinus
                      fontSize="2rem"
                      className="t-cursor-pointer t-text-secondary-100"
                      onClick={(e) => hanleRemoveFavourite(e, item.favId)}
                    />
                  </div>

                  <div className="t-flex t-justify-between">
                    <div className="card-tag t-font-thin t-text-secondary-100">
                      {item.OrganizationName}
                    </div>
                  </div>
                  <p className="t-line-clamp-1 t-text-sm">{item.Details}</p>
                  <div className="t-flex t-justify-end t-mt-2 t-space-x-1 t-h-8">
                    {item?.OrganizationId === organization?.id && (
                      <Button
                        type="primary"
                        size="small"
                        className="t-rounded-full"
                        onClick={(e) => goToControlScreen(e, item)}
                      >
                        Control
                      </Button>
                    )}
                    {item.StartDate &&
                      compareDateTimeWithNow(item.StartDate, 'after') && (
                        <Button
                          type="primary"
                          size="small"
                          className="t-rounded-full"
                          onClick={(e) => goToJoinScreen(e, item)}
                        >
                          Join
                        </Button>
                      )}
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </InfiniteScroll>
      </div>
    </Skeleton>
  )
}

export { FavouriteList }
