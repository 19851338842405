import React, { useEffect, useRef, useState } from 'react'
import { Skeleton, message } from 'antd'
import { AiFillDownCircle, AiFillUpCircle } from 'react-icons/ai'
import { getDefaultPaymentAccounts } from 'store/authSlice/authActions'
import { config } from 'configs'
import { Confirmation } from './Confirmation'
import { InvalidURL } from './InvalidURL'
import { ComponentInformation } from './ComponentInformation'
// eslint-disable-next-line
import { RegisterationForm } from './RegisterationForm'

const Payment = ({ paymentType, item }) => {
  const [subscriberPaymentInfo, setSubscriberPaymentInfo] = useState({
    stripe: {
      isConnected: false,
      stripeId: null,
    },
    paypal: {
      isConnected: false,
      paypalId: null,
    },
    markup: 1,
  })
  const [isFetchingInformation, setFetchingInformation] = useState(true)
  const [isRegistered, setIsRegister] = useState(false)

  const isProgram = paymentType === 'program-payment'
  const isService = paymentType === 'service-payment'
  const isVolunteer = paymentType === 'volunteer-payment'
  const isRoom = paymentType === 'room-payment'

  const organizationId = item?.organization?.id

  const getData = async () => {
    try {
      setFetchingInformation(true)
      // get subscriber payment information
      const paymentInformation = await getDefaultPaymentAccounts(organizationId)
      setSubscriberPaymentInfo({
        Id: paymentInformation.defaultCategory?.Id,
        stripe: {
          isConnected:
            paymentInformation.defaultCategory?.stripeID &&
            paymentInformation.defaultCategory?.stripeOnBoarded,
          stripeId: paymentInformation.defaultCategory?.stripeID,
        },
        paypal: {
          isConnected: !!paymentInformation.defaultCategory?.paypalID,
          paypalId: paymentInformation.defaultCategory?.paypalID,
        },
        markup: Number.isNaN(Number(paymentInformation?.markup))
          ? 1
          : Number(paymentInformation?.markup),
      })
      setFetchingInformation(false)
    } catch (error) {
      message.error(error?.response?.data?.error || config.defaultErrorMessage)
      setFetchingInformation(false)
    }
  }

  useEffect(() => {
    if (item) {
      getData()
    }
    // eslint-disable-next-line
  }, [item])

  //  Scroll
  const scrollableDivRef = useRef(null)
  const [isAtTop, setIsTop] = useState(true)
  const [isAtEnd, setIsAtEnd] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (scrollableDivRef.current) {
        const { scrollHeight, scrollTop, clientHeight } =
          scrollableDivRef.current
        // To check if scroll is at end
        if (scrollHeight - scrollTop === clientHeight) {
          setIsAtEnd(true)
        } else {
          // To check if scroll is at top
          if (scrollTop === 0) {
            setIsTop(true)
          } else {
            setIsTop(false)
          }

          setIsAtEnd(false)
        }
      }
    }

    scrollableDivRef.current.addEventListener('scroll', handleScroll)
    return () => {
      if (scrollableDivRef && scrollableDivRef.current)
        // eslint-disable-next-line
        scrollableDivRef.current.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const isProgramMaxLimitReached =
    isProgram &&
    parseInt(item.registeredUser, 10) >=
      parseInt(item.MaximumNumberOfRegistrations, 10)

  const isVolunteerMaxLimitReached =
    isVolunteer &&
    parseInt(item.registeredUser, 10) >= parseInt(item.AllowedRegistration, 10)

  const isMaxLimitReached =
    isProgramMaxLimitReached || isVolunteerMaxLimitReached
  return (
    <div
      className="t-relative t-h-screen t-overflow-auto no-scrollbar t-p-2"
      ref={scrollableDivRef}
    >
      {!isFetchingInformation && !isAtTop && (
        <div className="t-fixed t-top-6 t-right-6 t-z-10">
          <AiFillUpCircle
            fontSize="1.5rem"
            className="t-text-secondary-100 t-cursor-pointer"
            onClick={() =>
              scrollableDivRef.current.scrollTo({
                top: 0,
                behavior: 'smooth',
              })
            }
          />
        </div>
      )}
      <Skeleton
        loading={isFetchingInformation}
        active
        paragraph={{ rows: 18 }}
        className="t-w-full t-h-full"
      >
        {item && (isProgram || isService || isVolunteer || isRoom) ? (
          <>
            {isRoom &&
            (item?.PaymentStatus === 'Pending' ||
              item?.PaymentStatus === 'Completed') ? (
              <InvalidURL
                title="Already Paid"
                decscription="This service request payment has been already made"
              />
            ) : isMaxLimitReached ? (
              <>
                <InvalidURL
                  title="Maximum Limit Reached"
                  decscription="The maximum limit of registration has been reached"
                />
              </>
            ) : (
              <>
                <>
                  {!isRegistered ? (
                    <>
                      <ComponentInformation item={item} />
                      <RegisterationForm
                        item={item}
                        itemType={paymentType}
                        subscriberPaymentInfo={subscriberPaymentInfo}
                        setIsRegister={setIsRegister}
                      />
                    </>
                  ) : (
                    <>
                      <Confirmation
                        profileWebsite={item?.profile?.OrganizationWebsite}
                        orgWebsite={item?.organization?.Website}
                        orgDomain={item?.organization?.OrgDomain}
                      />
                    </>
                  )}
                </>
                <div className="t-flex t-justify-end t-w-full t-text-xs t-italic t-text-gray-500 t-mt-12">
                  Powered by{' '}
                  <a
                    class="t-ml-1 font-bold t-text-[#14B1E0] t-cursor-pointer"
                    href="/"
                    target="_blank"
                  >
                    Extensia
                  </a>
                </div>
              </>
            )}
          </>
        ) : (
          <InvalidURL />
        )}
      </Skeleton>
      {!isFetchingInformation && !isAtEnd && (
        <div className="t-fixed t-bottom-6 t-right-6">
          <AiFillDownCircle
            fontSize="1.5rem"
            className="t-text-secondary-100 t-cursor-pointer"
            onClick={() =>
              scrollableDivRef.current.scrollTo({
                top: scrollableDivRef.current.scrollHeight,
                behavior: 'smooth',
              })
            }
          />
        </div>
      )}
    </div>
  )
}

export { Payment }
