import React, { useState } from 'react'
import { Card, Dropdown, Input, Menu, Tooltip } from 'antd'
import {
  BsBookmarkHeart,
  BsBookmarkHeartFill,
  BsFolderX,
  BsFolderFill,
} from 'react-icons/bs'
import { BiSort } from 'react-icons/bi'
import { SearchOutlined } from '@ant-design/icons'
import { MdDashboard } from 'react-icons/md'
import { RiDashboardLine } from 'react-icons/ri'
import { config } from 'configs'
import { useRoles } from 'hooks'
import { ComponentViewHeader } from 'components/common/componentView/components'
import {
  FavouriteList,
  InactiveList,
  AuctionList,
  MyAuction,
} from './components/AuctionLists'

const initialValue = {
  fav: false,
  inactive: false,
  my: false,
}

const Auction = () => {
  const { isSubscriber } = useRoles()
  const [isViews, setViews] = useState(initialValue)
  const changeView = (key, value) => setViews({ ...initialValue, [key]: value })

  const [searchText, setSearchText] = useState(null)
  const [sortKey, setSortKey] = useState('CreatedDate')

  const menu = (
    <Menu>
      <Menu.Item
        key={1}
        onClick={() => setSortKey('CreatedDate')}
        style={
          sortKey === 'CreatedDate' && {
            backgroundColor: config.colors.primary,
            color: 'white',
          }
        }
      >
        By Created Date
      </Menu.Item>
      <Menu.Item
        key={2}
        onClick={() => setSortKey('StartDate')}
        style={
          sortKey === 'StartDate' && {
            backgroundColor: config.colors.primary,
            color: 'white',
          }
        }
      >
        By Start Date
      </Menu.Item>
      {isViews.fav && (
        <Menu.Item
          key={3}
          onClick={() => setSortKey('favDate')}
          style={
            sortKey === 'favDate' && {
              backgroundColor: config.colors.primary,
              color: 'white',
            }
          }
        >
          By Favorite Date
        </Menu.Item>
      )}
    </Menu>
  )

  const getSubHeading = () => {
    if (isViews.fav) return 'Favorite Auctions'
    if (isViews.inactive) return 'Inactive Auctions'
    if (isViews.my) return 'My Auctions'
    return 'All Auctions'
  }

  return (
    <Card
      bordered={false}
      className="header-solid mb-24"
      title={
        <div className="t-flex t-flex-wrap t-justify-between">
          <ComponentViewHeader
            heading="Auction"
            subHeading={getSubHeading()}
            buttonLink="/settings/create-auction"
          />
          <div className="t-flex t-justify-end t-items-center t-space-x-2 t-mt-4 sm:t-mt-0">
            <div>
              <Dropdown overlay={menu} placement="bottomLeft" arrow>
                <Tooltip title="Sort Filter">
                  <BiSort
                    fontSize="1.7rem"
                    className="t-text-secondary-100 t-cursor-pointer"
                  />
                </Tooltip>
              </Dropdown>
            </div>
            <div className="header-control t-mt-0">
              <Input
                className="header-search t-w-36"
                placeholder="Search ..."
                prefix={<SearchOutlined />}
                onChange={(e) => {
                  setSearchText(e.target.value)
                }}
              />
            </div>

            {isSubscriber && (
              <>
                {isViews.my ? (
                  <Tooltip title="See All Auctions">
                    <MdDashboard
                      fontSize="1.7rem"
                      id="volunteers-my-clicked"
                      className="t-text-secondary-100 t-cursor-pointer"
                      onClick={() => changeView('my', false)}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="See My Auction">
                    <RiDashboardLine
                      fontSize="1.7rem"
                      id="volunteers-my-not-clicked"
                      className="t-text-secondary-100 t-cursor-pointer"
                      onClick={() => changeView('my', true)}
                    />
                  </Tooltip>
                )}
                {isViews.inactive ? (
                  <Tooltip title="See all Auction">
                    <BsFolderFill
                      fontSize="1.7rem"
                      id="volunteers-inactive-clicked"
                      className="t-text-secondary-100 t-cursor-pointer"
                      onClick={() => changeView('inactive', false)}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="See inactive Auction">
                    <BsFolderX
                      fontSize="1.7rem"
                      id="volunteers-inactive-not-clicked"
                      className="t-text-secondary-100 t-cursor-pointer"
                      onClick={() => changeView('inactive', true)}
                    />
                  </Tooltip>
                )}
              </>
            )}

            {isViews.fav ? (
              <Tooltip title="See all Auction">
                <BsBookmarkHeartFill
                  fontSize="1.7rem"
                  id="volunteers-favourite-clicked"
                  className="t-text-secondary-100 t-cursor-pointer"
                  onClick={() => changeView('fav', false)}
                />
              </Tooltip>
            ) : (
              <Tooltip title="See favorite Auction">
                <BsBookmarkHeart
                  fontSize="1.7rem"
                  id="volunteers-favourite-not-clicked"
                  className="t-text-secondary-100 t-cursor-pointer"
                  onClick={() => changeView('fav', true)}
                />
              </Tooltip>
            )}
          </div>
        </div>
      }
    >
      <div style={{ minHeight: 'calc(100vh - 205px)' }}>
        {!isViews.fav && !isViews.inactive && !isViews.my && (
          <AuctionList searchText={searchText} sortKey={sortKey} />
        )}
        {isViews.fav && (
          <FavouriteList searchText={searchText} sortKey={sortKey} />
        )}
        {isViews.inactive && (
          <InactiveList searchText={searchText} sortKey={sortKey} />
        )}
        {isViews.my && <MyAuction searchText={searchText} sortKey={sortKey} />}
      </div>
    </Card>
  )
}

export { Auction }
