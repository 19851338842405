/* eslint-disable */
import React from 'react'

const Availability = ({ available }) => (
  <div>
    {available ? (
      <div className="t-flex t-flex-col">
        <div className="t-space-x-2 t-flex t-items-center t-border-2 t-px-2 t-py-1 t-border-green-400 t-rounded-full">
          <div className="t-h-3 t-w-3 t-bg-green-400 t-rounded-full" />
          <h1 className="t-text-secondary-100">Open</h1>
        </div>
      </div>
    ) : (
      <div>
        <div className="t-space-x-2 t-flex t-items-center t-border-2 t-px-2 t-py-1 t-border-red-400 t-rounded-full">
          <div className="t-h-3 t-w-3 t-bg-red-700 t-rounded-full" />
          <h1 className="t-text-secondary-100">Closed</h1>
        </div>
      </div>
    )}
  </div>
)

export { Availability }
