import { Button, Col, Divider, Form, Input, message, Row, Select } from 'antd'
import React, { useState } from 'react'
import Api from 'api'
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'
import { eventValidation } from 'validations'
import MaskedInput from 'antd-mask-input'
import { MaskPattern } from 'utils'

const UpdatePresenter = ({ form, close }) => {
  const [updateform] = Form.useForm()
  const [presenterValidate, setPresenterValidate] = useState(false)
  const [presenterFound, setPresenterFound] = useState(false)

  const onFinish = (values) => {
    if (presenterValidate) {
      form.setFieldValue('PresenterPhoneCode', values.PresenterPhoneCode)
      form.setFieldValue('PresenterMobileNumber', values.PresenterMobileNumber)
      form.setFieldValue('PresenterName', values.PresenterName)
      form.setFieldValue('PresenterEmail', values.PresenterEmail)
      close()
    } else {
      message.error('Kindly Validate Presenter')
    }
  }

  const validatePresenter = () => {
    const { PresenterPhoneCode, PresenterMobileNumber } =
      updateform.getFieldsValue()
    // removing - from string
    const FormatedPresenterMobileNumber = PresenterMobileNumber?.replace(
      /\D/g,
      '',
    )
    // removing zero if it's the first charater in the string
    const trimPhoneNumber = FormatedPresenterMobileNumber?.replace(/^0+/, '')
    const phone = PresenterPhoneCode + trimPhoneNumber
    Api.get(
      `/users/check-phone-number-with-info?phone=${encodeURIComponent(phone)}`,
    )
      .then(() => {
        setPresenterValidate(true)
        setPresenterFound(false)
        updateform.setFieldValue('PresenterName', '')
        updateform.setFieldValue('PresenterEmail', '')
      })
      .catch((err) => {
        const success = err?.response?.data?.message
        const data = err?.response?.data?.values
        if (success === 'User Found') {
          updateform.setFieldValue('PresenterName', data.name)
          updateform.setFieldValue('PresenterEmail', data.email)
          setPresenterFound(true)
          setPresenterValidate(true)
        } else {
          message.error('Something Went Wrong')
        }
      })
  }

  const handleInputChange = (value) => {
    updateform.setFieldsValue({ PresenterMobileNumber: value })
  }

  return (
    <Form
      form={updateform}
      layout="vertical"
      key={0}
      name="update presenter"
      onFinish={onFinish}
    >
      <Row className="t-p-1">
        <Col span={24} className="t-flex t-justify-between t-items-center">
          <div className="t-flex t-font-bold">New Presenter Details</div>
          <Button type="primary" onClick={() => validatePresenter()}>
            Validate Presenter
          </Button>
        </Col>
        <Col>
          {!presenterValidate ? (
            <span className="font-bold t-flex t-space-x-2 t-items-center">
              <h1 className="t-text-orange-600 t-text-sm">
                Click to validate the below information
              </h1>
              <AiFillCloseCircle
                fontSize="1rem"
                className="t-text-orange-600"
              />
            </span>
          ) : presenterFound ? (
            <span className="font-bold t-flex t-space-x-2 t-items-center">
              <h1 className="t-font-bold t-text-sm">
                Presenter Found Successfully
              </h1>
              <AiFillCheckCircle fontSize="1rem" className="t-text-green-600" />
            </span>
          ) : (
            <span className="font-bold t-flex t-space-x-2 t-items-center">
              <h1 className="t-font-bold t-text-sm">
                Enter new presenter name and email address
              </h1>
              <AiFillCloseCircle
                fontSize="1rem"
                className="t-text-orange-600"
              />
            </span>
          )}
        </Col>
        <Divider />
        <Col span={24}>
          <Form.Item
            name="PresenterPhoneCode"
            label="Select Country"
            colon={false}
            rules={[eventValidation]}
          >
            <Select placeholder="Select Country Code" style={{ width: '100%' }}>
              <Select.Option value="+1">
                United States/Canada (+1)
              </Select.Option>
              <Select.Option value="+54">Argentina (+54)</Select.Option>
              <Select.Option value="+92">Pakistan (+92)</Select.Option>
              <Select.Option value="+55">Brazil (+55)</Select.Option>
              <Select.Option value="+58">
                Venezuela, Bolivarian Republic of Venezuela (+58)
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="PresenterMobileNumber"
            label="Presenter Mobile Number"
            colon={false}
            rules={[eventValidation]}
          >
            <MaskedInput
              inputMode="numeric"
              mask={MaskPattern}
              onChange={(e) => {
                handleInputChange(e.maskedValue)
              }}
            />
          </Form.Item>
        </Col>
        {presenterValidate === true && (
          <>
            <Col span={24}>
              <Form.Item
                name="PresenterName"
                label="Presenter Name"
                colon={false}
                rules={[eventValidation]}
              >
                <Input placeholder="Enter Name" disabled={presenterFound} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="PresenterEmail"
                label="Presenter Email"
                colon={false}
                rules={[eventValidation]}
              >
                <Input placeholder="Enter Email" disabled={presenterFound} />
              </Form.Item>
            </Col>
          </>
        )}
        <Divider />
        <Col span={24} className="text-right">
          <Button
            // loading={loading}
            type="primary"
            className="px-25"
            htmlType="submit"
          >
            Invite
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export { UpdatePresenter }
