import React from 'react'
import { Card, Typography, Col, Button } from 'antd'
import { useHistory } from 'react-router-dom'
import { SVGIcon } from 'components/elements'

const { Title } = Typography

const PageNotFound = () => {
  const history = useHistory()
  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <Col>
          <Title level={4}>Error 404</Title>
        </Col>,
      ]}
    >
      <div
        style={{ minHeight: 'calc(100vh - 195px)' }}
        className="t-flex t-items-center t-justify-center"
      >
        <div className="ant-result ant-result-404">
          <SVGIcon />
          <div className="ant-result-title">404</div>
          <div className="ant-result-subtitle">
            Sorry, the page you visited does not exist.
          </div>
          <div className="ant-result-extra">
            <Button
              onClick={() => history.push('/organizations')}
              type="primary"
              size="small"
            >
              Back Home
            </Button>
          </div>
        </div>
      </div>
    </Card>
  )
}

export { PageNotFound }
