/* eslint-disable */
import React, { useEffect, useReducer, useState } from 'react'
import { Route, Switch, useLocation, withRouter } from 'react-router'
import { Layout, Drawer, Affix, Card } from 'antd'
import { Navigation } from 'components/layouts/main/navigation'
import { HeaderTop } from 'components/layouts/main/header'
import { SVGIcon } from 'components/elements'
import { useHistory } from 'react-router-dom'
import { useZipCode } from 'hooks'
import { config } from 'configs'
import { useAuthSelector } from 'store/authSlice/authReducer'
import routes from 'routes/routes'
import { RoleAuthenticator } from 'hoc'

const { Header } = Layout
const { Sider } = Layout

const initialState = {
  visible: false,
  placement: 'right',
  currentComponent: '',
  sidebarColor: config?.colors?.primary,
  sidenavType: 'transparent',
  fixed: true,
  name: '',
  subName: '',
  breadcrumb: '',
  collapsed: false,
  activateMouseHover: false,
}

function reducer(state, action) {
  switch (action.type) {
    case 'visible':
      return { ...state, visible: action.payload }
    case 'placement':
      return { ...state, placement: action.payload }
    case 'currentComponent':
      return { ...state, currentComponent: action.payload }
    case 'sidebarColor':
      return { ...state, sidebarColor: action.payload }
    case 'fixed':
      return { ...state, fixed: action.payload }
    case 'name':
      return { ...state, name: action.payload }
    case 'subName':
      return { ...state, subName: action.payload }
    case 'breadcrumb':
      return { ...state, breadcrumb: action.payload }
    case 'collapsed':
      return { ...state, collapsed: action.payload }
    case 'activateMouseHover':
      return { ...state, activateMouseHover: action.payload }
    default:
      return state
  }
}

const Main = withRouter((props) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { user, location } = useAuthSelector()
  const history = useHistory()
  const { isLoading, isAcccessGranted, isSuccess, message } = useZipCode()
  const { search } = useLocation()
  const [hideSidebar, setHideSidebar] = useState(false)

  const isSubscriber = user?.roles.includes(config?.roles?.subscriber)
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(search)
    const { hide } = Object.fromEntries(urlSearchParams.entries())
    if (hide || !isSubscriber) {
      // check if url has param hide true then hide sidebar
      setHideSidebar(true)
    } else {
      // if there no param then set sidebar to default options
      if (hideSidebar) {
        dispatch({ type: 'collapsed', payload: initialState.collapsed })
      }
      setHideSidebar(false)
    }
    // eslint-disable-next-line
  }, [search, props.location.pathname])

  useEffect(() => {
    if (!user) {
      const urlSearchParams = new URLSearchParams(search)
      const { hide } = Object.fromEntries(urlSearchParams.entries())
      if (hide) {
        history.push(`/auth?url=${history.location.pathname + '?hide=true'}`)
      } else history.push(`/auth?url=${history.location.pathname}`)
    }
  }, [history, user])

  useEffect(() => {
    if (user) {
      if (props?.location?.pathname) {
        let page = props.location.pathname.split('/')
        page = page[page.length - 1]
        dispatch({ type: 'currentComponent', payload: page })
      }
    }
  }, [user, props.location.pathname])

  const handleSidebarColor = (color) => {
    dispatch({ type: 'sidebarColor', payload: color })
  }

  const OpenDrawer = () => {
    dispatch({ type: 'visible', payload: !state.visible })
  }

  const handleSidenavType = (type) => {
    dispatch({ type: 'sidenavType', payload: type })
  }

  const handleFixedNavbar = (type) => {
    dispatch({ type: 'fixed', payload: type })
  }

  const toggleCollasped = () => {
    // when hideSidar is true change desktop sidebar menu view
    const urlSearchParams = new URLSearchParams(search)
    const { hide } = Object.fromEntries(urlSearchParams.entries())
    if (hide) {
      setHideSidebar(!hideSidebar)
    } else {
      setHideSidebar(false)
      dispatch({ type: 'collapsed', payload: !state.collapsed })
    }
  }

  const menu = routes.map((route, index) => (
    <Route
      key={index}
      path={route.path}
      exact={route.exact}
      name={route.name}
      render={(props) => (
        <RoleAuthenticator roles={route.roles}>
          <route.component {...props} key={index} />
        </RoleAuthenticator>
      )}
    />
  ))

  return (
    <>
      {isLoading && isAcccessGranted && isSuccess && message && false ? (
        <div>false</div>
      ) : (
        <Layout
          className={`layout-dashboard ant-layout ant-layout-has-sider has-sidebar ${
            state.currentComponent === 'all-projects' ||
            state.currentComponent === 'profile-overview'
              ? 'layout-profile'
              : ''
          } ${state.collapsed ? ' sidebar-minimized' : ''}

         ${state.currentComponent === 'new-product' ? 'layout-profile' : ''}
        ${state.currentComponent === 'rtl' ? 'layout-dashboard-rtl' : ''}
        `.trim()}
        >
          <Drawer
            title={false}
            placement={state.placement === 'right' ? 'left' : 'right'}
            closable={false}
            onClose={() =>
              dispatch({ type: 'visible', payload: !state.visible })
            }
            visible={state.visible}
            key={state.placement === 'right' ? 'left' : 'right'}
            width={250}
            className={`drawer-sidebar ${
              state.currentComponent === 'rtl' ? 'drawer-sidebar-rtl' : ''
            } `}
          >
            <Layout
              className={`layout-dashboard ${
                state.currentComponent === 'rtl' ? 'layout-dashboard-rtl' : ''
              }`}
            >
              <Sider
                trigger={null}
                width={250}
                theme="light"
                className={`sider-primary ant-layout-sider-primary ${
                  state.sidenavType === '#fff' ? 'active-route' : ''
                }`}
                style={{
                  background: state.sidenavType,
                }}
              >
                <Navigation
                  onClose={() => dispatch({ type: 'visible', payload: false })}
                  currentSelected={state.currentComponent}
                  handleSidebarColor={handleSidebarColor}
                  collapsed={state.collapsed}
                />
              </Sider>
            </Layout>
          </Drawer>
          {!hideSidebar && (
            <Sider
              breakpoint="lg"
              collapsedWidth="0"
              trigger={null}
              width={250}
              theme="light"
              className={`sider-primary ant-layout-sider-primary ${
                state.sidenavType === '#fff' ? 'active-route' : ''
              }`}
              style={{ background: state.sidenavType }}
            >
              <Navigation
                currentSelected={state.currentComponent}
                handleSidebarColor={handleSidebarColor}
                collapsed={state.collapsed}
              />
            </Sider>
          )}
          <Layout id="temp__div" className={`t-p-0 ${hideSidebar && 't-ml-0'}`}>
            {state.fixed ? (
              <Affix>
                <Header
                  style={{ margin: '0px', backgroundColor: '#fff' }}
                  className={`${state.fixed ? 'ant-header-fixed' : ''}`}
                >
                  <HeaderTop
                    onPress={OpenDrawer}
                    breadcrumb={state.breadcrumb}
                    name={state.name}
                    subname={state.subName}
                    handleSidebarColor={handleSidebarColor}
                    handleSidenavType={handleSidenavType}
                    handleFixedNavbar={handleFixedNavbar}
                    toggleCollasped={toggleCollasped}
                  />
                </Header>
              </Affix>
            ) : (
              <Header
                style={{
                  margin: '0px',
                  backgroundColor: '#FAFAFA',
                  boxShadow: '0px 2px 3px #0000001f',
                }}
                className={`${state.fixed ? 'ant-header-fixed' : ''}`}
              >
                <HeaderTop
                  breadcrumb={state.breadcrumb}
                  onPress={OpenDrawer}
                  placement={state.placement}
                  name={state.name}
                  subname={state.subName}
                  handleSidebarColor={handleSidebarColor}
                  handleSidenavType={handleSidenavType}
                  handleFixedNavbar={handleFixedNavbar}
                  toggleCollasped={toggleCollasped}
                />
              </Header>
            )}
            {/* <Content className="t-mt-3 md:t-mt-3 md:t-p-2">
              <Switch>{menu}</Switch>
            </Content> */}
            <div className="t-mt-0 t-px-0 md:t-mt-4 md:t-px-4">
              {isAcccessGranted || location?.zipcode ? (
                <Switch>{menu}</Switch>
              ) : (
                <Card className="header-solid" bordered={false}>
                  <div
                    style={{ minHeight: 'calc(100vh - 125px)' }}
                    className="t-flex t-items-center t-justify-center"
                  >
                    <div className="ant-result ant-result-404">
                      <SVGIcon />
                      <div className="ant-result-title">
                        {isLoading
                          ? 'Accessing Location ...'
                          : 'Location Access Denied'}
                      </div>
                      {!isLoading && (
                        <div className="ant-result-subtitle">
                          Please type in your zip code for optimal experience.
                          Your system is preventing us to auto detect your
                          location.
                        </div>
                      )}
                    </div>
                  </div>
                </Card>
              )}
            </div>
          </Layout>
        </Layout>
      )}
    </>
  )
})
export { Main }
