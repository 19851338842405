import React, { useState } from 'react'
import { ComponentView } from 'components/common/componentView'
import {
  FavouriteList,
  InactiveList,
  VolunteerList,
  MyVolunteers,
} from './components/VolunteerLists'

const Volunteer = () => {
  const [searchText, setSearchText] = useState(null)
  const [sortSwitchValue, setSortSwitchValue] = useState(false)
  const [sortKey, setSortKey] = useState('createdDate')

  const heading = 'Volunteer Opp'
  const optionList = {
    all: <VolunteerList searchText={searchText} sortKey={sortKey} />,
    my: <MyVolunteers searchText={searchText} sortKey={sortKey} />,
    inactive: <InactiveList searchText={searchText} sortKey={sortKey} />,
    favorite: <FavouriteList searchText={searchText} sortKey={sortKey} />,
  }
  const createButtonLink = '/settings/create-volunteer'

  const sortFilterData = [
    {
      label: 'By Created Date',
      value: 'createdDate',
      showOn: ['all', 'my', 'inactive', 'presenter', 'favorite'],
    },
    {
      label: 'By Schedule Date',
      value: 'Schedule',
      showOn: ['all', 'my', 'inactive', 'presenter', 'favorite'],
    },
    {
      label: 'By Favorite Date',
      value: 'favDate',
      showOn: ['favorite'],
    },
  ]

  return (
    <ComponentView
      heading={heading}
      optionList={optionList}
      createButtonLink={createButtonLink}
      setSearchText={setSearchText}
      sortFilterData={sortFilterData}
      sortKey={sortKey}
      setSortKey={setSortKey}
      sortSwitchValue={sortSwitchValue}
      setSortSwitchValue={setSortSwitchValue}
    />
  )
}

export { Volunteer }
