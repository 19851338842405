import { Upload, message } from 'antd'
import ImgCrop from 'antd-img-crop'
import { awsconfig, config } from 'configs'
import React, { useEffect, useState } from 'react'
import { imageSizeValidation } from 'utils'

const MultiImageUploader = ({
  maxAllowed = 10,
  defaultFiles,
  onFileChange,
  ratio = 1 / 1,
}) => {
  const [fileList, setFileList] = useState([])

  useEffect(() => {
    if (defaultFiles && defaultFiles.length > 0) {
      const mappedFiles = defaultFiles
        .filter((defaultFile) => defaultFile?.fileName)
        .map((file, index) => ({
          uid: `${index}`,
          name: file?.fileName || 'default.jpg',
          status: 'done',
          url: `${awsconfig.bucketUrl}${file?.fileName}`,
          type: file?.fileMimeType || 'image/jpeg',
        }))
      setFileList(mappedFiles)
    }
    // eslint-disable-next-line
  }, [])

  const onChange = ({ fileList: newFileList }) => {
    const recentFile =
      newFileList.length > 0 ? newFileList[newFileList.length - 1] : null
    // Adding Size Limitation
    if (recentFile && recentFile?.size) {
      const isValidFileSize = imageSizeValidation(recentFile)
      if (!isValidFileSize.isValid) {
        message.destroy()
        message.error(
          config.imageMaxSizeLimitErrorMessage ||
            'Image Size limit is not Valid',
        )
        return false
      }
    }

    setFileList(newFileList)
    const originFileObjList = newFileList.map((file) =>
      file?.originFileObj ? file.originFileObj : file,
    )
    onFileChange(originFileObjList.filter((file) => file !== null))
    return true
  }

  const onPreview = async (file) => {
    let src = file.url
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj)
        reader.onload = () => resolve(reader.result)
      })
    }
    const image = new Image()
    image.src = src
    const imgWindow = window.open(src)
    imgWindow?.document.write(image.outerHTML)
  }

  return (
    <>
      <ImgCrop rotate quality={1} aspect={ratio}>
        <Upload
          listType="picture-card"
          fileList={fileList}
          onChange={onChange}
          onPreview={onPreview}
          maxCount={maxAllowed}
          accept="image/*"
          // beforeUpload={handleBeforeUpload}
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        >
          {fileList.length >= maxAllowed ? null : '+ Upload'}
        </Upload>
      </ImgCrop>
    </>
  )
}
export { MultiImageUploader }
