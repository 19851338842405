import React, { useEffect, useState } from 'react'
import {
  Skeleton,
  Button,
  Card,
  Col,
  Row,
  Tooltip,
  Checkbox,
  message,
  Affix,
  Badge,
} from 'antd'
import { searchFundraisersRows } from 'utils'
import { awsconfig } from 'configs'
import { useDispatch } from 'react-redux'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useHistory } from 'react-router-dom'
import { CarryOutFilled } from '@ant-design/icons'
import sample from 'assets/images/sample/sample1.png'
import { downloadQRCodePDF } from 'store/authSlice/authActions'
import {
  fundraiserActions,
  useFundraiserSelector,
} from 'store/fundraiserSlice/fundraiserReducer'
import { getOrganizationEvents } from 'store/fundraiserSlice/fundraiserActions'
// import { convertFile } from 'store/reportSlice/reportActions'

const MyFundraiser = ({ searchText }) => {
  const { loading, fundraisers } = useFundraiserSelector()
  const { organization, user } = useAuthSelector()
  const dispatch = useDispatch()
  const history = useHistory()

  const [selectedIds, setSelectedIds] = useState([])

  useEffect(() => {
    dispatch(getOrganizationEvents(organization.id))
    // eslint-disable-next-line
  }, [organization])

  const handleEventSelected = (e, item) => {
    e.preventDefault()
    e.stopPropagation()
    if (item.type === 'ClickTap') {
      history.push(`settings/create-tap-fundraiser/${item.iD}`)
    } else {
      dispatch(fundraiserActions.setJoinedFundraiserDetails(null))
      dispatch(fundraiserActions.setFundraiser(null))
      history.push(`fundraisers/${item.iD}`)
    }
  }

  const goToJoinScreen = (e, item) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(fundraiserActions.setJoinedFundraiserDetails(null))
    dispatch(fundraiserActions.setFundraiser(null))
    history.push(`/fundraisers/${item?.iD}/join-event`)
  }

  const goToControlScreen = (e, item) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(fundraiserActions.setJoinedFundraiserDetails(null))
    dispatch(fundraiserActions.setFundraiser(item))
    history.push(`/fundraisers/${item?.iD}/control-screen`)
  }

  const goToEdit = (e, item) => {
    e.preventDefault()
    e.stopPropagation()
    if (item.type === 'ClickTap') {
      history.push(`/settings/create-tap-fundraiser/${item?.iD}`)
    } else {
      history.push(`/settings/create-fundraiser/${item?.iD}`)
    }
  }

  const onCheckboxChange = (fundraiserId) => {
    const isAlreadyPresent = selectedIds.find((id) => id === fundraiserId)
    if (isAlreadyPresent) {
      const updatedIds = selectedIds.filter((id) => id !== fundraiserId)
      setSelectedIds(updatedIds)
    } else {
      setSelectedIds([...selectedIds, fundraiserId])
    }
  }

  const type = 'fundraiser'
  const onPrintClick = async () => {
    message.loading('Generating QR code')
    const fundraisersToPrint = selectedIds.map((item) => ({
      id: item,
      name: fundraisers.find((element) => element?.iD === item)?.title,
      description: fundraisers.find((element) => element?.iD === item)
        ?.description,
      url: `${window.location.origin}/donation/${item}`,
    }))
    const payload = {
      data: fundraisersToPrint,
      component: 'Fundraisers',
      Organization: organization.name,
      logo: awsconfig.bucketUrl + organization.Logo?.fileName,
    }
    try {
      await downloadQRCodePDF(payload, dispatch)
      message.success('QR code generated!')
      // history.push(`/download/${type}`)
      window.open(`${window.location.origin}/download/${type}`, '_blank')
    } catch (error) {
      console.error('Error generating QR code:', error)
      message.error('Failed to generate QR code. Please try again.')
    }
  }

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
      <div className="t-flex t-flex-col t-justify-between t-h-full">
        {selectedIds.length > 0 && (
          <Row className="t-w-full t-flex t-justify-end t-p-0 md:t-p-1 -t-mt-6 md:-t-mt-8 t-mb-2 md:t-mb-2">
            <Affix offsetTop={75}>
              <Button
                type="primary"
                shape="round"
                onClick={onPrintClick}
                size="medium"
                className="glass_bg t-text-secondary-100 hover:t-text-white"
              >
                Print QR Codes
              </Button>
            </Affix>
          </Row>
        )}

        <Row gutter={[24, 24]} className="t-h-full">
          {searchFundraisersRows(fundraisers, searchText).length === 0 && (
            <div className="t-h-96 t-flex t-justify-center t-items-center t-w-full">
              <div className="t-flex t-flex-col t-items-center t-space-y-2">
                <CarryOutFilled
                  style={{ fontSize: '4rem' }}
                  className="t-text-secondary-100"
                />
                <p>No records found</p>
              </div>
            </div>
          )}

          {searchFundraisersRows(fundraisers, searchText).map((item, index) => (
            <Col span={24} md={8} xl={8} key={index}>
              <Badge.Ribbon
                color={item.type === 'ClickTap' ? 'green' : 'blue'}
                text={item.type}
              >
                <Card
                  bordered
                  hoverable
                  cover={
                    <img
                      alt="example"
                      className="t-w-full t-h-48 t-object-cover t-rounded-md"
                      src={
                        item?.flyer?.length
                          ? awsconfig.bucketUrl + item?.flyer[0]?.fileName
                          : item?.flyer?.fileName
                          ? awsconfig.bucketUrl + item?.flyer?.fileName
                          : item?.OrganizationBackgroundImage
                          ? awsconfig.bucketUrl +
                            item?.OrganizationBackgroundImage?.fileName
                          : sample
                      }
                    />
                  }
                  onClick={(e) => handleEventSelected(e, item)}
                >
                  <h5 className="t-text-2xl t-text-secondary-100 t-font-bold t-truncate">
                    <Tooltip title={item?.title}>{item?.title}</Tooltip>
                  </h5>
                  <div className="t-flex t-justify-between">
                    <div className="card-tag t-font-thin t-text-secondary-100">
                      {item?.organizationName}
                    </div>
                  </div>
                  <p className="t-line-clamp-1 t-text-sm">
                    {item?.description}
                  </p>
                  <div className="t-mt-2 t-space-x-1 t-h-8">
                    <div
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        onCheckboxChange(item?.iD)
                      }}
                    >
                      <Checkbox
                        checked={selectedIds.includes(item?.iD)}
                        className="t-font-bold t-text-secondary-100"
                      >
                        Add to QR code sheet
                      </Checkbox>
                    </div>
                  </div>
                  <div className="t-flex t-justify-end t-space-x-1">
                    <Button
                      type="primary"
                      size="small"
                      className="t-rounded-full"
                      onClick={(e) => goToEdit(e, item)}
                    >
                      Edit
                    </Button>
                    {(item?.organizationId === organization?.id ||
                      (item?.presenterEmail === user?.email &&
                        item?.acceptanceStatus === 'Accepted')) &&
                      item.type === 'Fundraiser' && (
                        <Button
                          type="primary"
                          size="small"
                          className="t-rounded-full"
                          onClick={(e) => goToControlScreen(e, item)}
                        >
                          Control
                        </Button>
                      )}
                    {item.startEvent && (
                      <Button
                        type="primary"
                        size="small"
                        className="t-rounded-full"
                        onClick={(e) => goToJoinScreen(e, item)}
                      >
                        Join
                      </Button>
                    )}
                  </div>
                </Card>
              </Badge.Ribbon>
            </Col>
          ))}
        </Row>
      </div>
    </Skeleton>
  )
}

export { MyFundraiser }
