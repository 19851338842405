/* eslint-disable */
import React, { useState } from 'react'
import { Modal, Skeleton } from 'antd'
import { formatAmountWithOutDecimal } from 'utils'
import { makeRequest } from 'api'
import { useAuthSelector } from 'store/authSlice/authReducer'

const Stepper = ({ donation, index, netGross }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modelData, setModalData] = useState({})

  // const filterPendingData = () =>{
  //   const paymentComplete = donation.filter((x)=>x.status === "Completed")
  // return paymentComplete
  // }
  const donorDetais = (data) => {
    setIsModalVisible(!isModalVisible)
    setModalData(data)
  }

  const option = {
    // You can Just customize this according to your need
    subTitle: donation.DonorName,
    date: donation.PaymentDate,
  }

  //  Formating the Date
  function formatDateTime(dateString) {
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    }

    const formattedDateTime = new Date(dateString).toLocaleString(
      'en-US',
      options,
    )
    return formattedDateTime
  }

  return (
    <>
      <div key={index} className="t-flex t-flex-row t-w-full">
        <div className="t-w-[6%]">
          <div
            className="t-h-[1.5rem] t-w-6 t-border-2 t-bg-cyan-500 t-border-cyan-500 t-mx-[.8rem] t-mt-[1px] t-z-10"
            style={{ borderRadius: '50%' }}
          ></div>
          <div className="t-ml-[1.4rem] t-mr-[1.2rem] t-h-[80%] md:t-h-[72%] t-w-[.2rem] t-bg-gray-300"></div>
        </div>
        <div className="t-flex t-flex-row t-w-[94%] t-ml-[6px]">
          <div className="t-w-[60%] t-pb-4 -t-mt-[2px]">
            <h1 className="sm:t-mx-4 t-mx-[1.2rem] t-text-base">
              <strong>
                {netGross
                  ? formatAmountWithOutDecimal(donation.NetAmount)
                  : formatAmountWithOutDecimal(donation.Amount)}
              </strong>{' '}
              - {option.subTitle}
            </h1>
            <h2 className="sm:t-mx-4 t-mx-[1.2rem] t-text-base">
              {formatDateTime(option.date)}
            </h2>
          </div>
          <div className="t-flex t-justify-center t-items-center t-w-[40%] t-px-4 ">
            <button
              onClick={() => donorDetais(donation)}
              className="t-bg-white t-border t-border-gray-500 t-px-6 t-py-1 t-shadow-sm t-shadow-gray-300 t-text-black t-text-sm t-mb-3 t-rounded-md hover:t-border-cyan-500 hover:t-text-cyan-500"
            >
              View Details
            </button>
          </div>
        </div>
      </div>
      <Modal
        title="Donor Details"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <div>
          <h1>
            Date: <strong>{formatDateTime(modelData.PaymentDate)}</strong>
          </h1>
          {/* <h1>
            Event Title: <strong>{modelData.EventTitle}</strong>
          </h1> */}
          <h1>
            Full Name: <strong>{modelData.DonorName}</strong>
          </h1>
          <h1>
            Donation Received:{' '}
            <strong>
              {netGross
                ? formatAmountWithOutDecimal(donation.NetAmount)
                : formatAmountWithOutDecimal(donation.Amount)}
            </strong>
          </h1>
          <h1>
            Payment Method: <strong>{modelData.PaymentMethod}</strong>
          </h1>
          <h1>
            Payment Reference No: <strong>{modelData.PaymentNumber}</strong>
          </h1>
        </div>
      </Modal>
    </>
  )
}

export default Stepper

// Second Strepper for Monthly Earning
const Stepper1 = ({ item, index, netGross }) => {
  const [isModal, setIsModal] = useState(false)
  const [earningDetail, setEarningDetails] = useState({})
  const earningDetails = (item) => {
    setIsModal(!isModal)
    setEarningDetails(item)
  }

  const option = {
    // You can Just customize this according to your need
    title: item.Amount,
    subTitle: item.UserName,
    date: item.PaymentDate,
    netAmount: item.NetAmount,
    grossAmount: item.GrossAmount,
  }

  //  Formating the Date
  function formatDateTime(dateString) {
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    }

    const formattedDateTime = new Date(dateString).toLocaleString(
      'en-US',
      options,
    )
    return formattedDateTime
  }

  return (
    <>
      <div key={index} className="t-flex t-flex-row t-w-full">
        <div className="t-w-[6%]">
          <div
            className="t-h-[1.5rem] t-w-6 t-border-2 t-bg-cyan-500 t-border-cyan-500 t-mx-[.8rem] t-mt-[1px] t-z-10"
            style={{ borderRadius: '50%' }}
          ></div>
          <div className="t-ml-[1.4rem] t-mr-[1.2rem] t-h-[80%] md:t-h-[72%] t-w-[.2rem] t-bg-gray-300"></div>
        </div>
        <div className="t-flex t-flex-row t-w-[94%] t-ml-[6px]">
          <div className="t-w-[60%] t-pb-4 -t-mt-[2px]">
            <h1 className="sm:t-mx-4 t-mx-[1.2rem] t-text-base">
              <strong>
                {netGross
                  ? formatAmountWithOutDecimal(option.netAmount)
                  : formatAmountWithOutDecimal(option.grossAmount)}
              </strong>{' '}
              - {option.subTitle}
            </h1>
            <h2 className="sm:t-mx-4 t-mx-[1.2rem] t-text-base">
              {formatDateTime(option.date)}
            </h2>
          </div>
          <div className="t-flex t-justify-center t-items-center t-w-[40%] t-px-4  ">
            <button
              onClick={() => earningDetails(item)}
              className="t-bg-white t-border t-border-gray-500 t-px-6 t-py-1 t-shadow-sm t-shadow-gray-300 t-text-black t-text-sm t-mb-3 t-rounded-md hover:t-border-cyan-500 hover:t-text-cyan-500"
            >
              View Details
            </button>
          </div>
        </div>
      </div>
      <Modal
        title="View Details"
        open={isModal}
        onCancel={() => setIsModal(false)}
        footer={null}
      >
        <div>
          <h1>
            Event Title: <strong>{earningDetail.Title}</strong>
          </h1>
          <h1>
            Full Name: <strong>{earningDetail.UserName}</strong>
          </h1>
          <h1>
            Amount:{' '}
            <strong>
              {netGross
                ? formatAmountWithOutDecimal(earningDetail.NetAmount)
                : formatAmountWithOutDecimal(earningDetail.GrossAmount)}
            </strong>
          </h1>
          <h1>
            Payment Method: <strong>{earningDetail.PaymentMethod}</strong>
          </h1>
          <h1>
            Payment Reference No:{' '}
            <strong>{earningDetail.PaymentReferenceNumber}</strong>
          </h1>
          <h1>
            Payment Date:{' '}
            <strong>{formatDateTime(earningDetail.PaymentDate)}</strong>
          </h1>
        </div>
      </Modal>
    </>
  )
}
export { Stepper1 }

const Stepper2 = ({ item, netGross }) => {
  const [isModal, setIsModal] = useState(false)
  const [memberDetails, setMemberDetails] = useState({})
  const [loading, setLoading] = useState(true)
  const [YTDAmount, setYTDAmount] = useState({})
  const { organization } = useAuthSelector()

  const memberDetail = (item) => {
    setIsModal(!isModal)
    setMemberDetails(item)
    getYTDAmount(item.DonorPhoneNumber)
  }
  function formatDateTime(dateString) {
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    }

    const formattedDateTime = new Date(dateString).toLocaleString(
      'en-US',
      options,
    )
    return formattedDateTime
  }
  const option = {
    // You can Just customize this according to your need
    title: item.OrganizationName,
    date: item?.CreatedAt ? formatDateTime(item?.CreatedAt) : '',
  }

  // API call for YTD amount
  const getYTDAmount = async (phone) => {
    setLoading(true)
    makeRequest(
      'get',
      `subscriber-home/community-member-donation/${phone}?type=ytd`,
      {
        organization: organization.id,
      },
    )
      .then((res) => {
        setYTDAmount(res.results)
        setLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        setLoading(false)
      })
  }

  return (
    <>
      <div key={item.Id} className="t-flex t-flex-row t-w-full">
        <div className="t-w-[6%]">
          <div
            className="t-h-[1.5rem] t-w-6 t-border-2 t-bg-cyan-500 t-border-cyan-500 t-mx-[.8rem] t-mt-[1px] t-z-10"
            style={{ borderRadius: '50%' }}
          ></div>
          <div className="t-ml-[1.4rem] t-mr-[1.2rem] t-h-[80%] md:t-h-[72%] t-w-[.2rem] t-bg-gray-300"></div>
        </div>
        <div className="t-flex t-flex-row t-w-[94%] t-ml-[6px]">
          <div className="t-w-[60%] t-pb-4 -t-mt-[2px]">
            <h1 className="sm:t-mx-4 t-mx-[1.2rem] t-text-base">
              <strong>{option.title}</strong>
            </h1>
            <h2 className="sm:t-mx-4 t-mx-[1.2rem] t-text-base">
              {option.date}
            </h2>
          </div>

          <div className="t-flex t-justify-center t-items-center t-w-[40%] t-px-4 ">
            <button
              onClick={() => memberDetail(item)}
              className="t-bg-white t-border t-border-gray-500 t-px-6 t-py-1 t-shadow-sm t-shadow-gray-300 t-text-black t-text-sm t-mb-3 t-rounded-md hover:t-border-cyan-500 hover:t-text-cyan-500"
            >
              View Details
            </button>
          </div>
        </div>
      </div>
      <Modal
        title="View Member Details"
        open={isModal}
        onCancel={() => setIsModal(false)}
        footer={null}
      >
        <Skeleton loading={loading} active paragraph={{ rows: 7 }}>
          <div>
            <h1 className="t-py-[1px]">
              Full Name: <strong>{memberDetails.DonorName}</strong>
            </h1>
            <h1 className="t-py-[1px]">
              Email: <strong>{memberDetails.DonorEmail}</strong>
            </h1>
            <h1 className="t-py-[1px]">
              Mobile No: <strong>{memberDetails.DonorPhoneNumber}</strong>
            </h1>
            <h1 className="t-py-[1px]">
              Amount Donated:{' '}
              <strong>
                {formatAmountWithOutDecimal(
                  netGross
                    ? YTDAmount.totalNetAmount
                    : YTDAmount?.totalDonatedAmount,
                )}
              </strong>{' '}
            </h1>
          </div>
        </Skeleton>
      </Modal>
    </>
  )
}
export { Stepper2 }
