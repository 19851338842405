import React, { useEffect, useState } from 'react'
import {
  Card,
  Col,
  Tag,
  Skeleton,
  Switch,
  Button,
  Popconfirm,
  message,
} from 'antd'
import { MdHomeWork } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import {
  approveRefund,
  getOrganizationReservation,
} from 'store/roomSlice/roomActions'
import { useRoomSelector } from 'store/roomSlice/roomReducer'
import { useAuthSelector } from 'store/authSlice/authReducer'
import moment from 'moment'
import { formatAmount } from 'utils'
import { AiFillEye } from 'react-icons/ai'
import { ReservationCalendar } from './ReservationCalender'
import { ConfirmationModal } from './ConfirmationModal'
import { ViewReservation } from './ViewReservation'

const MyReservations = () => {
  const dispatch = useDispatch()
  const { organization } = useAuthSelector()
  const { loading, reservations } = useRoomSelector()

  const [calendarView, setCalendar] = useState(false)
  const [visible, setVisible] = useState(false)
  const [viewModal, setViewModal] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [selectedReservation, setSelectedReservation] = useState(null)

  useEffect(() => {
    refreshData()
    // eslint-disable-next-line
  }, [])

  const refreshData = () =>
    dispatch(getOrganizationReservation(organization?.id))

  const onTakeActionClick = (reservationId) => {
    const reservation = reservations.find((item) => item?.Id === reservationId)
    setSelectedReservation(reservation)
    setVisible(!visible)
  }

  const onViewReservation = (reservationId) => {
    const reservation = reservations.find((item) => item?.Id === reservationId)
    setSelectedReservation(reservation)
    setViewModal(!viewModal)
  }

  const onConfirmRefund = async (reservationId) => {
    setLoading(true)
    try {
      await approveRefund(reservationId)
      message.success('Refund request approved')
      dispatch(getOrganizationReservation(organization?.id))
    } catch (error) {
      message.error('Error sending refund request')
    }
    setLoading(true)
  }

  return (
    <Card
      title={[
        <div>
          <h4 className="mb-0">My Reservations</h4>
          <p className="font-regular">Manage Reservations</p>
        </div>,
      ]}
      className="t-mb-5"
    >
      <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
        <div className="t-w-full t-flex t-justify-end t-mb-4 t-space-x-2 t-items-center">
          <h1 className="t-font-bold t-text-secondary-100">Calender View</h1>
          <Switch
            title="View As Admin"
            checked={calendarView}
            defaultChecked={false}
            onChange={(checked) => setCalendar(checked)}
          />
        </div>
        {!calendarView ? (
          <Col span={24}>
            {reservations.length === 0 && (
              <Card className="t-h-96 t-w-full t-flex t-justify-center t-items-center">
                <div>No Reservations</div>
              </Card>
            )}
            {reservations.map((item, index) => {
              let scheduleDate = moment(item?.Date)
              scheduleDate = moment([
                scheduleDate.year(),
                scheduleDate.month(),
                scheduleDate.date(),
              ])
              let currentDate = moment()
              currentDate = moment([
                currentDate.year(),
                currentDate.month(),
                currentDate.date(),
              ])
              const days = scheduleDate.diff(currentDate, 'days')
              const isActionableDatePassed = days > 0
              const isRequestPending = item?.AcceptanceStatus === 'Pending'
              const isRequestRejected = item?.AcceptanceStatus === 'Rejected'
              const isRequestAccepted = item?.AcceptanceStatus === 'Accepted'
              const isChangeRequestPending =
                item?.AcceptanceStatus === 'ChangeRequested'
              const isRefundRequestPending =
                item?.RefundRequestStatus === 'Pending'
              const isRefundRequestApproved =
                item?.RefundRequestStatus === 'Approved'
              const isPaid = item?.PaymentStatus === 'Completed'
              const isPaymentProcessing = item?.PaymentStatus === 'Processing'
              return (
                <div
                  key={index}
                  className="t-flex t-justify-between t-items-center t-my-2 t-border t-rounded-md t-p-3 t-shadow"
                >
                  <div className="t-flex t-flex-col sm:t-flex-row t-space-x-4 t-items-center t-space-y-4 md:t-space-y-0">
                    <MdHomeWork
                      fontSize="2rem"
                      className="t-text-secondary-100"
                    />
                    <div className="t-flex t-flex-col t-justify-center">
                      <h1 className="t-text-secondary-100 t-font-bold">{`${
                        item?.RoomName
                      } (${
                        item?.UpdatedCost
                          ? formatAmount(item?.UpdatedCost)
                          : formatAmount(item?.TotalCost)
                      })`}</h1>
                      <h1>{`Reservation by ${item?.CreatorName}`}</h1>
                    </div>
                  </div>
                  <div className="t-flex t-space-x-1 t-items-center">
                    {isRequestAccepted && !isPaid && !isPaymentProcessing && (
                      <Tag color="green" className="font-bold">
                        Accepted
                      </Tag>
                    )}
                    {isRequestRejected && (
                      <Tag color="red" className="font-bold">
                        Rejected
                      </Tag>
                    )}
                    {isRequestPending && isActionableDatePassed && (
                      <Button
                        onClick={() => onTakeActionClick(item?.Id)}
                        type="primary"
                        size="small"
                      >
                        Take Action
                      </Button>
                    )}
                    {isPaid &&
                      !isRefundRequestPending &&
                      !isRefundRequestApproved && (
                        <Tag color="green" className="font-bold">
                          Paid
                        </Tag>
                      )}
                    {isRefundRequestPending && (
                      <Popconfirm
                        title="Do you want to accept the refund request？"
                        okText="Refund"
                        cancelText="No"
                        placement="left"
                        loading={isLoading}
                        onConfirm={() => onConfirmRefund(item?.Id)}
                      >
                        <Button type="primary" size="small">
                          Approve Refund Request
                        </Button>
                      </Popconfirm>
                    )}
                    {isRefundRequestApproved && (
                      <Tag color="blue" className="font-bold">
                        Refund Approved
                      </Tag>
                    )}
                    {isChangeRequestPending && (
                      <Tag color="purple" className="font-bold">
                        Change request sent
                      </Tag>
                    )}
                    {isPaymentProcessing && (
                      <Tag color="gray" className="font-bold">
                        Payment Processing
                      </Tag>
                    )}
                    <AiFillEye
                      fontSize="1.5rem"
                      className="t-text-secondary-100 t-cursor-pointer"
                      onClick={() => onViewReservation(item?.Id)}
                    />
                  </div>
                </div>
              )
            })}
          </Col>
        ) : (
          <ReservationCalendar />
        )}
      </Skeleton>
      <ConfirmationModal
        visible={visible}
        reservation={selectedReservation}
        refresh={refreshData}
        setVisible={() => setVisible(!visible)}
      />
      <ViewReservation
        visible={viewModal}
        reservation={selectedReservation}
        setVisible={() => setViewModal(!viewModal)}
      />
    </Card>
  )
}

export { MyReservations }
