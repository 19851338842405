import React, { useEffect, useState } from 'react'
import {
  Input,
  Form,
  Row,
  Col,
  Select,
  Card,
  Button,
  Radio,
  message,
} from 'antd'
import * as Yup from 'yup'
import MaskedInput from 'antd-mask-input'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useDispatch } from 'react-redux'
import { AutoCompleteAddress } from 'components/common'
import { MaskPattern, REGEXS, getFormatedPhone } from 'utils'
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { awsconfig } from 'configs'
import { loadStripe } from '@stripe/stripe-js'
import { BankDebit, Paypal } from 'components/common/unAuthenticatedPayments'
import { AmountInput, PaymentNotConfigured } from 'components/elements'
import {
  payBusinessServicePaypal,
  payBusinessServiceStripe,
} from 'store/businessSlice/businessActions'

const stringRegex = /^[A-Za-z ]+$/
const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
const phoneNumberValidation = '^[0-9-() ]{14,15}$'
const amountValidation = REGEXS.amountRegex

const schema = Yup.object().shape({
  askedAmount: Yup.string()
    .required('Required')
    .matches(amountValidation, 'Invalid amount'),
  firstName: Yup.string()
    .required('Required')
    .matches(stringRegex, 'Invalid First Name'),
  lastName: Yup.string()
    .required('Required')
    .matches(stringRegex, 'Invalid Last Name'),
  email: Yup.string().required('Required').matches(emailRegex, 'Invalid Email'),
  countryCode: Yup.string(),
  phoneNumber: Yup.string()
    .nullable()
    .required('Required')
    .matches(phoneNumberValidation, 'Invalid Phone number e.g 3456789123'),
})

const donationValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

const initialValues = {
  askedAmount: '',
  card: 'no-card-selected',
  bank: null,
  firstName: '',
  lastName: '',
  email: '',
  countryCode: '+1',
  phoneNumber: null,
  mailingAddress: '',
  PaymentMethod: 0,
}

const WrappedRegisterationForm = ({
  item,
  subscriberPaymentInfo,
  setIsRegister,
}) => {
  const dispatch = useDispatch()
  const [donateDataForm] = Form.useForm()
  const { user, location } = useAuthSelector()
  const [authenticatedUser, setAuthenticatedUser] = useState(false)
  const [loading, setLoading] = useState(false)
  const [banks, setBanks] = useState([])

  const stripe = useStripe()
  const elements = useElements()

  Form.useWatch('askedAmount', donateDataForm)
  Form.useWatch('countryCode', donateDataForm)
  Form.useWatch('phoneNumber', donateDataForm)
  Form.useWatch('PaymentMethod', donateDataForm)
  const paymentMethodValue = Form.useWatch('PaymentMethod', donateDataForm)

  Form.useWatch('bank', donateDataForm)
  const bank = donateDataForm.getFieldValue('bank')
  const isNoBankSelected = bank === null || bank === undefined
  const isNewBankSelected = bank === 'new-bank-selected'

  const handleInputChange = (value) => {
    donateDataForm.setFieldsValue({ phoneNumber: value })
  }

  useEffect(() => {
    if (user) {
      setAuthenticatedUser(true)
      const { firstname, lastname, email, phone } = user

      donateDataForm.setFieldsValue({
        firstName: firstname,
        lastName: lastname,
        email,
      })

      if (phone) {
        const regex = /^\+(\d{1,3})(\d{10})$/
        const match = phone.match(regex)

        if (match) {
          const countrycode = `+${match[1]}`
          const phoneNumber = match[2]
          const formattedPhoneNumber = `(${phoneNumber.slice(
            0,
            3,
          )}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`
          donateDataForm.setFieldValue('countryCode', countrycode)
          donateDataForm.setFieldValue('phoneNumber', formattedPhoneNumber)
        }
      }
    }
    // eslint-disable-next-line
  }, [user])

  useEffect(() => {
    if (item?.Price) {
      donateDataForm.setFieldsValue({ askedAmount: item?.Price })
    }
    // eslint-disable-next-line
  }, [item?.Price])

  useEffect(() => {
    const phoneNumber = donateDataForm.getFieldValue('phoneNumber')
    if (phoneNumber === '(___) ___-____') {
      donateDataForm.setFieldValue('phoneNumber', null)
    }
    // eslint-disable-next-line
  }, [donateDataForm.getFieldValue('phoneNumber')])

  useEffect(() => {
    const firstFormItemInput = document.querySelector(
      '.ant-form-item-control input',
    )
    if (firstFormItemInput) {
      firstFormItemInput.focus()
    }
  }, [])

  const callAction = () => {
    setIsRegister(true)
  }

  /* eslint-disable consistent-return */
  const onFinish = async (data) => {
    const {
      PaymentMethod,
      askedAmount,
      firstName,
      lastName,
      email,
      countryCode,
      phoneNumber,
      mailingAddress,
      suite,
      zipCode,
      city,
      state,
      country,
    } = data

    const isBankAccount = PaymentMethod === 1
    let cardToken = null
    let bankDetails = null
    if (isBankAccount) {
      if (!data?.bank || data?.bank === 'no-bank-selected') {
        message.error('No Bank Account Selected')
        return false
      }
      bankDetails = banks.find((bankItem) => bankItem.id === data?.bank)
    } else {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      })
      if (error) {
        message.error(error?.message || 'Invalid Card Information')
        return false
      }
      cardToken = paymentMethod.id
    }
    const StripeAccountID = subscriberPaymentInfo?.stripe?.stripeId

    const phone = getFormatedPhone(countryCode, phoneNumber)
    const completeAddress = {
      suite: suite || '',
      city: city || '',
      state: state || '',
      country: country || '',
      zipCode: zipCode || '',
    }

    const payload = {
      isBankAccount,
      cardToken,
      bank: bankDetails,
      stripeAccount: StripeAccountID,
      commission: subscriberPaymentInfo?.markup,
      amount: Number(askedAmount),
      user: {
        firstname: firstName,
        lastname: lastName,
        email,
        phone,
        countryCode,
        phoneNumber: phoneNumber?.replace(/\D/g, ''),
      },
      location: user ? location : null,
      mailingAddress,
      completeAddress,
      item,
    }
    dispatch(payBusinessServiceStripe(payload, setLoading, callAction))
  }

  const onPaypalSuccess = async (values) => {
    const data = donateDataForm.getFieldsValue()
    const {
      askedAmount,
      firstName,
      lastName,
      email,
      countryCode,
      phoneNumber,
      mailingAddress,
      suite,
      zipCode,
      city,
      state,
      country,
    } = data

    const phone = getFormatedPhone(countryCode, phoneNumber)
    const completeAddress = {
      suite: suite || '',
      city: city || '',
      state: state || '',
      country: country || '',
      zipCode: zipCode || '',
    }

    const payload = {
      paymentId: values.paymentId,
      merchantId: values.merchantId,
      commission: subscriberPaymentInfo?.markup,
      amount: Number(askedAmount),
      user: {
        firstname: firstName,
        lastname: lastName,
        email,
        phone,
        countryCode,
        phoneNumber: phoneNumber?.replace(/\D/g, ''),
      },
      location: user ? location : null,
      mailingAddress,
      completeAddress,
      item,
    }
    dispatch(payBusinessServicePaypal(payload, setLoading, callAction))
  }

  const isStripeAvailable = subscriberPaymentInfo?.stripe?.isConnected
  const isPaypalAvailable = subscriberPaymentInfo?.paypal?.isConnected

  useEffect(() => {
    if (isStripeAvailable) {
      donateDataForm.setFieldValue('PaymentMethod', 0)
    } else if (isPaypalAvailable) {
      donateDataForm.setFieldValue('PaymentMethod', 2)
    }
    // eslint-disable-next-line
  }, [isStripeAvailable, isPaypalAvailable])

  const isStripeAvailableAndNotPaypalSelected =
    isStripeAvailable && paymentMethodValue !== 2

  const isCreditCardOrNotNewBankSelected =
    paymentMethodValue === 0 || (banks.length > 0 && !isNewBankSelected)

  const isBankAndBankPresentButNotSelected =
    paymentMethodValue === 1 && banks.length > 0 && isNoBankSelected
  return (
    <Form
      form={donateDataForm}
      layout="vertical"
      key={0}
      onFinish={onFinish}
      name="donation"
      scrollToFirstError
      initialValues={initialValues}
    >
      <Row gutter={[24, 0]}>
        <Col xl={14} md={13} xs={24} sm={24}>
          <Card
            className="header-solid t-p-2 md:t-p-10 t-m-4 t-shadow-2xl t-rounded-lg"
            bordered={false}
          >
            <Row gutter={[24, 0]}>
              <Col xl={12} md={12} xs={24} sm={12}>
                <Form.Item
                  name="firstName"
                  label="First Name *"
                  rules={[donationValidation]}
                  className="t-mb-[12px] md:t-mb-[24px]"
                >
                  <Input
                    placeholder="First Name"
                    disabled={authenticatedUser}
                  />
                </Form.Item>
              </Col>
              <Col xl={12} md={12} xs={24} sm={12}>
                <Form.Item
                  name="lastName"
                  label="Last Name *"
                  rules={[donationValidation]}
                  className="t-mb-[12px] md:t-mb-[24px]"
                >
                  <Input placeholder="Last Name" disabled={authenticatedUser} />
                </Form.Item>
              </Col>
              <Col xl={12} md={12} xs={24} sm={12}>
                <Form.Item
                  name="countryCode"
                  label="Country Code"
                  rules={[donationValidation]}
                  className="t-mb-[12px] md:t-mb-[24px]"
                >
                  <Select
                    placeholder="Select Country Code"
                    style={{ width: '100%' }}
                    disabled={authenticatedUser}
                  >
                    <Select.Option value="+1">
                      United States/Canada (+1)
                    </Select.Option>
                    <Select.Option value="+54">Argentina (+54)</Select.Option>
                    <Select.Option value="+92">Pakistan (+92)</Select.Option>
                    <Select.Option value="+55">Brazil (+55)</Select.Option>
                    <Select.Option value="+58">
                      Venezuela, Bolivarian Republic of Venezuela (+58)
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={12} md={12} xs={24} sm={12}>
                <Form.Item
                  name="phoneNumber"
                  label="Mobile Number *"
                  rules={[donationValidation]}
                  className="t-mb-[12px] md:t-mb-[24px]"
                >
                  {authenticatedUser ? (
                    <Input disabled={authenticatedUser} />
                  ) : (
                    <MaskedInput
                      inputMode="numeric"
                      mask={MaskPattern}
                      onChange={(e) => {
                        handleInputChange(e.maskedValue)
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xl={12} md={12} xs={24} sm={12}>
                <Form.Item
                  name="email"
                  label="Email *"
                  rules={[donationValidation]}
                  className="t-mb-[12px] md:t-mb-[24px]"
                >
                  <Input placeholder="Email" disabled={authenticatedUser} />
                </Form.Item>
              </Col>
              <AutoCompleteAddress form={donateDataForm} />
            </Row>
          </Card>
        </Col>
        <Col xl={10} md={11} xs={24} sm={24}>
          <Card
            className="header-solid t-p-2 md:t-p-10 t-m-4 t-shadow-2xl t-rounded-lg"
            bordered={false}
          >
            <>
              {(isStripeAvailable || isPaypalAvailable) && (
                <Col span={24} className="t-p-0 t-mb-2">
                  <Form.Item
                    name="PaymentMethod"
                    label="Select Payment Method"
                    colon={false}
                  >
                    <Radio.Group className="t-space-y-1">
                      {isStripeAvailable && (
                        <Radio value={0}>Credit Card</Radio>
                      )}
                      {isStripeAvailable && (
                        <Radio value={1}>Bank Account</Radio>
                      )}
                      {isPaypalAvailable && <Radio value={2}>Paypal</Radio>}
                    </Radio.Group>
                  </Form.Item>
                </Col>
              )}

              <Col xl={24} md={24} xs={24} sm={24} className="t-p-0">
                <Form.Item
                  name="askedAmount"
                  label="Donation *"
                  colon={false}
                  rules={[donationValidation]}
                >
                  <AmountInput
                    placeholder="Donation"
                    onChange={(value) =>
                      donateDataForm.setFieldsValue({ askedAmount: value })
                    }
                    disabled
                  />
                </Form.Item>
              </Col>

              {isStripeAvailable && (
                <Col xl={24} md={24} xs={24} sm={24} className="t-mb-2 t-p-0">
                  {paymentMethodValue === 0 && (
                    <>
                      <h1 className="t-text-sm t-my-2 t-font-semibold">
                        Enter Card Number
                      </h1>
                      <div className="t-p-2 t-rounded-md t-border t-border-grey-900'">
                        <CardElement
                          options={{
                            style: {
                              base: {
                                fontSize: '15px',
                                fontSmoothing: 'antialiased',
                              },
                            },
                          }}
                        />
                      </div>
                    </>
                  )}
                  {paymentMethodValue === 1 && (
                    <BankDebit
                      banks={banks}
                      setBanks={setBanks}
                      form={donateDataForm}
                      stripeAccount={subscriberPaymentInfo?.stripe?.stripeId}
                    />
                  )}
                </Col>
              )}

              {isStripeAvailableAndNotPaypalSelected && (
                <>
                  {isCreditCardOrNotNewBankSelected && (
                    <Col span={24} className="text-right t-p-0 t-mb-2">
                      <Button
                        type="primary"
                        className="t-w-full"
                        htmlType="submit"
                        loading={loading}
                        disabled={
                          !stripe ||
                          !elements ||
                          isBankAndBankPresentButNotSelected
                        }
                      >
                        Pay
                      </Button>
                    </Col>
                  )}
                </>
              )}
              {isPaypalAvailable && paymentMethodValue === 2 && (
                <Paypal
                  form={donateDataForm}
                  subscriberPaymentInfo={subscriberPaymentInfo}
                  paypalId={subscriberPaymentInfo?.paypal?.paypalId}
                  onSubmit={onPaypalSuccess}
                  donating={loading}
                />
              )}

              {!isStripeAvailable && !isPaypalAvailable && (
                <PaymentNotConfigured />
              )}
            </>
          </Card>
        </Col>
      </Row>
    </Form>
  )
}

function StripeProvider({ item, subscriberPaymentInfo, setIsRegister }) {
  const stripePromise = loadStripe(awsconfig.stripe_publish_key, {
    stripeAccount: subscriberPaymentInfo?.stripe?.stripeId,
  })

  return (
    <Elements stripe={stripePromise}>
      <WrappedRegisterationForm
        item={item}
        subscriberPaymentInfo={subscriberPaymentInfo}
        setIsRegister={setIsRegister}
      />
    </Elements>
  )
}
const RegisterationForm = StripeProvider
export { RegisterationForm }
