import React, { useEffect, useState } from 'react'
import {
  Form,
  Row,
  Col,
  Card,
  Button,
  Radio,
  message,
  Input,
  Switch,
  Typography,
} from 'antd'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { useDispatch } from 'react-redux'
import {
  formatAmount,
  getEstimatedProcessingFee,
  getFormatedPhone,
  isPaymentCategoriesAdded,
} from 'utils'
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { awsconfig } from 'configs'
import { loadStripe } from '@stripe/stripe-js'
import { AmountInput, PaymentNotConfigured } from 'components/elements'
import {
  registerForProgram,
  registerWithPaypalForProgram,
} from 'store/programSlice/programActions'
import {
  registerForService,
  registerWithPaypalForService,
} from 'store/serviceSlice/serviceActions'
import { paypalRentalPayment, stripePayment } from 'store/roomSlice/roomActions'
import { registerForVolunteer } from 'store/volunteerSlice/volunteerActions'
import { paymentValidation } from 'validations'
// eslint-disable-next-line
import { UserForm } from 'components/common/donations/components/UserForm'
import { ClassForm, FuneralForm, MarriageForm } from './extraForms'
import { BankDebit } from './BankDebit'
import { Paypal } from './Paypal'
import { ZakaatForm } from './extraForms/ZakaatForm'
import { TermsAndConditionsModal } from '../donations/components/TermsAndConditionsModal'

const { TextArea } = Input

const initialValues = {
  askedAmount: '',
  extraAskedAmount: '',
  card: 'no-card-selected',
  bank: null,
  firstName: '',
  lastName: '',
  email: '',
  countryCode: '+1',
  phoneNumber: null,
  mailingAddress: '',
  PaymentMethod: 0,
  includeProcessingFees: false,
  showExtraAmount: false,
  isRecurringPayment: false,
  numberOfRecurring: null,
  isSplitPayment: false,
  numberOfSplit: null,

  agreeToTerms: false,
  HaveChildren: false,
  // Gender: 'Male',
}

const WrappedRegisterationForm = ({
  donateDataForm,
  item,
  itemType,
  setIsRegister,
  subscriberPaymentInfo,
  isPaymentCategoriesPresent,
  paymentCategories,
  selectedCategory,
  setSelectedCategory,
}) => {
  const dispatch = useDispatch()
  const { user, location } = useAuthSelector()
  const [loading, setLoading] = useState(false)
  const [banks, setBanks] = useState([])

  const stripe = useStripe()
  const elements = useElements()
  Form.useWatch('card', donateDataForm)
  Form.useWatch('countryCode', donateDataForm)
  Form.useWatch('phoneNumber', donateDataForm)
  Form.useWatch('extraAskedAmount', donateDataForm)
  const extraAskedAmountValue = Form.useWatch(
    'extraAskedAmount',
    donateDataForm,
  )
  Form.useWatch('askedAmount', donateDataForm)
  const askedAmountValue = Form.useWatch('askedAmount', donateDataForm)
  Form.useWatch('PaymentMethod', donateDataForm)
  const paymentMethodValue = Form.useWatch('PaymentMethod', donateDataForm)
  Form.useWatch('PaymentCategory', donateDataForm)
  const paymentCategoryValue = Form.useWatch('PaymentCategory', donateDataForm)
  Form.useWatch('includeProcessingFees', donateDataForm)
  const includeProcessingValue = Form.useWatch(
    'includeProcessingFees',
    donateDataForm,
  )
  Form.useWatch('showExtraAmount', donateDataForm)
  const isShowExtraAmount = Form.useWatch('showExtraAmount', donateDataForm)

  Form.useWatch('isRecurringPayment', donateDataForm)
  Form.useWatch('numberOfRecurring', donateDataForm)
  const isRecurring = donateDataForm.getFieldValue('isRecurringPayment')

  Form.useWatch('isSplitPayment', donateDataForm)
  Form.useWatch('numberOfSplit', donateDataForm)
  const isSplit = donateDataForm.getFieldValue('isSplitPayment')
  const numberOfSplitValue = donateDataForm.getFieldValue('numberOfSplit')

  Form.useWatch('bank', donateDataForm)
  const bank = donateDataForm.getFieldValue('bank')
  const isNoBankSelected = bank === null || bank === undefined
  const isNewBankSelected = bank === 'new-bank-selected'

  useEffect(() => {
    if (isProgram || isService) {
      if (item?.ProgramFees) {
        donateDataForm.setFieldValue('askedAmount', item?.ProgramFees)
      }

      if (isProgram && item?.AllowSplitPayments) {
        // if item is program and it allows split payments
        // donateDataForm.setFieldValue('isSplitPayment', item?.AllowSplitPayments)
        donateDataForm.setFieldValue(
          'numberOfSplit',
          item?.NumberOfDefaultSplit,
        )
      }

      if (isService && item?.AllowRecurringPayments) {
        // if item is service and it allows recurring payments
        donateDataForm.setFieldValue(
          'isRecurringPayment',
          item?.AllowRecurringPayments,
        )
        donateDataForm.setFieldValue(
          'numberOfRecurring',
          item?.NumberOfDefaultRecurring,
        )
      }

      if (item?.IncludeProcessingFee) {
        donateDataForm.setFieldValue(
          'includeProcessingFees',
          item?.IncludeProcessingFee,
        )
      }
    } else if (isRoom) {
      if (item?.UpdatedCost) {
        donateDataForm.setFieldValue('askedAmount', item?.UpdatedCost)
      }
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isPaymentCategoriesPresent) {
      if (selectedCategory === null) {
        donateDataForm.setFieldValue('PaymentCategory', 0)
      } else {
        donateDataForm.setFieldValue('PaymentCategory', selectedCategory)
      }
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const firstFormItemInput = document.querySelector(
      '.ant-form-item-control input',
    )
    if (firstFormItemInput) {
      firstFormItemInput.focus()
    }
  }, [])

  const callAction = () => {
    setIsRegister(true)
  }

  /* eslint-disable consistent-return */
  const onFinish = async (data) => {
    setLoading(true)
    const {
      PaymentMethod,
      askedAmount,
      firstName,
      lastName,
      email,
      countryCode,
      phoneNumber,
      mailingAddress,
      suite,
      zipCode,
      city,
      state,
      country,
    } = data

    const isBankAccount = PaymentMethod === 1
    let cardToken = null
    let bankDetails = null
    if (isPaidOrRoom) {
      if (isBankAccount) {
        if (!data?.bank || data?.bank === 'no-bank-selected') {
          message.error('No Bank Account Selected')
          setLoading(false)
          return false
        }
        bankDetails = banks.find((bankItem) => bankItem.id === data?.bank)
      } else {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
        })
        if (error) {
          message.error(error?.message || 'Invalid Card Information')
          setLoading(false)
          return false
        }
        cardToken = paymentMethod.id
      }
    }

    const phone = getFormatedPhone(countryCode, phoneNumber)
    const completeAddress = {
      suite: suite || '',
      city: city || '',
      state: state || '',
      country: country || '',
      zipCode: zipCode || '',
    }

    const DummyPaymentCategory = data?.PaymentCategory
    let StripeAccountID
    if (DummyPaymentCategory !== undefined) {
      StripeAccountID = paymentCategories[DummyPaymentCategory]?.StripeId
    } else {
      StripeAccountID = subscriberPaymentInfo?.stripe?.stripeId
    }

    const selectedPaymentCategoryId = getSelectedPaymentCategoryId()
    const { amountWithExtraAmountPlusProcessingFees, processingFees } =
      getTotal()

    const amount =
      includeProcessingValue || isShowExtraAmount
        ? amountWithExtraAmountPlusProcessingFees
        : askedAmount

    if (isPaidOrRoom && Number(amount) <= 0) {
      message.error('Amount must be greater than 0')
      setLoading(false)
      return false
    }

    const payload = {
      isPaid: isPaidOrRoom,
      isBankAccount,
      cardToken,
      bank: bankDetails,
      paymentCategory: selectedPaymentCategoryId,
      stripeAccount: StripeAccountID,
      commission: subscriberPaymentInfo?.markup,
      amount: Number(amount),
      includeEstimatedFees: includeProcessingValue,
      estimatedFees: includeProcessingValue ? processingFees : 0,
      user: {
        firstname: firstName,
        lastname: lastName,
        email,
        phone,
        countryCode,
        phoneNumber: phoneNumber?.replace(/\D/g, ''),
      },
      location: user ? location : null,
      mailingAddress,
      completeAddress,
    }
    if (isRecurringPaymentAllowed) {
      payload.isRecurringPayment = data.isRecurringPayment
      payload.numberOfRecurring = data?.numberOfRecurring
    }
    if (isSplitPaymentAllowed) {
      payload.isSplitPayment = data.isSplitPayment
      payload.numberOfSplit = data?.numberOfSplit
    }
    if (isProgram) {
      payload.programType = item?.ProgramType
      payload.programId = item?.ProgramId
      payload.programName = item?.Title
      payload.organizationId = item?.organization?.id
      payload.programTypeData = null
      if (isClassProgram) {
        const { Name, Age, Gender, Instructions } = data

        if (
          parseInt(Age, 10) < parseInt(item.MinimumAge, 10) ||
          parseInt(Age, 10) > parseInt(item.MaximumAge, 10)
        ) {
          message.error(
            `Age should be between ${item.MinimumAge} and ${item.MaximumAge}`,
          )
          setLoading(false)
          return false
        }

        const subTypeData = {
          Name,
          Age,
          Gender,
          Instructions,
        }
        payload.programTypeData = subTypeData
      }
      dispatch(registerForProgram(payload, setLoading, callAction))
    }
    if (isService) {
      payload.serviceType = item?.ServiceType
      payload.serviceId = item?.ServiceId
      payload.serviceName = item?.Title
      payload.organizationId = item?.organization?.id
      payload.serviceTypeData = null
      if (isFuneralService) {
        const {
          Name,
          DOB,
          SSN,
          NextKin,
          DateOfDeath,
          GraveyardName,
          SpecialInstructions,
          DateOfBurial,
          OtherInstructions,
        } = data
        const subTypeData = {
          Name,
          DOB,
          SSN,
          NextKin,
          DateOfDeath,
          GraveyardName,
          SpecialInstructions,
          DateOfBurial,
          OtherInstructions,
        }
        payload.serviceTypeData = subTypeData
      }
      if (isMarriageService) {
        const {
          Date,
          RequestedTime,
          MarriageLicenseNumber,
          CourtMarriageLocation,
          CeremonyLocation,
          BrideName,
          BrideReligion,
          GroomName,
          GroomReligion,
          NumberOfAttendees,
          WaliWakeelName,
          WaliWakeelRelationship,
          Witness1Name,
          Witness2Name,
          MahrMuqaddam,
          MahrMuakhar,
        } = data
        const subTypeData = {
          Date,
          RequestedTime,
          MarriageLicenseNumber,
          CourtMarriageLocation,
          CeremonyLocation,
          BrideName,
          BrideReligion,
          GroomName,
          GroomReligion,
          NumberOfAttendees,
          WaliWakeelName,
          WaliWakeelRelationship,
          Witness1Name,
          Witness2Name,
          MahrMuqaddam,
          MahrMuakhar,
        }
        payload.serviceTypeData = subTypeData
      }
      if (isZakaatService) {
        const {
          SSN,
          PrimaryResidence,
          Age,
          Gender,
          MaritalStatus,
          HaveChildren,
          NumberOfChildren,
          LiveWithParent,
          EmploymentStatus,
          JobTitle,
          EmployerName,
          EmployerAddress,
          EmployerPhoneNumber,
          AnnualHouseholdIncome,
          HealthInsuranceInformation,
          HighestLevelOfEducationCompleted,
          CurrentTransportationMeans,
          MonthlyHouseholdExpenseDetails,
          CurrentAssets,
          PublicOrPrivateDebts,
          AmountReceivedInLast12Months,
          AidCurrentlyReceived,
          JustificationForZakaatFunds,
        } = data
        const subTypeData = {
          FirstName: user?.firstname,
          LastName: user?.lastname,
          PhoneNumber: phone,
          StreetAddress: mailingAddress,
          SSN,
          PrimaryResidence,
          Age,
          Gender,
          MaritalStatus,
          HaveChildren,
          NumberOfChildren,
          LiveWithParent,
          EmploymentStatus,
          JobTitle,
          EmployerName,
          EmployerAddress,
          EmployerPhoneNumber: EmployerPhoneNumber
            ? EmployerPhoneNumber?.replace(/\D/g, '')
            : null,
          AnnualHouseholdIncome,
          HealthInsuranceInformation,
          HighestLevelOfEducationCompleted,
          CurrentTransportationMeans,
          MonthlyHouseholdExpenseDetails,
          CurrentAssets,
          PublicOrPrivateDebts,
          AmountReceivedInLast12Months,
          AidCurrentlyReceived,
          JustificationForZakaatFunds,
        }
        payload.serviceTypeData = subTypeData
      }
      dispatch(registerForService(payload, setLoading, callAction))
    }
    if (isVolunteer) {
      const volunteerPayload = {
        firstName,
        lastName,
        email,
        phoneNumber: phone,
        mailingAddress,
        completeAddress,
        comment: data?.comment,
        volunteerId: item?.id,
        title: item?.Title,
        subscriber: item?.CreatedBy,
        item,
      }
      dispatch(registerForVolunteer(volunteerPayload, setLoading, callAction))
    }
    if (isRoom) {
      payload.item = item
      dispatch(stripePayment(payload, setLoading, callAction))
    }
  }

  const onPaypalSuccess = async (values) => {
    const data = donateDataForm.getFieldsValue()
    const {
      askedAmount,
      firstName,
      lastName,
      email,
      countryCode,
      phoneNumber,
      mailingAddress,
      suite,
      zipCode,
      city,
      state,
      country,
    } = data

    const phone = getFormatedPhone(countryCode, phoneNumber)
    const completeAddress = {
      suite: suite || '',
      city: city || '',
      state: state || '',
      country: country || '',
      zipCode: zipCode || '',
    }

    const selectedPaymentCategoryId = getSelectedPaymentCategoryId()
    const { amountWithExtraAmountPlusProcessingFees, processingFees } =
      getTotal()

    const amount =
      includeProcessingValue || isShowExtraAmount
        ? amountWithExtraAmountPlusProcessingFees
        : askedAmount

    if (isPaidOrRoom && Number(amount) <= 0) {
      message.error('Amount must be greater than 0')
      setLoading(false)
      return false
    }

    const payload = {
      isPaid: isPaidOrRoom,
      paymentId: values.paymentId,
      merchantId: values.merchantId,
      paymentCategory: selectedPaymentCategoryId,
      commission: subscriberPaymentInfo?.markup,
      amount: Number(amount),
      includeEstimatedFees: includeProcessingValue,
      estimatedFees: includeProcessingValue ? processingFees : 0,
      user: {
        firstname: firstName,
        lastname: lastName,
        email,
        phone,
        countryCode,
        phoneNumber: phoneNumber?.replace(/\D/g, ''),
      },
      location: user ? location : null,
      mailingAddress,
      completeAddress,
    }
    if (isProgram) {
      payload.programType = item?.ProgramType
      payload.programId = item?.ProgramId
      payload.programName = item?.Title
      payload.organizationId = item?.organization?.id
      payload.programTypeData = null
      if (isClassProgram) {
        const { Name, Age, Gender, Instructions } = data

        if (
          parseInt(Age, 10) < parseInt(item.MinimumAge, 10) ||
          parseInt(Age, 10) > parseInt(item.MaximumAge, 10)
        ) {
          message.error(
            `Age should be between ${item.MinimumAge} and ${item.MaximumAge}`,
          )
          setLoading(false)
          return false
        }

        const subTypeData = {
          Name,
          Age,
          Gender,
          Instructions,
        }
        payload.programTypeData = subTypeData
      }
      dispatch(registerWithPaypalForProgram(payload, setLoading, callAction))
    }
    if (isService) {
      payload.serviceType = item?.ServiceType
      payload.serviceId = item?.ServiceId
      payload.serviceName = item?.Title
      payload.organizationId = item?.organization?.id
      payload.serviceTypeData = null
      if (isFuneralService) {
        const {
          Name,
          DOB,
          SSN,
          NextKin,
          DateOfDeath,
          GraveyardName,
          SpecialInstructions,
          DateOfBurial,
          OtherInstructions,
        } = data
        const subTypeData = {
          Name,
          DOB,
          SSN,
          NextKin,
          DateOfDeath,
          GraveyardName,
          SpecialInstructions,
          DateOfBurial,
          OtherInstructions,
        }
        payload.serviceTypeData = subTypeData
      }
      if (isMarriageService) {
        const {
          Date,
          RequestedTime,
          MarriageLicenseNumber,
          CourtMarriageLocation,
          CeremonyLocation,
          BrideName,
          BrideReligion,
          GroomName,
          GroomReligion,
          NumberOfAttendees,
          WaliWakeelName,
          WaliWakeelRelationship,
          Witness1Name,
          Witness2Name,
          MahrMuqaddam,
          MahrMuakhar,
        } = data
        const subTypeData = {
          Date,
          RequestedTime,
          MarriageLicenseNumber,
          CourtMarriageLocation,
          CeremonyLocation,
          BrideName,
          BrideReligion,
          GroomName,
          GroomReligion,
          NumberOfAttendees,
          WaliWakeelName,
          WaliWakeelRelationship,
          Witness1Name,
          Witness2Name,
          MahrMuqaddam,
          MahrMuakhar,
        }
        payload.serviceTypeData = subTypeData
      }
      dispatch(registerWithPaypalForService(payload, setLoading, callAction))
    }
    if (isRoom) {
      payload.item = item
      dispatch(paypalRentalPayment(payload, setLoading, callAction))
    }
  }

  const getTotal = () => {
    const { markup } = subscriberPaymentInfo

    const amountWithExtraAmount =
      Number(askedAmountValue) +
      (isShowExtraAmount ? Number(extraAskedAmountValue || 0) : 0)

    const processingFees = getEstimatedProcessingFee(
      markup,
      amountWithExtraAmount,
    )

    const amountWithExtraAmountPlusProcessingFees =
      Number(amountWithExtraAmount) +
      (includeProcessingValue ? processingFees : 0)

    return {
      amountWithExtraAmountPlusProcessingFees,
      processingFees,
    }
  }

  const getSplitedAmount = () => {
    const { amountWithExtraAmountPlusProcessingFees } = getTotal()

    const splitAmount = parseFloat(
      amountWithExtraAmountPlusProcessingFees / numberOfSplitValue,
    ).toFixed(2)

    return splitAmount
  }

  const isShowBreakdown =
    Number(getTotal()?.amountWithExtraAmountPlusProcessingFees) > 0 &&
    ((includeProcessingValue && Number(includeProcessingValue) > 0) ||
      (isShowExtraAmount && Number(extraAskedAmountValue) > 0))

  const getBreakdownText = () => {
    const { processingFees } = getTotal()
    const fee = `${formatAmount(askedAmountValue)} Payment`
    const extraFee =
      isShowExtraAmount && Number(extraAskedAmountValue) > 0
        ? `+ ${formatAmount(extraAskedAmountValue)} Extra Payment`
        : ''
    const processFee =
      includeProcessingValue && Number(processingFees) > 0
        ? `+ ${formatAmount(processingFees)} Processing Fee`
        : ''

    const text = `(${fee} ${extraFee} ${processFee})`
    return text
  }

  const isStripeAvailable =
    paymentCategoryValue !== undefined
      ? paymentCategories[paymentCategoryValue]?.StripeId
      : subscriberPaymentInfo?.stripe?.isConnected

  const isPaypalAvailable =
    paymentCategoryValue !== undefined
      ? paymentCategories[paymentCategoryValue]?.PaypalId
      : subscriberPaymentInfo?.paypal?.isConnected

  useEffect(() => {
    if (isStripeAvailable) {
      donateDataForm.setFieldValue('PaymentMethod', 0)
    } else if (isPaypalAvailable) {
      donateDataForm.setFieldValue('PaymentMethod', 2)
    }
    // eslint-disable-next-line
  }, [isStripeAvailable, isPaypalAvailable])

  const getSelectedPaymentCategoryId = () => {
    const DummyPaymentCategory = paymentCategoryValue
    let SelectedPaymentCategory

    if (DummyPaymentCategory !== undefined) {
      SelectedPaymentCategory = paymentCategories[DummyPaymentCategory]?.Id
    } else {
      SelectedPaymentCategory = subscriberPaymentInfo?.Id
    }
    return SelectedPaymentCategory
  }

  const getStripeId = () => {
    const DummyPaymentCategory = paymentCategoryValue
    let StripeAccountID

    if (DummyPaymentCategory !== undefined) {
      StripeAccountID = paymentCategories[DummyPaymentCategory]?.StripeId
    } else {
      StripeAccountID = subscriberPaymentInfo?.stripe?.stripeId
    }
    return StripeAccountID
  }

  const getPaypalId = () => {
    const DummyPaymentCategory = paymentCategoryValue
    let PaypalAccountID

    if (DummyPaymentCategory !== undefined) {
      PaypalAccountID = paymentCategories[DummyPaymentCategory]?.PaypalId
    } else {
      PaypalAccountID = subscriberPaymentInfo?.paypal?.paypalId
    }
    return PaypalAccountID
  }

  const isStripeAvailableAndNotPaypalSelected =
    isStripeAvailable && paymentMethodValue !== 2

  const isCreditCardOrNotNewBankSelected =
    paymentMethodValue === 0 || (banks.length > 0 && !isNewBankSelected)

  const isBankAndBankPresentButNotSelected =
    paymentMethodValue === 1 && banks.length > 0 && isNoBankSelected

  const isPaid = Number(item?.ProgramFees) > 0
  const isProgram = itemType === 'program-payment'
  const isClassProgram = item?.ProgramType === 'Class'

  const isService = itemType === 'service-payment'
  const isMarriageService = item?.ServiceType === 'Marriage'
  const isFuneralService = item?.ServiceType === 'Funeral'
  const isZakaatService = item?.ServiceType === 'Zakaat'

  const isProgramOrService = isProgram || isService

  const isVolunteer = itemType === 'volunteer-payment'
  const isRoom = itemType === 'room-payment'

  const isPaymentNeed =
    (isProgram && isPaid) || (isService && !isZakaatService && isPaid)
  const isPaidOrRoom = isPaymentNeed || isRoom

  const isProcessingFeeMandotory =
    isProgramOrService && item?.IncludeProcessingFee === true
  const isRecurringPaymentAllowed =
    isService &&
    isPaid &&
    item?.AllowRecurringPayments === true &&
    paymentMethodValue !== 2
  const isSplitPaymentAllowed =
    isProgram &&
    isPaid &&
    item?.AllowSplitPayments === true &&
    paymentMethodValue !== 2

  const isAllowExtraPayment = item?.AllowExtraPayment
  const isAllowToEditFee = isProgram && isPaid && item?.AllowToEditFee

  return (
    <Form
      form={donateDataForm}
      layout="vertical"
      key={0}
      onFinish={onFinish}
      name="donation"
      scrollToFirstError
      initialValues={initialValues}
    >
      <Row gutter={[24, 0]}>
        <Col xl={14} md={13} xs={24} sm={24}>
          <UserForm
            form={donateDataForm}
            isShowMailingAddress
            itemType={itemType}
            item={item}
          />
        </Col>
        <Col xl={10} md={11} xs={24} sm={24}>
          <Card
            className="header-solid t-p-2 md:t-p-10 t-m-4 t-shadow-2xl t-rounded-lg"
            bordered={false}
          >
            {isClassProgram && (
              <ClassForm form={donateDataForm} program={item} />
            )}
            {isMarriageService && (
              <MarriageForm form={donateDataForm} service={item} />
            )}
            {isFuneralService && <FuneralForm form={donateDataForm} />}
            {isZakaatService && <ZakaatForm form={donateDataForm} />}

            {isPaidOrRoom ? (
              <>
                <Col
                  xl={24}
                  md={24}
                  xs={24}
                  sm={24}
                  className={`t-p-0 ${
                    isPaymentCategoriesPresent && paymentCategories?.length >= 2
                      ? 't-block'
                      : 't-hidden'
                  }`}
                >
                  <Form.Item
                    name="PaymentCategory"
                    label="Select Payment Category"
                    colon={false}
                  >
                    <Radio.Group
                      // value={form.getFieldValue('MasterRecord')}
                      onChange={(e) => setSelectedCategory(e.target.value)}
                    >
                      <Radio value={0}>
                        {paymentCategories?.length > 0 &&
                          paymentCategories[0]?.Name}
                      </Radio>
                      <Radio value={1}>
                        {paymentCategories?.length > 1 &&
                          paymentCategories[1]?.Name}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                {(isStripeAvailable || isPaypalAvailable) && (
                  <Col span={24} className="t-p-0 t-mb-2">
                    <Form.Item
                      name="PaymentMethod"
                      label="Select Payment Method"
                      colon={false}
                    >
                      <Radio.Group className="t-space-y-1">
                        {isStripeAvailable && (
                          <Radio value={0}>Credit Card</Radio>
                        )}
                        {isStripeAvailable && (
                          <Radio value={1}>Bank Account</Radio>
                        )}
                        {isPaypalAvailable && <Radio value={2}>Paypal</Radio>}
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                )}

                <Col xl={24} md={24} xs={24} sm={24} className="t-p-0">
                  <Form.Item
                    name="askedAmount"
                    label="Payment *"
                    colon={false}
                    rules={[paymentValidation]}
                  >
                    <AmountInput
                      placeholder="Payment"
                      onChange={(value) =>
                        donateDataForm.setFieldsValue({ askedAmount: value })
                      }
                      disabled={!isAllowToEditFee}
                    />
                  </Form.Item>
                </Col>

                {isAllowExtraPayment && (
                  <Row>
                    <Col
                      xl={isShowExtraAmount ? 12 : 24}
                      md={isShowExtraAmount ? 12 : 24}
                      xs={24}
                      sm={24}
                      className="t-p-0"
                    >
                      <Form.Item
                        name="showExtraAmount"
                        label="Pay Extra Amount"
                        colon={false}
                        rules={[paymentValidation]}
                      >
                        <Switch
                          checked={donateDataForm.getFieldValue(
                            'showExtraAmount',
                          )}
                        />
                      </Form.Item>
                    </Col>
                    {isShowExtraAmount && (
                      <Col
                        xl={isShowExtraAmount ? 12 : 24}
                        md={isShowExtraAmount ? 12 : 24}
                        xs={24}
                        sm={24}
                        className="t-p-0"
                      >
                        <Form.Item
                          name="extraAskedAmount"
                          label="Extra Payment Amount *"
                          colon={false}
                          rules={[paymentValidation]}
                        >
                          <AmountInput
                            placeholder="Extra Payment"
                            onChange={(value) =>
                              donateDataForm.setFieldsValue({
                                extraAskedAmount: value,
                              })
                            }
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                )}

                {isRecurringPaymentAllowed && (
                  <Row>
                    <Col
                      xl={isRecurring ? 12 : 24}
                      md={isRecurring ? 12 : 24}
                      xs={24}
                      sm={24}
                      className="t-p-0"
                    >
                      {/* <div className="horizontal-override"> */}
                      <Form.Item
                        name="isRecurringPayment"
                        label="Schedule Recurring Payment"
                        colon={false}
                        rules={[paymentValidation]}
                        // labelCol={{ span: 24 }}
                        // wrapperCol={{ span: 24 }}
                      >
                        <Switch
                          checked={donateDataForm.getFieldValue(
                            'isRecurringPayment',
                          )}
                        />
                      </Form.Item>
                      {/* </div> */}
                    </Col>
                    {isRecurring && (
                      <Col
                        xl={isRecurring ? 12 : 24}
                        md={isRecurring ? 12 : 24}
                        xs={24}
                        sm={24}
                        className="t-p-0"
                      >
                        <Form.Item
                          name="numberOfRecurring"
                          label="Number of Months"
                          colon={false}
                          rules={[paymentValidation]}
                        >
                          <Input
                            placeholder="Number of Months"
                            // disabled={isSplit}
                            inputMode="numeric"
                            onChange={(e) =>
                              donateDataForm.setFieldsValue({
                                numberOfRecurring: e.target.value.replace(
                                  /\D/g,
                                  '',
                                ),
                              })
                            }
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                )}

                {isSplitPaymentAllowed && (
                  <Row>
                    <Col
                      xl={isSplit ? 12 : 24}
                      md={isSplit ? 12 : 24}
                      xs={24}
                      sm={24}
                      className="t-p-0"
                    >
                      {/* <div className="horizontal-override"> */}
                      <Form.Item
                        name="isSplitPayment"
                        label="Schedule Split Payment"
                        colon={false}
                        rules={[paymentValidation]}
                        // labelCol={{ span: 24 }}
                        // wrapperCol={{ span: 24 }}
                      >
                        <Switch
                          checked={donateDataForm.getFieldValue(
                            'isSplitPayment',
                          )}
                        />
                      </Form.Item>
                      {/* </div> */}
                    </Col>
                    {isSplit && (
                      <Col
                        xl={isSplit ? 12 : 24}
                        md={isSplit ? 12 : 24}
                        xs={24}
                        sm={24}
                        className="t-p-0"
                      >
                        <Form.Item
                          name="numberOfSplit"
                          label="Number of Months"
                          colon={false}
                          rules={[paymentValidation]}
                        >
                          <Input
                            placeholder="Number of Months"
                            // disabled={isSplit}
                            inputMode="numeric"
                            onChange={(e) =>
                              donateDataForm.setFieldsValue({
                                numberOfSplit: e.target.value.replace(
                                  /\D/g,
                                  '',
                                ),
                              })
                            }
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                )}

                <Col
                  xl={24}
                  md={24}
                  xs={24}
                  sm={24}
                  className={`t-p-0 ${
                    isProcessingFeeMandotory ? 't-hidden' : 't-block'
                  }`}
                >
                  {/* <div className="horizontal-override"> */}
                  <Form.Item
                    name="includeProcessingFees"
                    label="Include Processing Fees"
                    colon={false}
                    rules={[paymentValidation]}
                    // labelCol={{ span: 24 }}
                    // wrapperCol={{ span: 24 }}
                  >
                    <Switch
                      checked={donateDataForm.getFieldValue(
                        'includeProcessingFees',
                      )}
                    />
                  </Form.Item>
                  {/* </div> */}
                </Col>

                {isProgramOrService && (
                  <Col xl={24} md={24} xs={24} sm={24} className="t-p-0">
                    <Typography.Title level={5} className="text-right">
                      {`Estimated Total: ${formatAmount(
                        getTotal()?.amountWithExtraAmountPlusProcessingFees,
                      )}`}
                    </Typography.Title>
                    {isShowBreakdown && (
                      <div className="text-right">
                        <Typography.Text type="secondary">
                          {getBreakdownText()}
                        </Typography.Text>
                      </div>
                    )}
                    {isSplit &&
                      Number(numberOfSplitValue) > 0 &&
                      Number(
                        getTotal()?.amountWithExtraAmountPlusProcessingFees,
                      ) > 0 && (
                        <Typography.Paragraph className="t-text-justify t-mt-4">
                          Your card will be charged{' '}
                          {formatAmount(getSplitedAmount())} today. This charge
                          will be processed on a monthly basis until the balance
                          is paid in full.
                        </Typography.Paragraph>
                      )}
                  </Col>
                )}

                {isPaidOrRoom && isStripeAvailable && (
                  <Col xl={24} md={24} xs={24} sm={24} className="t-mb-2 t-p-0">
                    {paymentMethodValue === 0 && (
                      <>
                        <h1 className="t-text-sm t-my-2 t-font-semibold">
                          Enter Card Number
                        </h1>
                        <div className="t-p-2 t-rounded-md t-border t-border-grey-900'">
                          <CardElement
                            options={{
                              style: {
                                base: {
                                  fontSize: '15px',
                                  fontSmoothing: 'antialiased',
                                },
                              },
                            }}
                          />
                        </div>
                      </>
                    )}
                    {paymentMethodValue === 1 && (
                      <BankDebit
                        banks={banks}
                        setBanks={setBanks}
                        form={donateDataForm}
                        stripeAccount={getStripeId()}
                      />
                    )}
                  </Col>
                )}

                {!isPaidOrRoom ||
                  (isStripeAvailableAndNotPaypalSelected && (
                    <>
                      {isCreditCardOrNotNewBankSelected && (
                        <Col span={24} className="text-right t-p-0 t-mb-2">
                          <Button
                            type="primary"
                            className="t-w-full"
                            htmlType="submit"
                            loading={loading}
                            disabled={
                              !stripe ||
                              !elements ||
                              isBankAndBankPresentButNotSelected
                            }
                          >
                            Register
                          </Button>
                        </Col>
                      )}
                    </>
                  ))}
                {isPaidOrRoom &&
                  isPaypalAvailable &&
                  paymentMethodValue === 2 && (
                    <Paypal
                      form={donateDataForm}
                      subscriberPaymentInfo={subscriberPaymentInfo}
                      paypalId={getPaypalId()}
                      onSubmit={onPaypalSuccess}
                      donating={loading}
                    />
                  )}

                {isStripeAvailable || isPaypalAvailable ? (
                  <TermsAndConditionsModal />
                ) : (
                  <PaymentNotConfigured />
                )}
              </>
            ) : (
              <>
                {isVolunteer && (
                  <Col xl={24} md={24} xs={24} sm={24}>
                    <Form.Item
                      name="comment"
                      label="Comment"
                      className="t-mb-[12px] md:t-mb-[24px]"
                    >
                      <TextArea
                        placeholder="write a comment"
                        autoSize={{ minRows: 3, maxRows: 5 }}
                      />
                    </Form.Item>
                  </Col>
                )}
                <Col span={24} className="text-right t-p-0 t-mb-2">
                  <Button
                    type="primary"
                    className="t-w-full"
                    htmlType="submit"
                    loading={loading}
                  >
                    {isZakaatService ? 'Submit' : 'Register'}
                  </Button>
                </Col>
              </>
            )}
          </Card>
        </Col>
      </Row>
    </Form>
  )
}

function StripeProvider({
  item,
  itemType,
  subscriberPaymentInfo,
  setIsRegister,
}) {
  const [donateDataForm] = Form.useForm()
  const [firstLoad, setFirstLoad] = useState(true)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [stripePromise, setStripePromise] = useState(null)

  const isPaymentCategoriesPresent = isPaymentCategoriesAdded(
    item?.PaymentCategoryAdded,
    item?.PaymentCategories,
  )

  const paymentCategories = item?.paymentCategories

  const getStripeAccountId = () => {
    let stripeAccount = null
    if (selectedCategory !== null) {
      stripeAccount = paymentCategories[selectedCategory]?.StripeId
    } else if (firstLoad && isPaymentCategoriesPresent) {
      stripeAccount = paymentCategories[0]?.StripeId
    } else {
      stripeAccount = subscriberPaymentInfo?.stripe?.stripeId
    }
    return stripeAccount
  }

  useEffect(() => {
    const stripeAccount = getStripeAccountId()
    const newStripePromise = loadStripe(awsconfig.stripe_publish_key, {
      stripeAccount,
    })
    setStripePromise(newStripePromise)
    if (firstLoad) setFirstLoad(false)
    // eslint-disable-next-line
  }, [selectedCategory])

  return (
    <Elements stripe={stripePromise}>
      <WrappedRegisterationForm
        donateDataForm={donateDataForm}
        item={item}
        itemType={itemType}
        setIsRegister={setIsRegister}
        subscriberPaymentInfo={subscriberPaymentInfo}
        isPaymentCategoriesPresent={isPaymentCategoriesPresent}
        paymentCategories={paymentCategories}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
    </Elements>
  )
}
const RegisterationForm = StripeProvider
export { RegisterationForm }
