/* eslint-disable */
import React from 'react'
import { Col, Row, Card, Tag, Typography } from 'antd'
import { Icon } from 'components'
import { Availability } from './Availability'
import { awsconfig } from 'configs'

const { Title, Paragraph } = Typography

const RoomDetail = ({ room }) => {
  return (
    <Card>
      <Row gutter={[24, 0]}>
        <Col span={24} className="t-flex t-justify-between">
          {room?.Image?.fileName ? (
            <img
              src={awsconfig.bucketUrl + room?.Image?.fileName}
              className="t-h-20 md:t-h-32 t-w-48 md:t-w-96 t-rounded-lg shadow-3 t-object-cover"
              alt="card-2"
            />
          ) : (
            <Icon
              src="sample1"
              className="t-w-40 md:t-w-96 t-h-24 t-object-cover t-rounded-lg"
            />
          )}
          <Availability available={room?.Available} />
        </Col>
        <Col
          span={24}
          className="t-flex t-justify-between t-mt-4 t-items-center"
        >
          <Title
            level={4}
            className="t-text-secondary-100 t-font-bold"
          >{`${room?.Name} (${room?.Breadth}×${room?.Width}) ft`}</Title>
          <Title
            level={5}
            className="t-text-secondary-100"
          >{`Person capacity is  ${room?.Capacity}`}</Title>
        </Col>
        <Col span={24}>
          <Paragraph>{room?.Instructions}</Paragraph>
        </Col>
        <Col span={24} className="t-flex t-justify-end">
          <Tag color="blue">{`$${room?.PerHourRate} Per Hour`}</Tag>
        </Col>
      </Row>
    </Card>
  )
}

export { RoomDetail }
