import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Row, Skeleton, Avatar, Tooltip, Tag } from 'antd'
import { AreaChartOutlined, HomeOutlined } from '@ant-design/icons'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { formatAmount, searchRentals } from 'utils'
import { useHistory } from 'react-router-dom'
import { config } from 'configs'
import Api from 'api'
import { getNearByRooms } from 'store/roomSlice/roomActions'
import { useRoomSelector } from 'store/roomSlice/roomReducer'
import { useDispatch } from 'react-redux'
import { useRoles } from 'hooks'

const RentalList = ({ searchText, rentalPriority }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { location, organization } = useAuthSelector()
  const [renLoading, setLoading] = useState(true)
  const [orgRoom, setOrgRoom] = useState([])
  const { isSubscriber, isDonor } = useRoles()
  const { loading, rooms } = useRoomSelector()
  const positionData = {
    lat: location?.lat,
    lon: location?.lng,
    zipCode: location?.zipcode,
  }

  const orgRooms = () => {
    setLoading(true)
    Api.get(`/room/organizationRooms?id=${organization?.id}`)
      .then((res) => {
        if (res.data) {
          setOrgRoom(res?.data?.filter((x) => x.Available === true))
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        setLoading(false)
      })
  }

  const allRooms = () => {
    setLoading(true)
    Api.get(`/component/room/all`)
      .then((res) => {
        if (res.results) {
          setOrgRoom(res?.results?.filter((x) => x.Available === true))
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        setLoading(false)
      })
  }

  useEffect(() => {
    const zipCode = location?.zipcode
    if (zipCode) {
      dispatch(getNearByRooms(positionData))
    }
    // eslint-disable-next-line
  }, [location, dispatch])

  useEffect(() => {
    if (isSubscriber) {
      orgRooms()
    } else if (isDonor) {
      allRooms()
    } else {
      orgRooms()
    }
    // eslint-disable-next-line
  }, [organization?.id, isSubscriber, isDonor])

  const onClickCard = (roomId) => {
    history.push(`/rentals/${roomId}`)
  }

  const updatedRooms = searchRentals(
    !rentalPriority ? orgRoom : rooms.filter((x) => x.Available === true),
    searchText,
  )

  return (
    <Skeleton loading={renLoading || loading} active paragraph={{ rows: 18 }}>
      <Row gutter={[24, 24]}>
        {Array.isArray(updatedRooms) && updatedRooms?.length === 0 && (
          <div className="t-h-96 t-flex t-justify-center t-items-center t-w-full">
            <div className="t-flex t-flex-col t-items-center t-space-y-2">
              <AreaChartOutlined
                style={{ fontSize: '2rem' }}
                className="t-text-secondary-100"
              />
              <p>No records found</p>
            </div>
          </div>
        )}
        {Array.isArray(updatedRooms) &&
          updatedRooms?.map((item, index) => (
            <Col span={24} lg={8} className="mb-24" key={index}>
              <Card
                bodyStyle={{ paddingTop: '14px' }}
                bordered={false}
                onClick={() => onClickCard(item?.Id)}
                className="card-project-2 header-solid t-cursor-pointer"
                title={[
                  <Row gutter={[24, 24]} key={0}>
                    <Col>
                      <Avatar
                        src={<HomeOutlined />}
                        style={{
                          width: '25px',
                          height: '25px',
                          lineHeight: '74px',
                          borderRadius: '8px',
                          padding: '16px',
                          backgroundColor: config.colors.primary,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      />
                    </Col>
                    <Col>
                      <h6 className="font-semibold t-w-64 t-truncate">
                        {`${item?.Name} (${item?.Breadth}×${item?.Width}) ft`}
                      </h6>
                      <div className="t-flex t-space-x-2">
                        <div className="card-tag t-font-thin t-text-secondary-100 t-text-sm">
                          <Tooltip title="Location Type">
                            {item?.OrganizationName}
                          </Tooltip>
                        </div>
                      </div>
                    </Col>
                  </Row>,
                ]}
              >
                <p className="t-truncate">{item?.Instructions}</p>
                <hr />
                <div className="t-flex t-flex-wrap t-justify-between t-items-center t-mt-2 t-space-y-2">
                  <Tag color="green">{`Room capacity is ${item?.Capacity}`}</Tag>
                  <Button
                    type="primary"
                    size="small"
                    className="t-rounded-full"
                  >
                    {`${formatAmount(item?.PerHourRate)}/hr`}
                  </Button>
                </div>
              </Card>
            </Col>
          ))}
      </Row>
    </Skeleton>
  )
}

export { RentalList }
