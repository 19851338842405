/* eslint-disable */
import React from 'react'
import { Row } from 'antd'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { config } from 'configs'
import moment from 'moment'
import { useRoomSelector } from 'store/roomSlice/roomReducer'

const localizer = momentLocalizer(moment)

// const bookings = [
//   {
//     id: 0,
//     title: 'All Day Event very long title',
//     allDay: true,
//     start: new Date(2022, 12, 0),
//     end: new Date(2022, 12, 1),
//   },
//   {
//     id: 1,
//     title: 'Long Event',
//     start: new Date(2022, 12, 7),
//     end: new Date(2022, 12, 10),
//   },

//   {
//     id: 2,
//     title: 'DTS STARTS',
//     start: new Date(2016, 12, 13, 0, 0, 0),
//     end: new Date(2016, 12, 20, 0, 0, 0),
//   },

//   {
//     id: 3,
//     title: 'DTS ENDS',
//     start: new Date(2016, 12, 6, 0, 0, 0),
//     end: new Date(2016, 12, 13, 0, 0, 0),
//   },

//   {
//     id: 4,
//     title: 'Some Event',
//     start: new Date(2022, 12, 9, 0, 0, 0),
//     end: new Date(2022, 12, 10, 0, 0, 0),
//   },
//   {
//     id: 5,
//     title: 'Conference',
//     start: new Date(2022, 12, 11),
//     end: new Date(2022, 12, 13),
//     desc: 'Big conference for important people',
//   },
//   {
//     id: 6,
//     title: 'Meeting',
//     start: new Date(2022, 12, 12, 10, 30, 0, 0),
//     end: new Date(2022, 12, 12, 12, 30, 0, 0),
//     desc: 'Pre-meeting meeting, to prepare for the meeting',
//   },
//   {
//     id: 7,
//     title: 'Lunch',
//     start: new Date(2022, 12, 12, 12, 0, 0, 0),
//     end: new Date(2022, 12, 12, 13, 0, 0, 0),
//     desc: 'Power lunch',
//   },
//   {
//     id: 8,
//     title: 'Meeting',
//     start: new Date(2022, 12, 12, 14, 0, 0, 0),
//     end: new Date(2022, 12, 12, 15, 0, 0, 0),
//   },
//   {
//     id: 9,
//     title: 'Happy Hour',
//     start: new Date(2022, 12, 12, 17, 0, 0, 0),
//     end: new Date(2022, 12, 12, 17, 30, 0, 0),
//     desc: 'Most important meal of the day',
//   },
//   {
//     id: 10,
//     title: 'Dinner',
//     start: new Date(2022, 12, 12, 20, 0, 0, 0),
//     end: new Date(2022, 3, 12, 21, 0, 0, 0),
//   },
//   {
//     id: 11,
//     title: 'Planning Meeting with Paige',
//     start: new Date(2022, 12, 13, 8, 0, 0),
//     end: new Date(2022, 12, 13, 10, 30, 0),
//   },
//   {
//     id: 11.1,
//     title: 'Inconvenient Conference Call',
//     start: new Date(2022, 12, 13, 9, 30, 0),
//     end: new Date(2022, 12, 13, 12, 0, 0),
//   },
//   {
//     id: 11.2,
//     title: "Project Kickoff - Lou's Shoes",
//     start: new Date(2022, 12, 13, 11, 30, 0),
//     end: new Date(2022, 12, 13, 14, 0, 0),
//   },
//   {
//     id: 11.3,
//     title: 'Quote Follow-up - Tea by Tina',
//     start: new Date(2022, 12, 13, 15, 30, 0),
//     end: new Date(2022, 12, 13, 16, 0, 0),
//   },
//   {
//     id: 12,
//     title: 'Late Night Event',
//     start: new Date(2022, 12, 17, 19, 30, 0),
//     end: new Date(2022, 12, 18, 2, 0, 0),
//   },
//   {
//     id: 12.5,
//     title: 'Late Same Night Event',
//     start: new Date(2022, 12, 17, 19, 30, 0),
//     end: new Date(2022, 12, 17, 23, 30, 0),
//   },
//   {
//     id: 13,
//     title: 'Multi-day Event',
//     start: new Date(2022, 12, 20, 19, 30, 0),
//     end: new Date(2022, 12, 22, 2, 0, 0),
//   },
//   {
//     id: 16,
//     title: 'Video Record',
//     start: new Date(2022, 12, 14, 15, 30, 0),
//     end: new Date(2022, 12, 14, 19, 0, 0),
//   },
//   {
//     id: 17,
//     title: 'Dutch Song Producing',
//     start: new Date(2022, 12, 14, 16, 30, 0),
//     end: new Date(2022, 12, 14, 20, 0, 0),
//   },
//   {
//     id: 18,
//     title: 'Itaewon Halloween Meeting',
//     start: new Date(2022, 12, 14, 16, 30, 0),
//     end: new Date(2022, 12, 14, 17, 30, 0),
//   },
//   {
//     id: 19,
//     title: 'Online Coding Test',
//     start: new Date(2022, 12, 14, 17, 30, 0),
//     end: new Date(2022, 12, 14, 20, 30, 0),
//   },
//   {
//     id: 20,
//     title: 'An overlapped Event',
//     start: new Date(2022, 12, 14, 17, 0, 0),
//     end: new Date(2022, 12, 14, 18, 30, 0),
//   },
//   {
//     id: 21,
//     title: 'Phone Interview',
//     start: new Date(2022, 12, 14, 17, 0, 0),
//     end: new Date(2022, 12, 14, 18, 30, 0),
//   },
//   {
//     id: 22,
//     title: 'Cooking Class',
//     start: new Date(2022, 12, 14, 17, 30, 0),
//     end: new Date(2022, 12, 14, 19, 0, 0),
//   },
//   {
//     id: 23,
//     title: 'Go to the gym',
//     start: new Date(2022, 12, 14, 18, 30, 0),
//     end: new Date(2022, 12, 14, 20, 0, 0),
//   },
// ]

const createEvents = (data) => {
  const myevents = []
  for (let i = 0; i < data.length; i++) {
    const reservationDetail = data[i]
    let scheduleDate = moment(reservationDetail?.Date)
    if (reservationDetail?.IsRecurring) {
      for (let k = 0; k < reservationDetail?.RecurringDates.length; k++) {
        scheduleDate = moment(reservationDetail?.RecurringDates[k])

        for (let j = 0; j < reservationDetail?.Durations?.length; j++) {
          const durationDetail = reservationDetail?.Durations[j]
          const fromParts = durationDetail.from.split(':')
          let fromHour = parseInt(fromParts[0])
          const fromMinute = parseInt(fromParts[1])
          const fromAMPM = durationDetail.from.toLowerCase().includes('am')
            ? 'am'
            : 'pm'

          const toParts = durationDetail.to.split(':')
          let toHour = parseInt(toParts[0])
          const toMinute = parseInt(toParts[1])
          const toAMPM = durationDetail.to.toLowerCase().includes('am')
            ? 'am'
            : 'pm'

          if (fromHour === 12 && fromAMPM === 'am') {
            fromHour = 0
          } else if (fromHour !== 12 && fromAMPM === 'pm') {
            fromHour += 12
          }

          if (toHour === 12 && toAMPM === 'am') {
            toHour = 0
          } else if (toHour !== 12 && toAMPM === 'pm') {
            toHour += 12
          }

          const eventDetail = {
            id: new Date().getTime(),
            title: `${reservationDetail?.CreatorName} booked ${
              reservationDetail?.RoomName
            } (${reservationDetail?.PaymentStatus || 'Not Paid'})`,
            allDay: false,
            start: new Date(
              scheduleDate.year(),
              scheduleDate.month(),
              scheduleDate.date(),
              fromHour,
              fromMinute,
              0,
            ),
            end: new Date(
              scheduleDate.year(),
              scheduleDate.month(),
              scheduleDate.date(),
              toHour,
              toMinute,
              0,
            ),
          }
          myevents.push(eventDetail)
        }
      }
    } else {
      for (let j = 0; j < reservationDetail?.Durations?.length; j++) {
        const durationDetail = reservationDetail?.Durations[j]
        const fromParts = durationDetail.from.split(':')
        let fromHour = parseInt(fromParts[0])
        const fromMinute = parseInt(fromParts[1])
        const fromAMPM = durationDetail.from.toLowerCase().includes('am')
          ? 'am'
          : 'pm'

        const toParts = durationDetail.to.split(':')
        let toHour = parseInt(toParts[0])
        const toMinute = parseInt(toParts[1])
        const toAMPM = durationDetail.to.toLowerCase().includes('am')
          ? 'am'
          : 'pm'

        // Adjust 'from' hour
        if (fromHour === 12 && fromAMPM === 'am') {
          fromHour = 0
        } else if (fromHour !== 12 && fromAMPM === 'pm') {
          fromHour += 12
        }

        // Adjust 'to' hour
        if (toHour === 12 && toAMPM === 'am') {
          toHour = 0
        } else if (toHour !== 12 && toAMPM === 'pm') {
          toHour += 12
        }

        const eventDetail = {
          id: new Date().getTime(),
          title: `${reservationDetail?.CreatorName} booked ${
            reservationDetail?.RoomName
          } (${reservationDetail?.PaymentStatus || 'Not Paid'})`,
          allDay: false,
          start: new Date(
            scheduleDate.year(),
            scheduleDate.month(),
            scheduleDate.date(),
            fromHour,
            fromMinute,
            0,
          ),
          end: new Date(
            scheduleDate.year(),
            scheduleDate.month(),
            scheduleDate.date(),
            toHour,
            toMinute,
            0,
          ),
        }
        myevents.push(eventDetail)
      }
    }
  }
  return myevents
}

const ReservationCalendar = () => {
  const { reservations } = useRoomSelector()
  const acceptedReservations = reservations.filter(
    (item) =>
      item.AcceptanceStatus === 'Accepted' &&
      item.RefundRequestStatus !== 'Approved',
  )
  const formattedBookings = createEvents(acceptedReservations)
  return (
    <Row gutter={[24, 0]}>
      <Calendar
        localizer={localizer}
        events={formattedBookings}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 600 }}
        className="t-w-full t-p-4"
        eventPropGetter={() => {
          const newStyle = {
            backgroundColor: config.colors.primary,
            color: 'white',
            borderRadius: '6px',
            border: 'none',
          }
          return {
            className: '',
            style: newStyle,
          }
        }}
      />
    </Row>
  )
}

export { ReservationCalendar }
