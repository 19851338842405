import React, { useEffect, useState } from 'react'
import { Card, Dropdown, Input, Menu, Tooltip } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import {
  BsBookmarkHeart,
  BsBookmarkHeartFill,
  BsFolderX,
  BsFolderFill,
} from 'react-icons/bs'
import { MdDashboard } from 'react-icons/md'
import { RiDashboardLine } from 'react-icons/ri'
import {
  HiPresentationChartBar,
  HiOutlinePresentationChartBar,
} from 'react-icons/hi'
import { BiSort } from 'react-icons/bi'
import { useRoles } from 'hooks'
import { config } from 'configs'
import {
  ComponentViewHeader,
  getComponentCurrentView,
  getComponentInitialValues,
  getComponentOptions,
  getComponentSubHeading,
} from './components'

const ComponentView = ({
  heading,
  optionList,
  createButtonLink,
  setSearchText,
  sortFilterData,
  sortKey,
  setSortKey,
  createTapButtonLink,
  type,
}) => {
  const optionKeys = Object.keys(optionList)
  const initialValue = getComponentInitialValues(optionKeys)
  const { isAll, isMy, isInactive, isFavorite, isPresent } =
    getComponentOptions(optionKeys)
  const { isSubscriber, isPresenter } = useRoles()
  const [isViews, setViews] = useState(initialValue)
  const changeView = (key, value) => setViews({ ...initialValue, [key]: value })
  const currentView = getComponentCurrentView(isViews)

  const sortFilterMenu = (
    <Menu>
      {sortFilterData
        .filter((item) => item?.showOn?.includes(currentView))
        .map((item, index) => (
          <Menu.Item
            key={index}
            onClick={() => setSortKey(item.value)}
            style={
              sortKey === item.value && {
                backgroundColor: config.colors.primary,
                color: 'white',
              }
            }
          >
            {item.label}
          </Menu.Item>
        ))}
    </Menu>
  )

  useEffect(() => {
    if (isAll && isSubscriber) changeView('my', true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const listTobeRendered =
    optionKeys.find((key) => isViews[key] === true) ?? 'all'
  return (
    <Card
      bordered={false}
      className="header-solid mb-24"
      title={
        <div className="t-flex t-flex-wrap t-justify-between">
          <ComponentViewHeader
            heading={heading}
            subHeading={getComponentSubHeading(isViews, heading)}
            buttonLink={createButtonLink}
            createTapButtonLink={createTapButtonLink}
            type={type}
          />
          <div className="t-flex t-justify-end t-items-center t-space-x-2 t-mt-4 sm:t-mt-0">
            <div>
              <>
                {sortFilterMenu?.props?.children?.length > 0 && (
                  <Dropdown
                    overlay={sortFilterMenu}
                    placement="bottomLeft"
                    arrow
                  >
                    <Tooltip title="Sort Filter">
                      <BiSort
                        fontSize="1.7rem"
                        className="t-text-secondary-100 t-cursor-pointer"
                      />
                    </Tooltip>
                  </Dropdown>
                )}
              </>
            </div>
            <div className="header-control t-mt-0">
              <Input
                id="searchEvent"
                className="header-search t-w-36"
                placeholder="Search ..."
                prefix={<SearchOutlined />}
                onChange={(e) => {
                  setSearchText(e.target.value)
                }}
              />
            </div>

            {/* If present is in options */}
            {isPresent && isPresenter && (
              <>
                {isViews.presenter ? (
                  <Tooltip title={`See All ${heading}`}>
                    <HiPresentationChartBar
                      fontSize="1.7rem"
                      id="events-presenter-clicked"
                      className="t-text-secondary-100 t-cursor-pointer"
                      onClick={() => changeView('presenter', false)}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title={`Presenter ${heading}`}>
                    <HiOutlinePresentationChartBar
                      fontSize="1.7rem"
                      id="events-presenter-not-clicked"
                      className="t-text-secondary-100 t-cursor-pointer"
                      onClick={() => changeView('presenter', true)}
                    />
                  </Tooltip>
                )}
              </>
            )}

            {/* If my is in options */}
            {isMy && isSubscriber && (
              <>
                {isViews.my ? (
                  <Tooltip title={`See All ${heading}`}>
                    <MdDashboard
                      fontSize="1.7rem"
                      id="events-my-clicked"
                      className="t-text-secondary-100 t-cursor-pointer"
                      onClick={() => changeView('my', false)}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title={`See My ${heading}`}>
                    <RiDashboardLine
                      fontSize="1.7rem"
                      id="events-my-not-clicked"
                      className="t-text-secondary-100 t-cursor-pointer"
                      onClick={() => changeView('my', true)}
                    />
                  </Tooltip>
                )}
              </>
            )}

            {/* If inactive is in options */}
            {isInactive && isSubscriber && (
              <>
                {isViews.inactive ? (
                  <Tooltip title={`See All ${heading}`}>
                    <BsFolderFill
                      fontSize="1.7rem"
                      id="events-inactive-clicked"
                      className="t-text-secondary-100 t-cursor-pointer"
                      onClick={() => changeView('inactive', false)}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title={`See Inactive ${heading}`}>
                    <BsFolderX
                      fontSize="1.7rem"
                      id="events-inactive-not-clicked"
                      className="t-text-secondary-100 t-cursor-pointer"
                      onClick={() => changeView('inactive', true)}
                    />
                  </Tooltip>
                )}
              </>
            )}

            {/* If favorite is in options */}
            {isFavorite && isSubscriber && (
              <>
                {isViews.favorite ? (
                  <Tooltip title={`See All ${heading}`}>
                    <BsBookmarkHeartFill
                      fontSize="1.7rem"
                      id="events-favourite-clicked"
                      className="t-text-secondary-100 t-cursor-pointer"
                      onClick={() => changeView('favorite', false)}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title={`See Favorite ${heading}`}>
                    <BsBookmarkHeart
                      fontSize="1.7rem"
                      id="events-favourite-not-clicked"
                      className="t-text-secondary-100 t-cursor-pointer"
                      onClick={() => changeView('favorite', true)}
                    />
                  </Tooltip>
                )}
              </>
            )}
          </div>
        </div>
      }
    >
      <div style={{ minHeight: 'calc(100vh - 205px)' }}>
        {optionList[listTobeRendered]}
      </div>
    </Card>
  )
}

export { ComponentView }
