import React, { useState } from 'react'
import { Card, Dropdown, Input, Menu, Tooltip } from 'antd'
import { BsBookmarkHeart, BsBookmarkHeartFill } from 'react-icons/bs'
import { RiDashboardLine } from 'react-icons/ri'
import { SearchOutlined } from '@ant-design/icons'
import { BiSort } from 'react-icons/bi'
import { config } from 'configs'
import { BusinessList, FavouriteList } from './components/BusinessLists'

const initialValue = {
  fav: false,
}

const DonorOrganizationsView = () => {
  const [isViews, setViews] = useState(initialValue)

  const [searchText, setSearchText] = useState(null)
  const [sortKey, setSortKey] = useState('createdDate')

  const changeView = (key, value) => setViews({ ...initialValue, [key]: value })

  const menu = (
    <Menu>
      <Menu.Item
        key={1}
        onClick={() => setSortKey('createdDate')}
        style={
          sortKey === 'createdDate' && {
            backgroundColor: config.colors.primary,
            color: 'white',
          }
        }
      >
        By Created Date
      </Menu.Item>
      <Menu.Item
        key={2}
        onClick={() => setSortKey('favDate')}
        style={
          sortKey === 'favDate' && {
            backgroundColor: config.colors.primary,
            color: 'white',
          }
        }
      >
        By Favorite Date
      </Menu.Item>
    </Menu>
  )

  return (
    <Card
      bordered={false}
      className="header-solid mb-24"
      title={
        <div className="t-flex t-flex-wrap t-justify-between">
          <div>
            <h6 className="font-semibold  t-text-2xl">Businesses</h6>
            <p>
              <p>
                {isViews.fav && 'Your favorite Businesses'}
                {!isViews.fav && 'Businesses found near you'}
              </p>
            </p>
          </div>
          <div className="t-flex t-justify-end t-items-center t-space-x-2 t-mt-4 sm:t-mt-0">
            {isViews.fav && (
              <div>
                <Dropdown overlay={menu} placement="bottomLeft" arrow>
                  <Tooltip title="Sort Filter">
                    <BiSort
                      fontSize="1.7rem"
                      className="t-text-secondary-100 t-cursor-pointer"
                    />
                  </Tooltip>
                </Dropdown>
              </div>
            )}
            <div className="header-control t-mt-0">
              <Input
                className="header-search t-w-36"
                placeholder="Search ..."
                prefix={<SearchOutlined />}
                onChange={(e) => {
                  setSearchText(e.target.value)
                }}
              />
            </div>
            <Tooltip title="See all Businesses">
              <RiDashboardLine
                fontSize="1.7rem"
                id="org-list"
                className="t-text-secondary-100 t-cursor-pointer"
                onClick={() => changeView('fav', false)}
              />
            </Tooltip>
            {isViews.fav ? (
              <Tooltip title="See all Businesses">
                <BsBookmarkHeartFill
                  fontSize="1.7rem"
                  id="org-favourite-clicked"
                  className="t-text-secondary-100 t-cursor-pointer"
                  onClick={() => changeView('fav', false)}
                />
              </Tooltip>
            ) : (
              <Tooltip title="See Favorite Businesses">
                <BsBookmarkHeart
                  fontSize="1.7rem"
                  id="org-favourite-not-clicked"
                  className="t-text-secondary-100 t-cursor-pointer"
                  onClick={() => changeView('fav', true)}
                />
              </Tooltip>
            )}
          </div>
        </div>
      }
    >
      <>
        {!isViews.fav && <BusinessList searchText={searchText} />}
        {isViews.fav && (
          <FavouriteList searchText={searchText} sortKey={sortKey} />
        )}
      </>
    </Card>
  )
}

export { DonorOrganizationsView }
